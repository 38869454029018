const STG_API_URL = 'https://ltda-stage.lely.cloud/api';
const PRD_API_URL = 'https://ltda.lely.cloud/api';
const LOCALHOST_API_URL = 'https://localhost:44334/api';
const isDeployedToPRD = (apiUrl) => apiUrl === PRD_API_URL;
const isDeployedToSTG = (apiUrl) => apiUrl === STG_API_URL;
const isDeploymentOperation = (apiUrl) => isDeployedToPRD(apiUrl);

const PRD_VERSION_FEATURE_FLAG_NAME = 'latest_application_version_prd';
const STG_VERSION_FEATURE_FLAG_NAME = 'latest_application_version_stg';
const PRD_FEATURE_FLAG_URL = 'https://ltda.lely.cloud/api/featureflags/';
const STG_FEATURE_FLAG_URL = 'https://ltda-stage.lely.cloud/api/featureflags/';

const getVersionFeatureFlagName = (apiUrl) => {
  let versionFeatureFlagName = '';
  if (isDeployedToPRD(apiUrl)) {
    versionFeatureFlagName = PRD_VERSION_FEATURE_FLAG_NAME;
  } else if (isDeployedToSTG(apiUrl)) {
    versionFeatureFlagName = STG_VERSION_FEATURE_FLAG_NAME;
  }

  return versionFeatureFlagName;
};

const getFeatureFlagURL = (apiUrl) => {
  let featureFlagURL = '';
  if (isDeployedToPRD(apiUrl)) {
    featureFlagURL = PRD_FEATURE_FLAG_URL;
  } else if (isDeployedToSTG(apiUrl)) {
    featureFlagURL = STG_FEATURE_FLAG_URL;
  }
  return featureFlagURL;
};

module.exports = {
  STG_API_URL,
  PRD_API_URL,
  LOCALHOST_API_URL,
  isDeployedToPRD,
  isDeployedToSTG,
  isDeploymentOperation,
  PRD_VERSION_FEATURE_FLAG_NAME,
  STG_VERSION_FEATURE_FLAG_NAME,
  getVersionFeatureFlagName,
  getFeatureFlagURL,
};
