import {
  PayloadAction,
  createAction,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from './rootReducer';

export type FeatureFlagType = {
  name: string;
  description: string;
  active: boolean;
};

type FeatureFlagsInitialState = {
  availableFeatureFlags: Record<string, FeatureFlagType>;
};

export const initialState: FeatureFlagsInitialState = {
  availableFeatureFlags: {},
};

export const featureFlagSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    getFeatureFlagSuccess: (state, action: PayloadAction<FeatureFlagType>) => {
      const { name } = action.payload;
      state.availableFeatureFlags[name] = action.payload;
    },
  },
});

export const { getFeatureFlagSuccess } = featureFlagSlice.actions;

export const checkFeatureFlag = createAction<{
  flagNames: string[];
  currentUser: string | undefined;
}>('featureFlags/checkFeatureFlag');

export const featureFlagsSelectors = {
  isFlagActive: (state: RootState, flag: string) => {
    return state.featureFlag.availableFeatureFlags[flag]?.active ?? false;
  },
  selectAreAllFeatureFlagsFetched: (state: RootState, flags: string[]) => {
    return (
      Object.keys(state.featureFlag.availableFeatureFlags).length ===
      flags.length
    );
  },
};

export const selectAreAllFeatureFlagsFetched = createSelector(
  (state: RootState) => state.featureFlag.availableFeatureFlags,
  (_: RootState, flags: string[]) => flags,
  (availableFlags, flags) => {
    return Object.keys(availableFlags).length === flags.length;
  },
);

export default featureFlagSlice.reducer;
