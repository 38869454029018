import { MOCK_GET_BLOCKS_DATA_RESPONSE } from './mockData/updateHistory';

export const api = {
  async getUpdateHistoryForFarm() {
    return new Promise((resolve) => {
      resolve(MOCK_GET_BLOCKS_DATA_RESPONSE);
    });
  },
};

export default api;
