import { put, take, call } from 'redux-saga/effects';
import { GET_CHARTS_CONFIGURATION_REQUEST } from '../types/dataChartTypes';
import {
  getDataChartConfigFileSuccess,
  getDataChartConfigFileError,
  getDataChartLinkedConfigFileSuccess,
} from '../actions/dataConfigChartActions';
import {
  setStartLoading,
  setEndLoading,
} from '../../../common/redux/loadManager/actions';
import { getLoadId } from '../../../common/redux/loadManager/methods';

let currentLoadId = 0;

export default function* dataChartConfigurationSaga(api) {
  while (true) {
    const getDataChartRequestedAction = yield take(
      GET_CHARTS_CONFIGURATION_REQUEST,
    );
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));
    const { entityId } = getDataChartRequestedAction.payload;
    yield call(getdataChartConfigFile, api, entityId);
  }
}

function* getdataChartConfigFile(api, entityId) {
  try {
    const chartData = yield call(
      api.getConfigDataForCharts,

      entityId,
    );
    yield put(getDataChartConfigFileSuccess(chartData.data.mainConfig));
    yield put(getDataChartLinkedConfigFileSuccess(chartData.data.linkedConfig));
    yield put(setEndLoading(currentLoadId));
  } catch (error) {
    yield put(getDataChartConfigFileError(error));
    yield put(setEndLoading(currentLoadId));
  }
}
