import type {
  GeneralProperties,
  AdvancedProperties,
} from '../../../components/AstronautDataExplorer/AstronautFilterMenu/types';

export const getCheckboxesState = (events, filteredEvents) => {
  return {
    some:
      filteredEvents.length > 0 &&
      filteredEvents.length !== Object.keys(events).length,
    every: filteredEvents.length === Object.keys(events).length,
  };
};

export const filterActiveEvents = (events?) => {
  return events
    ? Object.keys(events).filter((eventKey) => events[eventKey].isSelected)
    : [];
};

export const getEventsInArray = (events: string): string[] => {
  return events === 'non-selected' ? [] : events?.split(',');
};

export const updateSelectedEvents = <T extends Object>(
  events: T,
  selectedEvents: string[],
): T => {
  const updatedReduxValues: typeof events = {} as T;

  Object.keys(events).forEach((eventKey) => {
    updatedReduxValues[eventKey] = {
      ...events[eventKey],
      isSelected: selectedEvents.includes(eventKey),
    };
  });

  return updatedReduxValues;
};

export const splitStringEventsAndUpdateSelected = (
  selectedEvents: string,
  eventsObject: any,
) => {
  const events = getEventsInArray(selectedEvents);
  return updateSelectedEvents<typeof eventsObject>(eventsObject, events);
};

export function isAdvancedProperties(
  event: GeneralProperties | AdvancedProperties,
): event is AdvancedProperties {
  return Object.keys(event).some((key) => {
    return key === 'DEBUG';
  });
}
