import axiosInstance from '../axiosInstance';
import { UPDATE_ENTITY_NAME } from './config';

export const api = {
  async updateEntityName(entityId, updatedName) {
    return axiosInstance.put(UPDATE_ENTITY_NAME, {
      externalId: entityId,
      name: updatedName,
    });
  },
};

export default { api };
