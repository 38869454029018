import {
  GET_ALARMS_DROPDOWN_LEVELS_REQUEST,
  GET_ALARMS_DROPDOWN_LEVELS_REQUEST_SUCCESS,
  GET_ALARMS_DROPDOWN_LEVELS_REQUEST_ERROR,
} from './types';

/** Create action which will get all information alarm dropdown levels .
 * @param {String} accessToken - the access token
 * @return {Object} Return type and info for alarm dropdown level
 */
export function getAlarmDropdownLevels(accessToken) {
  return {
    type: GET_ALARMS_DROPDOWN_LEVELS_REQUEST,
    payload: {
      accessToken,
    },
  };
}

/** Create action which to allow to put dropdown levels in the redux store
 * @param {Object} dropdownLevels - The dropdown levels data
 * @return {Object} Return name and order of dropdown levels
 */
export function getAlarmDropdownLevelsSuccess(dropdownLevels) {
  return {
    type: GET_ALARMS_DROPDOWN_LEVELS_REQUEST_SUCCESS,
    payload: { ...dropdownLevels },
  };
}

/** Create action which get errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function getAlarmDropdownLevelsError(errorMessage) {
  return {
    type: GET_ALARMS_DROPDOWN_LEVELS_REQUEST_ERROR,
    payload: errorMessage,
  };
}
