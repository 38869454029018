let lastLoadIdGenerated = 0;

export const getLoadId = () => {
  lastLoadIdGenerated++;
  return lastLoadIdGenerated;
};

export default {
  getLoadId,
};
