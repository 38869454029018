import { put, take, call } from 'redux-saga/effects';
import { REQUEST_GET_UPDATE_HISTORY_DATA } from '../types/updateHistoryTypes';
import {
  setUpdateHistoryData,
  setIsGetUpdateHistoryError,
  resetUpdateHistoryState,
} from '../../../reducers/updateHistorySlice';

export default function* updateHistorySaga(api) {
  while (true) {
    const requestUpdateHistoryDataAction = yield take(
      REQUEST_GET_UPDATE_HISTORY_DATA,
    );
    yield put(resetUpdateHistoryState());
    const { farmId, startDate, endDate, systemId } =
      requestUpdateHistoryDataAction.payload;
    yield call(getUpdateHistoryData, api, farmId, startDate, endDate, systemId);
  }
}

function* getUpdateHistoryData(api, farmId, startDate, endDate, systemId) {
  try {
    const updateHistoryData = yield call(
      api.getUpdateHistoryForFarm,
      farmId,
      startDate,
      endDate,
      systemId,
    );
    yield put(
      setUpdateHistoryData({ updateHistoryData: updateHistoryData.data }),
    );
  } catch (error) {
    yield put(setIsGetUpdateHistoryError(error));
  }
}
