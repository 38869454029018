import axiosInstance from '../axiosInstance';
import {
  GET_ALARMS_FOR_ENTITY_OVERVIEW_SERVICE_URL,
  GET_ALARMS_FOR_ENTITY_OVERVIEW_TO_DATE,
  ALARMS_DROPDOWN_LEVELS,
} from './config';

export const api = {
  async getAlarmsDataForEntityOverview(entityId, toDate) {
    const getAlarmsForOverview =
      GET_ALARMS_FOR_ENTITY_OVERVIEW_SERVICE_URL +
      entityId +
      GET_ALARMS_FOR_ENTITY_OVERVIEW_TO_DATE +
      toDate;
    return axiosInstance.get(getAlarmsForOverview);
  },

  async getAlarmsDropdownLevels() {
    return axiosInstance.get(ALARMS_DROPDOWN_LEVELS);
  },
};

export type AlarmsStatusApIType = typeof api;
