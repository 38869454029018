import {
  UPDATE_FARM_NAME_REQUEST,
  UPDATE_FARM_NAME_REQUEST_SUCCESS,
  UPDATE_FARM_NAME_REQUEST_ERROR,
} from './types';

/** Create action which update farm name.
 * @param {string} accessToken - User access token
 * @param {any} entityId - The ID of the currently renamed entity
 * @param {string} updatedName - Updated name
 * @return {Object} Return type and entity details
 */
export function updateFarmNameOfTheEntity(accessToken, entityId, updatedName) {
  return {
    type: UPDATE_FARM_NAME_REQUEST,
    payload: {
      accessToken,
      entityId,
      updatedName,
    },
  };
}

/** Set updated name of entity to store.
 * @param {object} updateNameDetails - All available information for the updatedName
 * @return {Object} Return type and event details
 */
export function updateFarmNameOfTheEntitySuccess(updateNameDetails) {
  return {
    type: UPDATE_FARM_NAME_REQUEST_SUCCESS,
    payload: { updatedName: updateNameDetails },
  };
}

/** Error message
 * @param {string} errorMessage - Error message
 * @return {Object} Return type and error message
 */
export function updateFarmNameOfTheEntityError(errorMessage) {
  return {
    type: UPDATE_FARM_NAME_REQUEST_ERROR,
    payload: errorMessage,
  };
}
