import { format } from 'date-fns';
import type {
  KPIDataResponseType,
  KPIRequestParams,
} from '../../reducers/types/kpiDataTypes';
import {
  FROM_DATE_URL_APPENDIX,
  GET_PERFORMANCE_INDICATORS,
  TO_DATE_URL_APPENDIX,
} from './config';
import axiosInstance from '../axiosInstance';
import { VECTOR_KPI_POST_DATE_FORMAT } from '../../common/constants';

export const api = {
  async getKPIData({ entityId, fromDate, toDate }: KPIRequestParams) {
    const requestUrl = `${GET_PERFORMANCE_INDICATORS}${entityId}${FROM_DATE_URL_APPENDIX}${format(
      fromDate,
      VECTOR_KPI_POST_DATE_FORMAT,
    )}${TO_DATE_URL_APPENDIX}${format(toDate, VECTOR_KPI_POST_DATE_FORMAT)}`;
    return axiosInstance.get<KPIDataResponseType>(requestUrl, {});
  },
};

export type KPIDataApiType = typeof api;
