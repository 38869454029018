import {
  GET_CHART_EVENTS_REQUEST,
  GET_CHART_EVENTS_REQUEST_SUCCESS,
  GET_CHART_EVENTS_REQUEST_ERROR,
} from '../types/dataChartTypes';

/** Get data chart events.
 * @param {any} entityId - entity ID
 * @param {string} startDate - Start date
 * @param {string} endDate - End date
 */
export function getDataChartEvents(entityId, startDate, endDate) {
  return {
    type: GET_CHART_EVENTS_REQUEST,
    payload: {
      entityId,
      startDate,
      endDate,
    },
  };
}

/** Set data config file to store.
 * @param {Object} events - All events.
 * @param {Date} startDate - Start date
 * @param {Date} endDate - End date
 */
export function getDataChartEventsSuccess(events, startDate, endDate) {
  return {
    type: GET_CHART_EVENTS_REQUEST_SUCCESS,
    payload: {
      events,
      startDate,
      endDate,
    },
  };
}

/** Get error message if there are no farms.
 * @param {String} errorMessage - Error message.
 */
export function getDataChartEventsError(errorMessage) {
  return {
    type: GET_CHART_EVENTS_REQUEST_ERROR,
    payload: errorMessage,
  };
}
