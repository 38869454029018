let isTestMode = false;
export const setIsTestMode = (value) => {
  if (value === true) {
    isTestMode = value;
  }
};

export const getIsTestMode = () => {
  return isTestMode;
};
