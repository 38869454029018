import { put, take, call } from 'redux-saga/effects';
import { GET_USER_ROLES_REQUEST } from './types';

import {
  setStartLoading,
  setEndLoading,
} from '../../redux/loadManager/actions';
import { getLoadId } from '../../redux/loadManager/methods';
import { setUserRoles } from '../../../reducers/userSessionSlice';
import { GetUserRolesApi } from '../../../api/lelyBackend/userRoles';

let currentLoadId = 0;

export default function* getUserRolesSaga(api: GetUserRolesApi) {
  while (true) {
    yield take(GET_USER_ROLES_REQUEST);
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));
    yield call(getUserRolesRequest, api);
  }
}

function* getUserRolesRequest(api) {
  try {
    const allUserRoles = yield call(api.getUserRoles);
    const userRolesResponse = allUserRoles.data;
    yield put(setUserRoles(userRolesResponse));
    yield put(setEndLoading(currentLoadId));
  } catch (error) {
    yield put(setEndLoading(currentLoadId));
  }
}
