import { put, call, all } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { setStartLoading, setEndLoading } from '../loadManager/actions';
import { getLoadId } from '../loadManager/methods';
import {
  FeatureFlagType,
  getFeatureFlagSuccess,
} from '../../../reducers/featureFlagSlice';
import { api } from '../../../api/featureFlags/featureFlagsApi';

let currentLoadId = 0;

export default function* featureFlagSaga(
  action: PayloadAction<{
    flagNames: string[];
    currentUser: string | undefined;
  }>,
) {
  const flagNames = action.payload.flagNames;
  const currentUser = action.payload.currentUser;

  yield all(
    flagNames.map((flagName) => call(getFeatureFlag, flagName, currentUser)),
  );
}

function* getFeatureFlag(flagName: string, currentUser: string | undefined) {
  try {
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));
    const featureFlagData: AxiosResponse<FeatureFlagType> = yield call(
      api.getFeatureFlagDetails,
      flagName,
      currentUser,
    );
    yield put(getFeatureFlagSuccess(featureFlagData.data));
    yield put(setEndLoading(currentLoadId));
  } catch (error) {
    yield put(setEndLoading(currentLoadId));
  }
}
