import { put, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { Farm } from './types';
import {
  setStartLoading,
  setEndLoading,
} from '../../common/redux/loadManager/actions';
import { getLoadId } from '../../common/redux/loadManager/methods';
import { setFarms, setIsFarmError } from '../../reducers/farmsSlice';
import { api } from '../../api/lelyBackend/farmsApi';

let currentLoadId = 0;

export default function* farmsSaga() {
  currentLoadId = getLoadId();
  yield put(setStartLoading(currentLoadId));
  try {
    const response: AxiosResponse<Farm[]> = yield call(api.getFarms);
    yield put(setFarms(response.data));
    yield put(setEndLoading(currentLoadId));
  } catch (error: any) {
    yield put(setIsFarmError(error));
    yield put(setEndLoading(currentLoadId));
  }
}
