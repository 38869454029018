import { put, call } from 'redux-saga/effects';
import { getLanguagesError, getLanguagesSuccess } from './actions';

import {
  setStartLoading,
  setEndLoading,
} from '../../redux/loadManager/actions';
import { getLoadId } from '../../redux/loadManager/methods';
import { api } from '../../../api/lelyBackend/translationApi';

let currentLoadId = 0;

export default function* getLanguagesRequest() {
  currentLoadId = getLoadId();
  yield put(setStartLoading(currentLoadId));
  try {
    const allLanguageDetails = yield call(api.getLanguages);
    const languagePreferenceResponse = allLanguageDetails.data;
    yield put(getLanguagesSuccess(languagePreferenceResponse));
    yield put(setEndLoading(currentLoadId));
  } catch (error: any) {
    yield put(getLanguagesError(error));
    yield put(setEndLoading(currentLoadId));
  }
}
