import { useCallback } from 'react';
import { formatInTimeZone, fromZonedTime } from 'date-fns-tz';
import { selectFarmTimeZone } from '../../reducers/entityInformationReducer';
import { useAppSelector } from '../../reducers/rootReducer';
import { UI_DATE_FORMAT } from '../constants';
import { Nullable } from '../types';

function useTimeConversion() {
  const farmTimeZone = useAppSelector(selectFarmTimeZone);

  const toFarmLocalTime = useCallback(
    (date?: Nullable<string | Date>, format = UI_DATE_FORMAT) => {
      if (date) {
        const localTime = formatInTimeZone(date, farmTimeZone, format);
        return localTime;
      }
      return '';
    },
    [farmTimeZone],
  );

  const toUtcTimeAsString = useCallback(
    (date?: Nullable<string | Date>) => {
      if (date) {
        return fromZonedTime(
          typeof date === 'string' ? new Date(date) : date,
          farmTimeZone,
        ).toISOString();
      }
      return '';
    },
    [farmTimeZone],
  );

  return { toFarmLocalTime, toUtcTimeAsString };
}

export default useTimeConversion;
