import React from 'react';
import type { TableTypes } from '../../common/types';

type Props = {
  isSortedByColumn: boolean;
  direction: TableTypes.SortingObject['direction'];
};

function SortingIcon({ isSortedByColumn, direction }: Props) {
  return (
    <button className="btn btn-sort" type="button">
      <i
        className={`lely-icon-caret-down desc ${
          isSortedByColumn && direction === 'asc' && 'active'
        }`}
      />
      <i
        className={`lely-icon-caret-down asc ${
          isSortedByColumn && direction === 'desc' && 'active'
        }`}
      />
    </button>
  );
}

export default SortingIcon;
