import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
/** Google analytics code */
const trackingIdGA4 = 'G-LTF54HBR42';
const universalGA = 'UA-162568648-1';

const useGoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize([
      {
        trackingId: trackingIdGA4,
      },
      {
        trackingId: universalGA,
      },
    ]);
  }, []);

  useEffect(() => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.send({ hitType: 'pageview', page: location.pathname }); // Record a page view for the given page
  }, [location]);
};

export default useGoogleAnalytics;
