import axiosInstance from '../axiosInstance';
import { GET_VECTOR_CONFIGURATION_SERVICE_URL } from './config';

export const api = {
  async getVectorConfiguration(entityId) {
    const getVectorConfigurationUrl =
      GET_VECTOR_CONFIGURATION_SERVICE_URL + entityId;
    return axiosInstance.get<any>(getVectorConfigurationUrl);
  },
};

export type VectorConfigurationApi = typeof api;
