import {
  GET_ENTITY_STATUS_REQUEST,
  GET_ENTITY_STATUS_REQUEST_SUCCESS,
  GET_ENTITY_STATUS_REQUEST_ERROR,
} from './types';

/** Create action which will need to get entity details.
 * @param {any} entityId - entity ID
 * @return {Object} Return type and entity details
 */
export function getEntityStatus(entityId) {
  return {
    type: GET_ENTITY_STATUS_REQUEST,
    payload: {
      entityId,
    },
  };
}

/** Set entity details to store.
 * @param {object} entityStatus - All available information for the entity offline/online state
 * @return {Object} Return type and entity details
 */
export function getEntityStatusSuccess(entityStatus) {
  return {
    type: GET_ENTITY_STATUS_REQUEST_SUCCESS,
    payload: { ...entityStatus },
  };
}

/** Error message
 * @param {string} errorMessage - Error message
 * @return {Object} Return type and error message
 */
export function getEntityStatusError(errorMessage) {
  return {
    type: GET_ENTITY_STATUS_REQUEST_ERROR,
    payload: errorMessage,
  };
}
