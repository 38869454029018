import { createAction } from '@reduxjs/toolkit';
import {
  UPDATE_LANGUAGE_REQUEST,
  UPDATE_LANGUAGE_REQUEST_ERROR,
  SET_PREFERRED_LANGUAGE,
  GET_LANGUAGES_REQUEST,
  GET_LANGUAGES_REQUEST_SUCCESS,
  GET_LANGUAGES_REQUEST_ERROR,
} from './types';

/** Create action which get all language.
 * @return {Object} Return type and selectedEntity
 */

export const getLanguages = createAction(GET_LANGUAGES_REQUEST);
/** Create action which will store all language.
 * @param {Object} languages - User access token
 * @return {Object} Return type and selectedEntity
 */
export function getLanguagesSuccess(languages) {
  return {
    type: GET_LANGUAGES_REQUEST_SUCCESS,
    payload: languages,
  };
}
/** Request error response message.
 * @param {String} error - Error message
 * @return {Object} Return type and selectedEntity
 */
export function getLanguagesError(error) {
  return {
    type: GET_LANGUAGES_REQUEST_ERROR,
    payload: error,
  };
}

/** Create action which update the language api value.
 * @param {String} accessToken - User access token
 * @param {String} languageValue - Preferred new language value (ex: 'en' / 'nl' / 'bg')
 * @return {Object} Return type and selectedEntity
 */
export function updateLanguage(accessToken, languageValue) {
  return {
    type: UPDATE_LANGUAGE_REQUEST,
    payload: { accessToken, languageValue },
  };
}

/** Create action which get errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function updateLanguageError(errorMessage) {
  return {
    type: UPDATE_LANGUAGE_REQUEST_ERROR,
    payload: errorMessage,
  };
}

/** Set preferred language.
 * @param {String} language - Preferred language
 */
export function setPreferredLanguage(language) {
  return {
    type: SET_PREFERRED_LANGUAGE,
    payload: { language },
  };
}
