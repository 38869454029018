import { put, take, call } from 'redux-saga/effects';
import {
  setStartLoading,
  setEndLoading,
} from '../../../common/redux/loadManager/actions';
import { getLoadId } from '../../../common/redux/loadManager/methods';
import { SettingsApiType } from '../../../api/lelyBackend/settingsApi';
import {
  getEntitySettingsOptions,
  setEntitySettingsOptions,
  setSettingsOptionsError,
} from '../../../reducers/entitySettingsOptionsSlice';
import { AwaitedApiResultType } from '../../../common/types';

let currentLoadId = 0;

export default function* entitySettingsOptionsSaga(api: SettingsApiType) {
  while (true) {
    const entitySettingsOptionsAction = yield take(
      getEntitySettingsOptions.type,
    );
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));
    const { entityId } = entitySettingsOptionsAction.payload;
    yield call(getEntitySettingsOptionsFromApi, api, entityId);
  }
}

function* getEntitySettingsOptionsFromApi(
  api: SettingsApiType,
  entityId: string,
) {
  try {
    const entitySettingsOptions: AwaitedApiResultType<
      typeof api.getSettingsOptions
    > = yield call(api.getSettingsOptions, entityId);
    yield put(
      setEntitySettingsOptions(entitySettingsOptions.data.farmAstronauts),
    );
    yield put(setEndLoading(currentLoadId));
  } catch (error: any) {
    yield put(setSettingsOptionsError(error));
    yield put(setEndLoading(currentLoadId));
  }
}
