import { SET_PRELOADER } from '../components/Preloader/types';

/** Store states
 * @constant {Object}
 */
export const initialState = {
  isPreloaderActive: false,
};

/** Preloader Reducer.
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function preloaderReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_PRELOADER:
      return {
        ...state,
        isPreloaderActive: action.payload.isActive,
      };
    default:
      return state;
  }
}

export default preloaderReducer;
