import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';

export interface NetworkStateType {
  errorMessage: string;
  errorStatus: number | undefined;
}

export const networkErrorState: NetworkStateType = {
  errorMessage: '',
  errorStatus: undefined,
};

const networkError = createSlice({
  name: 'networkError',
  initialState: networkErrorState,
  reducers: {
    setNetworkError(state, action: PayloadAction<AxiosError>) {
      state.errorMessage = action.payload.message;
      state.errorStatus = action.payload.response?.status;
    },
    resetNetworkErrorMessage(state) {
      state.errorMessage = networkErrorState.errorMessage;
    },
  },
});

export const { setNetworkError, resetNetworkErrorMessage } =
  networkError.actions;

export const selectNetworkErrorMessage = (state: RootState) =>
  state.networkError.errorMessage;
export const selectNetworkErrorStatus = (state: RootState) =>
  state.networkError.errorStatus;

export default networkError.reducer;
