import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

let pdbItemsData = [];
export const setPdbItemsData = (itemsData) => {
  pdbItemsData = convertItemsDataToPlainArrayOfEvents(itemsData);
};

export const getPdbItemsData = () => {
  return pdbItemsData;
};

let fgItemsData = [];
export const setFgItemsData = (itemsData) => {
  fgItemsData = convertItemsDataToPlainArrayOfEvents(itemsData);
};

export const getFgItemsData = () => {
  return fgItemsData;
};

let mfr1ItemsData = [];
export const setMfr1ItemsData = (itemsData) => {
  mfr1ItemsData = convertItemsDataToPlainArrayOfEvents(itemsData);
};

export const getMfr1ItemsData = () => {
  return mfr1ItemsData;
};

let latestAppliedProperties = [];
export const setLatestAppliedPropertiesData = (appliedProperties) => {
  latestAppliedProperties = appliedProperties;
};

export const getLatestAppliedPropertiesData = () => {
  return latestAppliedProperties;
};

export const convertItemsDataToPlainArrayOfEvents = (itemsData) => {
  let plainArrayOfEvents = [];
  for (const rootKey in itemsData) {
    for (const subKey in itemsData[rootKey]) {
      plainArrayOfEvents = plainArrayOfEvents.concat(
        itemsData[rootKey][subKey],
      );
    }
  }
  return plainArrayOfEvents;
};

let mfr2ItemsData = [];
export const setMfr2ItemsData = (itemsData) => {
  mfr2ItemsData = convertItemsDataToPlainArrayOfEvents(itemsData);
};

export const getMfr2ItemsData = () => {
  return mfr2ItemsData;
};

let mfr2ItemsAlarmsData = [];
export const setMfr2ItemsAlarmsData = (itemsData) => {
  mfr2ItemsAlarmsData = convertItemsDataToPlainArrayOfEvents(itemsData);
};

export const getMfr2ItemsAlarmsData = () => {
  return mfr2ItemsAlarmsData;
};

const getItemsDataArrayByMfrType = (
  mfrType,
  mfr1ItemsDataParam,
  mfr2ItemsDataParam,
  fgItemsDataParam,
  pdbItemsDataParam,
) => {
  switch (mfrType) {
    case 'mfr1':
      return mfr1ItemsDataParam;
    case 'mfr2':
      return mfr2ItemsDataParam;
    case 'fg':
      return fgItemsDataParam;
    case 'pdb':
      return pdbItemsDataParam;
    default:
      break;
  }
};

export const getEventsDataBasedOnSelectedItem = (
  selectedItemData,
  targetRangeInSeconds,
  mfr1ItemsDataParams,
  mfr2ItemsDataParams,
  fgItemsDataParams,
  pdbItemsDataParams,
) => {
  const itemsData = getItemsDataArrayByMfrType(
    selectedItemData.mfrType,
    mfr1ItemsDataParams,
    mfr2ItemsDataParams,
    fgItemsDataParams,
    pdbItemsDataParams,
  );
  const selectedItemTimeInSeconds = selectedItemData.time.getTime() / 1000;
  return itemsData.filter((currentEvent) => {
    const currentEventTimeInSeconds = currentEvent.time.getTime() / 1000;
    return (
      Math.abs(currentEventTimeInSeconds - selectedItemTimeInSeconds) <
      targetRangeInSeconds
    );
  });
};

export const getSortedEventsData = (eventsData) => {
  const sortedEventsData = eventsData?.sort((event1, event2) => {
    const isFirstEventBeforeSecondEvent = moment(event1.time).isSameOrBefore(
      event2.time,
    );
    if (isFirstEventBeforeSecondEvent) {
      return -1;
    }
    return 1;
  });
  return sortedEventsData;
};

export const filterSelectedPropertySelect = (configuration) => {
  const selectedCategories = [];
  for (const categoryKey in configuration) {
    for (const memberKey in configuration[categoryKey].members) {
      if (configuration[categoryKey].members[memberKey].selected) {
        selectedCategories.push(memberKey.toUpperCase());
      }
    }
  }
  return selectedCategories;
};

export const filterEventsBySelectedPropertySelect = (
  eventsData,
  selectedProperties,
) => {
  return eventsData.filter((eventData) => {
    if (eventData.type) {
      return selectedProperties.find((selectedProperty) => {
        return eventData.type.toUpperCase() === selectedProperty;
      });
    }

    return true;
  });
};

export const filterEventsBySelectedRange = (
  eventItemsData,
  zoomStartDate,
  zoomEndDate,
) => {
  return eventItemsData.filter((currentEventData) => {
    return moment(new Date(currentEventData.time)).isBetween(
      new Date(zoomStartDate),
      new Date(zoomEndDate),
    );
  });
};

export const filterAlarmsBySelectedRange = (
  eventItemsData,
  zoomStartDate,
  zoomEndDate,
) => {
  return !isEmpty(eventItemsData)
    ? eventItemsData.filter((currentEventData) => {
        return moment(new Date(currentEventData.startTime)).isBetween(
          new Date(zoomStartDate),
          new Date(zoomEndDate),
        );
      })
    : [];
};

export const populateEventTypeToNumberOfEventsDictionary = (
  eventItemsData,
  dictionaryObject,
) => {
  eventItemsData.forEach((currentMfrEvent) => {
    const currentEventName = currentMfrEvent.type.split(' ').join('');
    if (!dictionaryObject[currentEventName]) {
      dictionaryObject[currentEventName] = 0;
    }
    dictionaryObject[currentEventName]++;
  });
  return dictionaryObject;
};

export const decorateEventsConfigurationWithSummationData = (
  configuration,
  zoomStartDate,
  zoomEndDate,
) => {
  const newConfiguration = cloneDeep(configuration);
  const filteredMfr1Items = filterEventsBySelectedRange(
    getMfr1ItemsData(),
    zoomStartDate,
    zoomEndDate,
  );
  const filteredMfr2Items = filterEventsBySelectedRange(
    getMfr2ItemsData(),
    zoomStartDate,
    zoomEndDate,
  );
  const filteredFgItems = filterEventsBySelectedRange(
    getFgItemsData(),
    zoomStartDate,
    zoomEndDate,
  );
  const filteredPdbItems = filterEventsBySelectedRange(
    getPdbItemsData(),
    zoomStartDate,
    zoomEndDate,
  );
  const eventTypeToNumberOfEventsDictionaryForMfr1 =
    populateEventTypeToNumberOfEventsDictionary(filteredMfr1Items, {});
  const eventTypeToNumberOfEventsDictionaryForMfr2 =
    populateEventTypeToNumberOfEventsDictionary(filteredMfr2Items, {});
  const eventTypeToNumberOfEventsDictionaryForFg =
    populateEventTypeToNumberOfEventsDictionary(filteredFgItems, {});
  const eventTypeToNumberOfEventsDictionaryForPdb =
    populateEventTypeToNumberOfEventsDictionary(filteredPdbItems, {});

  for (const categoryKey in newConfiguration) {
    for (const memberKey in newConfiguration[categoryKey].members) {
      if (eventTypeToNumberOfEventsDictionaryForMfr1[memberKey]) {
        newConfiguration[categoryKey].members[memberKey].numberOfMfr1Events =
          eventTypeToNumberOfEventsDictionaryForMfr1[memberKey];
      } else {
        newConfiguration[categoryKey].members[memberKey].numberOfMfr1Events = 0;
      }

      if (eventTypeToNumberOfEventsDictionaryForMfr2[memberKey]) {
        newConfiguration[categoryKey].members[memberKey].numberOfMfr2Events =
          eventTypeToNumberOfEventsDictionaryForMfr2[memberKey];
      } else {
        newConfiguration[categoryKey].members[memberKey].numberOfMfr2Events = 0;
      }

      if (eventTypeToNumberOfEventsDictionaryForFg[memberKey]) {
        newConfiguration[categoryKey].members[memberKey].numberOfFgEvents =
          eventTypeToNumberOfEventsDictionaryForFg[memberKey];
      } else {
        newConfiguration[categoryKey].members[memberKey].numberOfFgEvents = 0;
      }

      if (eventTypeToNumberOfEventsDictionaryForPdb[memberKey]) {
        newConfiguration[categoryKey].members[memberKey].numberOfPdbEvents =
          eventTypeToNumberOfEventsDictionaryForPdb[memberKey];
      } else {
        newConfiguration[categoryKey].members[memberKey].numberOfPdbEvents = 0;
      }
    }
  }
  return newConfiguration;
};

export const extractActivitiesFromConfig = (convertedAlarmsItems, system) => {
  const alarms = Object.values(convertedAlarmsItems).map(
    (val) => val[system].alarms,
  );
  return alarms[0];
};

export const decorateAlarmsConfigurationWithSummationData = (
  configuration,
  zoomStartDate,
  zoomEndDate,
) => {
  const newConfiguration = cloneDeep(configuration);
  const convertedAlarmsItems =
    convertItemsDataToPlainArrayOfEvents(configuration);

  const mfr1ActivitiesCriticalAlarms = extractActivitiesFromConfig(
    convertedAlarmsItems,
    'MFR1',
  );
  const mfr2ActivitiesCriticalAlarms = extractActivitiesFromConfig(
    convertedAlarmsItems,
    'MFR2',
  );
  const pdbActivitiesCriticalAlarms = extractActivitiesFromConfig(
    convertedAlarmsItems,
    'PDB',
  );
  const fgActivitiesCriticalAlarms = extractActivitiesFromConfig(
    convertedAlarmsItems,
    'FeedGrabber',
  );
  const filteredMfr1Items = filterAlarmsBySelectedRange(
    mfr1ActivitiesCriticalAlarms,
    zoomStartDate,
    zoomEndDate,
  );
  const filteredMfr2Items = filterAlarmsBySelectedRange(
    mfr2ActivitiesCriticalAlarms,
    zoomStartDate,
    zoomEndDate,
  );
  const filteredFgItems = filterAlarmsBySelectedRange(
    fgActivitiesCriticalAlarms,
    zoomStartDate,
    zoomEndDate,
  );
  const filteredPdbItems = filterAlarmsBySelectedRange(
    pdbActivitiesCriticalAlarms,
    zoomStartDate,
    zoomEndDate,
  );
  for (const categoryKey in newConfiguration) {
    for (const memberKey in newConfiguration[categoryKey].members) {
      if (filteredMfr1Items && memberKey === 'MFR1') {
        newConfiguration[categoryKey].members[memberKey].alarms =
          filteredMfr1Items;
      }
      if (filteredMfr2Items && memberKey === 'MFR2') {
        newConfiguration[categoryKey].members[memberKey].alarms =
          filteredMfr2Items;
      }
      if (filteredFgItems && memberKey === 'FeedGrabber') {
        newConfiguration[categoryKey].members[memberKey].alarms =
          filteredFgItems;
      }
      if (filteredPdbItems && memberKey === 'PDB') {
        newConfiguration[categoryKey].members[memberKey].alarms =
          filteredPdbItems;
      }
    }
  }
  return newConfiguration;
};
