import { put, take, call } from 'redux-saga/effects';
import {
  setStartLoading,
  setEndLoading,
} from '../../../common/redux/loadManager/actions';
import { getLoadId } from '../../../common/redux/loadManager/methods';
import { SettingsApiType } from '../../../api/lelyBackend/settingsApi';
import {
  getEntitySettings,
  setEntitySettingsSuccess,
  setSettingsErrorMessage,
} from '../../../reducers/entitySettingsSlice';
import { AwaitedApiResultType } from '../../../common/types';

let currentLoadId = 0;

export default function* entitySettingsSaga(api: SettingsApiType) {
  while (true) {
    const entitySettingsAction = yield take(getEntitySettings.type);
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));
    const { externalId, entityIdsToCompare } = entitySettingsAction.payload;
    yield call(getEntitySettingsFromApi, api, externalId, entityIdsToCompare);
  }
}

function* getEntitySettingsFromApi(
  api: SettingsApiType,
  entityId: string,
  entityIdsToCompare: string[],
) {
  try {
    const entitySettingsData: AwaitedApiResultType<
      typeof api.getEntitySettings
    > = yield call(api.getEntitySettings, entityId, entityIdsToCompare);

    yield put(setEntitySettingsSuccess(entitySettingsData.data));
    yield put(setEndLoading(currentLoadId));
  } catch (error: any) {
    yield put(setSettingsErrorMessage(error));
    yield put(setEndLoading(currentLoadId));
  }
}
