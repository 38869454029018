import { put, take, call } from 'redux-saga/effects';
import { GET_ALARMS_FOR_ENTITY_OVERVIEW_REQUEST } from './types';
import {
  getAlarmsForEntityOverviewSuccess,
  getAlarmsForEntityOverviewError,
} from './actions';
import {
  setStartLoading,
  setEndLoading,
} from '../../redux/loadManager/actions';
import { getLoadId } from '../../redux/loadManager/methods';

let currentLoadId = 0;

export default function* alarmTablesSaga(api) {
  while (true) {
    const alarmDetailsForEntityOverviewAction = yield take(
      GET_ALARMS_FOR_ENTITY_OVERVIEW_REQUEST,
    );
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));
    const { entityId, toDate } = alarmDetailsForEntityOverviewAction.payload;
    yield call(getAlarmEntityOverviewDetails, api, entityId, toDate);
  }
}

function* getAlarmEntityOverviewDetails(api, entityId, toDate) {
  try {
    const alarmEntityOverviewDetails = yield call(
      api.getAlarmsDataForEntityOverview,
      entityId,
      toDate,
    );
    const alarmEntityOverviewResponse = alarmEntityOverviewDetails.data;
    yield put(getAlarmsForEntityOverviewSuccess(alarmEntityOverviewResponse));
    yield put(setEndLoading(currentLoadId));
  } catch (error) {
    yield put(getAlarmsForEntityOverviewError(error));
    yield put(setEndLoading(currentLoadId));
  }
}
