export const GET_ENTITY_BACKUP_SETTINGS_REQUEST =
  'GET_ENTITY_BACKUP_SETTINGS_REQUEST';
export const GET_ENTITY_BACKUP_SETTINGS_REQUEST_SUCCESS =
  'GET_ENTITY_BACKUP_SETTINGS_REQUEST_SUCCESS';
export const GET_ENTITY_BACKUP_SETTINGS_REQUEST_ERROR =
  'GET_ENTITY_BACKUP_SETTINGS_REQUEST_ERROR';
export const CREATE_ENTITY_BACKUP_SETTINGS_REQUEST =
  'CREATE_ENTITY_BACKUP_SETTINGS_REQUEST';
export const CREATE_ENTITY_BACKUP_SETTINGS_REQUEST_SUCCESS =
  'CREATE_ENTITY_BACKUP_SETTINGS_REQUEST_SUCCESS';
export const CREATE_ENTITY_BACKUP_SETTINGS_REQUEST_ERROR =
  'CREATE_ENTITY_BACKUP_SETTINGS_REQUEST_ERROR';
export const UPDATE_ENTITY_BACKUP_SETTING_DESCRIPTION_REQUEST =
  'UPDATE_ENTITY_BACKUP_SETTING_DESCRIPTION_REQUEST';
export const UPDATE_ENTITY_BACKUP_SETTING_DESCRIPTION_REQUEST_ERROR =
  'UPDATE_ENTITY_BACKUP_SETTING_DESCRIPTION_REQUEST_ERROR';
export const RESTORE_ENTITY_BACKUP_SETTING_REQUEST =
  'RESTORE_ENTITY_BACKUP_SETTING_REQUEST';
export const RESTORE_ENTITY_BACKUP_SETTING_REQUEST_SUCCESS =
  'RESTORE_ENTITY_BACKUP_SETTING_REQUEST_SUCCESS';
export const GET_RESTORE_AND_CREATE_ENABLED = 'GET_RESTORE_AND_CREATE_ENABLED';
export const RESTORE_ENTITY_BACKUP_SETTING_REQUEST_ERROR =
  'RESTORE_ENTITY_BACKUP_SETTING_REQUEST_ERROR';
export const CLEAR_ENTITY_BACKUP_SETTINGS = 'CLEAR_ENTITY_BACKUP_SETTINGS';
export const LISTEN_FOR_RESTORE_ENTITY_BACKUP_SETTING_REQUEST_SUCCESS =
  'LISTEN_FOR_RESTORE_ENTITY_BACKUP_SETTING_REQUEST_SUCCESS';
export const LISTEN_FOR_RESTORE_ENTITY_BACKUP_SETTING_REQUEST_ERROR =
  'LISTEN_FOR_RESTORE_ENTITY_BACKUP_SETTING_REQUEST_ERROR';
export const BACKUP_PROGRESS_REQUEST = 'BACKUP_PROGRESS_REQUEST';
export const LISTEN_FOR_BACKUP_SETTINGS_REQUEST_SUCCESS =
  'LISTEN_FOR_BACKUP_SETTINGS_REQUEST_SUCCESS';
export const LISTEN_FOR_BACKUP_RESTORE_REQUEST_SUCCESS =
  'LISTEN_FOR_BACKUP_RESTORE_REQUEST_SUCCESS';
export const LISTEN_FOR_DELETE_BACKUP = 'LISTEN_FOR_DELETE_BACKUP';
export const LISTEN_FOR_BACKUP_SETTINGS_REQUEST_ERROR =
  'LISTEN_FOR_BACKUP_SETTINGS_REQUEST_ERROR';
export const BACKUP_PROGRESS_REQUEST_SUCCESS =
  'BACKUP_PROGRESS_REQUEST_SUCCESS';
export const RESET_STATUS_MESSAGE = 'RESET_STATUS_MESSAGE';
export const BACKUP_TIMEOUT = 'BACKUP_TIMEOUT';
export const RESTORE_TIMEOUT = 'RESTORE_TIMEOUT';
export const FORMAT_TIME_FOR_TIMEOUT = 'FORMAT_TIME_FOR_TIMEOUT';
export const LISTEN_FOR_RENAME_BACKUP = 'LISTEN_FOR_RENAME_BACKUP';

export const BACKUP_RESTORE_STATUSES = {
  Sent: 'Sent',
  Finished: 'Finished',
  Successful: 'Successful',
  Failed: 'Failed',
  FailedTimeout: 'FailedTimeout',
  RestoreFailed: 'RestoreFailed',
};
