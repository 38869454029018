import { SettingsTypes } from '../../containers/Settings/types';
import axiosInstance from '../axiosInstance';
import {
  GET_ENTITY_SETTINGS,
  GET_SETTINGS_OPTIONS,
  COMPARE_SETTINGS_BY_ENTITY_ID,
} from './config';

export const api = {
  async getEntitySettings(
    entityId: string,
    entityIdsToCompare: string[] | null,
  ) {
    let getEntitySettingsServiceUrl = GET_ENTITY_SETTINGS + entityId;
    if (entityIdsToCompare) {
      entityIdsToCompare.forEach((entityMapId) => {
        getEntitySettingsServiceUrl +=
          COMPARE_SETTINGS_BY_ENTITY_ID + entityMapId;
      });
    }
    return axiosInstance.get<SettingsTypes.EntitySettingType[]>(
      getEntitySettingsServiceUrl,
    );
  },

  async getSettingsOptions(entityId: string) {
    const getSettingsOptionsServiceUrl = GET_SETTINGS_OPTIONS + entityId;
    return axiosInstance.get<SettingsTypes.GetEntitySettingsOptionsReqType>(
      getSettingsOptionsServiceUrl,
    );
  },
};

export type SettingsApiType = typeof api;
