import {
  GET_ENTITY_INFO_REQUEST_SUCCESS,
  GET_ENTITY_INFO_REQUEST_ERROR,
  RESET_ENTITY_INFO,
} from '../common/components/FarmAndEntityDetailsHeader/types';

/** Store states
 * @constant {Object} */
export const initialState = {
  entityInfo: {},
  errorMessage: '',
};

/** Components Reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function entityInformationReducer(state = initialState, action = {}) {
  switch (action.type) {
    /** Action Creator - Set components data to store if
     * API request passed successfully */
    case GET_ENTITY_INFO_REQUEST_SUCCESS:
      return {
        ...state,
        entityInfo: action.payload.entityInfoData,
        errorMessage: '',
      };

    /** Action Creator - Set components data to store if
     * API request passed successfully */
    case GET_ENTITY_INFO_REQUEST_ERROR:
      return {
        ...state,
        componentsData: {},
        errorMessage: action.payload,
      };
    case RESET_ENTITY_INFO:
      return initialState;
    default:
      return state;
  }
}
export const selectFarmTimeZone = (state) =>
  state.entityInfo?.entityInfo?.farmTimeZone;

export default entityInformationReducer;
