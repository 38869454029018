import { AxiosResponse } from 'axios';
import { AuthResponse } from '../lelyBackend/loginApi';
import {
  MOCK_VALID_USERNAME,
  MOCK_VALID_PASSWORD,
  MOCK_LOGIN_SUCCESS_RESPONSE,
  MOCK_LOGIN_ERROR,
  MOCK_VALID_ACCESS_TOKEN,
  MOCK_VALID_REFRESH_TOKEN,
} from './config';

const mockAreUserCredentialsValid = (username: string, password: string) => {
  return username === MOCK_VALID_USERNAME && password === MOCK_VALID_PASSWORD;
};

const mockAreTokensValid = (accessToken: string, refreshToken: string) => {
  return (
    accessToken === MOCK_VALID_ACCESS_TOKEN &&
    refreshToken === MOCK_VALID_REFRESH_TOKEN
  );
};

export const api = {
  async login(
    username: string,
    password: string,
  ): Promise<AxiosResponse<AuthResponse>> {
    return new Promise((resolve) => {
      if (mockAreUserCredentialsValid(username, password)) {
        resolve(MOCK_LOGIN_SUCCESS_RESPONSE);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw MOCK_LOGIN_ERROR;
      }
    });
  },
  async refreshAccessToken(
    accessToken: string,
    refreshToken: string,
  ): Promise<AxiosResponse<AuthResponse>> {
    return new Promise((resolve) => {
      if (mockAreTokensValid(accessToken, refreshToken)) {
        resolve(MOCK_LOGIN_SUCCESS_RESPONSE);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw MOCK_LOGIN_ERROR;
      }
    });
  },
};

export default api;
