import { Nullable } from '../../../common/types';

export const REQUEST_GET_UPDATE_HISTORY_DATA =
  'REQUEST_GET_UPDATE_HISTORY_DATA';
export const GET_UPDATE_HISTORY_DATA_RECEIVED =
  'GET_UPDATE_HISTORY_DATA_RECEIVED';
export const GET_UPDATE_HISTORY_DATA_ERROR = 'GET_UPDATE_HISTORY_DATA_ERROR';
export const RESET_UPDATE_HISTORY_DATA = 'RESET_UPDATE_HISTORY_DATA';
export const REQUEST_GET_UPDATE_HISTORY_DATA_FOR_SYSTEM =
  'REQUEST_GET_UPDATE_HISTORY_DATA_FOR_SYSTEM';
export const GET_UPDATE_HISTORY_DATA_RECEIVED_FOR_SYSTEM =
  'GET_UPDATE_HISTORY_DATA_RECEIVED_FOR_SYSTEM';
export const GET_UPDATE_HISTORY_DATA_ERROR_FOR_SYSTEM =
  'GET_UPDATE_HISTORY_DATA_ERROR_FOR_SYSTEM';

export type UpdateHistoryState = {
  updateHistoryData: Nullable<UpdateHistoryData>;
  updatesDataDictionary: UpdateHistoryDataDictionary;
  isGetUpdateHistoryError: boolean;
  errorMessage: string;
};
type UpdateHistoryData = {
  fromDate: string;
  toDate: string;
  systems: System[];
};

type UpdateHistoryDataDictionary = Record<string, System>;

export type System = {
  name: string;
  type: string;
  icon: string;
  color: string;
  devices: string[];
  updates: Record<string, Array<Update>>;
  systemId: string;
};

export type Update = {
  device: string;
  oldVersion: string;
  newVersion: string;
  shortHash?: string;
  buildNr?: number;
  branchName?: string;
  imageType?: string;
  isDowngrade?: boolean;
};
