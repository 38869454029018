/* eslint-disable no-console */
import isEmpty from 'lodash/isEmpty';
import { put, take, select, delay, call } from 'redux-saga/effects';
import { INVALIDATE_APPLICATION_CACHE_IF_NEEDED } from './types';
import { applicationVersionNumberSelect } from '../../../selectors/application_version_number';
import { resetApplicationStoreWithoutUserSession } from '../../redux/purgeStore';
import { updateApplicationVersionNumber } from './actions';
import { api as featureFlagsApi } from '../../../api/featureFlags/featureFlagsApi';
import { getVersionFeatureFlagName } from '../../versionUtils';
import { API_PREFIX } from '../../../api/lelyBackend/config';

export default function* applicationCacheSaga() {
  while (true) {
    yield take(INVALIDATE_APPLICATION_CACHE_IF_NEEDED);

    // Redux Saga allows to *sometimes* and preferrably rarely
    // access values from the Redux Store directly from a Saga.
    // It is a good practice to decentralize the access to store
    // properties in a selectors/ folder so that it is more transparent
    // where those interactions are happening and to extract them
    // from the Saga itself as in the case below.
    try {
      const lastApplicationVersionInLocalStorage = yield select(
        applicationVersionNumberSelect,
      );

      const latestApplicationVersionFeatureFlagName =
        getVersionFeatureFlagName(API_PREFIX);
      // console.log(
      //   'latestApplicationVersionFeatureFlagName:',
      //   latestApplicationVersionFeatureFlagName,
      // );
      // console.log('cacheBust saga token', accessToken);
      // console.log(
      //   'cacheBust saga latestApplicationVersionFeatureFlagName',
      //   latestApplicationVersionFeatureFlagName,
      // );
      const lastApplicationVersionFeatureFlagResponse = yield call(
        featureFlagsApi.getFeatureFlagDetails,
        latestApplicationVersionFeatureFlagName,
      );
      const { description: lastApplicationVersionFromFeatureFlag } =
        lastApplicationVersionFeatureFlagResponse.data;
      // console.log(
      //   'lastApplicationVersionFromFeatureFlag:',
      //   lastApplicationVersionFromFeatureFlag,
      // );

      const envVariablesPassed = process.env;
      const currentApplicationVersionFromPackageJson =
        envVariablesPassed.REACT_APP_VERSION;
      // TODO: dont use package.json here, use the FeatureFlag API and the app version feature flag for the respective environment
      // put here yield GET request to get tje data for tje correct
      // version feature flag;
      // .this should return the latest version
      // number deployed on the respective environment
      // (stg or prd)
      // the value from package.json that
      // was originally there can be used
      // as a fallback in case we are testing locally
      // or for some reason we could not fetch
      // the version feature flag value
      const latestVersion =
        lastApplicationVersionFromFeatureFlag ||
        currentApplicationVersionFromPackageJson;
      const isVersionOutdated =
        latestVersion !== lastApplicationVersionInLocalStorage;
      // console.log(
      //   'Last application version in local storage:',
      //   lastApplicationVersionInLocalStorage,
      //   'Latest version:',
      //   latestVersion,
      // );
      if (isVersionOutdated) {
        // console.log('Version is outdated.');
        yield put(updateApplicationVersionNumber(latestVersion));
        if (
          !isEmpty(latestVersion) &&
          !isEmpty(lastApplicationVersionInLocalStorage)
        ) {
          yield put(resetApplicationStoreWithoutUserSession()); // this will delete everything in the store apart from the application version information
          if (!isEmpty(lastApplicationVersionInLocalStorage)) {
            // console.log(
            //   'Version in local storage is not an empty string, reload needed',
            // );
            yield delay(500);
            window.location.reload(true);
          }
        }
      }
    } catch (error) {
      console.error(`[response error] [${JSON.stringify(error)}]`);
    }
  }
}
