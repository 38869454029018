import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { invalidateApplicationCacheIfNeeded } from '../../../common/components/CacheBust/actions';
import { setIsTestMode } from '../../../common/components/CacheBust/testUtil';
import { useAppSelector } from '../../../reducers/rootReducer';

const useInvalidateAppCache = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { accessToken } = useAppSelector((state) => state.userSession);

  useEffect(() => {
    const fullUrl =
      (location && location.pathname) + (location && location.search);
    const isTestRun = fullUrl.indexOf('isTest') !== -1;
    if (isTestRun) {
      setIsTestMode(true);
    }
    const isApplicationUsedInDevelopmentMode =
      process.env.NODE_ENV === 'development' || window.navigator.webdriver;
    if (!isApplicationUsedInDevelopmentMode && accessToken) {
      dispatch(invalidateApplicationCacheIfNeeded(accessToken));
      // the line above should be executed
      // when we get the feature flag values;
      // we should pass the version feature flag value
      // to the saga where comparison should take place
    }
  }, [location, accessToken]);
};

export default useInvalidateAppCache;
