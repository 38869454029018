import { put, take, call } from 'redux-saga/effects';
import { GET_DOMAINS_FOR_ENTITY_REQUEST } from './types';
import {
  getDomainsForEntitySuccess,
  getDomainsForEntityError,
} from './actions';
import {
  setStartLoading,
  setEndLoading,
} from '../../common/redux/loadManager/actions';
import { getLoadId } from '../../common/redux/loadManager/methods';

let currentLoadId = 0;

export default function* domainsSaga(api) {
  while (true) {
    const domainsForEntityAction = yield take(GET_DOMAINS_FOR_ENTITY_REQUEST);
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));
    const { entityId } = domainsForEntityAction.payload;
    yield call(getDomainsForEntity, api, entityId);
  }
}

function* getDomainsForEntity(api, entityId) {
  try {
    const domainsData = yield call(api.getDomainsForEntity, entityId);
    yield put(
      getDomainsForEntitySuccess({
        domainsData: domainsData.data,
      }),
    );
    yield put(setEndLoading(currentLoadId));
  } catch (error) {
    yield put(getDomainsForEntityError(error));
    yield put(setEndLoading(currentLoadId));
  }
}
