import moment from 'moment';

export const getRangeDurationInMilliseconds = (
  rangeStartDate,
  rangeEndDate,
) => {
  return rangeEndDate.getTime() - rangeStartDate.getTime();
};

export const getRangeDurationInSeconds = (rangeStartDate, rangeEndDate) => {
  return getRangeDurationInMilliseconds(rangeStartDate, rangeEndDate) / 1000;
};

export const getOverlapRangeInSecondsBasedOnCurrentZoom = (
  eventIconWidthInPixels,
  timelineWidthInPixels,
  zoomStartDate,
  zoomEndDate,
) => {
  const iconToTimelineWidthRatio =
    eventIconWidthInPixels / timelineWidthInPixels;
  const durationInSecondsOfCurrentZoom = getRangeDurationInSeconds(
    zoomStartDate,
    zoomEndDate,
  );
  return iconToTimelineWidthRatio * durationInSecondsOfCurrentZoom;
};

export const getArrowIncrementInSecondsBasedOnCurrentZoom = (
  timelineWidthInPixels,
  zoomStartDate,
  zoomEndDate,
) => {
  return getOverlapRangeInSecondsBasedOnCurrentZoom(
    timelineWidthInPixels / 5,
    timelineWidthInPixels,
    zoomStartDate,
    zoomEndDate,
  );
};

export const isLoadDataFromBackendNeeded = (
  newStartDate,
  newEndDate,
  rangeStartDate,
  rangeEndDate,
) => {
  return (
    moment(newStartDate).isBefore(rangeStartDate) ||
    moment(newEndDate).isAfter(rangeEndDate)
  );
};

export const getZoomStartAndEndDatesBasedOnOriginalRangeScaleAndTranslateX = (
  rangeStartDate,
  rangeEndDate,
  scaleX,
  translateX,
  timelineWidthInPixels,
) => {
  const durationInSecondsOfEntireRange = getRangeDurationInSeconds(
    rangeStartDate,
    rangeEndDate,
  );
  const currentWidth = scaleX * timelineWidthInPixels;
  const translateToCurrentWidthRatio = Math.abs(translateX) / currentWidth;
  const secondsOnTheLeftToRemoveFromOriginalRange =
    translateToCurrentWidthRatio * durationInSecondsOfEntireRange;
  const timelineWidthToCurrentWidthRatio = timelineWidthInPixels / currentWidth;
  const secondsToKeepFromOriginalRange =
    timelineWidthToCurrentWidthRatio * durationInSecondsOfEntireRange;
  const secondsOnTheRightToRemoveFromOriginalRange =
    durationInSecondsOfEntireRange -
    (secondsOnTheLeftToRemoveFromOriginalRange +
      secondsToKeepFromOriginalRange);

  const zoomStartDate = moment(rangeStartDate)
    .add(secondsOnTheLeftToRemoveFromOriginalRange, 'seconds')
    .toDate();
  const zoomEndDate = moment(rangeEndDate)
    .subtract(secondsOnTheRightToRemoveFromOriginalRange, 'seconds')
    .toDate();

  return { zoomStartDate, zoomEndDate };
};

export const getNewZoomRangeAndGetDataFromBackendIfNeeded = (
  isMoveLeftOperation,
  timelineWidthInPixels,
  zoomStartDate,
  zoomEndDate,
) => {
  const { newStartDate, newEndDate } = getNewRangeBasedOnOperationType(
    isMoveLeftOperation,
    timelineWidthInPixels,
    zoomStartDate,
    zoomEndDate,
  );
  newStartDate.setHours(0, 0, 0);
  newEndDate.setHours(0, 0, 0);
  return {
    newStartDate,
    newEndDate,
  };
};

export const getNewRangeBasedOnOperationType = (
  isMoveLeftOperation,
  timelineWidthInPixels,
  zoomStartDate,
  zoomEndDate,
) => {
  let newStartDate;
  let newEndDate;
  const incrementInSecondsForCurrentZoom =
    getArrowIncrementInSecondsBasedOnCurrentZoom(
      timelineWidthInPixels,
      zoomStartDate,
      zoomEndDate,
    );

  if (isMoveLeftOperation) {
    newStartDate = moment(zoomStartDate).subtract(1, 'days').toDate();
    newEndDate = moment(zoomEndDate).subtract(1, 'days').toDate();
  } else {
    newStartDate = moment(zoomStartDate)
      .add(incrementInSecondsForCurrentZoom, 'seconds')
      .toDate();
    newEndDate = moment(zoomEndDate)
      .add(incrementInSecondsForCurrentZoom, 'seconds')
      .toDate();
  }
  return { newStartDate, newEndDate };
};

let verticalLineLeftOffsetInPixels = 0;
export const setVerticalLineLeftOffsetInPixels = (
  newVerticalLineLeftOffsetInPixels,
) => {
  verticalLineLeftOffsetInPixels = newVerticalLineLeftOffsetInPixels;
};

export const getVerticalLineLeftOffsetInPixels = () => {
  return verticalLineLeftOffsetInPixels;
};

let timelineWidthInPixels = 0;
export const setTimelineWidthInPixels = (newTimelineWidthInPixels) => {
  timelineWidthInPixels = newTimelineWidthInPixels;
};

export const getTimelineWidthInPixels = () => {
  return timelineWidthInPixels;
};

let chart = {};
export const setChartInstance = (newChartInstance) => {
  chart = newChartInstance;
};

export const getChartInstance = () => {
  return chart;
};

let zoomStartDate = 0;
export const setZoomStartDate = (startDate) => {
  zoomStartDate = startDate;
};

export const getZoomStartDate = () => {
  return zoomStartDate;
};

let zoomEndDate = 0;
export const setZoomEndDate = (endDate) => {
  zoomEndDate = endDate;
};

export const getZoomEndDate = () => {
  return zoomEndDate;
};

let panLeftFn = 0;
export const setPanLeftFn = (newPanLeftFn) => {
  panLeftFn = newPanLeftFn;
};

export const getPanLeftFn = () => {
  return panLeftFn;
};

let panRightFn = 0;
export const setPanRightFn = (newPanRightFn) => {
  panRightFn = newPanRightFn;
};

export const getPanRightFn = () => {
  return panRightFn;
};
