import { Nullable } from '../../common/types';

export interface DetailsInterface {
  date: string;
  description: string;
  eventTimestamp: string;
  iconClass: string;
  id: string;
  indicationId: Nullable<string>;
  level: string;
}
export interface EventDataInterface {
  color: string;
  description: string;
  icon: string;
  iconClass: string;
  indicationId: string;
  level: string;
  message: string;
  source: string;
  x?: string;
}
export interface TableExplicitColumns {
  columnClass?: string;
  dataFunction: (any) => any;
  headerTitle: string;
  sortable?: string;
}
export interface EventsTableInterface {
  type: string;
}

export interface CurrentTypedInput {
  id?: string;
  name?: string;
  source?: string;
  description?: string;
}

export interface ExtendedPickerDate {
  end: string | null;
  start: string | null;
}
export interface DatePickerDate {
  end: Date;
  start: Date;
}

export interface LastPreferredEventFilter {
  currentTypedInput: CurrentTypedInput;
  datePickerDate: DatePickerDate;
  filteredEventsByType: FilteredEventsByType[];
}

export enum FilteredEventsByType {
  Emergency = 'EMERGENCY',
  Critical = 'CRITICAL',
  Error = 'ERROR',
  Warning = 'WARNING',
  Info = 'INFO',
  Debug = 'DEBUG',
  ShowAll = 'SHOW ALL',
}
interface AdditionalInformation {
  'Last 7 days': number;
  'Last 24 hours': number;
}

export interface SelectedEventDetails {
  'Additional Information': AdditionalInformation;
  date: string;
  iconClass: string;
  id: string;
  indicationId: null;
  level: FilteredEventsByType;
  message: string;
  name: string;
  source: string;
}

export interface EventDetailsInitialInterface {
  eventPageDetails: DetailsInterface[] | [];
  isEventDetailsError: boolean;
  errorMessage: string;
  selectedEventDetails: SelectedEventDetails | null;
  lastPreferredEventFilter: Nullable<LastPreferredEventFilter>;
  numberOfResults: number;
  latestOffset: number;
  extendedDates: ExtendedPickerDate;
  maxRowsPerPage: number;
  buttonsDisabledState: Record<EventsButtonType, boolean>;
  filteredEventsByType: string[] | [];
  checkboxTypeError: string | null;
  searchInputValue: Record<string, string> | CurrentTypedInput | null;
  isContextSelected: boolean;
  latestAppliedFilters: {
    appliedTypes: string[] | [];
    appliedSearchText: Record<string, string> | CurrentTypedInput | null;
  };
}

export interface EventPageDetailsInterface {
  location: LocationInterface;
}

interface LocationInterface {
  hash: string;
  key: string;
  pathname: string;
  search: string;
  state: StateInterface | undefined;
}

interface StateInterface {
  data: {
    date: { end: Date; start: Date };
    description: string;
    level: string[];
  };
}

export interface SidebarDetailsInterface {
  isStringSidebar?: boolean;
  title?: string;
}

export enum EventsButtonType {
  Succeeding = 'succeeding',
  Preceding = 'preceding',
  Context = 'fullContext',
}
