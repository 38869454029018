import React, { Fragment, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Button } from '@mui/material';
import useSortingObject from '../../common/customHooks/useSortingObject';
import { useAppSelector } from '../../reducers/rootReducer';
import SortingIcon from '../SortingIcon/SortingIcon';
import { restoreEntityBackupSettingsRequest } from '../../reducers/entityBackupSettingsSlice';
import useTimeConversion from '../../common/customHooks/useTimeConversion';
import { UI_DATE_FORMAT } from '../../common/constants';

export default function RestoreTable({ closeModal, setDisableBtn }) {
  const dispatch = useDispatch();
  const { externalId } = useParams<{ externalId: string }>();
  const { entityBackupSettings, backUpButtons } = useAppSelector(
    (state) => state.entityBackupSettings,
  );
  const { toFarmLocalTime } = useTimeConversion();

  const restoreTable = (filename) => {
    if (externalId) {
      dispatch(
        restoreEntityBackupSettingsRequest({
          entityId: externalId,
          filename,
        }),
      );
      setDisableBtn(true);
      closeModal();
    }
  };

  const columns = useMemo(
    () => [
      { title: 'Requested timestamp', key: 'formattedTimestampCreated' },
      { title: 'Created timestamp', key: 'formattedTimestampCreatedAtRobot' },
      { title: 'Activated by', key: 'activatedBy' },
      { title: 'Description', key: 'description' },
      { title: '', key: 'restoreButton' },
    ],
    [],
  );

  const [sortedValues, handleClick, sortingObject] = useSortingObject<any>(
    entityBackupSettings?.filter(
      (current) =>
        current.type === 'Backup' &&
        (current.status === 'Finished' || current.status === 'Successful'),
    ) || [],
    null,
    'requestedTimestamp',
    columns,
  );

  return (
    <table className="table backup-settings-list">
      <thead>
        <tr>
          {columns.map((item) => {
            return (
              <th
                className="asc fs--d-sm--bold"
                scope="col"
                key={item.key}
                onClick={() => handleClick(item)}
              >
                <span>{item.title}</span>
                <SortingIcon
                  isSortedByColumn={item.key === sortingObject.key}
                  direction={sortingObject.direction}
                />
              </th>
            );
          })}
        </tr>
      </thead>
      {!isEmpty(sortedValues) && (
        <tbody>
          {sortedValues.map((backupSetting, settingIndex) => (
            <Fragment
              // eslint-disable-next-line react/no-array-index-key
              key={`${backupSetting.formattedTimestampCreated} ${settingIndex}`}
            >
              <tr>
                <td>
                  {backupSetting.formattedTimestampCreated
                    ? toFarmLocalTime(
                        backupSetting.formattedTimestampCreated,
                        UI_DATE_FORMAT,
                      )
                    : '-'}
                </td>
                <td>
                  {backupSetting.formattedTimestampCreatedAtRobot
                    ? toFarmLocalTime(
                        backupSetting.formattedTimestampCreatedAtRobot,
                        UI_DATE_FORMAT,
                      )
                    : '-'}
                </td>
                <td>{backupSetting.activatedBy || '-'}</td>
                <td>{backupSetting.description || '-'}</td>
                <td>
                  <Button
                    onClick={() => restoreTable(backupSetting.fileName)}
                    color="primary"
                    disabled={!backUpButtons.restoreBackupEnabled}
                  >
                    Restore
                  </Button>
                </td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      )}
    </table>
  );
}
