import { GET_FARMS_SERVICE_URL } from './config';
import axiosInstance from '../axiosInstance';
import { Farm } from '../../components/FarmsTable/types';

export const api = {
  async getFarms() {
    return axiosInstance.get<Farm[]>(GET_FARMS_SERVICE_URL, {});
  },
};

export type FarmsApiType = typeof api;
