import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BackupRenameType,
  BackupTimeoutType,
  EntityBackupSettingsInitialState,
} from './types/entityBackupSettingsTypes';
import { RootState } from './rootReducer';
import { doesBackupTimeout } from '../containers/BackupSettings/utils';
import { BACKUP_RESTORE_STATUSES } from '../containers/BackupSettings/types';

export const initialState: EntityBackupSettingsInitialState = {
  entityBackupSettings: null,
  backUpButtons: {
    createBackupEnabled: false,
    restoreBackupEnabled: false,
  },
  successMessage: '',
  creationStatusMessage: '',
  creationStatusReason: '',
  errorMessage: '',
  updatedDescription: false,
  restoreStatusMessage: '',
  restoreBackUp: {},
  timeSpanToValidate: null,
  correlationId: null,
  formattedTimestampCreated: null,
  formattedTimestampFinished: null,
  isBackupTimeout: false,
  timeForTimeout: '20 min',
  latestBackup: null,
  restoreStatuses: null,
  progressType: null,
  formattedTimestampCreatedAtRobot: null,
};

export const entityBackupSettingsSlice = createSlice({
  name: 'entityBackupSettings',
  initialState,
  reducers: {
    getEntityBackupSettingsSuccess: (state, action) => {
      const { historyTableItems } = action.payload.entityBackupSettingsData;
      state.entityBackupSettings = historyTableItems;
      state.errorMessage = '';
      state.timeSpanToValidate =
        action.payload.entityBackupSettingsData.timeSpanToValidate;
      state.latestBackup =
        action.payload.entityBackupSettingsData.historyTableItems.find(
          (current) =>
            current.type === 'Backup' &&
            (current.status === BACKUP_RESTORE_STATUSES.Finished ||
              current.status === BACKUP_RESTORE_STATUSES.Successful),
        );
    },
    getRestoreAndCreateButtonEnabled: (state, action) => {
      state.backUpButtons = action.payload;
      state.errorMessage = '';
    },
    getEntityBackupSettingsError: (state, action) => {
      state.entityBackupSettings = [];
      state.creationStatusReason = action.payload;
    },
    createEntityBackupSettingsSuccess: (state, action) => {
      const { status, reason, formattedTimestampCreated, correlationId } =
        action.payload;
      state.creationStatusMessage = status;
      state.creationStatusReason = reason;
      state.correlationId = correlationId;
      state.formattedTimestampCreated = formattedTimestampCreated;
      state.restoreStatuses = null;
    },
    createEntityBackupSettingsError: (state, action) => {
      state.creationStatusMessage = action.payload;
      state.errorMessage = '';
    },
    listenForRenameOfBackup: (
      state,
      action: PayloadAction<BackupRenameType>,
    ) => {
      const { correlationId, description, filename } = action.payload;

      const backups = state.entityBackupSettings
        ? [...state.entityBackupSettings]
        : [];
      const renamedObjIndexId = backups.findIndex(
        (current) => current.correlationId === correlationId,
      );
      if (renamedObjIndexId >= 0) {
        backups[renamedObjIndexId].description = description;
      } else {
        const renamedObjIndexFilename = backups.findIndex(
          (current) => current.fileName === filename,
        );
        if (renamedObjIndexFilename >= 0) {
          backups[renamedObjIndexFilename].description = description;
        }
      }

      state.entityBackupSettings = backups;
      state.updatedDescription = true;
    },
    backupTimeout: (state, action: PayloadAction<BackupTimeoutType>) => {
      const { isTimeout, timeForTimeout } = action.payload;
      if (!isTimeout) {
        state.isBackupTimeout = isTimeout;
        return;
      }

      state.formattedTimestampFinished = timeForTimeout;
      state.isBackupTimeout = isTimeout;
      state.creationStatusMessage = BACKUP_RESTORE_STATUSES.FailedTimeout;
      state.creationStatusReason = 'Failed: Timeout after 20 min';
      state.backUpButtons = {
        createBackupEnabled: true,
        restoreBackupEnabled: true,
      };
    },
    formatTimeout: (state, action: PayloadAction<string>) => {
      const time = action.payload;
      state.timeForTimeout = time;
    },
    getBackupProgressSuccess: (state, action) => {
      const {
        reason,
        status,
        formattedTimestampCreated,
        formattedTimestampFinished,
        formattedTimestampCreatedAtRobot,
        restoreStatuses,
        createBackupEnabled,
        restoreBackupEnabled,
        type,
        correlationId,
        liveData,
      } = action.payload;
      state.progressType = type;
      state.correlationId = correlationId;
      state.formattedTimestampCreated = formattedTimestampCreated;
      state.formattedTimestampFinished = formattedTimestampFinished;
      state.formattedTimestampCreatedAtRobot = formattedTimestampCreatedAtRobot;
      state.creationStatusReason = reason;
      state.backUpButtons = {
        createBackupEnabled,
        restoreBackupEnabled,
      };

      if (type === 'Backup') {
        if (status === BACKUP_RESTORE_STATUSES.Failed) {
          state.creationStatusReason = reason;
          state.isBackupTimeout = doesBackupTimeout(
            formattedTimestampCreated,
            formattedTimestampFinished,
          );
        }
        state.creationStatusMessage = status;
      }

      if (liveData) {
        if (
          (status && status !== BACKUP_RESTORE_STATUSES.Sent) ||
          (restoreStatuses.length > 0 &&
            restoreStatuses?.[restoreStatuses.length - 1]?.status !==
              BACKUP_RESTORE_STATUSES.Sent)
        ) {
          const entityBackupSettings = state.entityBackupSettings
            ? [...state.entityBackupSettings]
            : [];

          entityBackupSettings.unshift(action.payload);
          state.entityBackupSettings = entityBackupSettings;
          if (
            type === 'Backup' &&
            (status === BACKUP_RESTORE_STATUSES.Finished || // Finished is the old status (will be removed in future)
              status === BACKUP_RESTORE_STATUSES.Successful) // Successful is the new status
          ) {
            state.latestBackup = action.payload;
          }
        }
      }
      if (type === 'Restore') {
        state.restoreStatuses = restoreStatuses;
      }
    },
    listenForCreationOfBackupSettingsError: (state, action) => {
      const { status, reason, formattedTimestampCreated, correlationId } =
        action.payload;
      state.creationStatusMessage = status;
      state.creationStatusReason = reason;
      state.correlationId = correlationId;
      state.formattedTimestampCreated = formattedTimestampCreated;
    },
    updateEntityBackupSettingDescriptionError: (state, action) => {
      state.errorMessage = action.payload;
      state.updatedDescription = false;
    },
    restoreEntityBackupSettingsSuccess: (state, action) => {
      state.correlationId =
        action.payload.successRestoreBackupMessage.correlationId;
      state.restoreStatusMessage = action.payload.successRestoreBackupMessage;
      state.errorMessage = '';
    },
    restoreEntityBackupSettingsError: (state, action) => {
      state.restoreStatusMessage = action.payload;
      state.errorMessage = '';
    },
    clearEntityBackupSettings: (state) => {
      state.entityBackupSettings = null;
      state.successMessage = '';
      state.errorMessage = '';
      state.updatedDescription = false;
      state.restoreStatusMessage = '';
      state.restoreBackUp = {};
      state.timeSpanToValidate = null;
      state.correlationId = null;
      state.formattedTimestampCreated = null;
      state.creationStatusMessage = '';
      state.creationStatusReason = '';
      state.isBackupTimeout = false;
      state.restoreStatuses = null;
    },
    resetStatusMessage: (state) => {
      state.creationStatusMessage = '';
      state.formattedTimestampCreated = null;
      state.creationStatusReason = '';
      state.formattedTimestampFinished = null;
      state.isBackupTimeout = false;
      state.restoreStatuses = null;
    },
  },
});

export const {
  getEntityBackupSettingsSuccess,
  getRestoreAndCreateButtonEnabled,
  getEntityBackupSettingsError,
  createEntityBackupSettingsSuccess,
  createEntityBackupSettingsError,
  listenForRenameOfBackup,
  backupTimeout,
  formatTimeout,
  getBackupProgressSuccess,
  listenForCreationOfBackupSettingsError,
  updateEntityBackupSettingDescriptionError,
  restoreEntityBackupSettingsSuccess,
  restoreEntityBackupSettingsError,
  clearEntityBackupSettings,
  resetStatusMessage,
} = entityBackupSettingsSlice.actions;

export const entityBackupSettingsSelectors = {
  entityBackupSettings: (state: RootState) =>
    state.entityBackupSettings.entityBackupSettings,
  backUpButtons: (state: RootState) => state.entityBackupSettings.backUpButtons,
  successMessage: (state: RootState) =>
    state.entityBackupSettings.successMessage,
  creationStatusMessage: (state: RootState) =>
    state.entityBackupSettings.creationStatusMessage,
  creationStatusReason: (state: RootState) =>
    state.entityBackupSettings.creationStatusReason,
  errorMessage: (state: RootState) => state.entityBackupSettings.errorMessage,
  updatedDescription: (state: RootState) =>
    state.entityBackupSettings.updatedDescription,
  restoreStatusMessage: (state: RootState) =>
    state.entityBackupSettings.restoreStatusMessage,
  restoreStatuses: (state: RootState) =>
    state.entityBackupSettings.restoreStatuses,
  restoreBackUp: (state: RootState) => state.entityBackupSettings.restoreBackUp,
  timeSpanToValidate: (state: RootState) =>
    state.entityBackupSettings.timeSpanToValidate,
  correlationId: (state: RootState) => state.entityBackupSettings.correlationId,
  formattedTimestampCreated: (state: RootState) =>
    state.entityBackupSettings.formattedTimestampCreated,
  formattedTimestampFinished: (state: RootState) =>
    state.entityBackupSettings.formattedTimestampFinished,
  isBackupTimeout: (state: RootState) =>
    state.entityBackupSettings.isBackupTimeout,
  timeForTimeout: (state: RootState) =>
    state.entityBackupSettings.timeForTimeout,
};

export default entityBackupSettingsSlice.reducer;

export const getBackupProgressSettings = createAction<{
  entityId: string;
  accessToken: string;
}>('BACKUP_PROGRESS_REQUEST');

export const createEntityBackupSettingsRequest = createAction<{
  entityId: string;
}>('CREATE_ENTITY_BACKUP_SETTINGS_REQUEST');

export const restoreEntityBackupSettingsRequest = createAction<{
  entityId: string;
  filename: string;
}>('RESTORE_ENTITY_BACKUP_SETTING_REQUEST');

export const updateEntityBackupSettingDescription = createAction<{
  entityId: string;
  correlationId: string;
  updatedDescription: string;
  tableItemType: string;
  filename: string;
}>('UPDATE_ENTITY_BACKUP_SETTING_DESCRIPTION_REQUEST');

export const getEntityBackupSettings = createAction<{ entityId: string }>(
  'GET_ENTITY_BACKUP_SETTINGS_REQUEST',
);
