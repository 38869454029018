/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { put, take, call, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  eventDetailsSelectors,
  getEventDetailsSuccess,
  getEventDetailsError,
  setLatestAppliedFilters,
  extendEventDetails,
  updateButtonState,
  setExtendedDates,
  getEventDetailsForEntityNew,
} from '../../../reducers/eventDetailsSlice';

import { api } from '../../../api/lelyBackend/eventsPageApi';
import {
  setStartLoading,
  setEndLoading,
} from '../../../common/redux/loadManager/actions';
import { getLoadId } from '../../../common/redux/loadManager/methods';
import { modifyFreeTextObject } from '../../../common/alarmSearchService';
import { EventsButtonType, SelectedEventDetails } from '../types';
import { getDateInTFormatWithMs } from '../../../common/domains';
import { AwaitedApiResultType } from '../../../common/types';

let currentLoadId = 0;
export function* eventPageDetailsSaga() {
  while (true) {
    const eventDetailsAction = yield take(getEventDetailsForEntityNew.type);
    const offset = yield select(eventDetailsSelectors.latestOffset);
    const latestAppliedFilters = yield select(
      eventDetailsSelectors.latestAppliedFilters,
    );

    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));
    const { entityId, startDateToTimestamp, endDateToTimestamp, limit } =
      eventDetailsAction.payload;

    yield call(
      getEventDetails,
      entityId,
      startDateToTimestamp,
      endDateToTimestamp,
      latestAppliedFilters.appliedTypes,
      latestAppliedFilters.appliedSearchText,
      offset,
      limit,
    );
  }
}

/** Create request to get all events of current entity
 * @param {any} entityId - entity ID
 * @param {string} startDateToTimestamp - Start date timestamp
 * @param {string} endDateToTimestamp - End date to timestamp
 * @param {array} namesOfSelectedTypes - Array of selected type names
 * @param {Object} searchParam - Search params
 * @param {Number} offset - Initial result which to receive
 * @param {Number} limit - Number of results after initial result to receive
 */
export function* getEventDetails(
  entityId: string,
  startDateToTimestamp: string | undefined,
  endDateToTimestamp: string | undefined,
  namesOfSelectedTypes: string[],
  searchParam: Record<string, string | string[]> | [],
  offset: number,
  limit: number,
) {
  try {
    const modifiedSearchParams = modifyFreeTextObject(searchParam);
    const eventsForEntityData: AwaitedApiResultType<
      typeof api.getEventsForEntity
    > = yield call(
      api.getEventsForEntity,
      entityId,
      startDateToTimestamp,
      endDateToTimestamp,
      namesOfSelectedTypes,
      modifiedSearchParams,
      offset,
      limit,
    );
    const eventsForEntity = eventsForEntityData.data;
    yield put(getEventDetailsSuccess(eventsForEntity));
    // yield put(
    //   setExtendedDates({
    //     start: startDateToTimestamp,
    //     end: endDateToTimestamp,
    //   }),
    // );
    /** Stop loader */
    yield put(setEndLoading(currentLoadId));
  } catch (error: any) {
    yield put(getEventDetailsError(error));
    yield put(setEndLoading(currentLoadId));
  }
}

export function* getMoreEvents(
  action: PayloadAction<{
    entityId: string;
    type: EventsButtonType;
    limit?: number;
    item?: SelectedEventDetails;
  }>,
) {
  currentLoadId = getLoadId();
  yield put(setStartLoading(currentLoadId));
  const { entityId, type, limit, item } = action.payload;

  const { appliedTypes, appliedSearchText } = yield select(
    eventDetailsSelectors.latestAppliedFilters,
  );
  const modifiedSearchParams = modifyFreeTextObject(appliedSearchText);

  yield call(
    getMoreEventsDetails,
    entityId,
    appliedTypes,
    modifiedSearchParams,
    type,
    item,
    limit,
  );
}

export function* getMoreEventsDetails(
  entityId: string,
  namesOfSelectedTypes: string[],
  modifiedSearchParams: Record<string, string | string[]> | [],
  type: EventsButtonType,
  item?: SelectedEventDetails,
  limit = 10,
) {
  try {
    const eventsForEntityData: AwaitedApiResultType<typeof api.getMoreEvents> =
      yield call(
        api.getMoreEvents,
        entityId,
        namesOfSelectedTypes,
        modifiedSearchParams,
        limit,
        type,
        item?.id,
      );
    const events = eventsForEntityData.data;
    yield put(
      extendEventDetails({
        type,
        events,
        item: item?.id || null,
      }),
    );
    yield put(
      updateButtonState({
        eventsLength: events.length,
        type,
        offset: limit,
      }),
    );

    yield put(setEndLoading(currentLoadId));
  } catch (error: any) {
    yield put(getEventDetailsError(error));
    yield put(setEndLoading(currentLoadId));
  }
}
