import { put, take, call } from 'redux-saga/effects';
import { BACKUP_PROGRESS_REQUEST } from './types';
import {
  getBackupProgressSuccess,
  listenForCreationOfBackupSettingsError,
} from '../../reducers/entityBackupSettingsSlice';

export default function* getBackupProgressSaga(api) {
  while (true) {
    const listenForBackupCreationSettingsAction = yield take(
      BACKUP_PROGRESS_REQUEST,
    );

    const { entityId } = listenForBackupCreationSettingsAction.payload;
    yield call(getBackupProgressSettings, api, entityId);
  }
}

function* getBackupProgressSettings(api, entityId) {
  try {
    const backupRequest = yield call(api.getBackupProgress, entityId);
    const backupResponse = backupRequest.data;
    yield put(getBackupProgressSuccess(backupResponse));
  } catch (error) {
    const errorStatusMessage = error?.response?.data?.message;
    yield put(listenForCreationOfBackupSettingsError({ errorStatusMessage }));
  }
}
