import { AxiosResponse } from 'axios';
import { call, put, take } from 'redux-saga/effects';
import { UpdatesApiType } from '../../api/lelyBackend/updatesApi';
import {
  setEndLoading,
  setStartLoading,
} from '../../common/redux/loadManager/actions';
import { getLoadId } from '../../common/redux/loadManager/methods';
import {
  getUpdatesAction,
  setIsUpdatesError,
  setUpdates,
} from '../../reducers/updatesSlice';
import { UpdatesType } from './types';

let currentLoadId = 0;

export default function* updatesSaga(api: UpdatesApiType) {
  while (true) {
    const updatesDetailsAction = yield take(getUpdatesAction);
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));
    const { id, fromDate, toDate } = updatesDetailsAction.payload;
    yield call(fetchUpdates, api, id, fromDate, toDate);
  }
}

function* fetchUpdates(api: UpdatesApiType, id, fromDate, toDate) {
  try {
    const response: AxiosResponse<UpdatesType[]> = yield call(
      api.getUpdates,
      id,
      fromDate,
      toDate,
    );
    yield put(setUpdates(response.data));
    yield put(setEndLoading(currentLoadId));
  } catch (error: any) {
    yield put(setIsUpdatesError(error));
    yield put(setEndLoading(currentLoadId));
  }
}
