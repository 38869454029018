interface Storage {
  /**
   * Sets the object of the pair identified by key to value, creating a new key/value pair if none existed for key previously.
   * optionally you can use custom type of serialization.
   * Extends localStorage.setItem
   * @param {string} key - key in localStorage
   * @param {Object} value - value could be any type of object
   * @param {Function} serialize - optional serialize function, default one is JSON.stringify
   */
  setObject: <T extends Object, K extends string>(
    key: K,
    value: T,
    serialize?: Function,
  ) => void;
  /**
   * Returns the current object associated with the given key, or null if the given key does not exist.
   * Optionally you can use custom type of deserialization.
   * Extends localStorage.getItem
   * @param {string} key - key in localStorage
   * @param {Function} deserialize - optional deserialize function, default one is JSON.parse
   * @returns {(Object|null)} - returns Object or null
   */
  getObject: <T extends Object, K extends string>(
    key: K,
    deserialize?: Function,
  ) => T | null;

  getOrDefaultObject: <T extends Object, K extends string>(
    key: K,
    defaultValue: string | number | string[] | number[] | boolean | object,
    deserialize?: Function,
  ) => T;

  updateObjectProperty: <T extends Object, K extends string>(
    objectKey: K,
    propertyKey: keyof T,
    propertyValue: T[keyof T],
  ) => void;

  updateObjectPropertyKey: <T extends Object, K extends string>(
    objectKey: K,
    propertyKey: keyof T,
    innerPropertyKey: string,
    innerPropertyValue: any,
  ) => void;
}

Storage.prototype.setObject = function setObject(
  key: T,
  value,
  serialize = JSON.stringify,
) {
  try {
    this.setItem(key, serialize(value));
  } catch {
    this.removeItem(key);
  }
};

Storage.prototype.getObject = function getObject<T>(
  key: string,
  deserialize = JSON.parse,
): T {
  const value = this.getItem(key);
  try {
    return value ? deserialize(value) : null;
  } catch {
    return null;
  }
};

Storage.prototype.getOrDefaultObject = function getObject<
  T,
  K extends string | number | string[] | number[] | boolean | object,
>(key: string, defaultValue: K, deserialize = JSON.parse): T {
  const value = this.getItem(key);
  if (value) {
    try {
      return value ? deserialize(value) : null;
    } catch {
      return null;
    }
  } else {
    this.setItem(key, JSON.stringify(defaultValue));
    return defaultValue;
  }
};

Storage.prototype.updateObjectProperty = function updateObjectProperty<T>(
  objectKey: string,
  propertyKey: keyof T,
  propertyValue: T[keyof T],
) {
  const object = this.getObject(objectKey);
  if (object) {
    object[propertyKey] = propertyValue;
    this.setObject(objectKey, object);
  } else {
    this.setObject(objectKey, { [propertyKey]: propertyValue });
  }
};

Storage.prototype.updateObjectPropertyKey = function updateObjectProperty<T>(
  objectKey: string,
  propertyKey: keyof T,
  innerPropertyKey: string,
  innerPropertyValue: T[keyof T],
) {
  const object = this.getObject(objectKey);
  if (!object) {
    this.setObject(objectKey, {
      [propertyKey]: { [innerPropertyKey]: innerPropertyValue },
    });
  } else {
    const innerObject = object[propertyKey];
    if (innerObject) {
      innerObject[innerPropertyKey] = innerPropertyValue;
    } else {
      object[propertyKey] = { [innerPropertyKey]: innerPropertyValue };
    }
    this.setObject(objectKey, object);
  }
};
