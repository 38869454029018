import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import {
  IPublicClientApplication,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';
import { useDispatch } from 'react-redux';
import axiosInstance from '../../api/axiosInstance';
import {
  ApisWithoutAccessToken,
  ApisWithoutRefreshing,
} from '../../api/lelyBackend/config';
import { api } from '../../api/lelyBackend/loginApi';
import { LOCAL_STORAGE_KEYS } from '../../common/constants';
import { store } from '../../configureStore';
import {
  resetNetworkErrorMessage,
  setNetworkError,
} from '../../reducers/networkErrorSlice';
import {
  setAccessTokenAndRefreshToken,
  setLoginSuccess,
} from '../../reducers/userSessionSlice';
import { getUserRoles } from '../../common/components/UserRoles/actions';
import { setInterceptorsStatus } from '../../reducers/interceptorsSlice';
import { getFarmNameParams } from './interceptorsUtils';

let isRefreshing = false;
let failedQueue: any[] = [];
const processQueue = (error, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const AxiosInterceptors = ({
  children,
}: {
  instance: IPublicClientApplication;
  children: React.ReactElement;
}) => {
  const dispatch = useDispatch();
  const { accounts, instance } = useMsal();

  useEffect(() => {
    const reqInteceptor = (request) => {
      store.dispatch(resetNetworkErrorMessage());
      const accessToken = localStorage.getObject(
        LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
      );

      const { shouldAddFarmName, farmName } = getFarmNameParams(request);
      const withoutAccessToken = !ApisWithoutAccessToken.find((item) => {
        return request.url?.match(item);
      });

      if (shouldAddFarmName) {
        if (request.method === 'get') {
          request.params = {
            ...request.params,
            farmName,
          };
        } else {
          request.data = {
            ...request.data,
            farmName,
          };
        }
      }

      if (accessToken && withoutAccessToken) {
        request.headers = {
          Authorization: `Bearer ${accessToken}`,
        };
      }

      return request;
    };

    const resInteceptor = (response) => {
      return response;
    };

    const errInteceptor = async (error: any): Promise<any> => {
      const originalRequest = error.config;
      /* eslint-disable no-underscore-dangle */
      const requestsWithRetry = !ApisWithoutRefreshing.find((item) => {
        return originalRequest.url?.match(item);
      });

      if (error?.message === 'Network Error') {
        store.dispatch(setNetworkError(error));
      }

      if (
        error.response.status === 401 &&
        !originalRequest._retry &&
        requestsWithRetry
      ) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              return axiosInstance(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;
        const { userSession } = store.getState();
        const { accessToken, refreshToken } = userSession;

        return new Promise((resolve, reject) => {
          if (accounts.length > 0) {
            const tokenRequest = {
              account: accounts[0],
              scopes: ['User.Read'],
              forceRefresh: true,
            };

            instance
              .acquireTokenSilent(tokenRequest)
              .then((response) => {
                // eslint-disable-next-line no-console
                localStorage.setObject(
                  LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
                  response.idToken,
                );

                dispatch(
                  setLoginSuccess({
                    username: response.account.username,
                    accessToken: response.idToken,
                    refreshToken: (response as any).refreshToken, // missing
                    userRoles: [],
                  }),
                );
                dispatch(getUserRoles());
                isRefreshing = false;
                originalRequest.headers.Authorization = `Bearer ${response.idToken}`;
                processQueue(null, response.idToken);
                resolve(axiosInstance(originalRequest));
              })
              .catch(async (e) => {
                // Catch interaction_required errors and call interactive method to resolve
                if (e instanceof InteractionRequiredAuthError) {
                  await instance.acquireTokenRedirect(tokenRequest);
                }

                throw e;
              });
          } else {
            api
              .refreshAccessToken(accessToken, refreshToken)
              .then(({ data }) => {
                localStorage.setObject(
                  LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
                  data.accessToken,
                );
                store.dispatch(
                  setAccessTokenAndRefreshToken({
                    accessToken: data.accessToken,
                    refreshToken: data.refreshToken,
                  }),
                );
                isRefreshing = false;
                originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
                processQueue(null, data.accessToken);
                resolve(axiosInstance(originalRequest));
              })
              .catch((errorRefresh) => {
                store.dispatch(setNetworkError(errorRefresh));
                reject(errorRefresh);
              })
              .finally(() => {
                isRefreshing = false;
              });
          }
        });
      }

      store.dispatch(setNetworkError(error));
      return Promise.reject(error);
    };

    const reqInterceptor =
      axiosInstance.interceptors.request.use(reqInteceptor);

    const resInterceptor = axiosInstance.interceptors.response.use(
      resInteceptor,
      errInteceptor,
    );

    dispatch(setInterceptorsStatus('initialized'));

    return () => {
      axiosInstance.interceptors.request.eject(reqInterceptor);
      axiosInstance.interceptors.response.eject(resInterceptor);
      dispatch(setInterceptorsStatus('not-initialized'));
    };
  }, [instance, accounts, dispatch]);

  return children;
};

export default AxiosInterceptors;
