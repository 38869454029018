import { useEffect } from 'react';

export const MOPINION_FORM_ID = 'deae9b878dbf0cc29172db58e9894a1190977207';

const useMopinion = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '/mopinion.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
};

export default useMopinion;
