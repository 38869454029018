import axiosInstance from '../axiosInstance';
import { UPDATE_LANGUAGE, GET_LANGUAGES } from './config';

export const MOCK_UPDATE_LANGUAGE_RESPONSE = {
  data: {
    language: 'en',
  },
};

export const MOCK_UPDATE_LANGUAGE_ERROR = 'language error';

export const MOCK_GET_ALL_LANGUAGES_RESPONSE = {
  data: {
    languages: [{ code: 'en', language: 'English' }],
  },
};
export const api = {
  async updateLelyPreferredLanguage(language) {
    const languageData = { languageCode: language };
    return axiosInstance.put(UPDATE_LANGUAGE, languageData);
  },

  async getLanguages() {
    return axiosInstance.get(GET_LANGUAGES);
  },
};
