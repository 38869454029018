import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import escapeRegExp from 'lodash/escapeRegExp';
import { FarmOptionsTypes } from '../components/TableFilter/types';
import {
  AdvancedSettingsEnum,
  SettingsFilterStringType,
  SettingsTypes,
} from '../containers/Settings/types';
import { settingsOptionsSelectors } from './entitySettingsOptionsSlice';
import { selectColumns } from './farmsOptionsSlice';
import { RootState } from './rootReducer';

export const entitySettingsInitialState: SettingsTypes.EntitySettingsInitialState =
  {
    entitySettings: [],
    errorMessage: '',
    filterSetting: SettingsFilterStringType.None,
    searchText: '',
  };

// createSlice
export const entitySettingsSlice = createSlice({
  name: 'entitySettings',
  initialState: entitySettingsInitialState,
  reducers: {
    setEntitySettingsSuccess: (
      state,
      action: PayloadAction<SettingsTypes.EntitySettingType[]>,
    ) => {
      state.entitySettings = action.payload;
      state.errorMessage = '';
    },
    setFilterSetting: (state, action: PayloadAction<string>) => {
      state.filterSetting = action.payload;
    },
    setSettingsSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    setSettingsErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
      state.entitySettings = [];
    },
    clearEntitySettings: () => entitySettingsInitialState,
  },
});

export const {
  setEntitySettingsSuccess,
  setFilterSetting,
  setSettingsSearchText,
  setSettingsErrorMessage,
  clearEntitySettings,
} = entitySettingsSlice.actions;

export const getEntitySettings = createAction<{
  externalId: string;
  entityIdsToCompare: string[];
}>('GET_ENTITY_SETTINGS_REQUEST');

export const entitySettingsSelectors = {
  selectEntitySettings: (state: RootState) =>
    state.entitySettings.entitySettings,
  selectFilterString: (state: RootState) => state.entitySettings.filterSetting,
  selectSettingsSearchText: (state: RootState) =>
    state.entitySettings.searchText,
};

const memoizedArray = [];
export const getFilteredSettings = createSelector(
  entitySettingsSelectors.selectEntitySettings,
  settingsOptionsSelectors.selectAdvancedSettings,
  entitySettingsSelectors.selectFilterString,
  entitySettingsSelectors.selectSettingsSearchText,
  selectColumns,
  (
    entitySettings,
    advancedSettings,
    filterString,
    searchText,
    dynamicColumns,
  ) => {
    if (dynamicColumns === null) return memoizedArray;

    const flattenedDynamicColumns = dynamicColumns.flat();
    const filteredSettings = entitySettings.filter((singleSetting) => {
      // filter default settings if filter is "hide default"
      if (
        filterString === SettingsFilterStringType.Default &&
        singleSetting.default
      ) {
        return false;
      }

      if (
        singleSetting.settingRole !== AdvancedSettingsEnum.User &&
        !advancedSettings.includes(singleSetting.settingRole)
      ) {
        return false;
      }

      // filter identical values if filter is "hide identical values astronaut-{number}"
      const chosenIdenticalColumn =
        singleSetting.dynamicColumns &&
        singleSetting.dynamicColumns.find((astronaut) => {
          return (
            astronaut.columnName.toLowerCase() === filterString.toLowerCase()
          );
        });

      const searchTextMatch = getEscapedSettingMatch(
        singleSetting,
        searchText,
        flattenedDynamicColumns,
      );
      if (chosenIdenticalColumn) {
        return !chosenIdenticalColumn.isIdentical && !!searchTextMatch;
      }

      return searchTextMatch;
    });

    return filteredSettings;
  },
);

export const getEscapedSettingMatch = (
  setting: SettingsTypes.EntitySettingType,
  searchText: string,
  dynamicColumns: FarmOptionsTypes.Selection[],
) => {
  const escapedSearchText = escapeRegExp(searchText);

  if (escapedSearchText === '') {
    return true;
  }

  const isAnyMatching = dynamicColumns.find((col) => {
    return (
      col.searchable &&
      setting[col.key]?.toLowerCase().match(escapedSearchText.toLowerCase())
    );
  });

  return isAnyMatching;
};

export default entitySettingsSlice.reducer;
