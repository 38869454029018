import {
  GET_COMPONENTS_FOR_DOMAIN_AND_ENTITY_REQUEST_SUCCESS,
  GET_COMPONENTS_FOR_DOMAIN_AND_ENTITY_REQUEST_ERROR,
} from '../components/DomainElements/types';

/** Store states
 * @constant {Object} */
export const initialState = {
  componentsData: {},
  isComponentsError: false,
  errorMessage: '',
};

/** Components Reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function componentsDataReducer(state = initialState, action = {}) {
  switch (action.type) {
    /** Action Creator - Set components data to store if
     * API request passed successfully */
    case GET_COMPONENTS_FOR_DOMAIN_AND_ENTITY_REQUEST_SUCCESS:
      return {
        ...state,
        componentsData: action.payload.componentsData,
        isComponentsError: false,
        errorMessage: '',
      };

    /** Action Creator - Set components data to store if
     * API request passed successfully */
    case GET_COMPONENTS_FOR_DOMAIN_AND_ENTITY_REQUEST_ERROR:
      return {
        ...state,
        componentsData: {},
        isComponentsError: true,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
}

export default componentsDataReducer;
