import {
  GET_CANBUS_TROUBLESHOOTS_SUCCESS,
  GET_CANBUS_TROUBLESHOOTS_ERROR,
  CURRENT_CANBUS_TROUBLESHOOTS_SCREEN,
  PREVIOUS_CANBUS_TROUBLESHOOT_SCREEN,
  CLEAR_CANBUS_TROUBLESHOOTS_DATA,
  GET_CANBUS_TROUBLESHOOTS_VERSION,
} from '../common/redux/canbusTroubleshooter/types';
import { getTroubleshootDataById } from '../common/domains';

/** Store states
 * @constant {Object} */
export const initialState = {
  initialTroubleshootData: [],
  currentScreenTroubleshootData: {},
  canbusTroubleshootResponseVersion: '',
  currentTutorialFilename: '',
  previousScreens: [],
  errorMessage: '',
};

/** Canbus troubleshoot Reducer.
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function canbusTroubleshootReducer(state = initialState, action = {}) {
  let selectedScreenTroubleshootData = {};
  switch (action.type) {
    /** Action Creator - Set troubleshoot data to store
     * if API request passed successfully */
    case GET_CANBUS_TROUBLESHOOTS_SUCCESS:
      return {
        ...state,
        initialTroubleshootData: action.payload.canbusTroubleshootResponse,
        currentTutorialFilename: action.payload.tutorialFileName,
        previousScreens: [],
        errorMessage: '',
      };
    /** Action Creator - Set troubleshoot topic version to store
     * if API request passed successfully */
    case GET_CANBUS_TROUBLESHOOTS_VERSION:
      return {
        ...state,
        canbusTroubleshootResponseVersion:
          action.payload.canbusTroubleshootResponseVersion,
      };
    /** Action Creator - By the provided name find in the store
     * the whole data for this screen and then put it in the store */
    case CURRENT_CANBUS_TROUBLESHOOTS_SCREEN:
      if (action.payload.idOfTheNextScreen !== '') {
        // Get current screen data
        selectedScreenTroubleshootData = getTroubleshootDataById(
          state.initialTroubleshootData,
          action.payload.idOfTheNextScreen,
        );
      }
      return {
        ...state,
        currentScreenTroubleshootData: selectedScreenTroubleshootData[0],
        errorMessage: '',
        previousScreens: [
          ...state.previousScreens,
          action.payload.idOfTheNextScreen,
        ],
      };

    /** Action Creator - Clear troubleshoot data */
    case PREVIOUS_CANBUS_TROUBLESHOOT_SCREEN: {
      const indexOfElement = state.previousScreens.indexOf(
        action.payload.currentScreenId,
      );
      const newPreviousPath = state.previousScreens.slice(0, indexOfElement);
      const lastScreenId = newPreviousPath[newPreviousPath.length - 1];
      // Get currents screen data
      selectedScreenTroubleshootData = getTroubleshootDataById(
        state.initialTroubleshootData,
        lastScreenId,
      );
      return {
        ...state,
        previousScreens: newPreviousPath,
        currentScreenTroubleshootData: selectedScreenTroubleshootData[0],
      };
    }
    /** Action Creator - Clear troubleshoot data */
    case CLEAR_CANBUS_TROUBLESHOOTS_DATA:
      return {
        ...state,
        currentScreenTroubleshootData: {},
      };

    /** Action Creator - Show error message if
     * request to API failed */
    case GET_CANBUS_TROUBLESHOOTS_ERROR:
      return {
        ...state,
        initialTroubleshootData: [],
        currentScreenTroubleshootData: {},
        selectedTutorialFileName: '',
        errorMessage: action.payload,
      };

    default:
      return state;
  }
}

export default canbusTroubleshootReducer;
