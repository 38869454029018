import axiosInstance from '../axiosInstance';
import { GET_FARM_ENTITIES_SERVICE_URL } from './config';

// const MOCK_ENTITY_DETAILS_RESPONSE = {
//   data: [
//     {
//       entities: [
//         {
//           id: '14634333-e3f0-e911-aac8-74da38c2319f',
//           name: 'astronaut-101',
//           isVector: false,
//           stateColor: '#73BD07',
//           kpis: [
//             {
//               kpiName: 'Milking',
//               current: '130',
//               weeklyAverage: '143.4',
//               color: '#73BD07',
//               icon: 'lely-icon-milk',
//               unit: '',
//               hasFormat: false,
//               weeklyData: [
//                 {
//                   date: '2021-03-26T00:00:00',
//                   value: '149',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-27T00:00:00',
//                   value: '151',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-28T00:00:00',
//                   value: '147',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-29T00:00:00',
//                   value: '156',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-30T00:00:00',
//                   value: '136',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-31T00:00:00',
//                   value: '135',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-04-01T00:00:00',
//                   value: '130',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//               ],
//             },
//             {
//               kpiName: 'Failed Milking',
//               current: '0',
//               weeklyAverage: '1.0',
//               color: '#73BD07',
//               icon: 'lely-icon-dry-off',
//               unit: '',
//               hasFormat: false,
//               weeklyData: [
//                 {
//                   date: '2021-03-26T00:00:00',
//                   value: '1',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-27T00:00:00',
//                   value: '1',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-28T00:00:00',
//                   value: '1',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-29T00:00:00',
//                   value: '2',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-30T00:00:00',
//                   value: '1',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-31T00:00:00',
//                   value: '1',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-04-01T00:00:00',
//                   value: '0',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//               ],
//             },
//             {
//               kpiName: 'Connection Time',
//               current: '28.3',
//               weeklyAverage: '25.6',
//               color: '#73BD07',
//               icon: 'lely-icon-clock',
//               unit: 's',
//               hasFormat: false,
//               weeklyData: [
//                 {
//                   date: '2021-03-26T00:00:00',
//                   value: '25.5',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-27T00:00:00',
//                   value: '25',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-28T00:00:00',
//                   value: '26.6',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-29T00:00:00',
//                   value: '25.6',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-30T00:00:00',
//                   value: '24.3',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-31T00:00:00',
//                   value: '23.7',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-04-01T00:00:00',
//                   value: '28.3',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//               ],
//             },
//             {
//               kpiName: 'Box Time',
//               current: '7m 28s',
//               weeklyAverage: 'N/A',
//               color: '#73BD07',
//               icon: 'lely-icon-clock',
//               unit: '',
//               hasFormat: true,
//               weeklyData: [
//                 {
//                   date: '2021-03-26T00:00:00',
//                   value: '404.5',
//                   color: '#73BD07',
//                   valueFormatted: '6m 44s',
//                 },
//                 {
//                   date: '2021-03-27T00:00:00',
//                   value: '417',
//                   color: '#73BD07',
//                   valueFormatted: '6m 57s',
//                 },
//                 {
//                   date: '2021-03-28T00:00:00',
//                   value: '444.8',
//                   color: '#ff1744',
//                   valueFormatted: '7m 24s',
//                 },
//                 {
//                   date: '2021-03-29T00:00:00',
//                   value: '403.1',
//                   color: '#73BD07',
//                   valueFormatted: '6m 43s',
//                 },
//                 {
//                   date: '2021-03-30T00:00:00',
//                   value: '429.5',
//                   color: '#ff1744',
//                   valueFormatted: '7m 9s',
//                 },
//                 {
//                   date: '2021-03-31T00:00:00',
//                   value: '428',
//                   color: '#ff1744',
//                   valueFormatted: '7m 8s',
//                 },
//                 {
//                   date: '2021-04-01T00:00:00',
//                   value: '448.6',
//                   color: '#ff1744',
//                   valueFormatted: '7m 28s',
//                 },
//               ],
//             },
//           ],
//           infoGraphic: {
//             handedness: 'R',
//             options: [
//               {
//                 name: 'TCS',
//                 color: '#850C18',
//                 isInstalled: true,
//               },
//               {
//                 name: 'FPS',
//                 color: '#850C18',
//                 isInstalled: true,
//               },
//               {
//                 name: 'Pura',
//                 color: '#850C18',
//                 isInstalled: true,
//               },
//               {
//                 name: 'Scale',
//                 color: '#CFCBC3',
//                 isInstalled: false,
//               },
//               {
//                 name: 'MQCC',
//                 color: '#CFCBC3',
//                 isInstalled: false,
//               },
//               {
//                 name: 'M4U',
//                 color: '#850C18',
//                 isInstalled: false,
//               },
//               {
//                 name: 'Jersey',
//                 color: '#CFCBC3',
//                 isInstalled: false,
//               },
//             ],
//             centralUnitAddress: '211',
//             connectedEntityLocalLdn: '102',
//             entityLocalLdn: '101',
//             threeWayValve: {
//               icon: 'lely-icon-valve',
//               options: [
//                 {
//                   name: '1',
//                   color: '#CFCBC3',
//                   isInstalled: false,
//                 },
//                 {
//                   name: '2',
//                   color: '#850C18',
//                   isInstalled: true,
//                 },
//                 {
//                   name: '3',
//                   color: '#CFCBC3',
//                   isInstalled: false,
//                 },
//               ],
//             },
//             version: '5.2.?',
//             totalAlarms: {
//               description: 'Alarms',
//               statisticDetails: [
//                 {
//                   name: 'Last week',
//                   value: 0,
//                 },
//                 {
//                   name: 'Last 24h',
//                   value: 0,
//                 },
//               ],
//             },
//           },
//         },
//         {
//           id: '15634333-e3f0-e911-aac8-74da38c2319f',
//           name: 'astronaut-102',
//           isVector: false,
//           stateColor: '#73BD07',
//           kpis: [
//             {
//               kpiName: 'Milking',
//               current: '149',
//               weeklyAverage: '155.3',
//               color: '#73BD07',
//               icon: 'lely-icon-milk',
//               unit: '',
//               hasFormat: false,
//               weeklyData: [
//                 {
//                   date: '2021-03-26T00:00:00',
//                   value: '151',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-27T00:00:00',
//                   value: '159',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-28T00:00:00',
//                   value: '158',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-29T00:00:00',
//                   value: '163',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-30T00:00:00',
//                   value: '155',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-31T00:00:00',
//                   value: '152',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-04-01T00:00:00',
//                   value: '149',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//               ],
//             },
//             {
//               kpiName: 'Failed Milking',
//               current: '0',
//               weeklyAverage: '0.9',
//               color: '#73BD07',
//               icon: 'lely-icon-dry-off',
//               unit: '',
//               hasFormat: false,
//               weeklyData: [
//                 {
//                   date: '2021-03-26T00:00:00',
//                   value: '1',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-27T00:00:00',
//                   value: '2',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-28T00:00:00',
//                   value: '1',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-29T00:00:00',
//                   value: '1',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-30T00:00:00',
//                   value: '1',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-31T00:00:00',
//                   value: '0',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-04-01T00:00:00',
//                   value: '0',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//               ],
//             },
//             {
//               kpiName: 'Connection Time',
//               current: '23.8',
//               weeklyAverage: '23.9',
//               color: '#73BD07',
//               icon: 'lely-icon-clock',
//               unit: 's',
//               hasFormat: false,
//               weeklyData: [
//                 {
//                   date: '2021-03-26T00:00:00',
//                   value: '24.3',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-27T00:00:00',
//                   value: '23.2',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-28T00:00:00',
//                   value: '24.1',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-29T00:00:00',
//                   value: '23.5',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-30T00:00:00',
//                   value: '23.4',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-31T00:00:00',
//                   value: '25.3',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-04-01T00:00:00',
//                   value: '23.8',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//               ],
//             },
//             {
//               kpiName: 'Box Time',
//               current: '6m 38s',
//               weeklyAverage: '6m 52s',
//               color: '#73BD07',
//               icon: 'lely-icon-clock',
//               unit: '',
//               hasFormat: true,
//               weeklyData: [
//                 {
//                   date: '2021-03-26T00:00:00',
//                   value: '415.6',
//                   color: '#ff1744',
//                   valueFormatted: '6m 55s',
//                 },
//                 {
//                   date: '2021-03-27T00:00:00',
//                   value: '408.2',
//                   color: '#73BD07',
//                   valueFormatted: '6m 48s',
//                 },
//                 {
//                   date: '2021-03-28T00:00:00',
//                   value: '420.9',
//                   color: '#ff1744',
//                   valueFormatted: '7m 0s',
//                 },
//                 {
//                   date: '2021-03-29T00:00:00',
//                   value: '413.7',
//                   color: '#ff1744',
//                   valueFormatted: '6m 53s',
//                 },
//                 {
//                   date: '2021-03-30T00:00:00',
//                   value: '417.9',
//                   color: '#ff1744',
//                   valueFormatted: '6m 57s',
//                 },
//                 {
//                   date: '2021-03-31T00:00:00',
//                   value: '411.7',
//                   color: '#73BD07',
//                   valueFormatted: '6m 51s',
//                 },
//                 {
//                   date: '2021-04-01T00:00:00',
//                   value: '398.9',
//                   color: '#73BD07',
//                   valueFormatted: '6m 38s',
//                 },
//               ],
//             },
//           ],
//           infoGraphic: {
//             handedness: 'R',
//             options: [
//               {
//                 name: 'TCS',
//                 color: '#CFCBC3',
//                 isInstalled: false,
//               },
//               {
//                 name: 'FPS',
//                 color: '#850C18',
//                 isInstalled: true,
//               },
//               {
//                 name: 'Pura',
//                 color: '#850C18',
//                 isInstalled: true,
//               },
//               {
//                 name: 'Scale',
//                 color: '#CFCBC3',
//                 isInstalled: false,
//               },
//               {
//                 name: 'MQCC',
//                 color: '#CFCBC3',
//                 isInstalled: false,
//               },
//               {
//                 name: 'M4U',
//                 color: '#850C18',
//                 isInstalled: false,
//               },
//               {
//                 name: 'Jersey',
//                 color: '#CFCBC3',
//                 isInstalled: false,
//               },
//             ],
//             centralUnitAddress: '211',
//             connectedEntityLocalLdn: '101',
//             entityLocalLdn: '102',
//             threeWayValve: {
//               icon: 'lely-icon-valve',
//               options: [
//                 {
//                   name: '1',
//                   color: '#CFCBC3',
//                   isInstalled: false,
//                 },
//                 {
//                   name: '2',
//                   color: '#850C18',
//                   isInstalled: true,
//                 },
//                 {
//                   name: '3',
//                   color: '#CFCBC3',
//                   isInstalled: false,
//                 },
//               ],
//             },
//             version: '5.2.?',
//             totalAlarms: {
//               description: 'Alarms',
//               statisticDetails: [
//                 {
//                   name: 'Last week',
//                   value: 0,
//                 },
//                 {
//                   name: 'Last 24h',
//                   value: 0,
//                 },
//               ],
//             },
//           },
//         },
//         {
//           id: '16634333-e3f0-e911-aac8-74da38c2319f',
//           name: 'astronaut-103',
//           isVector: false,
//           stateColor: '#73BD07',
//           kpis: [
//             {
//               kpiName: 'Milking',
//               current: '147',
//               weeklyAverage: '157.6',
//               color: '#73BD07',
//               icon: 'lely-icon-milk',
//               unit: '',
//               hasFormat: false,
//               weeklyData: [
//                 {
//                   date: '2021-03-26T00:00:00',
//                   value: '169',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-27T00:00:00',
//                   value: '157',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-28T00:00:00',
//                   value: '155',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-29T00:00:00',
//                   value: '156',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-30T00:00:00',
//                   value: '164',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-31T00:00:00',
//                   value: '155',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-04-01T00:00:00',
//                   value: '147',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//               ],
//             },
//             {
//               kpiName: 'Failed Milking',
//               current: '0',
//               weeklyAverage: '1.6',
//               color: '#73BD07',
//               icon: 'lely-icon-dry-off',
//               unit: '',
//               hasFormat: false,
//               weeklyData: [
//                 {
//                   date: '2021-03-26T00:00:00',
//                   value: '1',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-27T00:00:00',
//                   value: '2',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-28T00:00:00',
//                   value: '1',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-29T00:00:00',
//                   value: '2',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-30T00:00:00',
//                   value: '4',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-31T00:00:00',
//                   value: '1',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-04-01T00:00:00',
//                   value: '0',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//               ],
//             },
//             {
//               kpiName: 'Connection Time',
//               current: '26',
//               weeklyAverage: '23.6',
//               color: '#73BD07',
//               icon: 'lely-icon-clock',
//               unit: 's',
//               hasFormat: false,
//               weeklyData: [
//                 {
//                   date: '2021-03-26T00:00:00',
//                   value: '23',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-27T00:00:00',
//                   value: '23.4',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-28T00:00:00',
//                   value: '22.7',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-29T00:00:00',
//                   value: '21.4',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-30T00:00:00',
//                   value: '24.6',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-31T00:00:00',
//                   value: '24',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-04-01T00:00:00',
//                   value: '26',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//               ],
//             },
//             {
//               kpiName: 'Box Time',
//               current: '6m 51s',
//               weeklyAverage: '6m 37s',
//               color: '#73BD07',
//               icon: 'lely-icon-clock',
//               unit: '',
//               hasFormat: true,
//               weeklyData: [
//                 {
//                   date: '2021-03-26T00:00:00',
//                   value: '391.4',
//                   color: '#73BD07',
//                   valueFormatted: '6m 31s',
//                 },
//                 {
//                   date: '2021-03-27T00:00:00',
//                   value: '399.8',
//                   color: '#ff1744',
//                   valueFormatted: '6m 39s',
//                 },
//                 {
//                   date: '2021-03-28T00:00:00',
//                   value: '417.8',
//                   color: '#ff1744',
//                   valueFormatted: '6m 57s',
//                 },
//                 {
//                   date: '2021-03-29T00:00:00',
//                   value: '379',
//                   color: '#73BD07',
//                   valueFormatted: '6m 18s',
//                 },
//                 {
//                   date: '2021-03-30T00:00:00',
//                   value: '394.4',
//                   color: '#73BD07',
//                   valueFormatted: '6m 34s',
//                 },
//                 {
//                   date: '2021-03-31T00:00:00',
//                   value: '390.3',
//                   color: '#73BD07',
//                   valueFormatted: '6m 30s',
//                 },
//                 {
//                   date: '2021-04-01T00:00:00',
//                   value: '411.9',
//                   color: '#ff1744',
//                   valueFormatted: '6m 51s',
//                 },
//               ],
//             },
//           ],
//           infoGraphic: {
//             handedness: 'R',
//             options: [
//               {
//                 name: 'TCS',
//                 color: '#CFCBC3',
//                 isInstalled: false,
//               },
//               {
//                 name: 'FPS',
//                 color: '#850C18',
//                 isInstalled: true,
//               },
//               {
//                 name: 'Pura',
//                 color: '#850C18',
//                 isInstalled: true,
//               },
//               {
//                 name: 'Scale',
//                 color: '#CFCBC3',
//                 isInstalled: false,
//               },
//               {
//                 name: 'MQCC',
//                 color: '#CFCBC3',
//                 isInstalled: false,
//               },
//               {
//                 name: 'M4U',
//                 color: '#850C18',
//                 isInstalled: false,
//               },
//               {
//                 name: 'Jersey',
//                 color: '#CFCBC3',
//                 isInstalled: false,
//               },
//             ],
//             centralUnitAddress: '212',
//             connectedEntityLocalLdn: '104',
//             entityLocalLdn: '103',
//             threeWayValve: {
//               icon: 'lely-icon-valve',
//               options: [
//                 {
//                   name: '1',
//                   color: '#CFCBC3',
//                   isInstalled: false,
//                 },
//                 {
//                   name: '2',
//                   color: '#850C18',
//                   isInstalled: true,
//                 },
//                 {
//                   name: '3',
//                   color: '#CFCBC3',
//                   isInstalled: false,
//                 },
//               ],
//             },
//             version: '5.2.?',
//             totalAlarms: {
//               description: 'Alarms',
//               statisticDetails: [
//                 {
//                   name: 'Last week',
//                   value: 2,
//                 },
//                 {
//                   name: 'Last 24h',
//                   value: 0,
//                 },
//               ],
//             },
//           },
//         },
//         {
//           id: '17634333-e3f0-e911-aac8-74da38c2319f',
//           name: 'astronaut-104',
//           isVector: false,
//           stateColor: '#73BD07',
//           kpis: [
//             {
//               kpiName: 'Milking',
//               current: '139',
//               weeklyAverage: '148.6',
//               color: '#73BD07',
//               icon: 'lely-icon-milk',
//               unit: '',
//               hasFormat: false,
//               weeklyData: [
//                 {
//                   date: '2021-03-26T00:00:00',
//                   value: '156',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-27T00:00:00',
//                   value: '152',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-28T00:00:00',
//                   value: '152',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-29T00:00:00',
//                   value: '150',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-30T00:00:00',
//                   value: '147',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-31T00:00:00',
//                   value: '144',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-04-01T00:00:00',
//                   value: '139',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//               ],
//             },
//             {
//               kpiName: 'Failed Milking',
//               current: '0',
//               weeklyAverage: '1.9',
//               color: '#73BD07',
//               icon: 'lely-icon-dry-off',
//               unit: '',
//               hasFormat: false,
//               weeklyData: [
//                 {
//                   date: '2021-03-26T00:00:00',
//                   value: '8',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-27T00:00:00',
//                   value: '3',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-28T00:00:00',
//                   value: '0',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-29T00:00:00',
//                   value: '1',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-30T00:00:00',
//                   value: '1',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-31T00:00:00',
//                   value: '0',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-04-01T00:00:00',
//                   value: '0',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//               ],
//             },
//             {
//               kpiName: 'Connection Time',
//               current: '29.5',
//               weeklyAverage: '26.8',
//               color: '#73BD07',
//               icon: 'lely-icon-clock',
//               unit: 's',
//               hasFormat: false,
//               weeklyData: [
//                 {
//                   date: '2021-03-26T00:00:00',
//                   value: '24.5',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-27T00:00:00',
//                   value: '26.5',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-28T00:00:00',
//                   value: '26.4',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-29T00:00:00',
//                   value: '25',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-30T00:00:00',
//                   value: '25.4',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-31T00:00:00',
//                   value: '30.1',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-04-01T00:00:00',
//                   value: '29.5',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//               ],
//             },
//             {
//               kpiName: 'Box Time',
//               current: '6m 43s',
//               weeklyAverage: '6m 36s',
//               color: '#73BD07',
//               icon: 'lely-icon-clock',
//               unit: '',
//               hasFormat: true,
//               weeklyData: [
//                 {
//                   date: '2021-03-26T00:00:00',
//                   value: '390.1',
//                   color: '#73BD07',
//                   valueFormatted: '6m 30s',
//                 },
//                 {
//                   date: '2021-03-27T00:00:00',
//                   value: '394.9',
//                   color: '#73BD07',
//                   valueFormatted: '6m 34s',
//                 },
//                 {
//                   date: '2021-03-28T00:00:00',
//                   value: '406.2',
//                   color: '#ff1744',
//                   valueFormatted: '6m 46s',
//                 },
//                 {
//                   date: '2021-03-29T00:00:00',
//                   value: '378.5',
//                   color: '#73BD07',
//                   valueFormatted: '6m 18s',
//                 },
//                 {
//                   date: '2021-03-30T00:00:00',
//                   value: '405.3',
//                   color: '#ff1744',
//                   valueFormatted: '6m 45s',
//                 },
//                 {
//                   date: '2021-03-31T00:00:00',
//                   value: '399.7',
//                   color: '#ff1744',
//                   valueFormatted: '6m 39s',
//                 },
//                 {
//                   date: '2021-04-01T00:00:00',
//                   value: '403.5',
//                   color: '#ff1744',
//                   valueFormatted: '6m 43s',
//                 },
//               ],
//             },
//           ],
//           infoGraphic: {
//             handedness: 'R',
//             options: [
//               {
//                 name: 'TCS',
//                 color: '#CFCBC3',
//                 isInstalled: false,
//               },
//               {
//                 name: 'FPS',
//                 color: '#CFCBC3',
//                 isInstalled: false,
//               },
//               {
//                 name: 'Pura',
//                 color: '#850C18',
//                 isInstalled: true,
//               },
//               {
//                 name: 'Scale',
//                 color: '#CFCBC3',
//                 isInstalled: false,
//               },
//               {
//                 name: 'MQCC',
//                 color: '#CFCBC3',
//                 isInstalled: false,
//               },
//               {
//                 name: 'M4U',
//                 color: '#850C18',
//                 isInstalled: false,
//               },
//               {
//                 name: 'Jersey',
//                 color: '#CFCBC3',
//                 isInstalled: false,
//               },
//             ],
//             centralUnitAddress: '212',
//             connectedEntityLocalLdn: '103',
//             entityLocalLdn: '104',
//             threeWayValve: {
//               icon: 'lely-icon-valve',
//               options: [
//                 {
//                   name: '1',
//                   color: '#CFCBC3',
//                   isInstalled: false,
//                 },
//                 {
//                   name: '2',
//                   color: '#850C18',
//                   isInstalled: true,
//                 },
//                 {
//                   name: '3',
//                   color: '#CFCBC3',
//                   isInstalled: false,
//                 },
//               ],
//             },
//             version: '5.2.?',
//             totalAlarms: {
//               description: 'Alarms',
//               statisticDetails: [
//                 {
//                   name: 'Last week',
//                   value: 2,
//                 },
//                 {
//                   name: 'Last 24h',
//                   value: 0,
//                 },
//               ],
//             },
//           },
//         },
//         {
//           id: 'cd995743-f558-4f92-848e-5c54b31db87c',
//           name: 'Vector 85',
//           isVector: true,
//           stateColor: '#73BD07',
//           kpis: [
//             {
//               kpiName: 'Accuracy',
//               current: '90.1',
//               weeklyAverage: '90.5',
//               color: '#73BD07',
//               icon: 'lely-icon-income',
//               unit: '%',
//               hasFormat: false,
//               weeklyData: [
//                 {
//                   date: '2021-03-26T00:00:00',
//                   value: '88.3',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-27T00:00:00',
//                   value: '86.9',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-28T00:00:00',
//                   value: '93.8',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-29T00:00:00',
//                   value: '90',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-30T00:00:00',
//                   value: '91',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-31T00:00:00',
//                   value: '93.3',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-04-01T00:00:00',
//                   value: '90.1',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//               ],
//             },
//             {
//               kpiName: 'FreeTime',
//               current: '17',
//               weeklyAverage: '18.4',
//               color: '#73BD07',
//               icon: 'lely-icon-chart',
//               unit: '%',
//               hasFormat: false,
//               weeklyData: [
//                 {
//                   date: '2021-03-26T00:00:00',
//                   value: '16',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-27T00:00:00',
//                   value: '21.7',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-28T00:00:00',
//                   value: '18.9',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-29T00:00:00',
//                   value: '24.9',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-30T00:00:00',
//                   value: '16.1',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-31T00:00:00',
//                   value: '13.8',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-04-01T00:00:00',
//                   value: '17',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//               ],
//             },
//             {
//               kpiName: 'Load speed',
//               current: '937.2',
//               weeklyAverage: '921.2',
//               color: '#73BD07',
//               icon: 'lely-icon-speed',
//               unit: 'kg/hr',
//               hasFormat: false,
//               weeklyData: [
//                 {
//                   date: '2021-03-26T00:00:00',
//                   value: '902.1',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-27T00:00:00',
//                   value: '844.6',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-28T00:00:00',
//                   value: '1021.4',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-29T00:00:00',
//                   value: '991.8',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-30T00:00:00',
//                   value: '816.8',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-31T00:00:00',
//                   value: '934.6',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-04-01T00:00:00',
//                   value: '937.2',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//               ],
//             },
//             {
//               kpiName: 'FG dumped weight',
//               current: '8021',
//               weeklyAverage: '8702.1',
//               color: '#73BD07',
//               icon: 'lely-icon-grabber',
//               unit: 'kg',
//               hasFormat: false,
//               weeklyData: [
//                 {
//                   date: '2021-03-26T00:00:00',
//                   value: '9464',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-27T00:00:00',
//                   value: '8673',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-28T00:00:00',
//                   value: '8931',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-29T00:00:00',
//                   value: '8861',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-30T00:00:00',
//                   value: '8851',
//                   color: '#73BD07',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-03-31T00:00:00',
//                   value: '8114',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//                 {
//                   date: '2021-04-01T00:00:00',
//                   value: '8021',
//                   color: '#ff1744',
//                   valueFormatted: '',
//                 },
//               ],
//             },
//           ],
//           infoGraphic: {
//             loadedWeightLast24h: {
//               name: 'Loaded weight',
//               value: '9479,8 kg',
//             },
//             loadedWeightByFGPercentageLast24h: {
//               name: '',
//               icon: 'lely-icon-grabber',
//               value: '85 %',
//             },
//             version: 'V-MFR_3.0.0.622f8430',
//             totalAlarms: {
//               description: 'Alarms',
//               statisticDetails: [
//                 {
//                   name: 'Last week',
//                   value: 14,
//                 },
//                 {
//                   name: 'Last 24h',
//                   value: 2,
//                 },
//                 {
//                   name: 'Last week FG',
//                   icon: 'lely-icon-grabber',
//                   value: 4,
//                 },
//                 {
//                   name: 'Last week MFR1',
//                   icon: 'lely-icon-mfr',
//                   value: 3,
//                 },
//                 {
//                   name: 'Last week PDB',
//                   icon: 'lely-icon-feed',
//                   value: 2,
//                 },
//               ],
//             },
//           },
//         },
//       ],
//       id: 'c8cb744b-fceb-4f9f-8e49-ba5a8fe39a68',
//       farmName: 'Baan',
//       farmTimeZone: 'Europe/Amsterdam',
//     },
//   ],
// };

export const api = {
  async getFarmEntity(farmId) {
    const getFarmOverviewEntity = GET_FARM_ENTITIES_SERVICE_URL + farmId;
    return axiosInstance.get(getFarmOverviewEntity);
    // return new Promise((resolve) => {
    //   resolve(MOCK_ENTITY_DETAILS_RESPONSE);
    // });
  },
};

export default { api };
