import {
  GET_EVENT_MENU_LEVELS_REQUEST_SUCCESS,
  GET_EVENT_MENU_LEVELS_REQUEST_ERROR,
} from '../common/redux/eventDropdownLevelMenuOptions/types';

/** Store states
 * @constant {Object} */
export const initialState = {
  eventLevels: [],
  advancedLevels: [],
  errorMessage: '',
};

/** Alarm dropdown levels Reducer.
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */

function eventLevelMenuOptionsReducer(state = initialState, action = {}) {
  const eventsLevelMenuOptions = {};
  const advancedLevels = {};
  switch (action.type) {
    /** Action Creator - Set alarm levels to store
     * if API request passed successfully */
    case GET_EVENT_MENU_LEVELS_REQUEST_SUCCESS:
      action.payload?.eventMenuLevelData?.generalEvents.forEach(
        (currentEventOption) => {
          const currentEventLevel = currentEventOption.name;
          const currentEventColor = currentEventOption.color;
          const currentEventIconClass = currentEventOption.iconClass;

          eventsLevelMenuOptions[currentEventLevel] = {
            itemCSSClass: `bb-events-chart-${currentEventLevel}`,
            isSelected: true,
          };
          eventsLevelMenuOptions[currentEventLevel].color = currentEventColor;
          eventsLevelMenuOptions[currentEventLevel].iconClass =
            currentEventIconClass;
        },
      );
      action.payload?.eventMenuLevelData?.advanced.forEach(
        (currentEventOption) => {
          const currentEventLevel = currentEventOption.name;
          const currentEventColor = currentEventOption.color;
          const currentEventIconClass = currentEventOption.iconClass;

          advancedLevels[currentEventLevel] = {
            itemCSSClass: `bb-events-chart-${currentEventLevel}`,
            isSelected: true,
          };
          advancedLevels[currentEventLevel].color = currentEventColor;
          advancedLevels[currentEventLevel].iconClass = currentEventIconClass;
        },
      );
      return {
        ...state,
        eventLevels: eventsLevelMenuOptions,
        advancedLevels,
        errorMessage: '',
      };

    /** Action Creator - Show error message if
     * request to API failed */
    case GET_EVENT_MENU_LEVELS_REQUEST_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        eventLevels: [],
        advancedLevels: [],
      };
    default:
      return state;
  }
}

export default eventLevelMenuOptionsReducer;
