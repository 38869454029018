import { put, take, call } from 'redux-saga/effects';
import {
  setStartLoading,
  setEndLoading,
} from '../../common/redux/loadManager/actions';
import { getLoadId } from '../../common/redux/loadManager/methods';
import {
  updateEntityBackupSettingDescription,
  updateEntityBackupSettingDescriptionError,
} from '../../reducers/entityBackupSettingsSlice';

let currentLoadId = 0;

export default function* updateEntityBackupSettingsSaga(api) {
  while (true) {
    const updateEntityBackupSettingsAction = yield take(
      updateEntityBackupSettingDescription.type,
    );
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));
    const {
      entityId,
      correlationId,
      updatedDescription,
      tableItemType,
      filename,
    } = updateEntityBackupSettingsAction.payload;
    yield call(
      updateEntityBackupSettingsDescription,
      api,
      entityId,
      correlationId,
      updatedDescription,
      tableItemType,
      filename,
    );
  }
}

function* updateEntityBackupSettingsDescription(
  api,
  entityId,
  correlationId,
  updatedDescription,
  tableItemType,
  filename,
) {
  try {
    yield call(
      api.updateEntityBackupDescription,
      entityId,
      correlationId,
      filename,
      updatedDescription,
      tableItemType,
    );
    yield put(setEndLoading(currentLoadId));
  } catch (error) {
    yield put(updateEntityBackupSettingDescriptionError(error));
    yield put(setEndLoading(currentLoadId));
  }
}
