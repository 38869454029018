import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getSearchParams, updateSearchParams } from '../../utils';
import translate from '../Translation/util';
import { isPageActive } from './utils';

type Props = {
  iconClassName: string;
  menuOptionTitle: string;
  entity?: string;
  appendixUrl?: string;
  overviewParam?: string;
  onClickFunction?: () => void;
  navigateTo?: boolean;
  link?: string;
  translation?: boolean;
};

function SideBarButton({
  iconClassName,
  menuOptionTitle,
  entity,
  appendixUrl,
  overviewParam,
  onClickFunction,
  navigateTo = true,
  link,
  translation = true,
}: Props) {
  const farmName = getSearchParams('farmName');
  const encodedFarmName = encodeURIComponent(farmName ?? '');
  const navigate = useNavigate();
  const url = window.location.pathname;
  const urlPageName =
    url.toString().indexOf('entities/') !== -1 && url.split('/')[3];
  const isPageSelected =
    urlPageName && appendixUrl && isPageActive(urlPageName, appendixUrl);

  const additionalClassName = isPageSelected ? 'active' : '';

  return (
    <span
      key={menuOptionTitle}
      className={`sidebar__navitem ${additionalClassName}`}
    >
      <a
        href={link || ''}
        title={
          translation
            ? translate(menuOptionTitle.toUpperCase())
            : menuOptionTitle
        }
        target={link ? '_blank' : ''}
        rel={link ? 'noreferrer' : ''}
        className=" sidebar__navbtn"
        onClick={(event) => {
          if (!link) {
            event.preventDefault();
          }
          if (onClickFunction) {
            onClickFunction();
          }
          if (overviewParam) {
            updateSearchParams('fromOverview', '', true);
          }
          if (navigateTo) {
            navigate(
              `/entities/${entity}/${appendixUrl}${
                farmName
                  ? `?farmName=${encodedFarmName}${
                      overviewParam ? '&fromOverview=true' : ''
                    }`
                  : ''
              }`,
            );
          }
        }}
      >
        <i className={iconClassName} />
        <span className="fs--d-header sidebar__navtitle">
          {translation
            ? translate(menuOptionTitle.toUpperCase())
            : menuOptionTitle}
        </span>
      </a>
    </span>
  );
}

export default SideBarButton;
