import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-grid-layout/css/styles.css';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { persistor, store } from './configureStore';
import './common/localStorage.d';
import Preloader from './components/Preloader/Preloader';
import App from './components/App/App';
import authConfig from './auth/authConfig';
import AxiosInterceptors from './components/AxiosInterceptors/AxiosInterceptors';

const initializeApplication = async () => {
  const msalInstance = new PublicClientApplication(authConfig.msal);
  await msalInstance.initialize();

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );

  root.render(
    <Provider store={store}>
      <PersistGate
        loading={<Preloader activeByDefault />}
        persistor={persistor}
      >
        <BrowserRouter>
          <MsalProvider instance={msalInstance}>
            <AxiosInterceptors instance={msalInstance}>
              {!(msalInstance as any).controller.initialized ? (
                <Preloader />
              ) : (
                <App />
              )}
            </AxiosInterceptors>
          </MsalProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>,
  );
};

initializeApplication();
