export const MOCK_GET_BLOCKS_DATA_RESPONSE = {
  data: {
    fromDate: '2022-04-30T00:00:00',
    toDate: '2022-05-30T00:00:00',
    systems: [
      {
        name: 'Milk unit 211',
        systemId: '',
        type: 'AstronautA5',
        icon: 'lely-icon-astronaut',
        color: '#fff',
        devices: ['astronaut-101', 'astronaut-102'],
        updates: {},
      },
      {
        name: 'Milk unit 212',
        systemId: '',
        type: 'AstronautA5',
        icon: 'lely-icon-astronaut',
        color: '#fff',
        devices: ['astronaut-103', 'astronaut-104'],
        updates: {},
      },
      {
        name: 'Vector 85',
        systemId: '51e2f58c-4c98-eb11-b566-000d3a221d55',
        type: 'VectorSystem',
        icon: 'lely-icon-mfr',
        color: '#fff',
        devices: ['Pdb', 'Mfr1', 'Fg'],
        updates: {
          '2022-05-04T00:00:00': [
            {
              device: 'Pdb',
              oldVersion: 'V-PIO_3.2.0.33006133',
              newVersion: 'V-PIO_3.2.0.08470662',
              date: '2022-05-03T21:00:00.000Z',
            },
            {
              device: 'Mfr1',
              oldVersion: 'V-MFR_3.2.0.33006133',
              newVersion: 'V-MFR_3.2.0.08470662',
              date: '2022-05-03T21:00:00.000Z',
            },
            {
              device: 'Fg',
              oldVersion: 'V-FGAM_3.2.0.33006133',
              newVersion: 'V-FGAM_3.2.0.08470662',
              date: '2022-05-03T21:00:00.000Z',
            },
          ],
          '2022-05-24T00:00:00': [
            {
              device: 'Pdb',
              oldVersion: 'V-PIO_3.2.0.08470662',
              newVersion: 'V-PIO_3.2.0.a1eed4a9',
              date: '2022-05-23T21:00:00.000Z',
            },
            {
              device: 'Mfr1',
              oldVersion: 'V-MFR_3.2.0.08470662',
              newVersion: 'V-MFR_3.2.0.a1eed4a9',
              date: '2022-05-23T21:00:00.000Z',
            },
            {
              device: 'Fg',
              oldVersion: 'V-FGAM_3.2.0.08470662',
              newVersion: 'V-FGAM_3.2.0.a1eed4a9',
              date: '2022-05-23T21:00:00.000Z',
            },
          ],
        },
      },
    ],
  },
};

export default {
  MOCK_GET_BLOCKS_DATA_RESPONSE,
};

export const MOCK_GET_UPDATE_DATA_RESPONSE = {
  updateHistoryDataForSystem: {
    fromDate: '2023-05-21T00:00:00',
    toDate: '2024-05-21T00:00:00',
    systems: [
      {
        name: 'Astronaut-101',
        type: 'AstronautA5',
        icon: 'lely-icon-astronaut',
        color: '#fff',
        systemId: '2f4457af-3338-a806-a10e-83d1f1a09555',
        devices: ['Astronaut-101'],
        updates: {},
      },
      {
        name: 'Astronaut-102',
        type: 'AstronautA5',
        icon: 'lely-icon-astronaut',
        color: '#fff',
        systemId: 'f559876f-b605-4cd0-77c4-0514c9a7aa14',
        devices: ['Astronaut-102'],
        updates: {},
      },
      {
        name: 'Astronaut-103',
        type: 'AstronautA5',
        icon: 'lely-icon-astronaut',
        color: '#fff',
        systemId: '94e1992b-ebaf-9c4d-6415-841e33b478c0',
        devices: ['Astronaut-103'],
        updates: {},
      },
      {
        name: 'Astronaut-104',
        type: 'AstronautA5',
        icon: 'lely-icon-astronaut',
        color: '#fff',
        systemId: '960b1c8f-48bd-5c41-e732-26b476f1ffdf',
        devices: ['Astronaut-104'],
        updates: {},
      },
      {
        name: 'Vector 85',
        type: 'VectorSystem',
        icon: 'lely-icon-mfr',
        color: '#fff',
        systemId: '375491db-33ba-4ad7-8c9d-a67a7075',
        devices: ['Pdb', 'Mfr1', 'Fg'],
        updates: {
          '2023-06-19T00:00:00': [
            {
              device: 'Pdb',
              oldVersion: 'V-PIO_3.2.0.39cc4de4',
              newVersion: 'V-PIO_3.2.0.e59840e7',
            },
            {
              device: 'Mfr1',
              oldVersion: 'V-MFR_3.2.0.39cc4de4',
              newVersion: 'V-MFR_3.2.0.e59840e7',
            },
          ],
          '2023-08-23T00:00:00': [
            {
              device: 'Pdb',
              oldVersion: 'V-PIO_3.2.0.e59840e7',
              newVersion: 'V-PIO_3.2.0.306b6966',
            },
            {
              device: 'Mfr1',
              oldVersion: 'V-MFR_3.2.0.e59840e7',
              newVersion: 'V-MFR_3.2.0.306b6966',
            },
          ],
          '2023-10-05T00:00:00': [
            {
              device: 'Pdb',
              oldVersion: 'V-PIO_3.2.0.306b6966',
              newVersion: 'V-PIO_3.3.0.84449582',
            },
            {
              device: 'Mfr1',
              oldVersion: 'V-MFR_3.2.0.306b6966',
              newVersion: 'V-MFR_3.3.0.84449582',
            },
            {
              device: 'Fg',
              oldVersion: 'V-FGAM_3.2.0.11ba260c',
              newVersion: 'V-FGAM_3.3.0.84449582',
            },
          ],
          '2024-02-22T00:00:00': [
            {
              device: 'Fg',
              oldVersion: 'V-FGAM_3.3.0.84449582',
              newVersion: 'V-FGAM_3.3.0.ca752366',
            },
          ],
        },
      },
    ],
  },
  systemId: '375491db-33ba-4ad7-8c9d-a67a7075aee4',
};

export const updatesDataDictionary = {
  '375491db-33ba-4ad7-8c9d-a67a7075aee4': {
    color: '#fff',
    devices: ['Pdb', 'Mfr1', 'Fg'],
    icon: 'lely-icon-mfr',
    name: 'Vector 85',
    systemId: '375491db-33ba-4ad7-8c9d-a67a7075aee4',
    type: 'VectorSystem',
    updates: {
      '2023-06-19T00:00:00': [
        {
          device: 'Pdb',
          newVersion: 'V-PIO_3.2.0.e59840e7',
          oldVersion: 'V-PIO_3.2.0.39cc4de4',
        },
        {
          device: 'Mfr1',
          newVersion: 'V-MFR_3.2.0.e59840e7',
          oldVersion: 'V-MFR_3.2.0.39cc4de4',
        },
      ],
      '2023-08-23T00:00:00': [
        {
          device: 'Pdb',
          newVersion: 'V-PIO_3.2.0.306b6966',
          oldVersion: 'V-PIO_3.2.0.e59840e7',
        },
        {
          device: 'Mfr1',
          newVersion: 'V-MFR_3.2.0.306b6966',
          oldVersion: 'V-MFR_3.2.0.e59840e7',
        },
      ],
      '2023-10-05T00:00:00': [
        {
          device: 'Pdb',
          newVersion: 'V-PIO_3.3.0.84449582',
          oldVersion: 'V-PIO_3.2.0.306b6966',
        },
        {
          device: 'Mfr1',
          newVersion: 'V-MFR_3.3.0.84449582',
          oldVersion: 'V-MFR_3.2.0.306b6966',
        },
        {
          device: 'Fg',
          newVersion: 'V-FGAM_3.3.0.84449582',
          oldVersion: 'V-FGAM_3.2.0.11ba260c',
        },
      ],
      '2024-02-22T00:00:00': [
        {
          device: 'Fg',
          newVersion: 'V-FGAM_3.3.0.ca752366',
          oldVersion: 'V-FGAM_3.3.0.84449582',
        },
      ],
    },
  },
};

export const MOCK_GET_UPDATE_DATA_SECOND_RESPONSE = {
  updateHistoryDataForSystem: {
    fromDate: '2023-05-21T00:00:00',
    toDate: '2024-05-21T00:00:00',
    systems: [
      {
        name: 'Vector 85',
        type: 'VectorSystem',
        icon: 'lely-icon-mfr',
        color: '#fff',
        systemId: '375491db-33ba-4ad7-8c9d-a67a7075',
        devices: ['Pdb', 'Mfr1', 'Fg'],
        updates: {
          '2023-06-19T00:00:00': [
            {
              device: 'Pdb',
              oldVersion: 'V-PIO_3.2.0.39cc4de4',
              newVersion: 'V-PIO_3.2.0.e59840e7',
            },
            {
              device: 'Mfr1',
              oldVersion: 'V-MFR_3.2.0.39cc4de4',
              newVersion: 'V-MFR_3.2.0.e59840e7',
            },
          ],
        },
      },
      {
        name: 'Vector 85',
        type: 'VectorSystem',
        icon: 'lely-icon-mfr',
        color: '#fff',
        systemId: '375491db-33ba-4ad7-8c9d-a67a',
        devices: ['Pdb', 'Mfr1', 'Fg'],
        updates: {
          '2023-06-19T00:00:00': [
            {
              device: 'Pdb',
              oldVersion: 'V-PIO_3.2.0.39cc4de4',
              newVersion: 'V-PIO_3.2.0.e59840e7',
            },
            {
              device: 'Mfr1',
              oldVersion: 'V-MFR_3.2.0.39cc4de4',
              newVersion: 'V-MFR_3.2.0.e59840e7',
            },
          ],
        },
      },
    ],
  },
  systemId: '375491db-33ba-4ad7-8c9d-a67a7075aee4',
};
