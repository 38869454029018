import axiosInstance from '../axiosInstance';
import {
  GET_FEATURE_FLAG_DETAILS_URL,
  USERNAME_URL_KEY,
} from '../lelyBackend/config';
import { FeatureFlagType } from '../../reducers/featureFlagSlice';

const MOCK_VERSION_FEATURE_FLAG_STAGE_DATA = {
  name: 'latest_application_version_stg',
  description: '1.49.0-0',
  active: true,
  version: 'new_version_flag',
  created_at: '2021-04-28T09:09:22.979+02:00',
  updated_at: '2021-04-28T10:19:15.497+02:00',
  scopes: [],
  strategies: [
    {
      id: 6,
      name: 'default',
      parameters: {},
      scopes: [
        {
          id: 26,
          environment_scope: '*',
        },
      ],
    },
  ],
};

export const mockApi = {
  async getFeatureFlagDetails() {
    return new Promise((resolve) => {
      resolve({ data: MOCK_VERSION_FEATURE_FLAG_STAGE_DATA });
    });
  },
};

export const api = {
  async getFeatureFlagDetails(
    featureFlagName: string,
    currentUser: string | undefined,
  ) {
    const getFeatureFlagDetailsServiceUrl =
      GET_FEATURE_FLAG_DETAILS_URL +
      featureFlagName +
      (currentUser ? `?${USERNAME_URL_KEY}=${currentUser}` : '');
    return axiosInstance.get<FeatureFlagType>(getFeatureFlagDetailsServiceUrl);
  },
};

export type FeatureFlagApiType = typeof api;
