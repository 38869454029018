import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CurrentTypedInput,
  DetailsInterface,
  EventDetailsInitialInterface,
  EventsButtonType,
  SelectedEventDetails,
} from '../containers/EventsPage/types';
import { RootState } from './rootReducer';
import { EMPTY_CHECK_BOX_LEVELS_ERROR_MESSAGE } from '../common/constants';

export const eventDetailsInitialState: EventDetailsInitialInterface = {
  eventPageDetails: [],
  isEventDetailsError: false,
  errorMessage: '',
  selectedEventDetails: null,
  lastPreferredEventFilter: null,
  numberOfResults: 0,
  latestOffset: 0,
  extendedDates: { start: null, end: null },
  maxRowsPerPage: 200,
  buttonsDisabledState: {
    preceding: false,
    succeeding: false,
    fullContext: false,
  },
  filteredEventsByType: [],
  checkboxTypeError: null,
  searchInputValue: null,
  isContextSelected: false,
  latestAppliedFilters: { appliedTypes: [], appliedSearchText: null },
};

export const eventDetailsSlice = createSlice({
  name: 'eventDetails',
  initialState: eventDetailsInitialState,
  reducers: {
    getEventDetailsSuccess: (state, action) => {
      state.eventPageDetails = action.payload.events;
      state.numberOfResults = action.payload.totalCount;
      if (action.payload.totalCount < state.latestOffset) {
        state.latestOffset = 0;
      }
      state.isEventDetailsError = false;
      state.errorMessage = '';
      state.selectedEventDetails = null;
      state.buttonsDisabledState = {
        preceding: false,
        succeeding: false,
        fullContext: false,
      };
      state.isContextSelected = false;
    },
    getEventDetailsError: (state, action) => {
      state.errorMessage = action.payload;
      state.eventPageDetails = [];
      state.isEventDetailsError = true;
      state.selectedEventDetails = null;
    },
    getDetailsForSelectedEventSuccess: (state, action) => {
      state.selectedEventDetails = action.payload;
    },
    getDetailsForSelectedEventError: (state) => {
      state.selectedEventDetails = null;
    },
    setPreferredEventFilters: (state, action) => {
      state.lastPreferredEventFilter = { ...action.payload };
    },
    resetPreferredEventFilters: (state) => {
      state.lastPreferredEventFilter = null;
    },
    resetSelectedEventDetails: (state) => {
      state.selectedEventDetails = null;
    },
    setLatestOffset: (state, action: PayloadAction<number>) => {
      state.latestOffset = action.payload;
    },
    setLatestAppliedFilters: (
      state,
      action: PayloadAction<{
        namesOfSelectedTypes?: string[];
        modifiedSearchParams?:
          | Record<string, string>
          | CurrentTypedInput
          | null;
      }>,
    ) => {
      const { namesOfSelectedTypes, modifiedSearchParams } = action.payload;
      if (namesOfSelectedTypes !== undefined) {
        state.filteredEventsByType = namesOfSelectedTypes;
        state.latestAppliedFilters.appliedTypes = namesOfSelectedTypes;
      }
      if (modifiedSearchParams !== undefined) {
        state.searchInputValue = modifiedSearchParams;
        state.latestAppliedFilters.appliedSearchText = modifiedSearchParams;
      }
    },
    extendEventDetails: (
      state,
      action: PayloadAction<{
        events: DetailsInterface[];
        type: EventsButtonType;
        item?: string | null;
      }>,
    ) => {
      const { events, type, item } = action.payload;

      if (type === EventsButtonType.Context) {
        state.numberOfResults = events.length;
        state.eventPageDetails = events;
        state.extendedDates = {
          start: events[events.length - 1].eventTimestamp,
          end: events[0].eventTimestamp,
        };
        const idexOfSelected = events.findIndex((e) => e.id === item);
        if (idexOfSelected !== 10 && idexOfSelected !== -1) {
          const selectedEvent = events[idexOfSelected];
          events.splice(idexOfSelected, 1);
          events.splice(10, 0, selectedEvent);
        }
        state.buttonsDisabledState.preceding = false;
        state.buttonsDisabledState.succeeding = false;
        state.latestOffset = 0;
        state.isContextSelected = true;
        return;
      }

      state.numberOfResults += events.length;
      state.eventPageDetails =
        type === EventsButtonType.Preceding
          ? [...state.eventPageDetails]
              .concat(events)
              .slice(0, state.maxRowsPerPage)
          : events
              .concat([...state.eventPageDetails])
              .slice(0, state.maxRowsPerPage);

      if (events.length) {
        state.extendedDates =
          type === EventsButtonType.Preceding
            ? {
                ...state.extendedDates,
                start: events[events.length - 1].eventTimestamp,
              }
            : {
                ...state.extendedDates,
                end: events[0].eventTimestamp,
              };
      }
    },
    updateButtonState: (
      state,
      action: PayloadAction<{
        eventsLength: number;
        type: EventsButtonType;
        offset: number;
      }>,
    ) => {
      const { eventsLength, type, offset } = action.payload;
      if (!eventsLength || eventsLength < offset - 1) {
        state.buttonsDisabledState[type] = true;
      }
    },
    setExtendedDates: (state, action) => {
      state.extendedDates = action.payload;
    },
    setFilteredEventsByType: (state, action) => {
      const selectedTypes = [...action.payload];
      if (selectedTypes.length === 6) {
        selectedTypes.push('SHOW ALL');
      }
      state.filteredEventsByType = selectedTypes;
      if (selectedTypes?.length < 1) {
        state.checkboxTypeError = EMPTY_CHECK_BOX_LEVELS_ERROR_MESSAGE;
      } else {
        state.checkboxTypeError = null;
      }
    },
    setCheckboxTypeError: (state, action) => {
      state.checkboxTypeError = action.payload;
    },
    setSearchInputValue: (state, action) => {
      state.searchInputValue = action.payload;
    },
    setIsContextSelected: (state) => {
      state.isContextSelected = false;
    },
  },
});

export const {
  getEventDetailsSuccess,
  getEventDetailsError,
  getDetailsForSelectedEventSuccess,
  getDetailsForSelectedEventError,
  setPreferredEventFilters,
  resetPreferredEventFilters,
  resetSelectedEventDetails,
  setLatestOffset,
  setLatestAppliedFilters,
  extendEventDetails,
  setExtendedDates,
  updateButtonState,
  setFilteredEventsByType,
  setCheckboxTypeError,
  setSearchInputValue,
  setIsContextSelected,
} = eventDetailsSlice.actions;

export const getEventDetailsForEntityNew = createAction<{
  entityId: string;
  startDateToTimestamp: string;
  endDateToTimestamp: string;
  limit: number;
}>('GET_EVENT_DETAILS_REQUEST_NEW');

export const getSelectedEventDetails = createAction<{
  entityId: string;
  eventData: DetailsInterface;
}>('GET_DETAILS_FOR_SELECTED_EVENT_REQUEST_NEW');

export const fetchMoreEvents = createAction<{
  entityId: string;
  type: EventsButtonType;
  limit?: number;
  item?: SelectedEventDetails | DetailsInterface;
}>('FETCH_MORE_EVENTS');

export const eventDetailsSelectors = {
  selectedEventDetails: (state: RootState) =>
    state.eventPageDetails.selectedEventDetails,
  eventDetails: (state: RootState) => state.eventPageDetails.eventPageDetails,
  numberOfResults: (state: RootState) => state.eventPageDetails.numberOfResults,
  lastPreferredEventFilter: (state: RootState) =>
    state.eventPageDetails.lastPreferredEventFilter,
  latestOffset: (state: RootState) => state.eventPageDetails.latestOffset,
  maxRowsPerPage: (state: RootState) => state.eventPageDetails.maxRowsPerPage,
  extendedDates: (state: RootState) => state.eventPageDetails.extendedDates,
  buttonsDisabledState: (state: RootState, type: EventsButtonType) =>
    state.eventPageDetails.buttonsDisabledState[type],
  filteredEventsByType: (state: RootState) =>
    state.eventPageDetails.filteredEventsByType,
  checkboxTypeError: (state: RootState) =>
    state.eventPageDetails.checkboxTypeError,
  searchInputValue: (state: RootState) =>
    state.eventPageDetails.searchInputValue,
  isContextSelected: (state: RootState) =>
    state.eventPageDetails.isContextSelected,
  latestAppliedFilters: (state: RootState) =>
    state.eventPageDetails.latestAppliedFilters,
};

export default eventDetailsSlice.reducer;
