import {
  GET_ENTITY_STATUS_REQUEST_SUCCESS,
  GET_ENTITY_STATUS_REQUEST_ERROR,
} from '../components/EntityState/types';

/** Store states
 * @constant {Object} */
export const initialState = {
  entityStatus: {},
  errorMessage: '',
};

/** Entity Details Reducer.
 * @param {Object} state - Initial state
 * @param {Object} action- Payload object
 * @return {Object} - return new state
 */
function entityStatusReducer(state = initialState, action = {}) {
  switch (action.type) {
    /** Action Creator - Successfully get entity details from API
     * it will receive entity details  dispatching from the saga
     */
    case GET_ENTITY_STATUS_REQUEST_SUCCESS:
      return {
        ...state,
        entityStatus: action.payload,
        errorMessage: '',
      };
    /** Action Creator - Unsuccessful API request to get entity details
     * it will receive error message dispatching from the saga
     */
    case GET_ENTITY_STATUS_REQUEST_ERROR:
      return {
        ...state,
        entityStatus: {},
        errorMessage: action.payload,
      };
    default:
      return state;
  }
}

export default entityStatusReducer;
