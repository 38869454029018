import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import React from 'react';

function RadioButtonElement(props: FormControlLabelProps) {
  return <FormControlLabel {...props} className="custom-radio-button" />;
}

export default RadioButtonElement;
