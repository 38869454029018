/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { getEntityInfo } from '../FarmAndEntityDetailsHeader/actions';
import './Breadcrumb.scss';
import { getSearchParams } from '../../utils';
import { useAppSelector } from '../../../reducers/rootReducer';
import { capitalizeFirstLetter } from '../../stringUtils';

function Breadcrumb(): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const url = window.location.pathname;
  const entityId = url.split('/')[2];
  const [routes, setRoutes] = useState<JSX.Element[]>();
  const { entityInfo } = useAppSelector((state) => state.entityInfo);

  useEffect(() => {
    if (url.indexOf('entities') !== -1 && entityId) {
      dispatch(getEntityInfo(entityId));
    }
  }, [entityId, isEmpty(entityInfo)]);

  useEffect(() => {
    BreadcrumbRoutes();
  }, [url, entityInfo]);

  function BreadcrumbRoutes() {
    const farmName = getSearchParams('farmName');

    const splittedUrl = url.split('/');
    let i = 1;
    const breadcrumbRoutes: JSX.Element[] = [];

    for (i; i <= splittedUrl.length - 1; ) {
      const currentItemClassName =
        splittedUrl.length - 1 === i
          ? 'breadcrumb-item active'
          : 'breadcrumb-item'; // needed in order to not show underline under active breadcrumb link
      if (splittedUrl[i] === 'entities') {
        const entityRoutes = [
          <li
            className="breadcrumb-item"
            key={i}
            onClick={(event) => {
              event.preventDefault();
              navigate(`/farms/${entityInfo.farmId}`);
            }}
          >
            <a href="#">{capitalizeFirstLetter(entityInfo.farmName)}</a>
          </li>,
          <li
            className="breadcrumb-item"
            key={splittedUrl[i]}
            aria-current="page"
            onClick={(event) => {
              event.preventDefault();
              const encodedFarmName = encodeURIComponent(farmName ?? '');

              if (entityInfo.type === 'AstronautA5') {
                navigate(
                  `/entities/${splittedUrl[2]}/overview${
                    farmName ? `?farmName=${encodedFarmName}` : ''
                  }`,
                );
              } else if (entityInfo.type === 'AutomaticMilkFilter') {
                navigate(
                  `/entities/${splittedUrl[2]}/AMF-settings${
                    farmName ? `?farmName=${encodedFarmName}` : ''
                  }`,
                );
              } else {
                navigate(
                  `/entities/${splittedUrl[2]}/Vector_Data_Explorer${
                    farmName ? `?farmName=${encodedFarmName}` : ''
                  }`,
                );
              }
            }}
          >
            <a href="#">{capitalizeFirstLetter(entityInfo.name)}</a>
          </li>,
        ];
        breadcrumbRoutes.push(...entityRoutes);
        i++;
      } else if (splittedUrl[i] !== 'farms') {
        // do not show Farms text in the breadcrumb for farms/ page
        if (
          splittedUrl[i] === 'settings' ||
          splittedUrl[i] === 'AMF-settings'
        ) {
          breadcrumbRoutes.push(
            <li key={i + splittedUrl[i]} className="breadcrumb-item" />,
          );
          break;
        }
        const isDomain =
          splittedUrl && splittedUrl[splittedUrl.length - 2] === 'domains';
        const isCanbus = splittedUrl && splittedUrl[i] === 'canbus';
        /** Get the penultimate page and add it to array */
        if (!isCanbus) {
          const otherRoutes = [
            <div
              className={currentItemClassName}
              key={i + splittedUrl[i]}
              onClick={
                splittedUrl.length - 1 === i || isDomain
                  ? undefined
                  : (event) => {
                      event.preventDefault();
                      navigate(
                        `/entities/${splittedUrl[2]}/${splittedUrl[i - 2]}`,
                      );
                    }
              }
            >
              <a href="#">
                {splittedUrl[i] === 'domains'
                  ? 'Diagnostic'
                  : capitalizeFirstLetter(splittedUrl[i].replace(/_/g, ' '))}
              </a>
            </div>,
          ];
          breadcrumbRoutes.push(...otherRoutes);
        }
      }
      i++;
    }
    /** Update the state with all routes */
    setRoutes(breadcrumbRoutes);
  }

  return (
    <nav
      aria-label="breadcrumb"
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <div className="breadcrumb">{routes}</div>
    </nav>
  );
}

export default Breadcrumb;
