import {
  GET_ENTITY_INFO_REQUEST,
  GET_ENTITY_INFO_REQUEST_SUCCESS,
  GET_ENTITY_INFO_REQUEST_ERROR,
  RESET_ENTITY_INFO,
} from './types';

/** Create action which to get all information for current entity .
 * @param {String} selectedEntityId - the id of the entity for which we want to get the data
 * @return {Object} Return type and info for current entity
 */
export function getEntityInfo(selectedEntityId) {
  return {
    type: GET_ENTITY_INFO_REQUEST,
    payload: {
      entityId: selectedEntityId,
    },
  };
}

/** Create action which to allow to put entity data in the redux store
 * @param {Object} entityInfo - the data for the selected entity
 * @return {Object} Return type and entity info (name,entityId,farmName,farmId)
 */
export function getEntityInfoSuccess(entityInfo) {
  return {
    type: GET_ENTITY_INFO_REQUEST_SUCCESS,
    payload: { ...entityInfo },
  };
}

/** Create action which get errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function getEntityInfoError(errorMessage) {
  return {
    type: GET_ENTITY_INFO_REQUEST_ERROR,
    payload: errorMessage,
  };
}

export function resetEntityInfo() {
  return {
    type: RESET_ENTITY_INFO,
  };
}
