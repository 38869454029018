import parseInt from 'lodash/parseInt';
import { scaleTime } from 'd3-scale';
import {
  isAfter,
  addMinutes,
  differenceInMinutes,
  addSeconds,
  differenceInSeconds,
  isBefore,
} from 'date-fns';
import moment from 'moment';
import {
  drawElementOnCanvas,
  MAX_CANVAS_WIDTH_IN_PIXELS,
} from '../mfrchart/MultipleCanvasesUtil';
import type { CanvasWorkerTypes } from './types';
import { STATE_ARROWS_MIN_LEVEL } from '../mfrchart/constants';
import { ZOOM_LEVEL_AFTER_WHICH_TO_SHOW_GRAB_TASK_STROKES } from '../mfrchart/VectorChartConfigUtil';

export const getCanvasContextByElementX = (
  elementX,
  maxCanvasWidthInPixels,
  contexts,
) => {
  const contextIndex = getCanvasContextIndexByElementX(
    elementX,
    maxCanvasWidthInPixels,
  );
  return contexts[contextIndex];
};

export const getCanvasContextIndexByElementX = (
  elementX,
  maxCanvasWidthInPixels,
) => {
  const canvasIndex = Math.floor(elementX / maxCanvasWidthInPixels);
  return canvasIndex;
};

export const isObjectOutsideOfOriginalZoomRange = (
  currentObjectStartTime,
  rangeStart,
  rangeEnd,
) => {
  return (
    currentObjectStartTime.getTime() < rangeStart.getTime() ||
    currentObjectStartTime.getTime() > rangeEnd.getTime()
  );
};

export const isObjectPartOfZoomRangeAlthoughItStartsBeforeOrEndsAfter = (
  currentObjectStartTime: Date,
  currentObjectEndTime: Date,
  rangeStart: Date,
  rangeEnd: Date,
) => {
  const isActivityPartOfZoomRangeAlthoughItStartsBeforeOrEndsAfter =
    (currentObjectStartTime.getTime() <= rangeStart.getTime() &&
      currentObjectEndTime?.getTime() <= rangeEnd.getTime()) ||
    (currentObjectStartTime.getTime() >= rangeStart.getTime() &&
      currentObjectEndTime?.getTime() >= rangeEnd.getTime());

  if (isActivityPartOfZoomRangeAlthoughItStartsBeforeOrEndsAfter) {
    return false;
  }

  const isObjectOutOfOriginalDateRange = isObjectOutsideOfOriginalZoomRange(
    currentObjectStartTime,
    rangeStart,
    rangeEnd,
  );
  if (isObjectOutOfOriginalDateRange) {
    return true;
  }
  return false;
};

export const shouldSkipActivityForRerenderedZoomLevel = (
  currentObjectStartTime,
  currentObjectEndTime,
  rangeConfig: CanvasWorkerTypes.RangeConfig,
  showStateArrow: boolean,
) => {
  const isActivityEndDateBeforeItsStartDate = isBefore(
    currentObjectEndTime,
    currentObjectStartTime,
  );

  const isActivityPartOfInnermostZoomRangeAlthoughItStartsBefore =
    isBefore(currentObjectStartTime, rangeConfig.dynamicZoomLevelStartDate) &&
    isAfter(currentObjectEndTime, rangeConfig.dynamicZoomLevelEndDate);

  const isObjectOutsideOfInnermostZoomLevelRange =
    isAfter(currentObjectStartTime, rangeConfig.dynamicZoomLevelEndDate) ||
    isBefore(currentObjectEndTime, rangeConfig.dynamicZoomLevelStartDate);

  const isObjectOutOfOriginalDateRange = isObjectOutsideOfOriginalZoomRange(
    currentObjectStartTime,
    rangeConfig.rangeStartDate,
    rangeConfig.rangeEndDate,
  );

  if (
    isAfter(currentObjectStartTime, rangeConfig.dynamicZoomLevelStartDate) &&
    isBefore(currentObjectStartTime, rangeConfig.dynamicZoomLevelEndDate) &&
    showStateArrow
  ) {
    return false;
  }

  if (
    isActivityEndDateBeforeItsStartDate ||
    isActivityPartOfInnermostZoomRangeAlthoughItStartsBefore
  ) {
    return false;
  }
  if (!currentObjectStartTime) {
    return true;
  }
  if (
    isObjectOutsideOfInnermostZoomLevelRange ||
    isObjectOutOfOriginalDateRange
  ) {
    return true;
  }

  return false;
};

export const getElementXByObject = (
  d,
  startTimePropertyName,
  scaleFunction,
) => {
  if (d[startTimePropertyName]) {
    return scaleFunction(d[startTimePropertyName]);
  }
};

export const getElementWidthByObject = (
  d,
  startTimePropertyName,
  scaleFunction,
) => {
  if (d[startTimePropertyName] && d.endTime) {
    const minWidth = d.device?.toLowerCase() === 'pdb' ? 1 : 0;
    const rectStartXPosition = scaleFunction(d[startTimePropertyName]);
    const rectEndXPosition = scaleFunction(d.endTime);
    let rectWidth = rectEndXPosition - rectStartXPosition;
    rectWidth = rectWidth >= minWidth ? rectWidth : minWidth;
    return rectWidth;
  }
  return 0;
};

export const getIntegerScaleFunction = (
  rangeConfig: CanvasWorkerTypes.RangeConfig,
) => {
  const scaleFunction = rangeConfig.isDynamic
    ? getScaleFunction(
        rangeConfig.dynamicZoomLevelStartDate,
        rangeConfig.dynamicZoomLevelEndDate,
        0,
        32000,
      )
    : getScaleFunction(
        rangeConfig.rangeStartDate,
        rangeConfig.rangeEndDate,
        0,
        rangeConfig.zoomLevelWidthInPx,
      );

  const integerScaleFunction = (date: Date) => {
    return parseInt(scaleFunction(date).toString());
  };
  return integerScaleFunction;
};

const getScaleFunction = (rangeStartDate, rangeEndDate, xStart, xEnd) => {
  return scaleTime()
    .domain([rangeStartDate, rangeEndDate])
    .range([xStart, xEnd]);
};

export const addGradientTypeToCanvasContext = (
  mfrType: string,
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  gradientTypeId: number,
  accuracyPercentage,
  plannedWeight,
  actualLoadedWeight,
  gradientHeightInPx,
  canvasContext,
  configOptions,
  gradientDictionary,
) => {
  // eslint-disable-next-line radix, no-param-reassign
  gradientHeightInPx = gradientHeightInPx ? parseInt(gradientHeightInPx) : 170;
  const gradientDefinition =
    canvasContext &&
    canvasContext.createLinearGradient &&
    canvasContext.createLinearGradient(x1, y1, x2, y2);
  const gradientStops = getGradientStopsArrayForLoadingActivity(
    mfrType,
    accuracyPercentage,
    plannedWeight,
    actualLoadedWeight,
    configOptions,
  );
  gradientDictionary[gradientTypeId] = gradientDefinition;
  addGradientStopsFromArray(gradientDefinition, gradientStops);
};

export const getGradientStopsArrayForLoadingActivity = (
  mfrType,
  accuracyPercentage,
  plannedWeight,
  actualLoadedWeight,
  configOptions,
) => {
  const { loadingActivityGradientGreenColor } = configOptions;
  const { loadingActivityGradientBrownColor } = configOptions;
  const { loadingActivityMissingFeedColor } = configOptions;
  const { loadingActivityExceedingFeedColor } = configOptions;

  let gradientStopsArray: {
    offset: number;
    stopColor: any;
  }[] = [];

  const isOverload = plannedWeight < actualLoadedWeight;
  const isUnderload = plannedWeight >= actualLoadedWeight;
  const overloadPercentage = isOverload
    ? ((actualLoadedWeight - plannedWeight) / actualLoadedWeight) * 100
    : 0;
  const underloadPercentage = !isOverload
    ? ((plannedWeight - actualLoadedWeight) / plannedWeight) * 100
    : 0;
  let latestPercentageRendered = 0;

  if (isOverload) {
    gradientStopsArray.push({
      offset: 0,
      stopColor: loadingActivityExceedingFeedColor,
    });
    gradientStopsArray.push({
      offset: overloadPercentage,
      stopColor: loadingActivityExceedingFeedColor,
    });
    latestPercentageRendered = overloadPercentage;
  }
  if (isUnderload) {
    gradientStopsArray.push({
      offset: 0,
      stopColor: loadingActivityMissingFeedColor,
    });
    gradientStopsArray.push({
      offset: underloadPercentage,
      stopColor: loadingActivityMissingFeedColor,
    });
    latestPercentageRendered = underloadPercentage;
  }
  if (accuracyPercentage >= 100) {
    // super accurate
    gradientStopsArray.push({
      offset: latestPercentageRendered,
      stopColor: loadingActivityGradientGreenColor,
    });
    gradientStopsArray.push({
      offset: 99,
      stopColor: loadingActivityGradientGreenColor,
    });
    gradientStopsArray.push({
      offset: 100,
      stopColor: loadingActivityGradientBrownColor,
    });
  } else if (accuracyPercentage >= 95 && accuracyPercentage < 100) {
    // quite accurate
    gradientStopsArray.push({
      offset: latestPercentageRendered,
      stopColor: loadingActivityGradientGreenColor,
    });
    gradientStopsArray.push({
      offset: 75,
      stopColor: loadingActivityGradientGreenColor,
    });
    gradientStopsArray.push({
      offset: 100,
      stopColor: loadingActivityGradientBrownColor,
    });
  } else if (accuracyPercentage >= 90 && accuracyPercentage < 95) {
    // quite inaccurate
    gradientStopsArray.push({
      offset: latestPercentageRendered,
      stopColor: loadingActivityGradientGreenColor,
    });
    gradientStopsArray.push({
      offset: 50,
      stopColor: loadingActivityGradientGreenColor,
    });
    gradientStopsArray.push({
      offset: 100,
      stopColor: loadingActivityGradientBrownColor,
    });
  } else if (accuracyPercentage >= 80 && accuracyPercentage < 90) {
    // really inaccurate
    gradientStopsArray.push({
      offset: latestPercentageRendered,
      stopColor: loadingActivityGradientGreenColor,
    });
    gradientStopsArray.push({
      offset: 100,
      stopColor: loadingActivityGradientBrownColor,
    });
  } else if (accuracyPercentage >= 70 && accuracyPercentage < 80) {
    // super inaccurate
    gradientStopsArray.push({
      offset: latestPercentageRendered,
      stopColor: loadingActivityGradientGreenColor,
    });
    gradientStopsArray.push({
      offset: Math.max(latestPercentageRendered, 50),
      stopColor: loadingActivityGradientBrownColor,
    });
    gradientStopsArray.push({
      offset: 100,
      stopColor: loadingActivityGradientBrownColor,
    });
  } else {
    // absolutely inaccurate
    gradientStopsArray.push({
      offset: latestPercentageRendered,
      stopColor: loadingActivityGradientGreenColor,
    });
    gradientStopsArray.push({
      offset: Math.min(latestPercentageRendered, 99),
      stopColor: loadingActivityGradientBrownColor,
    });
    gradientStopsArray.push({
      offset: 100,
      stopColor: loadingActivityGradientBrownColor,
    });
  }

  if (mfrType === 'mfr2') {
    // 'reverse' the gradient for mfr2 loading activities
    // TODO: this might be wired to the vertical display config
    gradientStopsArray = gradientStopsArray.reverse();
    gradientStopsArray = gradientStopsArray.map((currentStop) => {
      return {
        offset: 100 - currentStop.offset,
        stopColor: currentStop.stopColor,
      };
    });
  }
  gradientStopsArray = gradientStopsArray.map((currentStop) => {
    return {
      offset: currentStop.offset / 100,
      stopColor: currentStop.stopColor,
    };
  });

  return gradientStopsArray;
};

export const addGradientStopsFromArray = (
  gradientDefinitionReference,
  gradientStopsArray,
) => {
  gradientStopsArray.forEach((currentStopDetails) => {
    gradientDefinitionReference?.addColorStop(
      currentStopDetails.offset > 1 ? 1 : currentStopDetails.offset,
      currentStopDetails.stopColor,
    );
  });
};

const skipDrawingOutsideRange = (
  currentObjectToBeRendered,
  startTimePropertyName,
  rangeConfig,
) => {
  const currentObjectStartTime =
    currentObjectToBeRendered[startTimePropertyName];
  const currentObjectEndTime = currentObjectToBeRendered['endTime'];
  const momentStartDate = moment(currentObjectStartTime);
  const momentEndDate = moment(currentObjectEndTime);
  if (
    !currentObjectToBeRendered['endTime'] ||
    momentEndDate.isBefore(momentStartDate)
  ) {
    currentObjectToBeRendered.showStateArrow = true;
  }
  if (
    !currentObjectToBeRendered[startTimePropertyName] &&
    !currentObjectToBeRendered['endTime']
  ) {
    currentObjectToBeRendered.showStateArrow = false;
    return;
  }
  let shouldSkipRenderingForCurrentActivity =
    isObjectPartOfZoomRangeAlthoughItStartsBeforeOrEndsAfter(
      currentObjectStartTime,
      currentObjectEndTime,
      rangeConfig.rangeStartDate,
      rangeConfig.rangeEndDate,
    );
  if (rangeConfig.isDynamic) {
    shouldSkipRenderingForCurrentActivity =
      shouldSkipActivityForRerenderedZoomLevel(
        currentObjectStartTime,
        currentObjectEndTime,
        rangeConfig,
        currentObjectToBeRendered.showStateArrow,
      );
  }

  return shouldSkipRenderingForCurrentActivity;
};

export const renderElementsInDedicatedCanvas = (
  dataArray,
  startTimePropertyName,
  fillFunction,
  strokeFunction,
  heightInPixelsFn,
  yPositionInPixelsFn,
  tierY,
  isMFR1,
  scaleFunction,
  zoomLevel,
  rangeConfig,
  contexts,
  gradientDictionary,
  clickMapObject,
  absoluteOffsetTop = 0,
) => {
  const arrowsObject: any = [];
  dataArray?.sort(
    (a, b) => a[startTimePropertyName] - b[startTimePropertyName],
  );
  dataArray?.forEach((currentObjectToBeRendered, index) => {
    const shouldSkipDrawing = skipDrawingOutsideRange(
      currentObjectToBeRendered,
      startTimePropertyName,
      rangeConfig,
    );
    if (!shouldSkipDrawing) {
      if (currentObjectToBeRendered.showStateArrow) {
        const nextItem = dataArray[index + 1];

        // Check if there's a next object and it's start time is more than 2 minutes after current item's start time
        if (
          nextItem &&
          isAfter(
            nextItem[startTimePropertyName],
            addMinutes(currentObjectToBeRendered[startTimePropertyName], 2),
          )
        ) {
          currentObjectToBeRendered.endTime = addMinutes(
            currentObjectToBeRendered[startTimePropertyName],
            2,
          ); // Set end time to 2 minutes after start time
        }

        // If next object start time is within 2 minutes range
        else if (
          nextItem &&
          differenceInMinutes(
            nextItem[startTimePropertyName],
            currentObjectToBeRendered[startTimePropertyName],
          ) < 2
        ) {
          // If next object start time is in less then 15 seconds range
          if (
            differenceInSeconds(
              nextItem[startTimePropertyName],
              currentObjectToBeRendered[startTimePropertyName],
            ) < 15
          ) {
            // min length of an arrow is 15 seconds
            currentObjectToBeRendered.endTime = addSeconds(
              currentObjectToBeRendered[startTimePropertyName],
              15,
            ); // Set end time to 2 minutes after start time
          } else {
            currentObjectToBeRendered.endTime = addSeconds(
              nextItem[startTimePropertyName],
              -1,
            ); // Set end time to 1 second before the next object's start time
          }
        } else {
          currentObjectToBeRendered.endTime = addMinutes(
            currentObjectToBeRendered[startTimePropertyName],
            2,
          ); // Set end time to 2 minutes after start time
        }
      }

      const showStateArrow =
        currentObjectToBeRendered.showStateArrow &&
        zoomLevel >= STATE_ARROWS_MIN_LEVEL
          ? (currentObjectToBeRendered.showStateArrow = true)
          : (currentObjectToBeRendered.showStateArrow = false);
      if (showStateArrow) {
        arrowsObject.push({
          arrowObject: currentObjectToBeRendered,
          show: showStateArrow,
        });
      } else {
        renderActivity(
          currentObjectToBeRendered,
          startTimePropertyName,
          fillFunction,
          strokeFunction,
          heightInPixelsFn,
          yPositionInPixelsFn,
          tierY,
          isMFR1,
          scaleFunction,
          {
            showArrow: false,
            hasStroke: false,
          },
          rangeConfig,
          zoomLevel,
          contexts,
          gradientDictionary,
          clickMapObject,
          absoluteOffsetTop,
        );
      }
    }

    if (dataArray.length === index + 1 && arrowsObject.length) {
      arrowsObject.forEach((arrow) => {
        renderActivity(
          arrow.arrowObject,
          startTimePropertyName,
          fillFunction,
          strokeFunction,
          heightInPixelsFn,
          yPositionInPixelsFn,
          tierY,
          isMFR1,
          scaleFunction,
          {
            showArrow: arrow.show,
            hasStroke:
              arrow.arrowObject?.name === 'Grab request' &&
              zoomLevel > ZOOM_LEVEL_AFTER_WHICH_TO_SHOW_GRAB_TASK_STROKES,
          },
          rangeConfig,
          zoomLevel,
          contexts,
          gradientDictionary,
          clickMapObject,
          absoluteOffsetTop,
        );
      });
    }
  });

  if (rangeConfig.isActive) {
    postMessage({
      command: 'vectorLoaded',
    });
  }
};

export const renderActivity = (
  currentObjectToBeRendered,
  startTimePropertyName,
  fillFunction,
  strokeFunction,
  heightInPixelsFn,
  yPositionInPixelsFn,
  tierY,
  isMFR1,
  scaleFunction,
  stateArrow,
  rangeConfig,
  zoomLevel,
  contexts,
  gradientDictionary,
  clickMapObject,
  absoluteOffsetTop,
) => {
  let xPositionInPx = getElementXByObject(
    currentObjectToBeRendered,
    startTimePropertyName,
    scaleFunction,
  );
  let widthInPx = getElementWidthByObject(
    currentObjectToBeRendered,
    startTimePropertyName,
    scaleFunction,
  );

  if (rangeConfig.isDynamic && xPositionInPx < 0) {
    widthInPx += xPositionInPx;
    xPositionInPx = 0;
  }
  const heightInPx = parseInt(heightInPixelsFn(currentObjectToBeRendered));
  const yPositionInPx = isMFR1
    ? parseInt(yPositionInPixelsFn(currentObjectToBeRendered, heightInPx))
    : parseInt(yPositionInPixelsFn(currentObjectToBeRendered));

  let fillColor = {};
  if (isMFR1) {
    fillColor = fillFunction(
      currentObjectToBeRendered,
      xPositionInPx,
      yPositionInPx,
      xPositionInPx,
      yPositionInPx + heightInPx,
    );
  } else {
    fillColor = fillFunction(
      currentObjectToBeRendered,
      xPositionInPx,
      yPositionInPx,
      xPositionInPx,
      yPositionInPx + heightInPx,
    );
  }
  const fillStyle =
    fillColor.toString().indexOf('#') !== -1
      ? fillColor
      : gradientDictionary[fillColor as string];
  let strokeStyle;
  if (strokeFunction) {
    strokeStyle = strokeFunction(currentObjectToBeRendered);
  }
  drawElementOnCanvas(
    currentObjectToBeRendered,
    xPositionInPx,
    yPositionInPx,
    widthInPx,
    heightInPx,
    fillStyle,
    strokeStyle,
    contexts,
    MAX_CANVAS_WIDTH_IN_PIXELS,
    scaleFunction,
    rangeConfig.isDynamic,
    stateArrow,
  );
  addToClickMapIfNecessary(
    currentObjectToBeRendered,
    xPositionInPx,
    yPositionInPx + absoluteOffsetTop,
    widthInPx,
    heightInPx,
    tierY,
    clickMapObject,
    zoomLevel,
  );
};

const addToClickMapIfNecessary = (
  currentObjectToBeRendered,
  xPositionInPixels,
  yPositionInPixels,
  widthInPixels,
  heightInPixels,
  tierY,
  clickMapObj,
  zoomLevel,
) => {
  addClickableObjectToClickMap(
    currentObjectToBeRendered,
    xPositionInPixels,
    yPositionInPixels,
    widthInPixels,
    heightInPixels,
    tierY,
    clickMapObj,
    zoomLevel,
  );
};

const addClickableObjectToClickMap = (
  objectData,
  objectX,
  objectY,
  objectWidth,
  objectHeight,
  tierY,
  clickMapObject,
  currentZoomLevel,
) => {
  clickMapObject[currentZoomLevel][tierY].items.push({
    objectData,
    objectX,
    objectY,
    objectWidth,
    objectHeight,
  });
};

export const cloneDeep = <T>(obj: T): T => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  const clone: any = Array.isArray(obj) ? [] : {};

  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      if (obj[key] instanceof Date) {
        clone[key] = new Date((obj[key] as Date).getTime());
      } else {
        clone[key] = cloneDeep(obj[key]);
      }
    }
  }

  return clone;
};
