import * as React from 'react';
import { Radio, SxProps, Theme } from '@mui/material';
import { ReactComponent as RadioDefault } from '../../../resources/icons/RadioDefault.svg';
import { ReactComponent as RadioChecked } from '../../../resources/icons/RadioChecked.svg';

type Props = {
  checked?: boolean;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  sx?: SxProps<Theme>;
};

function RadioButton({ checked, onChange, value, name, sx }: Props) {
  return (
    <Radio
      value={value}
      icon={<RadioDefault />}
      checked={checked}
      checkedIcon={<RadioChecked />}
      onChange={onChange}
      name={name}
      disableTouchRipple
      sx={{
        '&': {
          paddingLeft: '0',
          paddingTop: '0',
          paddingBottom: '0',
          paddingRight: '8px',
        },
        '&:hover': {
          backgroundColor: 'transparent',
        },
        ...sx,
      }}
    />
  );
}

export default RadioButton;
