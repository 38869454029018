import React, { Suspense } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../reducers/rootReducer';
import Preloader from '../Preloader/Preloader';

export default function RoutesProtector() {
  const { isLoggedIn } = useAppSelector((state) => state.userSession);
  const location = useLocation();

  return isLoggedIn ? (
    <Suspense fallback={<Preloader />}>
      <Outlet />
    </Suspense>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}
