import { put, take, call } from 'redux-saga/effects';
import { GET_CHART_EVENTS_REQUEST } from '../types/dataChartTypes';
import {
  getDataChartEventsSuccess,
  getDataChartEventsError,
} from '../actions/dataChartEventsActions';
import {
  setStartLoading,
  setEndLoading,
} from '../../../common/redux/loadManager/actions';
import { getLoadId } from '../../../common/redux/loadManager/methods';

let currentLoadId = 0;

export default function* dataChartEventsSaga(api) {
  while (true) {
    const getDataChartRequestedAction = yield take(GET_CHART_EVENTS_REQUEST);
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));
    const { entityId, startDate, endDate } =
      getDataChartRequestedAction.payload;

    yield call(getDataChartEvents, api, entityId, startDate, endDate);
  }
}

function* getDataChartEvents(api, entityId, startDate, endDate) {
  try {
    const eventsData = yield call(
      api.getEventsDataForCharts,
      entityId,
      startDate,
      endDate,
    );
    yield put(getDataChartEventsSuccess(eventsData.data, startDate, endDate));
    yield put(setEndLoading(currentLoadId));
  } catch (error) {
    yield put(getDataChartEventsError(error));
    yield put(setEndLoading(currentLoadId));
  }
}
