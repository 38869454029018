import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UpdatesType } from '../components/UpdatesDetails/types';

import { RootState } from './rootReducer';

type UpdatesInitialStateType = {
  updates: UpdatesType[] | null;
  isUpdatesError: boolean;
  errorMessage: string;
};

export const initialState: UpdatesInitialStateType = {
  updates: null,
  isUpdatesError: false,
  errorMessage: '',
};

export const updatesSlice = createSlice({
  name: 'updates',
  initialState,
  reducers: {
    setUpdates: (state, action: PayloadAction<UpdatesType[]>) => {
      state.updates = action.payload;
      state.isUpdatesError = false;
      state.errorMessage = '';
    },
    setIsUpdatesError: (state, action: PayloadAction<string>) => {
      state.isUpdatesError = true;
      state.errorMessage = action.payload;
    },
  },
});

export const getUpdatesAction = createAction<{
  id: string;
  fromDate: string;
  toDate: string;
}>('GET_UPDATES_REQUEST');

export const { setUpdates, setIsUpdatesError } = updatesSlice.actions;

export const updatesSelectors = {
  selectUpdates: (state: RootState) => state.updates.updates,
  selectIsUpdatesError: (state: RootState) => state.updates.isUpdatesError,
  selectErrorMessage: (state: RootState) => state.updates.errorMessage,
};

export default updatesSlice.reducer;
