/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
// Redux

function FarmDetailBreadCrumb({ farmName }) {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" key={2}>
          <a href="#">{farmName}</a>
        </li>
      </ol>
    </nav>
  );
}

export default FarmDetailBreadCrumb;
