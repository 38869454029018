import { put, take, call } from 'redux-saga/effects';
import { UPDATE_COMPOSITE_STATES_REQUESTED } from '../types/compositeStateTypes';
import {
  setStartLoading,
  setEndLoading,
} from '../../../common/redux/loadManager/actions';
import { getLoadId } from '../../../common/redux/loadManager/methods';
import {
  getCompositeStateDataError,
  getCompositeStateDataSuccess,
  resetCompositeStatesData,
} from '../actions/compositeStateActions';
import { CompositeStateGraphApiType } from '../../../api/lelyBackend/compositeStateApi';
import { AwaitedApiResultType } from '../../../common/types';

let currentLoadId = 0;

export default function* compositeStateSaga(api: CompositeStateGraphApiType) {
  while (true) {
    const updateCompositeStateRequestedAction = yield take(
      UPDATE_COMPOSITE_STATES_REQUESTED,
    );
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));
    yield put(resetCompositeStatesData());
    const { entityId, startDate, endDate } =
      updateCompositeStateRequestedAction.payload;
    yield call(getCompositeStateData, api, entityId, startDate, endDate);
  }
}

function* getCompositeStateData(
  api: CompositeStateGraphApiType,
  entityId: string,
  startDate: string,
  endDate: string,
) {
  try {
    // TODO: Add types to the call
    const milkingStateData: AwaitedApiResultType<
      typeof api.getCompositeStateDetails
    > = yield call(
      api.getCompositeStateDetails,
      'milking',
      entityId,
      startDate,
      endDate,
    );

    // TODO: Add types to the call
    const cleaningStateData = yield call(
      api.getCompositeStateDetails,
      'cleaning',
      entityId,
      startDate,
      endDate,
    );

    yield put(
      getCompositeStateDataSuccess({
        ...milkingStateData.data,
        ...cleaningStateData.data,
      }),
    );
    yield put(setEndLoading(currentLoadId));
  } catch (error) {
    yield put(getCompositeStateDataError(error));
    yield put(setEndLoading(currentLoadId));
  }
}
