import axiosInstance from '../axiosInstance';
import { ENTITY_KPIS_SERVICE_URL } from './config';

export const api = {
  async getEntityKpiDetails(entityId: string) {
    const entityKpiServiceUrl = ENTITY_KPIS_SERVICE_URL + entityId;
    return axiosInstance.get(entityKpiServiceUrl);
  },
};

export type EntityKPIApiType = typeof api;
