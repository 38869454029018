import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  uppercaseFirstLetter,
  astronautsSortedByNumber,
} from '../common/utils';
import {
  advancedSettingsByRole,
  AdvancedSettingsEnum,
  SettingsTypes,
} from '../containers/Settings/types';
import { RootState } from './rootReducer';
import { userSessionSelectors } from './userSessionSlice';

export const settingsOptionsInitialState: SettingsTypes.SettingsOptionsInitialState =
  {
    entitySettingsOptions: [],
    filteredRobotOptions: [],
    advancedSettings: [],
    errorMessage: '',
  };

export const entitySettingsOptions = createSlice({
  name: 'entitySettingsOptions',
  initialState: settingsOptionsInitialState,
  reducers: {
    setEntitySettingsOptions: (
      state,
      action: PayloadAction<SettingsTypes.FarmAstronaut[]>,
    ) => {
      const ordered = action.payload.sort(astronautsSortedByNumber);
      state.entitySettingsOptions = ordered;
    },
    setFilteredOptions: (
      state,
      action: PayloadAction<SettingsTypes.FarmAstronaut[]>,
    ) => {
      const sorted = action.payload
        .map((astronaut) => {
          return { ...astronaut, name: uppercaseFirstLetter(astronaut.name) };
        })
        .sort(astronautsSortedByNumber);

      state.filteredRobotOptions = sorted;
    },
    setAdvancedSettings: (
      state,
      action: PayloadAction<AdvancedSettingsEnum[]>,
    ) => {
      state.advancedSettings = action.payload;
    },
    setSettingsOptionsError: (state, action: PayloadAction<string>) => {
      state.entitySettingsOptions = [];
      state.filteredRobotOptions = [];
      state.errorMessage = action.payload;
    },
    resetEntitySettingsOptions: () => settingsOptionsInitialState,
  },
});

export const {
  setEntitySettingsOptions,
  setFilteredOptions,
  setSettingsOptionsError,
  setAdvancedSettings,
  resetEntitySettingsOptions,
} = entitySettingsOptions.actions;

export const getEntitySettingsOptions = createAction<{
  entityId: string;
}>('GET_ENTITY_SETTINGS_OPTIONS_REQUEST');

// selectors
export const settingsOptionsSelectors = {
  selectSettingsOptions: (state: RootState) =>
    state.entitySettingsOptions.entitySettingsOptions,
  selectFilteredAstronautOptions: (state: RootState) =>
    state.entitySettingsOptions.filteredRobotOptions,
  selectAdvancedSettings: (state: RootState) =>
    state.entitySettingsOptions.advancedSettings,
  selectSettingsOptionsError: (state: RootState) =>
    state.entitySettingsOptions.errorMessage,
};

// Memoized selector determining available advanced settings options for filter
export const selectAdvancedSettingsByUserRoles = createSelector(
  userSessionSelectors.selectCurrentUserRoles,
  (currentUserRoles) => {
    const result = currentUserRoles
      .flatMap((role) => {
        return advancedSettingsByRole[role];
      })
      // find unique values
      .filter((value, idx, arr) => {
        return arr.indexOf(value) === idx;
      });

    return result;
  },
);

export default entitySettingsOptions.reducer;
