/* eslint-disable @typescript-eslint/no-unused-expressions */
import moment from 'moment';
import { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz';
import convert from 'convert-units';
import _, { cloneDeep, findKey, floor } from 'lodash';
import {
  addDays,
  addMinutes,
  format,
  addSeconds,
  differenceInSeconds,
} from 'date-fns';
import { GetArrayType, LabelValuePairType, Robots } from '../../common/types';
import {
  hideElementsWithCssClassName,
  showElementsWithCssClassName,
} from '../../common/styles';
import { getRangeDurationInMilliseconds } from '../AstronautDataExplorer/util/timeline_logic';
import { AVAILABLE_ZOOM_LEVELS, MAX_ZOOM_LEVEL } from './mfrchart/constants';
import { getInnermostZoomLevelBasedOnNumberOfCanvasesGenerated } from './mfrchart/MultipleCanvasesUtil';
import {
  SettingsWithMembers,
  TableDetailsByType,
} from './types/vectorDataChartTypes';
import {
  DEFAULT_SUPPORTED_SCREEN_WIDTH,
  HOURS_DATE_PICKER_FORMAT,
  MAX_SUPPORTED_SCREEN_WIDTH,
  UI_DATE_FORMAT,
} from '../../common/constants';
import {
  Activity,
  ActivitySpan,
  ActivityType,
  CriticalAlarmActivitySpan,
  Dosing,
  DrivingRouteActivitySpan,
  FGActivity,
  FGGrab,
  FGGrabTaskActivitySpan,
  MFRActivities,
  MFRLoadingActivitySpan,
  VectorDataChartResponse,
} from '../../reducers/types/vectorDataTypes';

let feedHeightGraphInstance;
export const setFeedHeightGraphInstance = (newFeedHeightGraphInstance) => {
  feedHeightGraphInstance = newFeedHeightGraphInstance;
};

export const getFeedHeightGraphInstance = () => {
  return feedHeightGraphInstance;
};

let currentFeedIdToColorDictionary;
export const setCurrentFeedIdToColorDictionary = (
  newFeedIdToColorDictionary,
) => {
  currentFeedIdToColorDictionary = newFeedIdToColorDictionary;
};

export const getCurrentFeedIdToColorDictionary = () => {
  return currentFeedIdToColorDictionary;
};

let currentVectorSettings;
export const setCurrentVectorSettings = (newVectorSettings) => {
  currentVectorSettings = newVectorSettings;
};

export const getCurrentVectorSettings = () => {
  return currentVectorSettings;
};

let latestVectorChartPan = 0;
export const setLatestVectorChartPan = (newVectorChartPan) => {
  latestVectorChartPan = newVectorChartPan;
};

export const getLatestVectorChartPan = () => {
  return latestVectorChartPan;
};

let latestVectorChartScaleX = 1;
export const setLatestVectorChartScaleX = (newVectorChartScaleX) => {
  latestVectorChartScaleX = newVectorChartScaleX;
};

export const getLatestVectorChartScaleX = () => {
  return latestVectorChartScaleX;
};

let latestDragEventDate = new Date();
export const setLatestDragEventDate = (newDragEventDate) => {
  latestDragEventDate = newDragEventDate;
};

export const getLatestDragEventDate = () => {
  return latestDragEventDate;
};

let initialPanValue = new Date();
export const setInitialPanValue = (newInitialPanValue) => {
  initialPanValue = newInitialPanValue;
};

export const getInitialPanValue = () => {
  return initialPanValue;
};

let currentInnermostZoomStartDate = new Date();
export const setCurrentInnermostZoomStartDate = (
  newCurrentInnermostZoomStartDate,
) => {
  currentInnermostZoomStartDate = newCurrentInnermostZoomStartDate;
};

export const getCurrentInnermostZoomStartDate = () => {
  return currentInnermostZoomStartDate;
};

let currentInnermostZoomEndDate = new Date();
export const setCurrentInnermostZoomEndDate = (
  newCurrentInnermostZoomEndDate,
) => {
  currentInnermostZoomEndDate = newCurrentInnermostZoomEndDate;
};

export const getCurrentInnermostZoomEndDate = () => {
  return currentInnermostZoomEndDate;
};

let latestDragXPosition = 0;
export const setLatestDragXPosition = (newDragXPosition) => {
  latestDragXPosition = newDragXPosition;
};

export const getLatestDragXPosition = () => {
  return latestDragXPosition;
};

let latestMouseXPosition = 0;
export const setLatestMouseXPosition = (newMouseXPosition) => {
  latestMouseXPosition = newMouseXPosition;
};

export const getLatestMouseXPosition = () => {
  return latestMouseXPosition;
};

let dragStartXPosition = 0;
export const setDragStartXPosition = (newDragStartXPosition) => {
  dragStartXPosition = newDragStartXPosition;
};

export const getDragStartXPosition = () => {
  return dragStartXPosition;
};

export const getFormattedDateText = (date) => {
  const toDate = new Date(date);
  let formattedDate = format(toDate, UI_DATE_FORMAT);

  if (formattedDate.endsWith(' 24:')) {
    formattedDate = `${formattedDate.slice(0, -5)} 00${formattedDate.slice(
      -3,
    )}`;
  }

  return formattedDate;
};

export const convertUnit = (unit: string, preferredUnit: string) => {
  if (preferredUnit === 'Imperial') {
    switch (unit) {
      case '%':
        return '%';
      case 'kg':
        return 'lb';
      case 'g':
        return 'oz';
      case 'gram':
        return `oz`;
      case 'mm':
        return 'in';
      case 'cm':
        return 'in';
      case 'm':
        return 'yd';
      case 'metres':
        return 'yard';
      case 'meters':
        return 'yard';
      case 'km':
        return 'mi';
      case 'ml':
        return 'fl-oz';
      case 'l':
        return 'gal';
      case 'mm/s':
        return 'in/s';
      case 'gram/s':
        return 'oz/s';
      case 'gr/s':
        return 'oz/s';
      case 'kg/h':
        return 'lb/h';
      case '°C':
        return '°F';
      default:
        break;
    }
  }
  return unit;
};

export const metricToImperialUnits = (
  value,
  unit,
  preferredUnit,
  returnOnlyValue?: boolean,
) => {
  if (preferredUnit === 'Imperial' && unit !== '%' && unit !== '') {
    const convertedUnit = convertUnit(unit, preferredUnit);
    let result;
    if (unit === 'gram') {
      result = _.round(convert(value).from('g').to('oz'), 2);
    } else if (unit === 'mm/s') {
      result = _.round(convert(value).from('mm').to('in'), 2);
    } else if (unit === 'gram/s' || unit === 'gr/s') {
      result = _.round(convert(value).from('g').to('oz'), 2);
    } else if (unit === 'kg/h') {
      result = _.round(convert(value).from('kg').to('lb'), 2);
    } else if (unit === '°C') {
      result = _.round(convert(value).from('C').to('F'), 2);
    } else if (unit === 'metres' || unit === 'meters') {
      result = _.round(convert(value).from('m').to('yd'), 2);
    } else {
      result = _.round(convert(value).from(unit).to(convertedUnit), 2);
    }

    if (returnOnlyValue) return result;
    return `${result} ${convertedUnit}`;
  }

  if (returnOnlyValue) return value;
  return `${value} ${unit}`;
};

export const updateChartUnitValues = (
  graphDataObject,
  currentLineChartConfig,
  metricPreference,
) => {
  let updatedGraphDataObject = [...graphDataObject];

  updatedGraphDataObject = updatedGraphDataObject?.map((current) => {
    return current.map((data) => {
      if (typeof data === 'number') {
        return metricToImperialUnits(
          data,
          currentLineChartConfig?.chartUnitLabel || '',
          metricPreference,
          true,
        );
      }
      return data;
    });
  });
  return updatedGraphDataObject;
};

export const gramsToKilograms = (grams: number) => {
  return (parseFloat(`${grams}`) / 1000).toFixed(2);
};

export const centimetersToMeters = (centimeters) => {
  return (parseFloat(centimeters) / 1000).toFixed(3);
};

export const getFormattedPosition = (position, metricPreference) => {
  let positionValueText = `x: ${metricToImperialUnits(
    centimetersToMeters(position.x),
    'meters',
    metricPreference,
  )}`;
  positionValueText += `, y: ${metricToImperialUnits(
    centimetersToMeters(position.y),
    'meters',
    metricPreference,
  )}`;
  positionValueText += `, z: ${metricToImperialUnits(
    centimetersToMeters(position.z),
    'meters',
    metricPreference,
  )}`;
  return positionValueText;
};

export const getFormattedDeviation = (deviationValue: number) => {
  const deviationValueInKg = deviationValue / 1000;
  return deviationValueInKg === 0
    ? Math.abs(deviationValueInKg)
    : deviationValueInKg.toFixed(2);
};

export const getFormattedMillimeters = (millimeters) => {
  return `${parseFloat(millimeters).toFixed(2)} mm`;
};

export const getFormattedDurationBetweenTwoDates = (startDate, endDate) => {
  let totalRangeDifferenceInSeconds = differenceInSeconds(
    new Date(endDate),
    new Date(startDate),
  );

  // calculate (and subtract) whole days
  const daysDifference = Math.floor(totalRangeDifferenceInSeconds / 86400);
  totalRangeDifferenceInSeconds -= daysDifference * 86400;
  // calculate (and subtract) whole hours
  const hoursDifference = Math.floor(totalRangeDifferenceInSeconds / 3600) % 24;
  totalRangeDifferenceInSeconds -= hoursDifference * 3600;
  // calculate (and subtract) whole minutes
  const minutesDifference = Math.floor(totalRangeDifferenceInSeconds / 60) % 60;
  totalRangeDifferenceInSeconds -= minutesDifference * 60;
  // what's left is seconds
  const secondsDifference = totalRangeDifferenceInSeconds % 60;
  const daysDifferenceStr = daysDifference ? `${daysDifference}d` : ' ';
  const hoursDifferenceStr = `${hoursDifference}h` || ' ';
  const minutesDifferenceStr = `${minutesDifference}m` || ' ';
  const secondsDifferenceStr = `${secondsDifference}s` || ' ';

  return `${daysDifferenceStr} ${hoursDifferenceStr} ${minutesDifferenceStr} ${secondsDifferenceStr}`;
};

export const getFormattedWattValue = (value) => {
  return `${value} Watt`;
};

export const secondsToMinutes = (seconds) => {
  return Math.floor(seconds / 60) % 60;
};

export const secondsToHours = (seconds) => {
  return Math.floor(seconds / 3600);
};

export const secondsToRemainderSeconds = (seconds) => {
  return seconds % 60;
};

export const durationBetweenDates = (date1, date2) => {
  return Math.floor(date1.getTime() - date2.getTime()) / 1000;
};

export const getFormattedBatteryCharge = (batteryChargeArray) => {
  const formattedBatteryCharges = batteryChargeArray.map(
    (currentBatteryCharge) => {
      return `${currentBatteryCharge / 10} Ah`;
    },
  );
  return formattedBatteryCharges.join(', ');
};

export const calculateAccuracy = (activity) => {
  const loadResultArray = activity.activity && activity.activity.loadResult;
  const numberOfLoadResults = loadResultArray.length;
  let ratioSum = 0;
  loadResultArray.forEach((currentLoadResult) => {
    const maxWeight = Math.max(
      currentLoadResult.reqWeight,
      currentLoadResult.loadedWeight,
    );
    const minWeight = Math.min(
      currentLoadResult.reqWeight,
      currentLoadResult.loadedWeight,
    );
    ratioSum += maxWeight / minWeight;
  });
  return ((numberOfLoadResults / ratioSum) * 100).toFixed(2);
};

export const getFormattedHourFromDate = (date) => {
  return format(new Date(date), HOURS_DATE_PICKER_FORMAT);
};

export const getFormattedDuration = (durationInSeconds) => {
  const activityDurationInHours = secondsToHours(durationInSeconds);
  let formattedHours = '';
  if (activityDurationInHours > 0) {
    formattedHours = `${activityDurationInHours}h `;
  }
  const activityDurationInMinutes = secondsToMinutes(durationInSeconds);
  let formattedMinuts = '';
  if (
    activityDurationInHours > 0 ||
    (activityDurationInHours === 0 && activityDurationInMinutes > 0)
  ) {
    formattedMinuts = `${activityDurationInMinutes}m `;
  }
  const activityDurationInSeconds =
    secondsToRemainderSeconds(durationInSeconds);
  let result = `${
    formattedHours + formattedMinuts + activityDurationInSeconds
  }s`;
  if (
    activityDurationInHours +
      activityDurationInMinutes +
      activityDurationInSeconds <
    0
  ) {
    result = `unknown end time`;
  }
  return result;
};

export const calculateActivity = (value, activityValue) => {
  return ((value / activityValue) * 100).toFixed(2);
};

export const calculateDiff = (loadedWeight, requstedWeight) => {
  return (loadedWeight / requstedWeight - 1) * 100;
};

export const getDifferenceColors = (
  loadedWeight: number,
  reqWeight: number,
) => {
  let colorClassName = '';
  const calcDifferenceInKg = Math.abs(
    parseFloat(gramsToKilograms(loadedWeight - reqWeight)),
  );
  const calcDiffInPercentage = Math.abs(calculateDiff(loadedWeight, reqWeight));
  if (
    (calcDifferenceInKg >= 2 &&
      calcDiffInPercentage >= 10 &&
      calcDiffInPercentage < 20) ||
    (calcDifferenceInKg >= 20 && calcDifferenceInKg < 30)
  ) {
    colorClassName = 'is-orange';
  } else if (
    (calcDifferenceInKg >= 4 && calcDiffInPercentage >= 20) ||
    calcDifferenceInKg >= 30
  ) {
    colorClassName = 'is-red';
  }
  return colorClassName;
};

export const decorateEachMemberWithValue = (
  configuration,
  propName,
  propValue,
) => {
  for (const categoryKey in configuration) {
    for (const memberKey in configuration[categoryKey].members) {
      configuration[categoryKey].members[memberKey][propName] = propValue;
    }
  }
  return configuration;
};

export const getRandomHEXColor = () => {
  let randomRed = Math.floor(Math.random() * 255);
  let randomGreen = Math.floor(Math.random() * 255);
  let randomBlue = Math.floor(Math.random() * 255);

  randomRed = Math.max(randomRed, 100);
  randomGreen = Math.max(randomGreen, 100);
  randomBlue = Math.max(randomBlue, 100);

  const randomNonDarkColor = `#${randomRed.toString(16)}${randomGreen.toString(
    16,
  )}${randomBlue.toString(16)}`;

  return randomNonDarkColor;
};

export const hideAndShowEventsBasedOnCurrentConfiguration = (configuration) => {
  for (const categoryKey in configuration) {
    for (const memberKey in configuration[categoryKey].members) {
      const cssClassNameOfSuchEvents = getCSSClassnameForEvent(
        categoryKey,
        memberKey,
      );
      if (configuration[categoryKey].members[memberKey].selected) {
        showElementsWithCssClassName(cssClassNameOfSuchEvents);
      } else {
        hideElementsWithCssClassName(cssClassNameOfSuchEvents);
      }
    }
  }
};

export const setProperCSSClassNameToEachEventFromConfiguration = (
  configuration,
) => {
  for (const categoryKey in configuration) {
    for (const memberKey in configuration[categoryKey].members) {
      configuration[categoryKey].members[memberKey].itemClassName =
        getCSSClassnameForEvent(categoryKey, memberKey);
    }
  }
  return configuration;
};

export const getCSSClassnameForEvent = (eventCategory, eventName) => {
  if (eventCategory && eventName) {
    return `bb-${eventCategory.split(' ').join('')}-${eventName
      .split(' ')
      .join('')}`;
  }

  return null;
};

export const clearPropertyFromObject = (prop, object) => {
  object[prop] = null;
  delete object.prop;
};

export const sortComparatorByTimeString = (a, b) => {
  const aTime = new Date(a).getTime() || new Date(a.time).getTime();
  const bTime = new Date(b).getTime() || new Date(b.time).getTime();
  return aTime - bTime;
};

export const extractVectorFeedSettings = (
  vectorOtherSettingData: SettingsWithMembers<string | number>,
): SettingsWithMembers<{ id: string }> => {
  const vectorNewFeedHeightSettingsData = {} as SettingsWithMembers<{
    id: string;
  }>;

  for (const categoryKey in vectorOtherSettingData) {
    vectorNewFeedHeightSettingsData[categoryKey] = { members: {} };
    vectorNewFeedHeightSettingsData[categoryKey].members = {};
    for (const [key, value] of Object.entries(
      vectorOtherSettingData[categoryKey].members,
    )) {
      const noOccurence = Object.values(
        vectorOtherSettingData[categoryKey].members,
      ).filter((obj) => obj === value).length;
      if (noOccurence > 1) {
        vectorNewFeedHeightSettingsData[categoryKey].members[
          `${value} ${key}`
        ] = { id: key };
      } else {
        vectorNewFeedHeightSettingsData[categoryKey].members[value] = {
          id: key,
        };
      }
    }
  }

  const sortedSettingsData = sortFeedHeightSettingsDataValues(
    vectorNewFeedHeightSettingsData,
  );

  return sortedSettingsData;
};

export const populateNamesFromChartData = (
  chartData: VectorDataChartResponse,
) => {
  const fenceNames: Record<
    keyof typeof chartData.feedHeights,
    keyof typeof chartData.settings.fenceNames
  > = {};

  Object.keys(chartData.feedHeights).forEach((key) => {
    fenceNames[key] = chartData.settings.fenceNames[key] ?? key;
  });

  const feedNames: Record<
    GetArrayType<
      (typeof chartData.feedIdsByType)[keyof typeof chartData.feedIdsByType]
    >,
    string | number
  > = {};

  Object.keys(chartData.feedIdsByType).forEach((key) => {
    Object.values(chartData.feedIdsByType[key]).forEach((feedId) => {
      feedNames[feedId] = chartData.settings.feedNames[feedId] ?? feedId;
    });
  });

  const rationNames: Record<string, string> = {};
  Object.keys(chartData.mfrRations).forEach((key) => {
    Object.values(chartData.mfrRations[key]).forEach((rationId) => {
      rationNames[rationId] =
        chartData.settings.rationNames[rationId] ?? rationId;
    });
  });

  return { rationNames, fenceNames, feedNames };
};

const sortFeedHeightSettingsDataValues = (
  settingsData: SettingsWithMembers<{
    id: string;
  }>,
) => {
  Object.keys(settingsData).forEach((key) => {
    const orderedMembers = Object.keys(settingsData[key].members)
      .sort((a, b) => {
        const { aValue, bValue } = padNumberWithZero(a, b);
        return aValue.toLowerCase().localeCompare(bValue.toLowerCase());
      })
      .reduce((obj, orderedKey) => {
        obj[orderedKey] = settingsData[key].members[orderedKey];
        return obj;
      }, {});

    settingsData[key].members = orderedMembers;
  });

  return settingsData;
};

// If you compare Test 7 and Test 10 ->
// Test 10 will go before Test 7 because it is starting with 1
// So we pad with 0 only for comparison reasons - Test 07 vs Test 10
export const padNumberWithZero = (
  a: string,
  b: string,
): { aValue: string; bValue: string } => {
  const aNumberMatch = a.match(/\d+/);
  const bNumberMatch = b.match(/\d+/);

  const aValue = paddingWithZero(aNumberMatch, a);
  const bValue = paddingWithZero(bNumberMatch, b);

  function paddingWithZero(
    matchArr: RegExpMatchArray | null,
    stringValue: string,
  ) {
    if (matchArr) {
      let number = matchArr[0];
      if (number.length === 1) {
        number = `0${number}`;
        return stringValue.replace(matchArr[0], number).replace('  ', ' ');
      }
    }

    return stringValue.replace('  ', ' ');
  }

  return { aValue, bValue };
};

export const getDatesArrayBetweenTwoDates = (
  startDate: Date,
  endDate: Date,
) => {
  startDate.setHours(0, 0, 0);

  return generateDates(startDate, endDate);
};

/** Get the X coordinate of a tick relative to a parent element
 * for a specific date, based on the time range to which
 * this date belongs to and the width of the parent element
 */
export const getTickXCoordinateByDateRangeAndComponentWidth = (
  specificDateInRange: Date,
  rangeStartDate: Date,
  rangeEndDate: Date,
  parentElementWidthInPixels: number,
  isMostLeftDay: boolean,
  currentZoomLevel: number,
  robot: Robots,
) => {
  const zoomLevelComparison =
    robot === Robots.Astronaut
      ? currentZoomLevel === MAX_ZOOM_LEVEL
      : currentZoomLevel >= 64;
  const zoomLevel = zoomLevelComparison
    ? getInnermostZoomLevelBasedOnNumberOfCanvasesGenerated(
        parentElementWidthInPixels,
      )
    : currentZoomLevel;

  const rangeDurationInMilliseconds = getRangeDurationInMilliseconds(
    rangeStartDate,
    rangeEndDate,
  );

  const targetDateDistanceToStartInMilliseconds: number =
    (robot === Robots.Astronaut && isMostLeftDay) ||
    (robot === Robots.Vector &&
      zoomLevel > AVAILABLE_ZOOM_LEVELS[0] &&
      isMostLeftDay)
      ? 0
      : specificDateInRange.getTime() - rangeStartDate.getTime();

  // eslint-disable-next-line radix
  const tickPos = parseInt(
    `${
      (targetDateDistanceToStartInMilliseconds / rangeDurationInMilliseconds) *
      parentElementWidthInPixels
    }`,
  );

  const dateTextHoursOffset = zoomLevel === 1 ? 8 : 11;
  const textStart = moment(targetDateDistanceToStartInMilliseconds)
    .add(dateTextHoursOffset, 'hours')
    .valueOf();

  // eslint-disable-next-line radix
  let textPos = parseInt(
    `${(textStart / rangeDurationInMilliseconds) * parentElementWidthInPixels}`,
  );
  textPos =
    robot === Robots.Astronaut && zoomLevel === 1 ? tickPos + 5 : textPos;

  return { tickPos, textPos };
};

const getOffsetOnlyMinutes = (timeZone: string) => {
  return 60 * ((getTimezoneOffset(timeZone) / 1000 / 60 / 60) % 1);
};

export const getDatesArrayBetweenTwoDatesBasedOnGranularity = (
  startDate: Date,
  endDate: Date,
  granularity: number,
  currentZoomLevel: number,
  robot: Robots,
  farmTimeZone: string,
) => {
  let hours = 0;
  let minutes = 0;
  const startHoursInFarmTimeZone = parseInt(
    formatInTimeZone(startDate, farmTimeZone, 'H'),
    10,
  );
  switch (robot) {
    case Robots.Vector:
      hours = currentZoomLevel > 16 ? startDate.getHours() : 0;
      minutes = currentZoomLevel > 480 ? startDate.getMinutes() : 0;
      break;
    case Robots.Astronaut:
      hours =
        startHoursInFarmTimeZone % 2 !== 0
          ? startDate.getHours() - 1
          : startDate.getHours();
      minutes =
        currentZoomLevel > 64
          ? startDate.getMinutes()
          : Math.abs(
              getOffsetOnlyMinutes(farmTimeZone) -
                getOffsetOnlyMinutes(
                  Intl.DateTimeFormat().resolvedOptions().timeZone,
                ),
            );
      break;
    default:
      break;
  }
  startDate.setHours(hours, minutes, 0);

  return generateDates(startDate, endDate, granularity);
};

export const getDatesForVerticalLinesBasedOnZoomLevel = (
  startDate: Date,
  endDate: Date,
  granularity: number,
) => {
  startDate.setHours(0, 0, 0);
  if (startDate.getHours() % 2 !== 0) {
    startDate.setHours(startDate.getHours() - 1);
  }

  return generateDates(startDate, endDate, granularity);
};

/**
 * Generating dates between certain dates
 * and if granularity is provided the dates
 * are generated in certain periods
 */
const generateDates = (
  startDate: Date,
  endDate: Date,
  granularity?: number,
) => {
  const startMoment = startDate;
  const endMoment = endDate;
  let now = startMoment;
  const dates: any[] = [];
  while (now.getTime() <= endMoment.getTime()) {
    dates.push(now);
    if (granularity) {
      now =
        granularity < 1
          ? addSeconds(now, 60 * 60 * granularity)
          : addMinutes(now, 60 * granularity);
    } else {
      now = addDays(now, 1);
    }
  }
  return dates;
};

export const getIndexFromAvailableZoomLevels = (level) => {
  return AVAILABLE_ZOOM_LEVELS.indexOf(level);
};

export const tableDetailsByType: TableDetailsByType = {
  dispenseWeight: (data, metricPreference) => ({
    columns: [
      { title: 'Feed type', key: 'feedType' },
      {
        title: `Loaded (${convertUnit('kg', metricPreference)})`,
        key: 'loaded',
      },
      {
        title: `Flow (${convertUnit('gr/s', metricPreference)})`,
        key: 'avgFlow',
      },
    ],
    aggregatedData: data.flatMap((feedItem) => {
      return {
        ...feedItem,
      };
    }),
  }),
  feedGrabs: (data, metricPreference) => ({
    columns: [
      { title: 'Feed type', key: 'feedType' },
      {
        title: `Estimated weight (${convertUnit('kg', metricPreference)})`,
        key: 'estimated',
      },
      { title: 'Successful grabs', key: 'grabs' },
      {
        title: `Weight/grab (${convertUnit('kg', metricPreference)})`,
        key: 'weightGrabRatio',
      },
      { title: 'Rejected grabs', key: 'retriesNum' },
      { title: 'Rejected grabs (%)', key: 'retriesInPercent' },
    ],
    aggregatedData: data.map((feedItem) => {
      return {
        ...feedItem,
        retriesNum: feedItem.retries.numeric,
        retriesInPercent: feedItem.retries.inPercent,
        weightGrabRatio: floor(
          feedItem.estimated / (feedItem.grabs - feedItem.retries.numeric),
          2,
        ),
      };
    }),
  }),
  Height: (
    data,
    metricPreference,
    chartUnitLabel,
    aggregationPropertyLabel,
  ) => ({
    columns: [
      { title: 'Fence', key: 'rowName' },
      {
        title: `Average ${aggregationPropertyLabel} (${convertUnit(
          chartUnitLabel!,
          metricPreference,
        )})`,
        key: 'avgValue',
      },
      {
        title: `Lowest ${aggregationPropertyLabel} (${convertUnit(
          chartUnitLabel!,
          metricPreference,
        )})`,
        key: 'minValue',
      },
      {
        title: `Maximum ${aggregationPropertyLabel} (${convertUnit(
          chartUnitLabel!,
          metricPreference,
        )})`,
        key: 'maxValue',
      },
    ],
    aggregatedData: data.map((feedItem) => {
      return {
        ...feedItem,
      };
    }),
  }),
  'Dosing Speed': (data, metricPreference, chartUnitLabel) => ({
    columns: [
      { title: 'Ration', key: 'rowName' },
      {
        title: `Avg dossec (${convertUnit(chartUnitLabel!, metricPreference)})`,
        key: 'avgValue',
      },
      {
        title: `Min dossec (${convertUnit(chartUnitLabel!, metricPreference)})`,
        key: 'minValue',
      },
      {
        title: `Max dossec (${convertUnit(chartUnitLabel!, metricPreference)})`,
        key: 'maxValue',
      },
    ],
    aggregatedData: data.map((feedItem) => {
      return {
        ...feedItem,
      };
    }),
  }),
  Accuracy: (data, metricPreference) => ({
    columns: [
      { title: 'Feed type', key: 'rowName' },
      {
        title: `Requested (${convertUnit('kg', metricPreference)})`,
        key: 'requestedSum',
      },
      {
        title: `Loaded (${convertUnit('kg', metricPreference)})`,
        key: 'loadedSum',
      },
      {
        title: `Difference (${convertUnit('kg', metricPreference)})`,
        key: 'differenceSum',
      },
    ],
    aggregatedData: data?.map((feedItem) => {
      return {
        ...feedItem,
      };
    }),
  }),
};

export const isFloat = (n: number) => {
  return Number(n) === n && n % 1 !== 0;
};

export const toFixedIfFloat = (n: number) => {
  return isFloat(n) ? n.toFixed(2) : n;
};

export const getMaxEventsPerPage = () => {
  const windowWidth = window.innerWidth;

  if (windowWidth < DEFAULT_SUPPORTED_SCREEN_WIDTH) {
    return 6;
  }

  if (windowWidth < MAX_SUPPORTED_SCREEN_WIDTH) {
    return 8;
  }

  return 11;
};

export const generateLoadingOptions = (
  selectedItemDetails,
  vectorSettings,
  metricPreference,
): LabelValuePairType[] => {
  const options = [
    {
      label: 'Ration',
      value: vectorSettings.rationNames[selectedItemDetails.activity.rationId],
    },
    {
      label: 'Start Weight',
      value: metricToImperialUnits(
        gramsToKilograms(selectedItemDetails.activity.startWeight),
        'kg',
        metricPreference,
      ),
    },
    {
      label: 'Requested Weight',
      value: metricToImperialUnits(
        gramsToKilograms(selectedItemDetails.activity.reqWeight),
        'kg',
        metricPreference,
      ),
    },
    {
      label: 'Loaded Weight',
      value: metricToImperialUnits(
        gramsToKilograms(selectedItemDetails.activity.loadedWeight),
        'kg',
        metricPreference,
      ),
    },
    {
      label: 'Accuracy',
      value: `${selectedItemDetails.activity.accuracyPercentage}%`,
    },
    {
      label: 'Mix Time',
      value: getFormattedDuration(selectedItemDetails.activity.postMixTime),
    },
    {
      label: 'Predicted loaded + mixing time',
      value: getFormattedDuration(
        selectedItemDetails.feedTask.predictedLoadingTime,
      ),
    },
  ];

  if (selectedItemDetails.activity.additionalMixTime > 0) {
    options.splice(options.length - 1, 0, {
      label: 'Intermix Time',
      value: getFormattedDuration(
        selectedItemDetails.activity.additionalMixTime,
      ),
    });
  }
  if (selectedItemDetails.feedTask) {
    options.unshift({
      label: selectedItemDetails.feedTask.taskDesc,
      value: selectedItemDetails.feedTask.highPriorityFenceDesc,
    });
  }
  if (selectedItemDetails.drivingRouteTask) {
    options.unshift({
      label: selectedItemDetails.drivingRouteTask.taskDesc,
      value: selectedItemDetails.drivingRouteTask.highPriorityFenceDesc,
    });
  }

  return options;
};

export const generateFenceOptions = (
  selectedItemDetails,
  metricPreference,
): LabelValuePairType[] => {
  const actionText = `${selectedItemDetails.routeId} - ${selectedItemDetails.actionId}`;
  const options = [
    { label: 'Time', value: getFormattedDateText(selectedItemDetails.time) },
    { label: 'Mfr', value: selectedItemDetails.mfr },
    { label: 'Route - Action id', value: actionText },
    { label: 'Fence', value: selectedItemDetails.feedName },
    {
      label: 'Scanned height',
      value: metricToImperialUnits(
        selectedItemDetails.height,
        selectedItemDetails.chartUnitLabel,
        metricPreference,
      ),
    },
    {
      label: 'Floor height',
      value: metricToImperialUnits(
        selectedItemDetails.fenceFloorHeight,
        selectedItemDetails.chartUnitLabel,
        metricPreference,
      ),
    },
    {
      label: 'Invalid scans',
      value: `${selectedItemDetails.invalidPercentage}%`,
    },
    {
      label: 'Valid',
      value: selectedItemDetails.valid.toString(),
    },
    {
      label: 'Predicted empty time',
      value: selectedItemDetails.feedHeightPrediction.predictedEmptyTime,
    },
    {
      label: 'Prediction made at',
      value: selectedItemDetails.feedHeightPrediction.time,
    },
  ];
  return options;
};

export const generateAccuracyActivityOptions = (
  selectedItemDetails,
  metricPreference,
): LabelValuePairType[] => {
  const options = [
    { label: 'Time', value: getFormattedDateText(selectedItemDetails.time) },
    { label: 'Feed id', value: selectedItemDetails.feedName },
    {
      label: 'Requested weight',
      value: metricToImperialUnits(
        selectedItemDetails.reqWeight,
        'kg',
        metricPreference,
      ),
    },
    {
      label: 'Loaded weight',
      value: metricToImperialUnits(
        selectedItemDetails.loadedWeight,
        'kg',
        metricPreference,
      ),
    },
  ];
  return options;
};

export const generateDosingOptions = (
  selectedItem,
  vectorSettings,
  currentDoseInfo,
  metricPreference,
) => {
  const options = [
    {
      label: 'Route - Action id',
      value: `${selectedItem.activity.routeId} - ${currentDoseInfo.actionId}`,
    },
    {
      label: 'Fence',
      value: vectorSettings.fenceNames[currentDoseInfo.fenceId],
    },
    {
      label: 'Start Weight',
      value: metricToImperialUnits(
        gramsToKilograms(currentDoseInfo.startWeight),
        'kg',
        metricPreference,
      ),
    },
    {
      label: 'End Weight',
      value: metricToImperialUnits(
        gramsToKilograms(currentDoseInfo.endWeight),
        'kg',
        metricPreference,
      ),
    },
    {
      label: 'Dosing Speed',
      value: metricToImperialUnits(
        currentDoseInfo.dossec,
        'gram/s',
        metricPreference,
      ),
    },
    {
      label: 'Dosing Mode',
      value: vectorSettings.dosingModeNames[currentDoseInfo.dosingMode],
    },
    {
      label: 'Max Driving Speed',
      value: metricToImperialUnits(
        currentDoseInfo.maxSpeed,
        'mm/s',
        metricPreference,
      ),
    },
    {
      label: 'Power Use Mixer (avg)',
      value: getFormattedWattValue(currentDoseInfo.powerConsumptionMixerAvg),
    },
  ];

  return options;
};

export const isLoadedWeightActivity = (
  obj: any,
): obj is { loadedWeight: number } => {
  return !!obj && typeof obj === 'object' && 'loadedWeight' in obj;
};

export const getFeedGrabberGrabsMembers = (
  activitiesArray: ActivityType<FGActivity>[],
  vectorFeedNames: Record<number, string | number>,
) => {
  const vectorFeedGrabberData: Record<
    FGGrabTaskActivitySpan['activity']['feedId'],
    string
  > = {};
  activitiesArray.forEach((currentActivity) => {
    if (isActivityGrabTask(currentActivity)) {
      const feedIds = currentActivity.activitySpans.map(
        (span) => span.activity.feedId,
      );
      Object.values(feedIds).forEach((value) => {
        if (value in vectorFeedNames) {
          vectorFeedGrabberData[value] = vectorFeedNames[value]?.toString();
        }
      });
    }
  });
  return vectorFeedGrabberData;
};

export function getDispensingMembers(activitiesArray, vectorFeedNames) {
  const vectorDispensingData = {};
  activitiesArray.forEach((currentActivity) => {
    if (currentActivity.name === 'Dispensing (weight)') {
      const feedTypes = currentActivity.activitySpans.flatMap(
        (span) => span.activity.feedType,
      );

      for (const key in vectorFeedNames) {
        if (feedTypes.includes(vectorFeedNames[key])) {
          vectorDispensingData[key] = vectorFeedNames[key];
        }
      }
    }
  });
  return vectorDispensingData;
}

export const getModifiedDosingMfrMembers = (
  otherSettingsData: SettingsWithMembers<string | number>,
  mfrString: string,
) => {
  const modifiedDosingMfrMembers: Record<string | number, string | number> = {};
  Object.keys(otherSettingsData[`Dosing${mfrString}`].members).forEach(
    (currentRationId) => {
      otherSettingsData[`Dosing${mfrString}`].members[currentRationId] =
        mfrString +
        otherSettingsData[`Dosing${mfrString}`].members[currentRationId];
      modifiedDosingMfrMembers[mfrString + currentRationId] =
        otherSettingsData[`Dosing${mfrString}`].members[currentRationId];
    },
  );
  return modifiedDosingMfrMembers;
};

export const extractCriticalAlarmsActivitiesFromChartData = <
  T extends ActivityType<Activity>,
>(
  activitiesArray: T[],
): ActivitySpan<CriticalAlarmActivitySpan>[][] => {
  const activityEntriesDictionary = activitiesArray
    ?.filter(isActivityCriticalAlarm)
    .map(
      (currentSpan) => currentSpan.activitySpans,
    ) as ActivitySpan<CriticalAlarmActivitySpan>[][];

  return activityEntriesDictionary;
};

// TODO: export types for usage after response normalizations => excess properties declared on parameter object
export type DosingEntriesDictionaryType = Record<
  string,
  DosingEntriesByRationId[]
>;
// TODO: export types for usage after response normalizations => excess properties declared on parameter object
export type DosingEntriesByRationId = {
  height: number;
  time: string;
  activitySpanObject: ActivitySpan<DrivingRouteActivitySpan>;
  currentDosing: Dosing;
  rationId: string;
};

export function isDrivingActivity(
  activity: ActivityType<MFRActivities>,
): activity is ActivityType<DrivingRouteActivitySpan> {
  if (
    activity.name === 'Driving route' ||
    activity.name ===
      'Driving route with dosing and feed height measurements' ||
    activity.name === 'Driving route with feed height measurements'
  ) {
    return true;
  }

  return false;
}

export function isLoadingActivity(
  activity: ActivityType<MFRActivities>,
): activity is ActivityType<MFRLoadingActivitySpan> {
  return activity.name.toLowerCase() === 'loading';
}

export const extractDosingActivitiesFromActivitiesArray = (
  activitiesArray: ActivityType<MFRActivities>[],
  mfrType: 'Mfr1' | 'Mfr2',
) => {
  const dosingEntriesDictionary: DosingEntriesDictionaryType = {};
  activitiesArray.forEach((currentActivity) => {
    if (isDrivingActivity(currentActivity)) {
      currentActivity.activitySpans.forEach((currentActivitySpan) => {
        const currentSpanInfo = currentActivitySpan.activity;
        const currentSpanDrivingRouteTask =
          currentActivitySpan.drivingRouteTask;
        const currentSpanRationId = currentSpanDrivingRouteTask?.rationId
          ? mfrType + currentSpanDrivingRouteTask.rationId
          : null;
        const currentSpanStartTime = currentActivitySpan.startTime;
        const dosingArray = currentSpanInfo.dosing;
        dosingArray.forEach((currentDosing) => {
          if (currentSpanRationId !== null) {
            if (!dosingEntriesDictionary[currentSpanRationId]) {
              dosingEntriesDictionary[currentSpanRationId] =
                [] as DosingEntriesByRationId[];
            }
            const activitySpanClone = cloneDeep(currentActivitySpan);
            (activitySpanClone as any).name = currentActivity.name; // create new type for usage of new props
            (activitySpanClone as any).time = new Date(currentSpanStartTime); // create new type for usage of new props
            (activitySpanClone as any).mfrType = mfrType; // create new type for usage of new props
            dosingEntriesDictionary[currentSpanRationId].push({
              height: currentDosing.dossec,
              time: currentSpanStartTime,
              activitySpanObject: activitySpanClone,
              currentDosing: cloneDeep(currentDosing),
              rationId: currentSpanRationId,
            });
          }
        });
      });
    }
  });
  return dosingEntriesDictionary;
};

// TODO: export types for usage after response normalizations => excess properties declared on parameter object
export type AccuracyEntriesDictionaryType = Record<string, AccuracyByFeedId[]>;
// TODO: export types for usage after response normalizations => excess properties declared on parameter object
export type AccuracyByFeedId = {
  height: number;
  reqWeight: number;
  loadedWeight: number;
  time: string;
};

export const extractAccuracyActivitiesFromActivitiesArray = (
  activitiesArray: ActivityType<MFRActivities>[],
) => {
  const accuracyKgEntriesDictionary: AccuracyEntriesDictionaryType = {};
  const accuracyPercentEntriesDictionary: AccuracyEntriesDictionaryType = {};

  activitiesArray.forEach((currentActivity) => {
    if (isLoadingActivity(currentActivity)) {
      currentActivity.activitySpans.forEach((currentActivitySpan) => {
        const currentSpanInfo = currentActivitySpan.activity;
        const currentSpanStartTime = currentActivitySpan.startTime;
        currentSpanInfo.loadResult.forEach((currentLoadResult) => {
          const currentActivityAccuracyInPercent =
            ((currentLoadResult.loadedWeight - currentLoadResult.reqWeight) /
              currentLoadResult.reqWeight) *
            100;
          const currentActivityAccuracyInKg =
            (currentLoadResult.loadedWeight - currentLoadResult.reqWeight) /
            1000;
          const currentFeedId = currentLoadResult.feedId;

          if (!accuracyKgEntriesDictionary[currentFeedId]) {
            accuracyKgEntriesDictionary[currentFeedId] = [];
          }

          accuracyKgEntriesDictionary[currentFeedId].push({
            height: currentActivityAccuracyInKg,
            reqWeight: currentLoadResult.reqWeight / 1000,
            loadedWeight: currentLoadResult.loadedWeight / 1000,
            time: currentSpanStartTime,
          });

          if (!accuracyPercentEntriesDictionary[currentFeedId]) {
            accuracyPercentEntriesDictionary[currentFeedId] = [];
          }

          accuracyPercentEntriesDictionary[currentFeedId].push({
            height: currentActivityAccuracyInPercent,
            reqWeight: currentLoadResult.reqWeight / 1000,
            loadedWeight: currentLoadResult.loadedWeight / 1000,
            time: currentSpanStartTime,
          });
        });
      });
    }
  });

  Object.keys(accuracyPercentEntriesDictionary).forEach((currentKey) => {
    accuracyPercentEntriesDictionary[currentKey].sort(
      sortComparatorByTimeString,
    );
  });

  Object.keys(accuracyKgEntriesDictionary).forEach((currentKey) => {
    accuracyKgEntriesDictionary[currentKey].sort(sortComparatorByTimeString);
  });
  return {
    accuracyPercentEntriesDictionary,
    accuracyKgEntriesDictionary,
  };
};

export function isActivityGrabTask(
  activity: ActivityType<FGActivity>,
): activity is ActivityType<FGGrabTaskActivitySpan> {
  return activity.name.toLowerCase() === 'grab task';
}

export function isActivityCriticalAlarm(
  activity: ActivitySpan<any>,
): activity is ActivitySpan<CriticalAlarmActivitySpan> {
  return activity.name.toLowerCase() === 'critical alarm';
}

export type FeedGrabberGrabsDictionaryType = Record<
  number,
  (FGGrab & { height: number; time: string })[]
>;

export const extractDispenseWeightsFromActivitiesArray = (
  chartData,
  vectorDispenseWeightData,
) => {
  const dispenseWeightDictionary = chartData.pdbActivities
    .filter((activity) => activity.name === 'Dispensing (weight)')
    .map((x) => {
      return x.activitySpans;
    })
    .reduce((x, y) => {
      return x.concat(y);
    }, [])
    .reduce((prev, next) => {
      const { feedType } = next.activity;
      const feedId = findKey(vectorDispenseWeightData, (val) => {
        return val === feedType;
      });

      if (!feedId) {
        return prev;
      }

      const { startTime } = next;
      const height = next.activity.actualFlow;

      if (!prev[feedId]) {
        prev[feedId] = [];
      }

      prev[feedId].push({
        time: startTime,
        height,
        ...next,
      });

      return prev;
    }, {});

  const dispenseWeightMembersWithData = Object.keys(
    dispenseWeightDictionary,
  ).reduce((prev, next) => {
    prev[next] =
      chartData.settings.otherSettings['Dispense weight'].members[next];

    return prev;
  }, {});

  return { dispenseWeightDictionary, dispenseWeightMembersWithData };
};

export const extractFeedGrabberGrabsFromActivitiesArray = (
  fgActivities: ActivityType<FGActivity>[],
) => {
  const feedGrabberGrabsDictionary = fgActivities
    .filter((activity): activity is ActivityType<FGGrabTaskActivitySpan> =>
      isActivityGrabTask(activity),
    )
    .map((x) => {
      return x.activitySpans;
    })
    .reduce((x, y) => {
      return x.concat(y);
    }, [] as ActivitySpan<FGGrabTaskActivitySpan>[])
    .filter((task) => task.activity)
    .reduce((prev, next) => {
      const { feedId } = next.activity;

      if (!feedId && !next.activity.grabs) {
        return prev;
      }

      next.activity.grabs.forEach((item) => {
        const { startTime } = item;
        const height = item.estimatedWeight / 1000;

        if (!prev[feedId]) {
          prev[feedId] = [];
        }

        prev[feedId].push({
          height,
          time: startTime,
          ...item,
        });
      });

      return prev;
    }, {} as FeedGrabberGrabsDictionaryType);

  return feedGrabberGrabsDictionary;
};
