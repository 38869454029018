import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  graphElementsData: {
    Legend: { iconClassName: 'lely-icon-tasks', isChecked: true },
    'Horizontal grid': {
      iconClassName: 'lely-icon-menu',
      isChecked: true,
    },
    'Vertical grid': {
      iconClassName: 'lely-icon-list',
      isChecked: true,
    },
    'Data points': { iconClassName: 'lely-icon-data-points', isChecked: true },
  },
  isLegendOn: true,
  isHorizontalGridOn: true,
  isVerticalGridOn: true,
  areDotsOn: true,
};

const astronautGraphElementsSlice = createSlice({
  name: 'astronautGraphElements',
  initialState,
  reducers: {
    setGraphElementsData(state, action) {
      state.graphElementsData = action.payload;
    },
    setIsLegendOn(state, action) {
      state.isLegendOn = action.payload;
      state.graphElementsData.Legend.isChecked = action.payload;
    },
    setIsHorizontalGridOn(state, action) {
      state.isHorizontalGridOn = action.payload;
      state.graphElementsData['Horizontal grid'].isChecked = action.payload;
    },
    setIsVerticalGridOn(state, action) {
      state.isVerticalGridOn = action.payload;
      state.graphElementsData['Vertical grid'].isChecked = action.payload;
    },
    setAreDotsOn(state, action) {
      state.areDotsOn = action.payload;
      state.graphElementsData['Data points'].isChecked = action.payload;
    },
  },
});

export const {
  setGraphElementsData,
  setIsLegendOn,
  setIsHorizontalGridOn,
  setIsVerticalGridOn,
  setAreDotsOn,
} = astronautGraphElementsSlice.actions;

export default astronautGraphElementsSlice.reducer;
