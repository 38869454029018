import axiosInstance from '../axiosInstance';
import { GET_ENTITY_STATE } from './config';

export const api = {
  async getEntityStatus(entityId) {
    const getEntityStatusUrl = GET_ENTITY_STATE.replace('{id}', entityId);
    return axiosInstance.get(getEntityStatusUrl);
  },
};

export type EntityStatusApiType = typeof api;
