import { RadioGroup, FormControl } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './RadioGroupSelection.scss';
import { useDispatch } from 'react-redux';
import {
  getAstronautFilteredData,
  getAstronautLinkedFilteredData,
  getAstronautSelectedProperties,
  getCurrentTimeRange,
  getDisabledFields,
  setDisabledFields,
  setShouldShowErrorMessage,
} from '../../../reducers/astronautPropertySelectSlice';
import {
  DATE_RANGE_DAY,
  DATE_RANGE_HOUR,
} from '../../../components/DataCharts/RangesLogic';
import { useAppSelector } from '../../../reducers/rootReducer';
import { getSelectedDisabledFields } from '../../../components/AstronautDataExplorer/AstronautFilterMenu/utils';
import RadioButtonElement from '../Elements/RadionButtonElement';
import RadioButton from '../Elements/RadioButton';

interface Props {
  labelsArray: string[];
  onSelectionChange: (
    rangeItemClicked: string,
    isAlreadyPresent?: boolean,
  ) => void;
  isDisabled?: boolean;
  defaultValue?: string;
}

export default function RadioGroupSelection({
  labelsArray,
  onSelectionChange,
  isDisabled = false,
  defaultValue,
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const currentSelectedRange = useAppSelector(getCurrentTimeRange);
  const [range, setRange] = useState(currentSelectedRange);
  const filteredData = useAppSelector(getAstronautFilteredData);
  const disabledFields = useAppSelector(getDisabledFields);
  const likedFilteredData = useAppSelector(
    getAstronautLinkedFilteredData,
  )?.configurations;
  const selectedProperties = useAppSelector(getAstronautSelectedProperties);
  const linkedRobotName = useAppSelector(
    getAstronautLinkedFilteredData,
  )?.linkedName;

  useEffect(() => {
    if (range !== currentSelectedRange) {
      setRange(currentSelectedRange);
    }
  }, [currentSelectedRange]);

  useEffect(() => {
    const newDisabledFields = getSelectedDisabledFields(
      filteredData,
      selectedProperties,
      'name',
    );
    const newDisabledLinkedFields = getSelectedDisabledFields(
      likedFilteredData,
      selectedProperties,
      'name',
      linkedRobotName,
    );
    dispatch(
      setDisabledFields([...newDisabledFields, ...newDisabledLinkedFields]),
    );
    if (!newDisabledFields && !newDisabledLinkedFields) {
      dispatch(setShouldShowErrorMessage(false));
    }
  }, [selectedProperties]);

  const handleRadioChange = (event) => {
    const newlySelectedRadioButtonLabel = event.target.value;

    if (typeof onSelectionChange === 'function') {
      onSelectionChange(newlySelectedRadioButtonLabel);
    }
    if (
      newlySelectedRadioButtonLabel === DATE_RANGE_DAY &&
      disabledFields &&
      disabledFields?.length > 0
    ) {
      dispatch(setShouldShowErrorMessage(true));
      setRange(DATE_RANGE_HOUR);
      return;
    }
    setRange(newlySelectedRadioButtonLabel);
  };

  return (
    <FormControl component="fieldset" className="radio-group-selection">
      <RadioGroup
        defaultValue={defaultValue || range}
        value={defaultValue || range}
        aria-label={defaultValue || range}
        onChange={handleRadioChange}
        className="radio-group"
        sx={{ flexDirection: 'row' }}
      >
        {labelsArray.map((currentLabel) => {
          return (
            <RadioButtonElement
              key={currentLabel}
              value={currentLabel}
              label={currentLabel}
              disabled={isDisabled}
              control={<RadioButton />}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}
