import {
  GET_CHART_EVENTS_REQUEST_SUCCESS,
  GET_CHART_EVENTS_REQUEST_ERROR,
} from '../components/DataCharts/types/dataChartTypes';

/** Store states
 * @constant {Object} */
export const initialState = {
  eventsChartData: {},
  isDataEventsChartDataError: true,
  errorMessage: '',
};

/** Data Chart Reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function dataChartEventsReducer(state = initialState, action = {}) {
  switch (action.type) {
    /** Action Creator - Set data chart to store if
     *  its respective API request passed successfully */
    case GET_CHART_EVENTS_REQUEST_SUCCESS: {
      const eventsChartData = action.payload;
      const eventsDataDictionary = {};

      eventsChartData?.events?.events?.forEach((currentEventData) => {
        const currentEventX =
          currentEventData && currentEventData.x
            ? new Date(currentEventData.x)
            : currentEventData.x;
        const currentEventLevel = currentEventData.level;
        const currentEventIcon = currentEventData.icon;
        const currentEventColor = currentEventData.color;
        if (!eventsDataDictionary[currentEventLevel]) {
          eventsDataDictionary[currentEventLevel] = {
            itemCSSClass: `bb-events-chart-${currentEventLevel}`,
            items: [],
            isSelected: true,
          };
        }
        eventsDataDictionary[currentEventLevel].color = currentEventColor;
        eventsDataDictionary[currentEventLevel].iconText = currentEventIcon;
        eventsDataDictionary[currentEventLevel].items.push({
          ...currentEventData,
          x: currentEventX,
        });
        Object.keys(eventsDataDictionary).forEach((currentKey) => {
          if (currentKey.toLowerCase() === 'warning') {
            eventsDataDictionary[currentKey].isSelected = false;
          }
        });
      });
      return {
        ...state,
        eventsDataDictionary,
        isDataEventsChartDataError: false,
        errorMessage: '',
      };
    }

    case GET_CHART_EVENTS_REQUEST_ERROR:
      return {
        ...state,
        eventsChartData: {},
        isDataEventsChartDataError: true,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
}

export default dataChartEventsReducer;
