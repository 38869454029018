import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import {
  extractAccuracyActivitiesFromActivitiesArray,
  extractCriticalAlarmsActivitiesFromChartData,
  extractDispenseWeightsFromActivitiesArray,
  extractDosingActivitiesFromActivitiesArray,
  extractFeedGrabberGrabsFromActivitiesArray,
  extractVectorFeedSettings,
  getModifiedDosingMfrMembers,
} from '../components/Vector/util';
import type { Nullable } from '../common/types';
import { SettingsWithMembers } from '../components/Vector/types/vectorDataChartTypes';
import { RootState } from './rootReducer';
import {
  VectorDataState,
  VectorDataExtendedResponse,
  CriticalAlarms,
  MembersType,
} from './types/vectorDataTypes';

export type VectorDataChartInitialStateType = {
  vectorChartsData: VectorDataState;
  vectorChartsDataSettings: Nullable<
    SettingsWithMembers<{
      id: string;
    }>
  >;
  isVectorDataChartsError: boolean;
  errorMessage: string;
  currentDomain: Date[];
};

export const initialState: VectorDataChartInitialStateType = {
  errorMessage: '',
  isVectorDataChartsError: false,
  vectorChartsDataSettings: {},
  vectorChartsData: {} as any,
  currentDomain: [],
};

export const vectorDataChartSlice = createSlice({
  name: 'vectorDataChartSlice',
  initialState,
  reducers: {
    setVectorDataChart: (
      state,
      action: PayloadAction<VectorDataExtendedResponse>,
    ) => {
      const { startDate, endDate, chartData } = action.payload;

      const modifiedDosingMfr1Members = getModifiedDosingMfrMembers(
        chartData.settings.otherSettings,
        'Mfr1',
      );
      const modifiedDosingMfr2Members = getModifiedDosingMfrMembers(
        chartData.settings.otherSettings,
        'Mfr2',
      );
      chartData.settings.otherSettings['DosingMfr1'].members =
        modifiedDosingMfr1Members;
      chartData.settings.otherSettings['DosingMfr2'].members =
        modifiedDosingMfr2Members;

      const mfr1ActivitiesCriticalAlarms =
        extractCriticalAlarmsActivitiesFromChartData(chartData.mfr1Activities);
      const mfr2ActivitiesCriticalAlarms =
        extractCriticalAlarmsActivitiesFromChartData(chartData.mfr2Activities);
      const pdbActivitiesCriticalAlarms =
        extractCriticalAlarmsActivitiesFromChartData(chartData.pdbActivities);
      const fgActivitiesCriticalAlarms =
        extractCriticalAlarmsActivitiesFromChartData(chartData.fgActivities);

      const criticalAlarmsDictionary: CriticalAlarms = {
        MFR1: {
          alarms: !isEmpty(mfr1ActivitiesCriticalAlarms)
            ? mfr1ActivitiesCriticalAlarms[0]
            : [],
          icon: 'lely-icon-mfr',
        },
        FeedGrabber: {
          alarms: !isEmpty(fgActivitiesCriticalAlarms)
            ? fgActivitiesCriticalAlarms[0]
            : [],
          icon: 'lely-icon-grabber',
        },
        PDB: {
          alarms: !isEmpty(pdbActivitiesCriticalAlarms)
            ? pdbActivitiesCriticalAlarms[0]
            : [],
          icon: 'lely-icon-pdb',
        },
        MFR2: {
          alarms: !isEmpty(mfr2ActivitiesCriticalAlarms)
            ? mfr2ActivitiesCriticalAlarms[0]
            : [],
          icon: 'lely-icon-mfr',
        },
      };

      const mfr1DosingActivitiesDictionary =
        extractDosingActivitiesFromActivitiesArray(
          chartData.mfr1Activities,
          'Mfr1',
        );
      const mfr2DosingActivitiesDictionary =
        extractDosingActivitiesFromActivitiesArray(
          chartData.mfr2Activities,
          'Mfr2',
        );

      const { accuracyPercentEntriesDictionary, accuracyKgEntriesDictionary } =
        extractAccuracyActivitiesFromActivitiesArray(
          chartData.mfr1Activities.concat(chartData.mfr2Activities),
        );

      const feedGrabberGrabsDictionary =
        extractFeedGrabberGrabsFromActivitiesArray(chartData.fgActivities);

      const { dispenseWeightDictionary, dispenseWeightMembersWithData } =
        extractDispenseWeightsFromActivitiesArray(
          chartData,
          chartData.settings.otherSettings['Dispense weight'].members,
        );

      const vectorNewFeedHeightSettingsData = extractVectorFeedSettings(
        chartData.settings.otherSettings,
      );

      state.vectorChartsData.chartData = {
        ...chartData,
        criticalAlarms: criticalAlarmsDictionary,
        accuracyPercents: accuracyPercentEntriesDictionary,
        accuracyKilograms: accuracyKgEntriesDictionary,
        dosingSpeedsMfr1: mfr1DosingActivitiesDictionary,
        dosingSpeedsMfr2: mfr2DosingActivitiesDictionary,
        feedGrabberGrabs: feedGrabberGrabsDictionary,
        dispenseWeights: dispenseWeightDictionary,
      };

      const feedGrabberMembersWithData = Object.keys(
        feedGrabberGrabsDictionary,
      ).reduce((prev, next) => {
        prev[next] =
          state.vectorChartsData.chartData.settings.otherSettings[
            'Feed grabber grabs'
          ].members[next];

        return prev;
      }, {} as Record<keyof typeof feedGrabberGrabsDictionary, MembersType[keyof MembersType]>);

      state.vectorChartsData.chartData.settings.otherSettings[
        'Feed grabber grabs'
      ].members = feedGrabberMembersWithData;

      state.vectorChartsData.chartData.settings.otherSettings[
        'Dispense weight'
      ].members = dispenseWeightMembersWithData;

      state.vectorChartsDataSettings = vectorNewFeedHeightSettingsData;
      state.isVectorDataChartsError = false;

      // TODO: WTF? why do we need that, possible deletion
      state.currentDomain = [new Date(startDate), new Date(endDate)];
    },
    setVectorDataChartError: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
      state.isVectorDataChartsError = true;
    },
    resetVectorDataChart: () => initialState,
  },
});

export const {
  resetVectorDataChart,
  setVectorDataChart,
  setVectorDataChartError,
} = vectorDataChartSlice.actions;

export const vectorDataChartSelectors = {
  selectVectorChartsData: (state: RootState) =>
    state.vectorDataCharts.vectorChartsData,
  selectChartData: (state: RootState) =>
    state.vectorDataCharts.vectorChartsData.chartData,
};

// robot : 'Mfr1'| 'Mfr2' |'Pdb' | 'Fg'
export const selectActivitiesForRobot = (
  state: RootState,
  robot: 'Mfr1' | 'Mfr2' | 'Pdb' | 'Fg',
) => {
  const key = `${robot.toLowerCase()}Activities`;
  if (
    state.vectorDataCharts.vectorChartsData &&
    state.vectorDataCharts.vectorChartsData.chartData &&
    state.vectorDataCharts.vectorChartsData.chartData[key]
  ) {
    return state.vectorDataCharts.vectorChartsData.chartData[key];
  }
  return undefined;
};

export const fetchVectorChartData = createAction<{
  entityId: string;
  startDate: string;
  endDate: string;
}>('vectorDataChartSlice/fetchVectorChartData');

export default vectorDataChartSlice.reducer;
