import isEmpty from 'lodash/isEmpty';

function getUrlAppendixStringByKeys(urlAppendix, keysArray, doFirstItemCheck) {
  let constructedUrlAppendix = '';
  if (!isEmpty(keysArray)) {
    constructedUrlAppendix = keysArray
      .map((currentKey, currentKeyIndex) => {
        let appendix = '';
        if (doFirstItemCheck && currentKeyIndex === 0) {
          appendix = urlAppendix + currentKey;
        } else {
          appendix = `&${urlAppendix}${currentKey}`;
        }
        return appendix;
      })
      .join('');
  }
  return constructedUrlAppendix;
}

export default getUrlAppendixStringByKeys;
