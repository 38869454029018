import React from 'react';
import SpacingWrapper from '../../common/components/SpacingWrapper/SpacingWrapper';

export default function NotFoundPage() {
  return (
    <SpacingWrapper>
      <h1>404 PAGE NOT FOUND</h1>
    </SpacingWrapper>
  );
}
