import { START_LOADING, END_LOADING } from './types';
/** Create action which will notify the loadManager reducer
 * for the beginning of new load operation
 * (which may be call to an API or some other asynchronous operation)
 * @param {Number} loadId - Number generated by the methods.js/loadId() method
 * @return {Object} Return action for starting loading
 */
export function setStartLoading(loadId: number) {
  return {
    type: START_LOADING,
    payload: {
      loadId,
    },
  };
}

/** Create action which will notify the loadManager reducer
 * for the end of existing load operation
 * @param {Number} loadId - Number generated by the methods.js/loadId() method
 * @return {Object} Return action for starting loading
 */
export function setEndLoading(loadId: number) {
  return {
    type: END_LOADING,
    payload: {
      loadId,
    },
  };
}

// actions for new loadManagerSlice in the future
// export { setStartLoading, setEndLoading };
