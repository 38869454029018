import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';

export type HoveredItemType = {
  id: string;
  index: number;
  name: string;
  tooltipXCoordinate: number;
  tooltipYCoordinate: number;
  value: number;
  x: Date;
  color?: string;
};

export type HoveredEventType = {
  level: string;
  description: string;
  tooltipXCoordinate: number;
  tooltipYCoordinate: number;
  value: number;
  x: Date;
  color: string;
};

export type TooltipInitialState = {
  hoveredItem: HoveredItemType | null;
  hoveredEventData: HoveredEventType | null;
};

export const initialState: TooltipInitialState = {
  hoveredItem: null,
  hoveredEventData: null,
};

export const tooltipSlice = createSlice({
  name: 'tooltip',
  initialState,
  reducers: {
    setHoveredItem: (
      state,
      { payload }: PayloadAction<HoveredItemType | null>,
    ) => {
      state.hoveredItem = payload;
    },
    setHoveredEventData: (
      state,
      { payload }: PayloadAction<HoveredEventType | null>,
    ) => {
      state.hoveredEventData = payload;
    },
  },
});

export const { setHoveredItem, setHoveredEventData } = tooltipSlice.actions;

export const tooltipSelectors = {
  selectHoveredItem: (state: RootState) => state.tooltip.hoveredItem,
  selectHoveredEventData: (state: RootState) => state.tooltip.hoveredEventData,
};

export default tooltipSlice.reducer;
