import {
  UPDATE_FARM_NAME_REQUEST_SUCCESS,
  UPDATE_FARM_NAME_REQUEST_ERROR,
} from '../components/EditFields/types';

/** Store states
 * @constant {Object} */
export const initialState = {
  updatedName: '',
  errorMessage: '',
};

/** Updated Entity Name Reducer.
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function updateEntityNameReducer(state = initialState, action = {}) {
  switch (action.type) {
    /** Action Creator - Set updated entity name to store
     * if API request passed successfully */
    case UPDATE_FARM_NAME_REQUEST_SUCCESS:
      return {
        ...state,
        updatedName: action.payload.updatedName,
        errorMessage: '',
      };

    /** Action Creator - Show error message if
     * request to API failed */
    case UPDATE_FARM_NAME_REQUEST_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        updatedName: '',
      };
    default:
      return state;
  }
}

export default updateEntityNameReducer;
