import {
  GET_DOMAINS_FOR_ENTITY_REQUEST,
  GET_DOMAINS_FOR_ENTITY_REQUEST_SUCCESS,
  GET_DOMAINS_FOR_ENTITY_REQUEST_ERROR,
} from './types';

/** Create action which redirect to given page.
 * @param {Object} selectedEntityId - the id of the entity for which to like to get the list of available domains
 * @return {Object} Return type and selectedEntity
 */
export function getDomainsForEntity(selectedEntityId) {
  return {
    type: GET_DOMAINS_FOR_ENTITY_REQUEST,
    payload: {
      entityId: selectedEntityId,
    },
  };
}

/** Create action which to allow to put domains data in the redux store
 * @param {Object} domainsData - array of domains data
 * @return {Object} Return type and domains array data
 */
export function getDomainsForEntitySuccess(domainsData) {
  return {
    type: GET_DOMAINS_FOR_ENTITY_REQUEST_SUCCESS,
    payload: domainsData,
  };
}

/** Create action which get errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function getDomainsForEntityError(errorMessage) {
  return {
    type: GET_DOMAINS_FOR_ENTITY_REQUEST_ERROR,
    payload: errorMessage,
  };
}
