import {
  GET_ALARMS_DROPDOWN_LEVELS_REQUEST_SUCCESS,
  GET_ALARMS_DROPDOWN_LEVELS_REQUEST_ERROR,
} from '../common/redux/alarmDropdownLevel/types';

/** Store states
 * @constant {Object} */
export const initialState = {
  alarmLevels: [],
  errorMessage: '',
};

/** Alarm dropdown levels Reducer.
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function alarmDropdownLevelsReducer(state = initialState, action = {}) {
  switch (action.type) {
    /** Action Creator - Set alarm levels to store
     * if API request passed successfully */
    case GET_ALARMS_DROPDOWN_LEVELS_REQUEST_SUCCESS:
      return {
        ...state,
        alarmLevels: action.payload.alarmDropdownLevelData,
        errorMessage: '',
      };

    /** Action Creator - Show error message if
     * request to API failed */
    case GET_ALARMS_DROPDOWN_LEVELS_REQUEST_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        alarmLevels: [],
      };
    default:
      return state;
  }
}

export default alarmDropdownLevelsReducer;
