export const MOCK_GET_ENTITIES_RESPONSE = {
  data: [
    {
      entityId: '15634333-e3f0-e911-aac8-74da38c2319f',
      name: 'astronaut-102',
      isVector: false,
      stateColor: '#73BD07',
      kpis: [
        {
          kpiName: 'Milking',
          current: '158',
          weeklyAverage: '153.1',
          color: '#73BD07',
          icon: 'lely-icon-milk',
          unit: '',
          weeklyData: [
            {
              date: '2021-03-22T00:00:00',
              value: '161',
              color: '#73BD07',
            },
            {
              date: '2021-03-23T00:00:00',
              value: '157',
              color: '#73BD07',
            },
            {
              date: '2021-03-24T00:00:00',
              value: '142',
              color: '#ff1744',
            },
            {
              date: '2021-03-25T00:00:00',
              value: '144',
              color: '#ff1744',
            },
            {
              date: '2021-03-26T00:00:00',
              value: '151',
              color: '#ff1744',
            },
            {
              date: '2021-03-27T00:00:00',
              value: '159',
              color: '#73BD07',
            },
            {
              date: '2021-03-28T00:00:00',
              value: '158',
              color: '#73BD07',
            },
          ],
        },
        {
          kpiName: 'Failed Milking',
          current: '1',
          weeklyAverage: '1',
          color: '#73BD07',
          icon: 'lely-icon-dry-off',
          unit: '',
          weeklyData: [
            {
              date: '2021-03-22T00:00:00',
              value: '1',
              color: '#ff1744',
            },
            {
              date: '2021-03-23T00:00:00',
              value: '1',
              color: '#ff1744',
            },
            {
              date: '2021-03-24T00:00:00',
              value: '0',
              color: '#73BD07',
            },
            {
              date: '2021-03-25T00:00:00',
              value: '1',
              color: '#ff1744',
            },
            {
              date: '2021-03-26T00:00:00',
              value: '1',
              color: '#ff1744',
            },
            {
              date: '2021-03-27T00:00:00',
              value: '2',
              color: '#ff1744',
            },
            {
              date: '2021-03-28T00:00:00',
              value: '1',
              color: '#ff1744',
            },
          ],
        },
        {
          kpiName: 'Connection Time',
          current: '24.1',
          weeklyAverage: '24.2',
          color: '#73BD07',
          icon: 'lely-icon-clock',
          unit: 's',
          weeklyData: [
            {
              date: '2021-03-22T00:00:00',
              value: '25.6',
              color: '#ff1744',
            },
            {
              date: '2021-03-23T00:00:00',
              value: '24.9',
              color: '#ff1744',
            },
            {
              date: '2021-03-24T00:00:00',
              value: '23',
              color: '#73BD07',
            },
            {
              date: '2021-03-25T00:00:00',
              value: '24.3',
              color: '#ff1744',
            },
            {
              date: '2021-03-26T00:00:00',
              value: '24.3',
              color: '#ff1744',
            },
            {
              date: '2021-03-27T00:00:00',
              value: '23.2',
              color: '#73BD07',
            },
            {
              date: '2021-03-28T00:00:00',
              value: '24.1',
              color: '#73BD07',
            },
          ],
        },
        {
          kpiName: 'Box Time',
          current: '420.9',
          weeklyAverage: '416.7',
          color: '#73BD07',
          icon: 'lely-icon-clock',
          unit: 's',
          weeklyData: [
            {
              date: '2021-03-22T00:00:00',
              value: '400.8',
              color: '#73BD07',
            },
            {
              date: '2021-03-23T00:00:00',
              value: '412',
              color: '#73BD07',
            },
            {
              date: '2021-03-24T00:00:00',
              value: '406.9',
              color: '#73BD07',
            },
            {
              date: '2021-03-25T00:00:00',
              value: '452.3',
              color: '#ff1744',
            },
            {
              date: '2021-03-26T00:00:00',
              value: '415.6',
              color: '#73BD07',
            },
            {
              date: '2021-03-27T00:00:00',
              value: '408.2',
              color: '#73BD07',
            },
            {
              date: '2021-03-28T00:00:00',
              value: '420.9',
              color: '#ff1744',
            },
          ],
        },
      ],
      infoGraphic: {
        handedness: 'R',
        options: [
          {
            name: 'TCS',
            color: '#CFCBC3',
            isInstalled: false,
          },
          {
            name: 'FPS',
            color: '#850C18',
            isInstalled: true,
          },
          {
            name: 'Pura',
            color: '#850C18',
            isInstalled: true,
          },
          {
            name: 'Scale',
            color: '#CFCBC3',
            isInstalled: false,
          },
          {
            name: 'MQCC',
            color: '#CFCBC3',
            isInstalled: false,
          },
          {
            name: 'M4U',
            color: '#850C18',
            isInstalled: false,
          },
          {
            name: 'Jersey',
            color: '#CFCBC3',
            isInstalled: false,
          },
        ],
        centralUnitAddress: '211',
        connectedentityLocalLdn: '101',
        entityLocalLdn: '102',
        threeWayValve: {
          icon: 'lely-icon-valve',
          options: [
            {
              name: '1',
              color: '#CFCBC3',
              isInstalled: false,
            },
            {
              name: '2',
              color: '#850C18',
              isInstalled: true,
            },
            {
              name: '3',
              color: '#CFCBC3',
              isInstalled: false,
            },
          ],
        },
        version: '5.2.?',
        totalAlarms: {
          description: 'Alarms',
          statisticDetails: [
            {
              name: 'Last week',
              value: 0,
            },
            {
              name: 'Last 24h',
              value: 0,
            },
          ],
        },
      },
    },
  ],
};
export const MOCK_FARM_DETAILS_RESPONSE = {
  farmId: 'df319c8e-35f7-e911-aac8-74da38c2319f',
  farmEntities: [
    { entityId: '566aedb6-35f7-e911-aac8-74da38c2319f', name: 'astronaut-120' },
    { entityId: '576aedb6-35f7-e911-aac8-74da38c2319f', name: 'astronaut-125' },
    { entityId: '531802c2-01fb-e911-aac9-74da38c2319f', name: 'astronaut-121' },
  ],
  farmName: 'Farm 1',
};
export const MOCK_GET_FARM_ID = '51e2f58c-4c98-eb11-b566-000d3a221d55';
export const MOCK_GET_FARM_DETAILS_RESPONSE = {
  farmId: 'df319c8e-35f7-e911-aac8-74da38c2319f',
  entitiesData: [
    { entityId: '566aedb6-35f7-e911-aac8-74da38c2319f', name: 'astronaut-120' },
    { entityId: '576aedb6-35f7-e911-aac8-74da38c2319f', name: 'astronaut-125' },
    { entityId: '531802c2-01fb-e911-aac9-74da38c2319f', name: 'astronaut-121' },
  ],
  farmName: 'Workshop',
};
export const MOCK_GET_FARM_DETAILS_REQUEST_ERROR = 'error in farm details';

export const MOCK_UPDATED_ENTITY_NAME_ERROR =
  'Request failed with status code 400';
export const MOCK_GET_UPDATED_ENTITY_NAME_ERROR = 'Entity name already exist';
export const MOCK_GET_ENTITIES_RENAME_RESPONSE = {
  data: 'astronaut-11',
};
export const MOCK_GET_CURRENT_SELECTED_ENTITY_NAME = {
  updatedName: 'astronaut-11',
};

export const MOCK_SET_CURRENT_SELECTED_ENTITY = {
  farmName: 'Farm 1',
  entityId: '14634333-e3f0-e911-aac8-74da38c2319f',
  name: 'A5 Entity 2',
};

export const api = {
  async getFarmEntity() {
    return new Promise((resolve) => {
      resolve(MOCK_GET_ENTITIES_RESPONSE);
    });
  },
};

export const apiUpdatedName = {
  async updateEntityName() {
    return new Promise((resolve) => {
      resolve(MOCK_GET_ENTITIES_RENAME_RESPONSE);
    });
  },
};
