import { ApisWithFarmName } from '../../api/lelyBackend/config';
import { LOCAL_STORAGE_KEYS } from '../../common/constants';
import { getSearchParams, updateSearchParams } from '../../common/utils';

export const getFarmNameParams = (request) => {
  let farmName = getSearchParams('farmName');

  const shouldAddFarmName = ApisWithFarmName.find((item) => {
    return request.url?.match(item);
  });

  if (!farmName) {
    farmName = localStorage.getObject(LOCAL_STORAGE_KEYS.FARM_NAME);
    if (farmName && shouldAddFarmName)
      updateSearchParams('farmName', farmName, true);
  } else {
    localStorage.setObject(LOCAL_STORAGE_KEYS.FARM_NAME, farmName);
  }

  return { shouldAddFarmName, farmName };
};

export default getFarmNameParams;
