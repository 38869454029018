import {
  GET_CANBUS_TROUBLESHOOTS_REQUEST,
  GET_CANBUS_TROUBLESHOOTS_SUCCESS,
  CURRENT_CANBUS_TROUBLESHOOTS_SCREEN,
  CLEAR_CANBUS_TROUBLESHOOTS_DATA,
  PREVIOUS_CANBUS_TROUBLESHOOT_SCREEN,
  GET_CANBUS_TROUBLESHOOTS_ERROR,
  GET_CANBUS_TROUBLESHOOTS_VERSION,
} from './types';

/** Create action which will get all information for canbus troubleshoot.
 * @param {String} accessToken - the access token
 * @param {String} domainName - Name of the selected domain (canbus)
 * @param {String} tutorialFileName - Name of the selected tutorial
 * @return {Object} Return domain name and tutorialFileName for troubleshoot
 */
export function getCanbusTroubleshoot(
  accessToken,
  domainName,
  tutorialFileName,
) {
  return {
    type: GET_CANBUS_TROUBLESHOOTS_REQUEST,
    payload: {
      accessToken,
      domainName,
      tutorialFileName,
    },
  };
}

/** Create action which to allow to put canbus troubleshoot details in the redux store
 * @param {Object} troubleshootDetails - Canbus troubleshoot data
 * @return {Object} Return troubleshootDetails of troubleshoot
 */
export function getCanbusTroubleshootSuccess(troubleshootDetails) {
  return {
    type: GET_CANBUS_TROUBLESHOOTS_SUCCESS,
    payload: {
      ...troubleshootDetails,
    },
  };
}

/** Create action which to allow to put canbus troubleshoot details in the redux store
 * @param {Object} troubleshootversionDetails - Canbus troubleshoot data
 * @return {Object} Return troubleshootDetails of troubleshoot
 */
export function getCanbusTroubleshootVersion(troubleshootVersionDetails) {
  return {
    type: GET_CANBUS_TROUBLESHOOTS_VERSION,
    payload: troubleshootVersionDetails,
  };
}

/** Create action which set current troubleshoot screen details in the redux store
 * @param {Number} idOfTheNextScreen - Id of the next troubleshoot screen
 * @return {Object} Return name and order of dropdown levels
 */
export function currentCanbusTroubleshootScreen(idOfTheNextScreen) {
  return {
    type: CURRENT_CANBUS_TROUBLESHOOTS_SCREEN,
    payload: { idOfTheNextScreen },
  };
}

/** Create action which will clear troubleshoot data in redux store
 * @return {Object} Return
 */
export function clearCanbusTroubleshootData() {
  return {
    type: CLEAR_CANBUS_TROUBLESHOOTS_DATA,
  };
}

/** Create action which will clear troubleshoot data in redux store
 * @param {Number} idOfCurrentScreen - Id of the current troubleshoot screen
 * @return {Object} Return
 */
export function canbusTroubleshootPreviousScreen(currentScreenId) {
  return {
    type: PREVIOUS_CANBUS_TROUBLESHOOT_SCREEN,
    payload: { currentScreenId },
  };
}

/** Create action which get errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function getCanbusTroubleshootError(errorMessage) {
  return {
    type: GET_CANBUS_TROUBLESHOOTS_ERROR,
    payload: errorMessage,
  };
}
