import React from 'react';

import { styled } from '@mui/system';
import { useSelector } from 'react-redux';
import './Preloader.scss';
import waitingCow from '../../resources/images/waitingCow.gif';

const StyledDiv = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'fixed',
  opacity: 0.4,
  backgroundColor: 'white',
  zIndex: 99999,
  '& > * + *': {
    marginTop: theme.spacing(2),
  },
}));

/** Show Preloader on page */
function Preloader({ activeByDefault = false }) {
  const loadManagerInfo = useSelector((state) => state.loadManager);
  const isInitialLoad = loadManagerInfo.initialPageLoad;
  const isPreloaderActive = loadManagerInfo.loadingOperations.length > 0;

  let preloader = null;
  if (activeByDefault || (isPreloaderActive && isInitialLoad)) {
    preloader = (
      <StyledDiv>
        <img src={waitingCow} className="cow-loading-image" alt="loading..." />
        {/* <LinearProgress color="secondary" /> */}
      </StyledDiv>
    );
  }
  return preloader;
}

export default Preloader;
