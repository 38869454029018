import isEmpty from 'lodash/isEmpty';
import {
  DATA_TOP_CHART_ID,
  DATA_MIDDLE_CHART_ID,
  MAX_UNITS_SELECTED_LIMIT,
  MIN_SELECTED_STATUS_CHART_LIMIT,
  STATUS_ID_APPENDIX,
  CHART_TYPE_LINE,
  CHART_TYPE_STATUS,
  CHART_TYPE_SPLINE,
} from '../constants';

export const DATA_CATEGORIES = [
  {
    name: 'Electrical',
    values: [
      {
        name: 'Vacuum level',
        dataLocationInStore: 'Sensors Vacuum level',
        min: 4.5,
        max: 12.3,
        type: 'line',
      },
      {
        name: 'Vacuum Desired Level',
        dataLocationInStore: 'Vacuum desired level',
        min: 4.5,
        max: 12.3,
        type: 'line',
      },
      {
        name: 'Central Unit Desired Level',
        dataLocationInStore: 'Central unit desired level',
        min: 4.5,
        max: 12.3,
        type: 'line',
      },
    ],
  },
  {
    name: 'Milk',
    values: [
      {
        name: 'Milk Yield',
        dataLocationInStore: 'Milk Yield',
        min: 4.5,
        max: 12.3,
        type: 'spline',
      },
      {
        name: 'System State',
        dataLocationInStore: 'Coordinator',
        min: 4.5,
        max: 12.3,
        type: 'status',
      },
      {
        name: 'Cow Handling State',
        dataLocationInStore: 'Cow Handling',
        min: 4.5,
        max: 12.3,
        type: 'status',
      },
    ],
  },
];

export const getPlaceholderObjectByPropertySelection = (
  propertySelection,
  dataChartConfigInLocalStorage,
) => {
  const placeholderObject = {
    areAllChartsLoaded: false,
    charts: {},
  };
  propertySelection.forEach((currentElement) => {
    if (currentElement.dataKeys.length === 0) return;

    placeholderObject.charts[currentElement.chartId] = {
      lineCharts: [],
      statusCharts: [],
    };
    currentElement.dataKeys.forEach((currentChartKey) => {
      const currentChartType = getChartTypeByChartName(
        currentChartKey,
        dataChartConfigInLocalStorage,
      );
      if (
        (currentChartType === CHART_TYPE_LINE ||
          currentChartType === CHART_TYPE_SPLINE) &&
        placeholderObject.charts[currentElement.chartId].lineCharts.length === 0
      ) {
        placeholderObject.charts[currentElement.chartId].lineCharts.push({
          chartId: `chart${currentElement.chartId}`,
          isLoaded: false,
        });
      } else if (currentChartType === CHART_TYPE_STATUS) {
        placeholderObject.charts[currentElement.chartId].statusCharts.push({
          chartId: getStatusChartIdByChartName(
            currentChartKey.toLowerCase(),
            true,
          ),
          isLoaded: false,
        });
      }
    });
  });
  return placeholderObject;
};

export const getChartInfoByChartName = (
  chartName,
  dataChartConfigInLocalStorage,
  chartNameProperty = 'dataLocationInStore',
) => {
  const allChartsData = !isEmpty(dataChartConfigInLocalStorage)
    ? dataChartConfigInLocalStorage
    : DATA_CATEGORIES;
  let chartInfo = {};
  const chartNameWithoutSpaces = chartName
    .toLowerCase()
    .replace(/\s/g, '')
    .replace(/\((?<=\().*?(?=\))\)/, '');
  allChartsData.forEach((currentCategory) => {
    const foundValue = currentCategory.values.find((currentValue) => {
      const currentChartNameWithoutSpaces = currentValue[chartNameProperty]
        .toLowerCase()
        .replace(/\s/g, '')
        .replace(/\((?<=\().*?(?=\))\)/, '');
      return currentChartNameWithoutSpaces === chartNameWithoutSpaces;
    });
    if (foundValue) {
      chartInfo = foundValue;
    }
  });
  return chartInfo;
};

export const getChartLocationInStoreByChartName = (
  chartName,
  dataChartConfigInLocalStorage,
) => {
  const chartInfo = getChartInfoByChartName(
    chartName,
    dataChartConfigInLocalStorage,
    'name',
  );
  return chartInfo && chartInfo.dataLocationInStore;
};

export const areSelectedMetricsOfTwoUnitsMax = (
  selectedProperties,
  allChartsData,
) => {
  const selectedUnitsDictionary = getUnitsInfoBySelectedProperties(
    selectedProperties,
    allChartsData,
  );
  const topChartUnits = selectedUnitsDictionary[DATA_TOP_CHART_ID];
  const middleChartUnits = selectedUnitsDictionary[DATA_MIDDLE_CHART_ID];

  return (
    topChartUnits.length <= MAX_UNITS_SELECTED_LIMIT &&
    middleChartUnits.length <= MAX_UNITS_SELECTED_LIMIT
  );
};

export const areSelectedMetricsOfOneStatusChartMin = (
  selectedProperties,
  allChartsData,
) => {
  const selectedNumberOfStatusChart = getStatusChartNumberBySelectedProperties(
    selectedProperties,
    allChartsData,
  );
  return selectedNumberOfStatusChart >= MIN_SELECTED_STATUS_CHART_LIMIT;
};

export const getStatusChartNumberBySelectedProperties = (
  selectedProperties,
  allChartsData,
) => {
  const arrayToPushInto = [];
  Object.keys(selectedProperties).forEach((currentStatusChart) => {
    const currentChartInfo = getChartInfoByChartName(
      currentStatusChart,
      allChartsData,
    );
    if (currentChartInfo.type === STATUS_ID_APPENDIX) {
      arrayToPushInto.push(currentChartInfo.type);
    }
  });
  return arrayToPushInto.length;
};

export const getUnitsInfoBySelectedProperties = (
  selectedProperties,
  allChartsData,
) => {
  const topChartUnits = [];
  const middleChartUnits = [];
  Object.keys(selectedProperties).forEach((currentMetricName) => {
    const currentMetricUnit = getChartUnitByChartName(
      currentMetricName,
      allChartsData,
    );
    if (currentMetricUnit) {
      const arrayToPushInto =
        selectedProperties[currentMetricName] === DATA_TOP_CHART_ID
          ? topChartUnits
          : middleChartUnits;
      arrayToPushInto.push(currentMetricUnit);
    }
  });
  const unitsInfoObject = {};
  unitsInfoObject[DATA_TOP_CHART_ID] = [...new Set(topChartUnits)];
  unitsInfoObject[DATA_MIDDLE_CHART_ID] = [...new Set(middleChartUnits)];
  return unitsInfoObject;
};

export const getLastLineChartNameFromArray = (
  array,
  dataChartConfigInLocalStorage,
) => {
  let lastLineChartName = '';
  array.forEach((currentDataKey) => {
    if (isChartLineChart(currentDataKey, dataChartConfigInLocalStorage)) {
      lastLineChartName = currentDataKey;
    }
  });
  return lastLineChartName;
};

export const isChartLineChart = (
  currentChartName,
  dataChartConfigInLocalStorage,
) => {
  const currentChartType = getChartTypeByChartName(
    currentChartName,
    dataChartConfigInLocalStorage,
  );
  return currentChartType === 'line' || currentChartType === 'spline';
};

export const getChartTypeByChartName = (
  chartName,
  dataChartConfigInLocalStorage,
  chartNameProperty,
) => {
  const chartInfo = getChartInfoByChartName(
    chartName,
    dataChartConfigInLocalStorage,
    chartNameProperty,
  );
  return chartInfo.type || CHART_TYPE_LINE; // charts are assumed to be line charts by default
};

export const getChartUnitByChartName = (
  chartName,
  dataChartConfigInLocalStorage,
) => {
  const chartInfo = getChartInfoByChartName(
    chartName,
    dataChartConfigInLocalStorage,
  );
  return chartInfo.unit; // charts are assumed to be line charts by default
};

export const getMinimumForSelectedNodes = (selectedNodesArray) => {
  let minimumSoFar = selectedNodesArray.length && selectedNodesArray[0];
  selectedNodesArray.forEach((currentNode) => {
    if (currentNode.value < minimumSoFar.value) {
      minimumSoFar = currentNode;
    }
  });
  return minimumSoFar;
};

export const getMaximumForSelectedNodes = (selectedNodesArray) => {
  let maximumSoFar = selectedNodesArray.length && selectedNodesArray[0];
  selectedNodesArray.forEach((currentNode) => {
    if (currentNode.value > maximumSoFar.value) {
      maximumSoFar = currentNode;
    }
  });
  return maximumSoFar;
};

export const sumOfArrayElements = (array, valueProp) => {
  return array.reduce((sumSoFar, currentValue) => {
    return sumSoFar + currentValue[valueProp];
  }, 0);
};

export const getAverageForSelectedNodes = (selectedNodesArray) => {
  return (
    sumOfArrayElements(selectedNodesArray, 'value') / selectedNodesArray.length
  );
};

export const getStatusChartIdByChartName = (
  chartName,
  doExecuteLinkedChartCheck,
) => {
  const idWithoutSpaces = chartName.replace(/\s/g, '');

  if (doExecuteLinkedChartCheck && isChartLinked(idWithoutSpaces)) {
    return (
      idWithoutSpaces.split('(')[0] + STATUS_ID_APPENDIX + LINKED_CHART_APPENDIX
    );
  }
  return idWithoutSpaces + STATUS_ID_APPENDIX;
};

export const isChartLinked = (chartName) => {
  return chartName.indexOf('(') !== -1;
};

export const transformColorsToBillboardObject = (
  chartData,
  feedIdToColorDictionary,
) => {
  const result = {};

  if (!feedIdToColorDictionary) {
    return result;
  }

  for (let i = 0; i < chartData.length; i++) {
    result[[chartData[i][0]]] = feedIdToColorDictionary[chartData[i][0]];
  }
  return result;
};

export const LINKED_CHART_APPENDIX = '-linked';
