/**
 *Get current environment
 */
const environment = () => {
  const urlHostname = window.location.hostname;
  switch (urlHostname) {
    case 'ltda-stage.lely.cloud':
      return 'Staging';
    case 'localhost':
      return 'Development';
    case 'ltda.lely.cloud':
      return 'Production';
    default:
      return 'Development';
  }
};

export default environment;
