import { AxiosError } from 'axios';
import { put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import redirect from '../../../components/CustomRedirect/actions';
import { Nullable } from '../../types';
import { resetApplicationStore } from '../purgeStore';
import { LOCAL_STORAGE_KEYS } from '../../constants';

export default function* errorCodeSaga(
  action: PayloadAction<Nullable<AxiosError>>,
) {
  const error = action.payload;

  if (!error || error.response === undefined) {
    return;
  }

  switch (error.response.status) {
    case 401:
      localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
      yield put(resetApplicationStore());
      yield put(
        redirect('/login', {
          from: {
            pathname: window.location.pathname,
            search: window.location.search,
          },
        }),
      );
      break;
    case 403:
      yield put(redirect('/forbidden'));
      break;
    default:
      // TODO : Add Geri's popup
      break;
  }
}
