import axiosInstance from '../axiosInstance';
import {
  GET_UPDATE_HISTORY_SERVICE_URL,
  FILTER_TIMELINE_START_DATE,
  TO_DATE_URL_APPENDIX,
  FILTER_SYSTEM_ID,
} from './config';

export const api = {
  async getUpdateHistoryForFarm(farmId, startDate, endDate, systemId) {
    let filteredSystemId = '';
    if (systemId) {
      filteredSystemId = systemId;
    }
    const getUpdateHistoryForFarmServiceUrl =
      GET_UPDATE_HISTORY_SERVICE_URL +
      farmId +
      FILTER_TIMELINE_START_DATE +
      startDate +
      TO_DATE_URL_APPENDIX +
      endDate +
      FILTER_SYSTEM_ID +
      filteredSystemId;
    return axiosInstance.get(getUpdateHistoryForFarmServiceUrl);
  },
};

export default api;
