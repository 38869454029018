import React from 'react';
import SpacingWrapper from '../../common/components/SpacingWrapper/SpacingWrapper';

function ForbiddenPage() {
  return (
    <SpacingWrapper className="next-to-sidebar">
      <h1 className="fs--b-xl">
        Тhe information you are trying to access is not accessible for you
      </h1>
    </SpacingWrapper>
  );
}

export default ForbiddenPage;
