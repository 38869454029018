import axiosInstance from '../axiosInstance';
import {
  GET_ENTITY_BACKUPS,
  CREATE_ENTITY_BACKUPS,
  UPDATE_ENTITY_BACKUP_DESCRIPTION,
  RESTORE_ENTITY_BACKUPS,
  LISTEN_FOR_RESTORE_ENTITY_BACKUPS,
  GET_BACKUP_PROGRESS,
} from './config';

export const api = {
  async getEntityBackups(entityId: string) {
    const getEntityBackupsServiceUrl = GET_ENTITY_BACKUPS + entityId;

    return axiosInstance.get(getEntityBackupsServiceUrl);
  },

  async createEntityBackup(backupEntityId: string) {
    const createEntityBackupsServiceUrl = CREATE_ENTITY_BACKUPS;
    const body = {
      entityId: backupEntityId,
    };
    return axiosInstance.post(createEntityBackupsServiceUrl, body);
  },

  async getBackupProgress(backupEntityId: string) {
    const getBackupProgressServiceUrl = GET_BACKUP_PROGRESS;
    const body = {
      entityId: backupEntityId,
    };
    return axiosInstance.get(getBackupProgressServiceUrl, {
      params: body,
    });
  },

  async updateEntityBackupDescription(
    entityId: string,
    correlationId: string,
    filename: string,
    updatedDescription: string,
    tableItemType: string,
  ) {
    return axiosInstance.put(UPDATE_ENTITY_BACKUP_DESCRIPTION, {
      entityId,
      correlationId,
      filename,
      description: updatedDescription,
      tableItemType,
    });
  },

  async restoreEntityBackup(backupEntityId: string, backupName: string) {
    const restoreEntityBackupsServiceUrl = RESTORE_ENTITY_BACKUPS;
    const body = {
      entityId: backupEntityId,
      filename: backupName,
    };
    return axiosInstance.post(restoreEntityBackupsServiceUrl, body);
  },

  async listenForRestoreEntityBackup(entityId: string, correlationId: string) {
    const restoreEntityBackupsServiceUrl = `${
      LISTEN_FOR_RESTORE_ENTITY_BACKUPS + entityId
    }&correlationId=${correlationId}`;

    return axiosInstance.get(restoreEntityBackupsServiceUrl);
  },
};

export type BackUpsApiType = typeof api;
