import { put, take, call } from 'redux-saga/effects';
import { GET_FARM_DETAILS_REQUEST } from './types';
import { getFarmDetailsSuccess, getFarmDetailsError } from './actions';
import {
  setStartLoading,
  setEndLoading,
} from '../../common/redux/loadManager/actions';
import { getLoadId } from '../../common/redux/loadManager/methods';

let currentLoadId = 0;

export default function* farmDetailsSaga(api) {
  while (true) {
    const farmDetailsAction = yield take(GET_FARM_DETAILS_REQUEST);
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));
    const { farmId, farmName } = farmDetailsAction.payload;
    yield call(getFarmDetails, api, farmId, farmName);
  }
}

function* getFarmDetails(api, farmId, farmName) {
  try {
    const farmDetails = yield call(api.getFarmEntity, farmId);
    const { entities, farmTimeZone } = farmDetails.data;
    yield put(
      getFarmDetailsSuccess({
        farmId,
        farmEntities: entities,
        farmName,
        farmTimeZone,
      }),
    );
    yield put(setEndLoading(currentLoadId));
  } catch (error) {
    yield put(getFarmDetailsError(error));
    yield put(setEndLoading(currentLoadId));
  }
}
