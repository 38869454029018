import {
  GET_CANBUS_TROUBLESHOOTS_TUTORIAL_SUCCESS,
  GET_CANBUS_TROUBLESHOOTS_TUTORIAL_ERROR,
} from '../common/redux/canbusTroubleshooterTutorial/types';

/** Store states
 * @constant {Object} */
export const initialState = {
  initialTutorials: [],
  errorMessage: '',
};

/** Canbus troubleshoot tutorial Reducer.
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function canbusTroubleshootTutorialReducer(state = initialState, action = {}) {
  switch (action.type) {
    /** Action Creator - Set troubleshoot data to store
     * if API request passed successfully */
    case GET_CANBUS_TROUBLESHOOTS_TUTORIAL_SUCCESS:
      return {
        ...state,
        initialTutorials: action.payload.canbusTroubleshootTutorialResponse,
        errorMessage: '',
      };

    /** Action Creator - Show error message if
     * request to API failed */
    case GET_CANBUS_TROUBLESHOOTS_TUTORIAL_ERROR:
      return {
        ...state,
        initialTutorials: [],
        errorMessage: action.payload,
      };
    default:
      return state;
  }
}

export default canbusTroubleshootTutorialReducer;
