import { put, take, call } from 'redux-saga/effects';
import { LogoutApi } from '../../api/lelyBackend/logoutApi';
import { LOCAL_STORAGE_KEYS } from '../../common/constants';
import { setEndLoading } from '../../common/redux/loadManager/actions';
import { resetApplicationStore } from '../../common/redux/purgeStore';
import { logoutRequest } from '../../reducers/userSessionSlice';
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import redirect from '../CustomRedirect/actions';

const currentLoadId = 0;

/** If detect type: LOGOUT_REQUEST call logoutUser() */
export default function* logoutSaga(api: LogoutApi) {
  while (true) {
    yield take(logoutRequest);
    yield call(logoutUser, api);
  }
}

/** Logout user */
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
function* logoutUser(api: LogoutApi) {
  try {
    yield call(api.logout);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    yield put(resetApplicationStore());
    yield put(redirect('/login'));
  } catch (error: any) {
    yield put(setEndLoading(currentLoadId));
  }
}
