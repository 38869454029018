import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { selectNetworkErrorMessage } from '../../reducers/networkErrorSlice';
import { useAppSelector } from '../../reducers/rootReducer';

export default function ErrorStatus(): JSX.Element {
  const errorMessage = useAppSelector(selectNetworkErrorMessage);
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    const onOnline = () => {
      setMessage(null);
    };
    const onOffline = () => {
      setMessage('Your network connection is lost');
    };
    window.addEventListener('online', onOnline);
    window.addEventListener('offline', onOffline);

    return () => {
      window.removeEventListener('online', onOnline);
      window.removeEventListener('offline', onOffline);
    };
  }, []);

  useEffect(() => {
    if (errorMessage === 'Network Error') {
      setMessage('Server connection is lost');
    } else {
      setMessage(null);
    }
  }, [errorMessage]);

  const handleClose = () => {
    setMessage(null);
  };

  return (
    <Snackbar
      open={!!message}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        severity="error"
        sx={{ width: '100%', left: '45%', backgroundColor: '#c30a14' }}
        onClose={handleClose}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
}
