import { put, take, call } from 'redux-saga/effects';
import { GET_COMPONENTS_FOR_DOMAIN_AND_ENTITY_REQUEST } from './types';
import {
  getComponentsForDomainAndEntityError,
  getComponentsForDomainAndEntitySuccess,
} from './actions';
import {
  setStartLoading,
  setEndLoading,
} from '../../common/redux/loadManager/actions';
import { getLoadId } from '../../common/redux/loadManager/methods';

let currentLoadId = 0;

export default function* domainComponentsSaga(api) {
  while (true) {
    const componentsForDomainAndEntityAction = yield take(
      GET_COMPONENTS_FOR_DOMAIN_AND_ENTITY_REQUEST,
    );
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));
    const { entityId, domainId, date } =
      componentsForDomainAndEntityAction.payload;
    yield call(getComponentsForEntityAndDomain, api, entityId, domainId, date);
  }
}

function* getComponentsForEntityAndDomain(api, entityId, domainId, date) {
  try {
    const componentsData = yield call(
      api.getComponentsDataForEntityAndDomain,
      entityId,
      domainId,
      date,
    );
    yield put(
      getComponentsForDomainAndEntitySuccess({
        componentsData: componentsData.data,
      }),
    );
    yield put(setEndLoading(currentLoadId));
  } catch (error) {
    yield put(getComponentsForDomainAndEntityError(error));
    yield put(setEndLoading(currentLoadId));
  }
}
