import cloneDeep from 'lodash/cloneDeep';
import { START_LOADING, END_LOADING } from '../common/redux/loadManager/types';
import { PAGES_WITH_CONSTANT_COW_LOADER } from '../common/constants';
/** Store states
 * @constant {Object} */
export const initialState = {
  loadingOperations: [],
  loadedScreen: '',
  initialPageLoad: true,
};

/** Load Manager Reducer.
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function loadManagerReducer(state = initialState, action = {}) {
  const loadId = action.payload && action.payload.loadId;
  const newLoadOperations = cloneDeep(state.loadingOperations);
  let newInitialPageLoad = cloneDeep(state.initialPageLoad);
  let newLoadedScreen = cloneDeep(state.loadedScreen);
  // example: "overview"
  const currentPageUrlPath = window.location.pathname
    .split('/')
    .slice(-1)
    .pop()
    .toLowerCase();

  // In some pages cow loader need to be always shown
  const isThePageWithConstantCowLoader =
    PAGES_WITH_CONSTANT_COW_LOADER.includes(currentPageUrlPath);
  const isThePageChanged = newLoadedScreen !== currentPageUrlPath;

  switch (action.type) {
    case START_LOADING:
      newLoadOperations.push(loadId);
      if (isThePageChanged || isThePageWithConstantCowLoader) {
        // If the page has been change, show initial load
        newInitialPageLoad = true;
      }
      return {
        ...state,
        loadingOperations: newLoadOperations,
        initialPageLoad: newInitialPageLoad,
      };
    case END_LOADING:
      newLoadOperations.splice(newLoadOperations.indexOf(loadId), 1);
      if (newLoadOperations.length === 0) {
        // If initialLoad is done , hide initial load for other requests
        newInitialPageLoad = false;
        newLoadedScreen = isThePageChanged
          ? currentPageUrlPath
          : newLoadedScreen;
      }
      return {
        ...state,
        loadingOperations: newLoadOperations,
        initialPageLoad: newInitialPageLoad,
        loadedScreen: newLoadedScreen,
      };
    default:
      return state;
  }
}

export const selectors = {
  selectPreloaderState: (state) => state.loadManager.loadingOperations > 0,
};

export default loadManagerReducer;

// "Invariant failed: A state mutation was detected inside a dispatch, in the path: vectorDataCharts.vectorChartsData.chartData.mfr1Activities.5.activitySpans.0.activity.accuracyPercentage.
// Take a look at the reducer(s) handling the action
// {"type":"END_LOADING","payload":{"loadId":4}}.
// (https://redux.js.org/style-guide/style-guide#do-not-mutate-state)"
