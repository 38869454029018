import {
  CANBUS_STATUS_GRAY,
  CANBUS_STATUS_RED,
  CANBUS_STATUS_YELLOW,
  CANBUS_STATUS_GREEN,
} from './constants';

export const updateRcbRealTimeColoring = (frontendMetadata) => {
  /** Find RCB component object */
  const rcbObject = frontendMetadata.find(
    (o) => o.componentIdentifier === 'RCB' || o.componentIdentifier === 'ACB',
  );

  /** "rcb can0" and "rcb can1" data */
  const rcbDescriptionData = rcbObject.componentData.filter(
    (o) =>
      o.description === 'rcb can0' ||
      o.description === 'rcb can1' ||
      o.description === 'acb can0' ||
      o.description === 'acb can1',
  );

  const rcbCanZeroState = rcbDescriptionData[0].value
    .split('\n')[0]
    .split(': ')[1];
  const rcbCanFirstState = rcbDescriptionData[1].value
    .split('\n')[0]
    .split(': ')[1];

  // Update RCB Color
  rcbObject.status = rcbColorPriority(rcbCanZeroState, rcbCanFirstState);
};

const rcbColorPriority = (stateOne, sateTwo) => {
  const containsGray =
    stateOne === CANBUS_STATUS_GRAY || sateTwo === CANBUS_STATUS_GRAY;

  const containsRed =
    CANBUS_STATUS_RED.includes(stateOne) || CANBUS_STATUS_RED.includes(sateTwo);

  const containsYellow =
    CANBUS_STATUS_YELLOW.includes(stateOne) ||
    CANBUS_STATUS_YELLOW.includes(sateTwo);

  const containsGreen =
    CANBUS_STATUS_GREEN.includes(stateOne) ||
    CANBUS_STATUS_GREEN.includes(sateTwo);

  switch (true) {
    case containsGray:
      return 0;
    case containsRed:
      return 3;
    case containsYellow:
      return 1;
    case containsGreen:
      return 2;
    default:
      return 0;
  }
};

export default { updateRcbRealTimeColoring };
