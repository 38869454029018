import React from 'react';

/** Add margin and padding to components inside him
 * @param {Object} props - Passed params like props
 * @param {Object} props.children - The components / jsx
 * @param {String} [props.className] - The components class
 * @param {String} [props.testId] - Data-testId which is needed for testing the UI
 */
export default function SpacingWrapper({
  children,
  className,
  testId,
  addMargin = true,
  addPadding = true,
}) {
  const style = {
    margin: addMargin ? '8px 8px 8px 8px' : '0px 0px 0px 0px',
    padding: addPadding ? '8px 8px 8px 8px' : '0px 0px 0px 0px',
  };

  return (
    <div data-testid={testId} className={className} style={style}>
      {children}
    </div>
  );
}
