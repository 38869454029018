import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { convertKeysToCapitalizedText } from '../components/AstronautDataExplorer/AstronautDetailsSection/utils';
import { RootState } from './rootReducer';
import {
  AstronautInitialState,
  AstronautClickedElementType,
  SelectedActivityType,
  RobotEvent,
} from '../components/AstronautDataExplorer/types';
import { setAstronautLatestGeneralEventsProperties } from './astronautPropertySelectSlice';
import type { AstronautPropertySelectInitialState } from '../components/AstronautDataExplorer/AstronautFilterMenu/types';

export const astronautInitalState: AstronautInitialState = {
  selectedActivity: null,
  selectedEvents: null,
  isSectionOpened: false,
  clickedElementType: null,
  astroanutChartLoaded: false,
};

export const austronautSelectedActivityDetailsSlice = createSlice({
  name: 'austronautSelectedActivityDetails',
  initialState: astronautInitalState,
  reducers: {
    setSelectedActivityDetails: (
      state,
      action: PayloadAction<SelectedActivityType>,
    ) => {
      state.selectedActivity = convertKeysToCapitalizedText(action.payload);
      state.isSectionOpened = true;
    },
    setIsSectionOpened: (state, action: PayloadAction<boolean>) => {
      state.isSectionOpened = action.payload;
    },
    resetSelectedActivityDetails: (state) => {
      state.selectedActivity = null;
      state.isSectionOpened = false;
    },
    setAstronautClickedItemType: (
      state,
      action: PayloadAction<AstronautClickedElementType | null>,
    ) => {
      state.clickedElementType = action.payload;
    },
    setSelectedEvents: (state, action: PayloadAction<RobotEvent[]>) => {
      state.selectedEvents = action.payload;
      state.selectedActivity = null;
      state.isSectionOpened = true;
    },
    resetSelectedEventsDetails: (state) => {
      state.selectedEvents = null;
      state.isSectionOpened = false;
    },
  },
  extraReducers: (builder) =>
    builder.addCase(
      setAstronautLatestGeneralEventsProperties,
      (
        state,
        action: PayloadAction<
          AstronautPropertySelectInitialState['latestGeneralEventsProperties']
        >,
      ) => {
        const generalEvents = action.payload;
        if (generalEvents && state.selectedEvents) {
          const currentActiveCheckboxes = Object.keys(generalEvents).filter(
            (key) => generalEvents[key].isSelected,
          );

          state.selectedEvents = state.selectedEvents.filter((event) =>
            currentActiveCheckboxes.includes(event.level),
          );
        }
      },
    ),
});

export const {
  setSelectedActivityDetails,
  setAstronautClickedItemType,
  setIsSectionOpened,
  resetSelectedActivityDetails,
  setSelectedEvents,
  resetSelectedEventsDetails,
} = austronautSelectedActivityDetailsSlice.actions;

export const astronautConfigurationSelectors = {
  selectClickedElementType: (state: RootState) =>
    state.astronautSelectedActivity.clickedElementType,
  isClickedElement: (
    state: RootState,
    element: AstronautClickedElementType | undefined,
  ) => {
    return element
      ? state.astronautSelectedActivity.clickedElementType === element
      : false;
  },
  selectIsAstronautChartLoaded: (state: RootState) =>
    state.astronautSelectedActivity.astroanutChartLoaded,
};

export default austronautSelectedActivityDetailsSlice.reducer;
