import React, { MouseEvent, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import RestoreTable from './RestoreTable';
import './Dialog.scss';

type Props = {
  title: string | React.JSX.Element;
  content: string | React.JSX.Element;
  rightAction: (e: MouseEvent<HTMLButtonElement>) => void;
  leftActionProp?: () => void;
  dataId?: string;
  leftButton: string;
  rightButton: string;
  className: string;
  setDisableBtn?: any;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  disabledButton: boolean;
};

export default function DialogComponent({
  title,
  content,
  rightAction,
  dataId,
  leftButton,
  rightButton,
  className,
  setDisableBtn,
  setOpen,
  open,
  disabledButton,
  leftActionProp,
}: Props) {
  const [mainContent, setMainContent] = React.useState(true);
  const [modalTitle, setModalTitle] = React.useState<
    string | React.JSX.Element
  >('');

  useEffect(() => {
    setModalTitle(title);
  }, [title]);

  useEffect(() => {
    return () => {
      if (!open) {
        setModalTitle(title);
        setMainContent(true);
      }
    };
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const onConfirmRight = (e) => {
    rightAction(e);
  };

  const leftAction = () => {
    if (rightButton.split(' ')[0] === 'Restore') {
      setMainContent(false);
      setModalTitle('Select which backup to restore');
    } else if (leftActionProp) {
      leftActionProp();
    } else {
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={`user-dialog-wrapper ${
        !mainContent ? 'restore-table-dailog' : className
      }`}
    >
      <DialogTitle id="alert-dialog-title" className="user-dialog-title">
        {modalTitle}
        <i className="lely-icon-close" onClick={handleClose} />
      </DialogTitle>
      {mainContent ? (
        <>
          <DialogContent className="user-dialog-content alert-dialog-description fs--b-base">
            {content}
          </DialogContent>
          <DialogActions className="user-dialog-buttons">
            <Button
              disableRipple
              className="fs--b-base dialog-buttons"
              onClick={leftAction}
              color="secondary"
              variant="outlined"
              autoFocus
              id={dataId}
            >
              {leftButton}
            </Button>
            <Button
              disableRipple
              className="fs--b-base dialog-buttons user-save"
              onClick={(e) => onConfirmRight(e)}
              color="primary"
              disabled={disabledButton}
            >
              {rightButton}
            </Button>
          </DialogActions>
        </>
      ) : (
        <DialogContent className="user-dialog-content">
          <RestoreTable
            closeModal={handleClose}
            setDisableBtn={setDisableBtn}
          />
        </DialogContent>
      )}
    </Dialog>
  );
}
