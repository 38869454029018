import isEmpty from 'lodash/isEmpty';
import axiosInstance from '../axiosInstance';
import {
  GET_EVENTS_FOR_ENTITY_SERVICE_URL,
  EVENTS_DROPDOWN_LEVELS,
  FROM_DATE_URL_APPENDIX,
  TO_DATE_URL_APPENDIX,
  FILTER_EVENT_BY_TYPE,
  EVENTS_DROPDOWN_TYPES,
  SELECTED_EVENT_DETAILS,
  FILTER_EVENT_OFFSET,
  FILTER_EVENT_LIMIT,
} from './config';

export const api = {
  async getEventsForEntity(
    entityId,
    startDate,
    endDate,
    selectedTypes,
    searchParam,
    offset,
    limit,
  ) {
    let getEventsForEntityUrl = GET_EVENTS_FOR_ENTITY_SERVICE_URL + entityId;
    const typesUrlAppendix = selectedTypes
      .map((currentType) => {
        if (currentType === 'SHOW ALL') {
          return '';
        }
        return FILTER_EVENT_BY_TYPE + currentType;
      })
      .join('');
    getEventsForEntityUrl += typesUrlAppendix;
    getEventsForEntityUrl = startDate
      ? getEventsForEntityUrl + FROM_DATE_URL_APPENDIX + startDate
      : getEventsForEntityUrl;
    getEventsForEntityUrl = endDate
      ? getEventsForEntityUrl + TO_DATE_URL_APPENDIX + endDate
      : getEventsForEntityUrl;
    getEventsForEntityUrl += FILTER_EVENT_OFFSET + offset;
    getEventsForEntityUrl += FILTER_EVENT_LIMIT + limit;

    // Add all search criteria in to the URL
    if (!isEmpty(searchParam)) {
      // Contains Free Text
      if (Array.isArray(searchParam)) {
        searchParam.forEach((freeText) => {
          getEventsForEntityUrl += `&freetext=${freeText}`;
        });
      }
      // Contains Name or Source
      else {
        for (const [key, value] of Object.entries(searchParam)) {
          getEventsForEntityUrl += `&${key}=${value}`;
        }
      }
    }
    return axiosInstance.get(getEventsForEntityUrl);
  },

  async getEventsDropdownLevels() {
    return axiosInstance.get(EVENTS_DROPDOWN_LEVELS);
  },

  async getEventsDropdownTypes() {
    return axiosInstance.get(EVENTS_DROPDOWN_TYPES);
  },

  async getDetailsForSelectedEvent(entityId, eventId) {
    const eventDetailsServiceUrl = SELECTED_EVENT_DETAILS.replace(
      '{entityId}',
      entityId,
    ).replace('{eventId}', eventId);
    return axiosInstance.get(eventDetailsServiceUrl);
  },
};

export type EventsApiType = typeof api;
