import {
  COMPOSITE_STATES_DATA_ERROR,
  COMPOSITE_STATES_DATA_RECEIVED,
  RESET_COMPOSITE_STATES_DATA,
  UPDATE_COMPOSITE_STATES_REQUESTED,
} from '../types/compositeStateTypes';

// /** Create action to request composite state data
//  * @param {String} entityId - Id of selected entity
//  * @param {string} startDate - Start date
//  * @param {string} endDate - End date
//  * @return {Object} - Action object
//  */
export function requestCompositeStateUpdate(entityId, startDate, endDate) {
  return {
    type: UPDATE_COMPOSITE_STATES_REQUESTED,
    payload: {
      entityId,
      startDate,
      endDate,
    },
  };
}

/** Create action which to allow to put data for composite states in the redux store
 * @param {Object} compositeStatesData - composite states data
 * @param {Date} startDate - start date of the range for which the received data is about
 * @param {Date} endDate - end date of the range for which the received data is about
 * @param {String} entityId - the entity ID for which composite state data is received
 * @return {Object} - Action object
 */
export function getCompositeStateDataSuccess(compositeStatesData) {
  return {
    type: COMPOSITE_STATES_DATA_RECEIVED,
    payload: { compositeStatesData },
  };
}

/** Create action which get errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function getCompositeStateDataError(errorMessage) {
  return {
    type: COMPOSITE_STATES_DATA_ERROR,
    payload: errorMessage,
  };
}

/** Create action for resetting the data for
 * the composite states component in the
 * Data Explorer screen
 * We would use this when we are about to
 * fetch new data from the backend
 * in order to avoid showing to users new data in
 * one chart and old data in another
 * @return {Object} Action object
 */
export function resetCompositeStatesData() {
  return {
    type: RESET_COMPOSITE_STATES_DATA,
    payload: {},
  };
}
