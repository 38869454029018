/* eslint-disable no-console */
import { call, put, take } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { getLogContentAction, setLogContent } from '../../reducers/logsSlice';
import { LogsApiType } from '../../api/lelyBackend/logsApi';
import { AwaitedApiResultType } from '../../common/types';

export default function* logsContentSaga(api: LogsApiType) {
  while (true) {
    const logNodesAction: PayloadAction<{
      entityId: string;
      logFileName: string;
      startDate: string;
      preceding: boolean;
    }> = yield take(getLogContentAction.type);

    if (logNodesAction.payload) {
      const { entityId, startDate, logFileName, preceding } =
        logNodesAction.payload;
      yield call(
        getLogsContent,
        api,
        entityId,
        logFileName,
        startDate,
        preceding,
      );
    }
  }
}

function* getLogsContent(
  api: LogsApiType,
  entityId: string,
  logFileName: string,
  logDateTime: string,
  preceding: boolean,
) {
  try {
    const logContent: AwaitedApiResultType<typeof api.getLogContent> =
      yield call(api.getLogContent, entityId, logFileName, logDateTime);

    yield put(
      setLogContent({ content: logContent.data, preceding, logDateTime }),
    );
  } catch (error) {
    console.error('Error while fetching logs menu options:', error);
  }
}
