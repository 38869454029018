import { put, take, call } from 'redux-saga/effects';
// import { RESTORE_ENTITY_BACKUP_SETTING_REQUEST } from './types';
import {
  restoreEntityBackupSettingsError,
  restoreEntityBackupSettingsRequest,
} from '../../reducers/entityBackupSettingsSlice';

export default function* restoreEntityBackupSettingsSaga(api) {
  while (true) {
    const restoreEntityBackupSettingsAction = yield take(
      restoreEntityBackupSettingsRequest.type,
    );
    const { entityId, filename } = restoreEntityBackupSettingsAction.payload;
    yield call(restoreEntityBackupSettings, api, entityId, filename);
  }
}

function* restoreEntityBackupSettings(api, entityId, filename) {
  try {
    yield call(api.restoreEntityBackup, entityId, filename);
  } catch (error) {
    const errorStatusMessage = error.response.data.message;
    yield put(restoreEntityBackupSettingsError({ errorStatusMessage }));
  }
}
