import React from 'react';

import { useAppSelector } from '../../../reducers/rootReducer';
import SideBarButton from './SideBarButton';

function AccountButton({ onClickFunction }) {
  let { currentUser: username } = useAppSelector((state) => state.userSession);
  username = username.split('@')[0];
  username = username.charAt(0).toUpperCase() + username.slice(1);

  return (
    <div className="borderdown">
      <SideBarButton
        onClickFunction={onClickFunction}
        iconClassName="lely-icon-person"
        navigateTo={false}
        menuOptionTitle={username}
        translation={false}
      />
    </div>
  );
}

export default AccountButton;
