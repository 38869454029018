import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';
import {
  LineChartConfigInitialState,
  LineChartType,
} from './types/lineChartTypes';
import { Nullable } from '../common/types';

export const initialState: LineChartConfigInitialState = {
  lineChartConfig: null,
  timelineWidth: 0,
  accuracyLabel: 'kg',
};

// createSlice
export const lineChartConfigSlice = createSlice({
  name: 'lineChartConfig',
  initialState,
  reducers: {
    setLineChartConfig: (state, { payload }: PayloadAction<LineChartType>) => {
      state.lineChartConfig = payload;
    },
    setTimelineWidth: (state, { payload }: PayloadAction<number>) => {
      if (state.timelineWidth !== payload) {
        state.timelineWidth = payload;
      }
    },
    setLineChartConfigProperty: (
      state,
      { payload }: PayloadAction<{ key: string; value: Nullable<string> }>,
    ) => {
      if (state.lineChartConfig) {
        state.lineChartConfig[payload.key] = payload.value;
      }
    },
    setAccuracyLabel: (state, { payload }: PayloadAction<'kg' | '%'>) => {
      state.accuracyLabel = payload;
    },
    resetLineChartConfig: () => initialState,
  },
});

// TODO: line chart config could be created from success vector call
// like feedProperties in appliedPropertiesSlice

export const {
  setLineChartConfig,
  setTimelineWidth,
  setLineChartConfigProperty,
  resetLineChartConfig,
  setAccuracyLabel,
} = lineChartConfigSlice.actions;

// selectors
export const lineChartConfigSelectors = {
  selectLineChartConfig: (state: RootState) =>
    state.lineChartConfig.lineChartConfig,
  selectTimelineWidth: (state: RootState) =>
    state.lineChartConfig.timelineWidth,
  selectAccuracyLabel: (state: RootState) =>
    state.lineChartConfig.accuracyLabel,
};

export default lineChartConfigSlice.reducer;
