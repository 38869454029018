import { put, take, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { GET_VECTOR_CHARTS_CONFIGURATION_REQUEST } from '../types/vectorDataChartTypes';
import {
  getVectorConfigurationSuccess,
  getVectorConfigurationError,
  resetVectorConfiguration,
} from '../../../reducers/vectorConfigurationSlice';
import {
  setStartLoading,
  setEndLoading,
} from '../../../common/redux/loadManager/actions';
import { getLoadId } from '../../../common/redux/loadManager/methods';
import { VectorConfigurationApi } from '../../../api/lelyBackend/vectorConfigurationApi';

let currentLoadId = 0;

export default function* vectorConfigurationSaga(api: VectorConfigurationApi) {
  while (true) {
    const getVectorConfigurationRequestedAction = yield take(
      GET_VECTOR_CHARTS_CONFIGURATION_REQUEST,
    );
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));
    yield put(resetVectorConfiguration());
    const { externalId } = getVectorConfigurationRequestedAction.payload;

    yield call(getVectorConfiguration, api, externalId);
  }
}

function* getVectorConfiguration(
  api: VectorConfigurationApi,
  entityId: string,
) {
  try {
    // TODO: go trough vector configuration request data and do correct typings
    const vectorConfigurationData: AxiosResponse<any> = yield call(
      api.getVectorConfiguration,
      entityId,
    );
    yield put(getVectorConfigurationSuccess(vectorConfigurationData.data));
    yield put(setEndLoading(currentLoadId));
  } catch (error: any) {
    yield put(getVectorConfigurationError(error));
    yield put(setEndLoading(currentLoadId));
  }
}
