import { put, take, call } from 'redux-saga/effects';
import { GET_ENTITY_KPI_DETAILS_REQUEST } from './types';
import {
  getEntityKpiDetailsSuccess,
  getEntityKpiDetailsError,
} from './actions';
import {
  setStartLoading,
  setEndLoading,
} from '../../redux/loadManager/actions';
import { getLoadId } from '../../redux/loadManager/methods';

let currentLoadId = 0;

export default function* entityKpiDetailsSaga(api) {
  while (true) {
    const entityKpiDetailsAction = yield take(GET_ENTITY_KPI_DETAILS_REQUEST);
    /** Run loader */
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));
    const { entityId } = entityKpiDetailsAction.payload;
    yield call(getEntityKpiDetails, api, entityId);
  }
}

function* getEntityKpiDetails(api, entityId) {
  try {
    const entityKpiDetails = yield call(api.getEntityKpiDetails, entityId);
    const entityKpiDetailsResponse = entityKpiDetails.data;
    yield put(getEntityKpiDetailsSuccess(entityKpiDetailsResponse));
    yield put(setEndLoading(currentLoadId));
  } catch (error) {
    yield put(getEntityKpiDetailsError(error));
    yield put(setEndLoading(currentLoadId));
  }
}
