import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Farm } from '../components/FarmsTable/types';
import { RootState } from './rootReducer';

export type FarmsInitialState = {
  farms: undefined | Farm[];
  isFarmsError: boolean;
  errorMessage: string;
};

// initialState
export const farmsInitialState: FarmsInitialState = {
  farms: undefined,
  isFarmsError: false,
  errorMessage: '',
};

// createSlice
export const farmsSlice = createSlice({
  name: 'farms',
  initialState: farmsInitialState,
  reducers: {
    setFarms: (state, action) => {
      state.errorMessage = '';
      state.isFarmsError = false;
      state.farms = action.payload;
      if (state.farms) {
        const responseData = state.farms;
        const transformedData = responseData.map((currentfarm) => {
          const data = currentfarm?.dailyVectorKpi?.reduce((acc, item) => {
            acc[
              item.kpiName.charAt(0).toLowerCase() +
                item.kpiName.slice(1).replace(/\./g, '').replace(/ /g, '')
            ] = `${item.value}`;
            return acc;
          }, {});
          return { ...currentfarm, ...data };
        });
        state.farms = transformedData;
      }
    },
    setIsFarmError: (state, action: PayloadAction<string>) => {
      state.isFarmsError = true;
      state.errorMessage = action.payload;
    },
  },
});

export const getFarms = createAction('GET_FARMS_REQUEST');

export const { setFarms, setIsFarmError } = farmsSlice.actions;

// selectors
export const farmsSelectors = {
  selectFarms: (state: RootState) => state.farms.farms,
  selectIsFarmsError: (state: RootState) => state.farms.isFarmsError,
  selectErrorMessage: (state: RootState) => state.farms.errorMessage,
};

export default farmsSlice.reducer;
