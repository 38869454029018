import {
  GET_ENTITY_KPI_DETAILS_REQUEST_SUCCESS,
  GET_ENTITY_KPI_DETAILS_REQUEST_ERROR,
  CLEAR_ENTITY_KPI_DETAILS,
} from '../common/components/KpiGrid/types';

/** Store states
 * @constant {Object} */
export const initialState = {
  entityKpiDetails: [],
  errorMessage: '',
};

/** Entity Kpi Details Reducer.
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function entityKpiDetailsReducer(state = initialState, action = {}) {
  switch (action.type) {
    /** Action Creator - Set entity Kpi details to store
     * if API request passed successfully */
    case GET_ENTITY_KPI_DETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        entityKpiDetails: action.payload.entityKpiDetails,
        errorMessage: '',
      };

    /** Action Creator - Show error message if
     * request to API failed */
    case GET_ENTITY_KPI_DETAILS_REQUEST_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        entityKpiDetails: [],
      };

    case CLEAR_ENTITY_KPI_DETAILS:
      return {
        ...state,
        entityKpiDetails: [],
        errorMessage: '',
      };
    default:
      return state;
  }
}

export default entityKpiDetailsReducer;
