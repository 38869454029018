/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-return-assign */
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
// Constants
import { jsonRegex } from './constants';

/** Modify free text object from {'0':text} to {'freetext': text}
 * @param {Object} currentSearchParams - All search params
 */
export function modifyFreeTextObject(currentSearchParams) {
  let modifiedObject = [];
  const hasSource = (currentSearchParams && currentSearchParams.source) || '';
  const hasName = (currentSearchParams && currentSearchParams.name) || '';
  const hasId = (currentSearchParams && currentSearchParams.id) || '';
  const hasSourceOrNameOrItIsEmpty =
    hasSource || hasName || hasId || isEmpty(currentSearchParams);

  if (!hasSourceOrNameOrItIsEmpty) {
    Object.entries(currentSearchParams).forEach(([key, value]) => {
      modifiedObject = [...modifiedObject, value];
    });
  } else {
    modifiedObject = { ...currentSearchParams };
  }
  return modifiedObject;
}

/** Filter alarms by name and source from the props data and return all matched alarms.
 * Search by exactly match "==="
 * @param {Object} dataFromProps - The received props data from parent with (name,source,date, ...)
 * @param {Array} alarmDetails - Data for all alarms / event
 */
export function filterDataFromPropsByThreeEquals(dataFromProps, alarmDetails) {
  const hasSource = dataFromProps && dataFromProps.hasOwnProperty('source');
  const hasIndicationId =
    dataFromProps && dataFromProps.hasOwnProperty('indicationId');
  if (hasIndicationId) {
    return alarmDetails.filter(
      (alarm) => alarm.indicationId === dataFromProps.indicationId,
    );
  }
  if (hasSource) {
    return alarmDetails.filter(
      (alarm) =>
        alarm.source === dataFromProps.source &&
        alarm.name === dataFromProps.name,
    );
  }
  return alarmDetails.filter((alarm) => alarm.name === dataFromProps.name);
}

/** Filter alarms by name and source from search input data and return all matched alarms.
 * Search by matched word ".includes"
 * @param {Object} searchInputValue - Applied alarm search filters(words)  ///  example: {name:example_1,source:example_2}
 * @param {Array} alarmDetails - Data for all alarms / event
 */
export function filterDataByNameAndSource(searchInputValue, alarmDetails) {
  const hasSourceAndName =
    searchInputValue.appliedInput.source && searchInputValue.appliedInput.name;
  const hasOnlySource = searchInputValue.appliedInput.source;
  const hasOnlyIndicationId = searchInputValue.appliedInput.id;
  if (hasSourceAndName) {
    return alarmDetails.filter(
      (alarm) =>
        alarm.source
          .toLowerCase()
          .includes(searchInputValue.appliedInput.source.toLowerCase()) &&
        alarm.name
          .toLowerCase()
          .includes(searchInputValue.appliedInput.name.toLowerCase()),
    );
  }
  if (hasOnlySource) {
    return alarmDetails.filter((alarm) =>
      alarm.source
        .toLowerCase()
        .includes(searchInputValue.appliedInput.source.toLowerCase()),
    );
  }
  if (hasOnlyIndicationId) {
    return alarmDetails.filter((alarm) =>
      alarm.indicationId
        .toLowerCase()
        .includes(searchInputValue.appliedInput.id.toLowerCase()),
    );
  }
  return alarmDetails.filter((alarm) =>
    alarm.name
      .toLowerCase()
      .includes(searchInputValue.appliedInput.name.toLowerCase()),
  );
}

/** Filter alarms by including the free text in name,source and message.
 * Search by matched text ".includes" in name,source,message
 * @param {Object} searchInputValue - Applied alarm search filters(words)  ///  example: {0:example_1, 1:example_2}
 * @param {Array} alarmDetails - Data for all alarms
 */
export function filterDataByNameSourceMessage(searchInputValue, alarmDetails) {
  let filteredAlarms = [...alarmDetails];
  Object.entries(searchInputValue).forEach(([key, value]) => {
    filteredAlarms = filteredAlarms.filter((alarm) => {
      if (alarm.message && alarm.source && alarm.name) {
        // Remove extra brackets from message
        // TODO to improve
        alarm.message = JSON.stringify(alarm.message).replace(jsonRegex, ' ');
        alarm.message = alarm.message.replaceAll('  ', ' ');
        return (
          alarm.name.toLowerCase().includes(value.toLowerCase()) ||
          alarm.source.toLowerCase().includes(value.toLowerCase()) ||
          alarm.message.toLowerCase().includes(value.toLowerCase())
        );
      }
      return (
        alarm.name.toLowerCase().includes(value.toLowerCase()) ||
        alarm.source.toLowerCase().includes(value.toLowerCase()) ||
        alarm.indicationId.toLowerCase().includes(value.toLowerCase())
      );
    });
  });
  return filteredAlarms;
}

/** Create and return object with name and source from provided props
 *  data like the object which search input returns
 * @param {Object} dataFromProps - The received props data from parent with (name,source,date, ...)
 */
export function adjustPropsDataToMatchTheCurrentInputExpectation(
  dataFromProps,
) {
  let searchParams = {};
  if (dataFromProps && dataFromProps.source) {
    searchParams = { name: dataFromProps.name, source: dataFromProps.source };
  } else if (dataFromProps && dataFromProps.indicationId) {
    searchParams = { id: dataFromProps.indicationId };
  } else {
    searchParams = { name: dataFromProps.name };
  }
  return searchParams;
}

/** Create and return array with key and value from provided data object
 *  This will be visualize in alarm filter modal in area for current filters
 *  return example : ["name:example", "source:example"]
 * @param {Object} dataFromProps - The received props data from parent with (name,source,date, ...)
 */
export function transformObjectDataToArrayForCurrentFilter(newSearchParams) {
  const newSearchFilters = [];

  for (const [key, value] of Object.entries(newSearchParams)) {
    newSearchFilters.push.apply(newSearchFilters, [`${key}: ${value}`]);
  }
  return newSearchFilters;
}

/** Return array with typed words and the type of search (by name,source,name&source or just text without key)
 * @param {string} searchInput - Text from search input
 */
export function typeAndValueOfWrittenTextInSearchInput(searchInput) {
  const textToLowerCase = searchInput.toLowerCase();
  const nameIndex = textToLowerCase.indexOf('name:');
  const sourceIndex = textToLowerCase.indexOf('source:');
  const idIndex = textToLowerCase.indexOf('id:');
  // Check if the text includes 'name:' and 'source:' strings
  const hasMultipleParams = nameIndex !== -1 && sourceIndex !== -1;
  // Split words by space
  const splittedWordBySpace = textToLowerCase.split(' ');
  if (hasMultipleParams) {
    // returnParams(splittedWordBySpace,'multiParams')
    return [splittedWordBySpace, 'name&source'];
  }
  if (sourceIndex !== -1) {
    // returnParams(splittedWordBySpace,'source')
    return [splittedWordBySpace, 'source'];
  }
  if (nameIndex !== -1) {
    return [splittedWordBySpace, 'name'];
  }
  if (idIndex !== -1) {
    return [splittedWordBySpace, 'id'];
  }

  // returnParams(splittedWordBySpace)
  return [[textToLowerCase]];
}

/** Transform data to desire object with name and source
 * @param {Array} splittedWord - Splitted word from search input
 * @param {string | string[]} searchType -  Typed of search / "all", "source", "name"
 */
export function searchParamsToObject(typedSearchValues, searchType) {
  const names = [];
  const sources = [];
  const ids = [];
  const words = [];
  typedSearchValues.forEach((text) => {
    if (text.includes('source:')) {
      sources.push(text.split('source:')[1]);
    } else if (text.includes('id:')) {
      ids.push(text.split('id:')[1]);
    } else if (text.includes('name:')) {
      names.push(text.split('name:')[1]);
    } else {
      words.push(text);
    }
  });

  let result = {};

  // Prepare the result object  according the data we have
  if (searchType === 'name&source') {
    result = {
      name: names[0],
      source: sources[0],
    };
  } else if (searchType === 'source') {
    result = {
      source: sources[0],
    };
  } else if (searchType === 'name') {
    result = {
      name: names[0],
    };
  } else if (searchType === 'id') {
    result = {
      id: ids[0],
    };
  } else {
    words.map((word, index) => {
      return (result[index] = word);
    });
  }
  return result;
}

/** Search validation
 * @param {Object} currentTypedInput - Current selected data for filtering
 * @param {Object} searchType - Current search value
 */
export function searchInputValidation(currentTypedInput, searchInput) {
  let errorMessage;
  const currentFilterHasNameOrSource =
    currentTypedInput?.hasOwnProperty('name') ||
    currentTypedInput?.hasOwnProperty('source') ||
    currentTypedInput?.hasOwnProperty('id');
  const currentFilterHasBasicWord = currentTypedInput?.hasOwnProperty('0');

  const newTypedDataHasNameOrSourceOrId =
    searchInput?.hasOwnProperty('name') ||
    searchInput?.hasOwnProperty('source') ||
    searchInput?.hasOwnProperty('id');
  const newTypedDataHasBasicWord = searchInput?.hasOwnProperty('0');

  const transformInputToArray = Object.values(searchInput);
  const hasEmptyRules =
    transformInputToArray.filter((item) => item === '').length !== 0;

  if (
    (currentFilterHasNameOrSource && newTypedDataHasBasicWord) ||
    (currentFilterHasBasicWord && newTypedDataHasNameOrSourceOrId)
  ) {
    errorMessage =
      'You are not allowed to search by key and free text! Choose just one of the two options.';
  }
  if (hasEmptyRules) {
    errorMessage = 'You are not allowed to add empty rules';
  }

  return errorMessage;
}

/** Combine incoming search data and old one and update the keys
 * of the new one in order all objects to have unique keys
 * @param {Object} currentTypedInput - Current selected data for filtering
 * @param {Object} searchType - Incoming data from search input in format {0:word,1:word}
 */
export function combineNewSearchDataWithPrevData(
  currentTypedInput,
  searchParams,
) {
  // Clone currentTypedInput data
  let combinedData = { ...currentTypedInput };
  // Get length of currentTypedInput
  let lengthOfCombinedData = size(combinedData);
  // Combine old and new data and update the keys of the new
  // data in order all objects to have unique keys
  // ex: {0:word_0, 1:word_1, ... }
  for (const [key, value] of Object.entries(searchParams)) {
    if (key === '0') {
      const newKey = createUniqueKey(combinedData, lengthOfCombinedData);
      combinedData = { ...combinedData, [newKey]: value };
    } else {
      combinedData = { ...combinedData, [key]: value };
    }
    lengthOfCombinedData++;
  }

  return combinedData;
}

const createUniqueKey = (existingSearchData, keyToCheck) => {
  let newKey = keyToCheck;
  if (existingSearchData[newKey]) {
    newKey++;
    return createUniqueKey(existingSearchData, newKey);
  }
  return newKey;
};
