// eslint-disable-next-line import/prefer-default-export
export function getSelectedDisabledFields(
  filteredData,
  selectedProperties,
  returnType,
  robotName?: string | null,
) {
  const disabledFields = filteredData
    ? filteredData.flatMap((itemData) => {
        return itemData.values
          .filter((val) => {
            return val.dayDisabled
              ? robotName
                ? !!selectedProperties?.[
                    `${val.dataLocationInStore} (${robotName})`
                  ]
                : !!selectedProperties?.[val.dataLocationInStore]
              : false;
          })
          .map((i) => {
            return returnType === 'dataLocationInStore'
              ? i.dataLocationInStore
              : i.name;
          });
      })
    : [];
  return disabledFields;
}
