import { put, take, call } from 'redux-saga/effects';
import { GET_ENTITY_BACKUP_SETTINGS_REQUEST } from './types';
import {
  getEntityBackupSettingsError,
  getEntityBackupSettingsSuccess,
} from '../../reducers/entityBackupSettingsSlice';

export default function* entityBackupSettingsSaga(api) {
  while (true) {
    const entityBackupSettingsAction = yield take(
      GET_ENTITY_BACKUP_SETTINGS_REQUEST,
    );
    const { entityId } = entityBackupSettingsAction.payload;
    yield call(getEntityBackupSettings, api, entityId);
  }
}

/** Create request to get entity backup Settings
 * @param {any} entityId - Entity id
 */
function* getEntityBackupSettings(api, entityId) {
  try {
    const entityBackupSettingsData = yield call(api.getEntityBackups, entityId);
    yield put(
      getEntityBackupSettingsSuccess({
        entityBackupSettingsData: entityBackupSettingsData.data,
      }),
    );
  } catch (error) {
    yield put(getEntityBackupSettingsError(error));
  }
}
