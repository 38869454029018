import React, { ReactElement } from 'react';
import Clock from 'react-live-clock';
import './FarmLocalTimezone.scss';
import moment from 'moment-timezone';
import { useAppSelector } from '../../reducers/rootReducer';

function checkForCorrectZoneFormat(timezone: string): moment.MomentZone | null {
  return moment.tz.zone(timezone);
}

function FarmLocalTimezone({ farmDetails }): ReactElement {
  /** Get the farm id from the URL / react-router-dom */
  const { entityInfo } = useAppSelector((state) => state.entityInfo);

  const entityZone = checkForCorrectZoneFormat(
    farmDetails.farmTimeZone || entityInfo.farmTimeZone,
  );
  let farmClock: ReactElement;
  if (entityZone != null) {
    farmClock = (
      <div className="farm-clock">
        <span>Farm Local Time:</span>
        <Clock
          format="HH:mm"
          ticking
          timezone={farmDetails.farmTimeZone || entityInfo.farmTimeZone}
        />
      </div>
    );
  } else {
    farmClock = (
      <div className="farm-clock">
        <span>Time in UTC:</span>
        <Clock format="HH:mm" ticking timezone="UTC" />
      </div>
    );
  }
  return farmClock;
}
export default FarmLocalTimezone;
