import {
  INVALIDATE_APPLICATION_CACHE_IF_NEEDED,
  UPDATE_CURRENT_APPLICATION_VERSION,
  UPDATE_TIMESTAMP_OF_LATEST_BUILD,
} from './types';

/** Create action which will initiate a check
 * whether the cache needs to be cleared and if so
 * resets the local storage state
 * @param {string} accessToken - Token
 * @return {Object} Action object
 */
export function invalidateApplicationCacheIfNeeded(accessToken) {
  return {
    type: INVALIDATE_APPLICATION_CACHE_IF_NEEDED,
    payload: accessToken,
  };
}

/** Create action which will update
 * the latest application version number
 * in the local storage
 * @param {string} newVersionNumber - New application version number coming directly from package.json or somewhere else
 * @return {Object} Action object
 */
export function updateApplicationVersionNumber(newVersionNumber) {
  return {
    type: UPDATE_CURRENT_APPLICATION_VERSION,
    payload: newVersionNumber,
  };
}

/** Create action which will update
 * the latest build timestamp
 * in the local storage
 * @param {string} latestBuildTimestamp - Timestamp of latest build
 * @return {Object} Action object
 */
export function updateTimestampOfLatestBuild(latestBuildTimestamp) {
  return {
    type: UPDATE_TIMESTAMP_OF_LATEST_BUILD,
    payload: latestBuildTimestamp,
  };
}
