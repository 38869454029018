import { put, take, call } from 'redux-saga/effects';
import { GET_EVENTS_DROPDOWN_TYPES_REQUEST } from './types';
import {
  getEventDropdownTypesError,
  getEventDropdownTypesSuccess,
} from './actions';
import { setStartLoading, setEndLoading } from '../loadManager/actions';
import { getLoadId } from '../loadManager/methods';

let currentLoadId = 0;

export default function* eventDropdownTypesSaga(api) {
  while (true) {
    yield take(GET_EVENTS_DROPDOWN_TYPES_REQUEST);
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));
    yield call(getEventsDropdownTypes, api);
  }
}

function* getEventsDropdownTypes(api) {
  try {
    const eventDropdownTypeData = yield call(api.getEventsDropdownTypes);
    yield put(
      getEventDropdownTypesSuccess({
        eventDropdownTypeData: eventDropdownTypeData.data,
      }),
    );
    yield put(setEndLoading(currentLoadId));
  } catch (error) {
    yield put(getEventDropdownTypesError(error));
    yield put(setEndLoading(currentLoadId));
  }
}
