import { UserRolesType } from '../../components/LoginForm/types';
import axiosInstance from '../axiosInstance';
import { LOGIN_SERVICE_URL, REFRESH_ACCESS_TOKEN_SERVICE_URL } from './config';

export type AuthResponse = {
  accessToken: string;
  refreshToken: string;
  userRoles: UserRolesType[];
  userLanguage: string;
};

export const api = {
  async login(username: string, password: string) {
    return axiosInstance.post<AuthResponse>(LOGIN_SERVICE_URL, {
      username,
      password,
    });
  },
  async refreshAccessToken(accessToken: string, refreshToken: string) {
    return axiosInstance.post<AuthResponse>(REFRESH_ACCESS_TOKEN_SERVICE_URL, {
      accessToken,
      refreshToken,
    });
  },
};

export type LoginApi = typeof api;
