export const classesAddedToDocument = [];

// remove # in the beginning of hex colors
export const getHexWithoutHash = (hexString) => {
  return hexString && hexString.substr(1);
};

/**
 * Change the color of line graph
 * Expecting the new color
 */
export const createCustomCssClassForLineChartColor = (fillColor) => {
  const cssSelector = '.bb-chart .bb-chart-lines .bb-target-MILK-YIELD path';
  const isClassAddedAlready = doesCssClassExist(cssSelector);
  if (!isClassAddedAlready) {
    const newClassStyle = document.createElement('style');
    document.head.appendChild(newClassStyle);
    // Insert a CSS Rule to the sheet at position 0.
    let cssRuleString = cssSelector;
    cssRuleString += ' { ';
    cssRuleString += `stroke: ${fillColor} !important;`;
    cssRuleString += ' }';
    newClassStyle.sheet.insertRule(cssRuleString, 0);
  }
};

export const zoomSpecificActivityType = (activityCssSelector, zoomLevel) => {
  const newClassStyle = document.createElement('style');
  document.head.appendChild(newClassStyle);
  // Insert a CSS Rule to the sheet at position 0.
  let cssRuleString = activityCssSelector;
  cssRuleString += ' { ';
  cssRuleString += `transform: scale3d(${zoomLevel}, 1, 1);`;
  cssRuleString += ' }';
  newClassStyle.sheet.insertRule(cssRuleString, 0);
};

export const hideElementsWithCssClassName = (cssClassName) => {
  const elementsWithClassName = document.getElementsByClassName(cssClassName);
  for (let i = 0; i < elementsWithClassName.length; i++) {
    elementsWithClassName.item(i).classList.remove('is-visible');
    elementsWithClassName.item(i).classList.add('is-hidden');
  }
};

export const showElementsWithCssClassName = (cssClassName) => {
  const elementsWithClassName = document.getElementsByClassName(cssClassName);
  for (let i = 0; i < elementsWithClassName.length; i++) {
    elementsWithClassName.item(i).classList.remove('is-hidden');
    elementsWithClassName.item(i).classList.add('is-visible');
  }
};

/**
 * Lighten or Darken Color
 *
 * The CSS preprocessors Sass and LESS can take any color and darken() or
 * lighten() it by a specific value. But no such ability is built into
 * JavaScript. This takes colors in hex format (i.e. #F06D06, with or
 * without hash) and lightens or darkens them with a value.
 *
 * @param {String} colorCode The hex color code (with or without # prefix).
 * @param {Int} amount
 */
export const lightenDarkenColor = (colorCode, amount) => {
  let usePound = false;
  let newColorCode = colorCode;

  if (colorCode[0] === '#') {
    newColorCode = newColorCode.slice(1);
    usePound = true;
  }

  const num = parseInt(newColorCode, 16);

  let r = (num >> 16) + amount;

  if (r > 255) {
    r = 255;
  } else if (r < 0) {
    r = 0;
  }

  let b = ((num >> 8) & 0x00ff) + amount;

  if (b > 255) {
    b = 255;
  } else if (b < 0) {
    b = 0;
  }

  let g = (num & 0x0000ff) + amount;

  if (g > 255) {
    g = 255;
  } else if (g < 0) {
    g = 0;
  }

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

// construct a data region class name, e.g.: region-ff0000
export const getRegionClassNameByRegionColor = (regionColor) => {
  const regionHexColorWithoutHash = getHexWithoutHash(regionColor);
  return `region-${regionHexColorWithoutHash}`;
};

// We are using the CSS Object Model API here to add dynamically a CSS class.
// It is more optimal to add data region fills with CSS class because then
// similar-coloured data regions will reuse the class.
// If we were to use inline styles it would mean zero reuse and worse performance.
export const createCustomCssClassForDataRegionFill = (
  fillColor,
  tooltipText,
) => {
  const regionClassName = getRegionClassNameByRegionColor(fillColor);
  const isClassAddedAlready = doesCssClassExist(`.${regionClassName}`);
  if (!isClassAddedAlready) {
    const newClassStyle = document.createElement('style');
    document.head.appendChild(newClassStyle);
    // Insert a CSS Rule to the sheet at position 0.
    let cssRuleString = `.${regionClassName}`;
    cssRuleString += ' { ';
    cssRuleString += `fill: ${fillColor};`;
    cssRuleString += 'fill-opacity: 1;';
    cssRuleString += 'opacity: 1;';
    cssRuleString += `stroke: ${fillColor};`;
    cssRuleString += 'overflow: visible;';
    cssRuleString += ' }';
    newClassStyle.sheet.insertRule(cssRuleString, 0);
    if (tooltipText) {
      cssRuleString = `.${regionClassName}:hover { `;
      cssRuleString += 'opacity: 0.5;';
      cssRuleString += '}';
      newClassStyle.sheet.insertRule(cssRuleString, 0);
    }
  }
};

/**
 * This searches for the existence of a specified CSS selector
 *
 * @param (string) selector - This is the name of the selector you'd like to find
 * @return (bool) - Returns true if the selector is found, false if it's not found
 * @example - console.log(selectorInStyleSheet ('myStyleSheet.css', '.myClass'));
 */
export const doesCssClassExist = (selector) => {
  /*
   * Get the index of 'styleSheetName' from the document.styleSheets object
   */
  let isClassDeclarationFound = false;
  for (let i = 0; i < document.styleSheets.length; i++) {
    /*
     * Check the stylesheet for the specified selector
     */
    const styleSheet = document.styleSheets[i];
    const cssRules = styleSheet.rules ? styleSheet.rules : styleSheet.cssRules;
    for (let j = 0; j < cssRules.length; ++j) {
      if (cssRules[j].selectorText === selector) {
        isClassDeclarationFound = true;
        break;
      }
    }
  }
  return isClassDeclarationFound;
};
