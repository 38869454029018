import { put, take, call } from 'redux-saga/effects';
import { GET_CANBUS_TROUBLESHOOTS_TUTORIAL_REQUEST } from './types';
import {
  getCanbusTroubleshootTutorialError,
  getCanbusTroubleshootTutorialSuccess,
} from './actions';
import { setStartLoading, setEndLoading } from '../loadManager/actions';
import { getLoadId } from '../loadManager/methods';

let currentLoadId = 0;

export default function* canbusTroubleshootTutorialSaga(api) {
  while (true) {
    const canbusTroubleshootTutorialAction = yield take(
      GET_CANBUS_TROUBLESHOOTS_TUTORIAL_REQUEST,
    );
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));
    const { domainName } = canbusTroubleshootTutorialAction.payload;
    yield call(getTroubleshootTutorial, api, domainName);
  }
}

function* getTroubleshootTutorial(api, domainName) {
  try {
    const canbusTroubleshootTutorialResponse = yield call(
      api.getTroubleshootTutorialsData,
      domainName,
    );
    yield put(
      getCanbusTroubleshootTutorialSuccess({
        canbusTroubleshootTutorialResponse:
          canbusTroubleshootTutorialResponse.data,
      }),
    );
    yield put(setEndLoading(currentLoadId));
  } catch (error) {
    yield put(getCanbusTroubleshootTutorialError(error));
    yield put(setEndLoading(currentLoadId));
  }
}
