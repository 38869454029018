import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { Nullable } from '../common/types';
import type { RootState } from './rootReducer';

type HoveredObjectType = {
  title: string;
  leftPosition: number;
  topPosition: number;
  objectData: {
    color: string;
    time: string;
    endTime: string;
    name: string;
    details?: { connections: number };
    startTime: string;
  };
  date: string;
  hexColor: string;
};

export type TooltipSliceInitialState = {
  hoveredObject: Nullable<HoveredObjectType>;
};

export const initialState: TooltipSliceInitialState = {
  hoveredObject: null,
};

export const astronautTooltipSlice = createSlice({
  name: 'astronautTooltip',
  initialState,
  reducers: {
    setHoveredObject: (
      state,
      action: PayloadAction<Nullable<HoveredObjectType>>,
    ) => {
      state.hoveredObject = action.payload;
    },
  },
});

export const { setHoveredObject } = astronautTooltipSlice.actions;

export const astronautTooltipSelectors = {
  selectHoveredObject: (state: RootState) =>
    state.astronautTooltip.hoveredObject,
};

export default astronautTooltipSlice.reducer;
