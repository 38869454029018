import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginForm from '../../components/LoginForm/LoginForm';
import loginPageImg from '../../resources/images/Lely_login_img.webp';
import './LoginPage.scss';
import { useAppSelector } from '../../reducers/rootReducer';

function LoginPage(): JSX.Element {
  const navigate = useNavigate();
  const { isLoggedIn } = useAppSelector((state) => state.userSession);
  const { state } = useLocation();

  useEffect(() => {
    if (isLoggedIn) {
      if (
        state?.from &&
        state?.from.pathname !== '/' &&
        state?.from.pathname !== '/login'
      ) {
        const prevLocation = state.from.pathname + state.from.search;
        navigate(prevLocation, { replace: true });
      } else {
        navigate('/farms', { replace: true });
      }
    }
  }, [isLoggedIn]);

  return (
    <div
      className="LoginPage"
      style={{
        backgroundImage: `url(${loginPageImg})`,
      }}
    >
      <LoginForm />
    </div>
  );
}

export default LoginPage;
