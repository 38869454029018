import React, { ReactElement, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';
import './EntityState.scss';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { getEntityStatus, getEntityStatusSuccess } from './actions';
import { useAppSelector } from '../../reducers/rootReducer';

export default function EntityStatus(): React.ReactElement {
  const dispatch = useDispatch();

  const url = window.location.pathname;
  const entityId = url.split('/')[2];
  /** Import reducer state for current status */
  const { entityStatus } = useAppSelector((state) => state.entityStatus);
  const [currentState, setCurrentState] = useState<{ status: string } | null>();
  let entityState: ReactElement;
  /** Get entity information / entityId, name,farmName,farmId  */
  useEffect(() => {
    const entityStatusRequesterInterval = setInterval(
      () => entityStatusRequest(),
      60000,
    );
    return () => clearInterval(entityStatusRequesterInterval);
  });
  /**  Update the state if new entity state data occur */
  useEffect(() => {
    setCurrentState(entityStatus);
  }, [entityStatus]);

  /**  entity requester */
  const entityStatusRequest = () => {
    dispatch(getEntityStatus(entityId));
  };

  useEffect(() => {
    if (!isEmpty(entityId)) {
      dispatch(getEntityStatus(entityId));
    }
    return () => {
      dispatch(getEntityStatusSuccess(''));
    };
  }, [entityId]);

  if (currentState?.status === 'Online') {
    entityState = (
      <div className="online-state">
        <RadioButtonCheckedIcon fontSize="small" />
        <span>{currentState?.status} robot</span>
      </div>
    );
  } else {
    entityState = (
      <div className="offline-state">
        <CloudOffIcon fontSize="small" />
        <span>Offline robot</span>
      </div>
    );
  }
  return entityState;
}
