import _ from 'lodash';
import { getFilteredGraphConstituents } from '../components/AstronautDataExplorer/util/CustomZoomDomainFiltering';
import {
  COMPOSITE_STATES_DATA_RECEIVED,
  COMPOSITE_STATES_DATA_ERROR,
  RESET_COMPOSITE_STATES_DATA,
} from '../components/DataCharts/types/compositeStateTypes';
import { MILK_JAR_WEIGHT_REQUEST_SUCCESS } from '../components/DataCharts/types/dataChartTypes';

/** Store states
 * @constant {Object} */
export const initialState = {
  compositeStatesData: {},
  milkJarWeightData: {},
  milkJarWeightTakeOffReasonData: {},
  chartColorDictionary: {},
  isCompositeStatesDataError: false,
  errorMessage: '',
};

/** Composite States Reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function compositeStatesReducer(state = initialState, action = {}) {
  const stateCopy = _.cloneDeep(state);
  let graphConstituents;
  const chartColorDictionary = {};
  const maxConnectionAttempts = 10;
  const minConnectionAttempts = 1;
  const milkJarWeightTakeOffReasonData = [];
  switch (action.type) {
    case MILK_JAR_WEIGHT_REQUEST_SUCCESS: {
      const { chartData, startDate, endDate } = action.payload;
      graphConstituents = getFilteredGraphConstituents(
        chartData[0].graphs[0].axesData,
        [new Date(startDate), new Date(endDate)],
        true,
        800,
      );
      chartData[0].graphDates = graphConstituents.graphDates;
      chartData[0].graphValues = graphConstituents.graphValues;
      stateCopy.milkJarWeightData = chartData;
      return stateCopy;
    }
    case COMPOSITE_STATES_DATA_RECEIVED: {
      const { compositeStatesData } = action.payload;
      const devicesArray = [
        'teatLeftRear',
        'teatRightRear',
        'teatRightFront',
        'teatLeftFront',
      ];
      Object.keys(compositeStatesData).forEach((currentStateCategory) => {
        const stateCategorySubtypes = compositeStatesData[currentStateCategory];
        stateCategorySubtypes.forEach((currentStateSubtype) => {
          if (currentStateSubtype?.spans?.length > 0) {
            if (currentStateSubtype.name === 'Failed milking') {
              currentStateSubtype?.spans.forEach((span) => {
                chartColorDictionary[
                  `${currentStateSubtype.name}_${span.details.result}`
                ] = span.color;
              });
            } else {
              chartColorDictionary[currentStateSubtype.name] =
                currentStateSubtype.color;
            }
          }
          currentStateSubtype.spans.forEach((currentStateEvent) => {
            currentStateEvent.startTime = new Date(currentStateEvent.startTime);
            currentStateEvent.endTime = new Date(currentStateEvent.endTime);
            currentStateEvent.name = currentStateSubtype.name;
            currentStateEvent.color = currentStateEvent.color
              ? currentStateEvent.color
              : currentStateSubtype.color;
          });
        });
      });
      compositeStatesData.milkingStages
        .filter(
          (currentMilkingStage) => currentMilkingStage.name === 'Attaching',
        )
        .forEach((currentStage) => {
          currentStage.maxConnectionAttempts = maxConnectionAttempts;
          currentStage.minConnectionAttempts = minConnectionAttempts;
        });
      compositeStatesData?.overallState?.forEach((milkState) => {
        milkState.spans.forEach((currentMilkStateSpan) => {
          if (!_.isEmpty(currentMilkStateSpan.details)) {
            Object.keys(currentMilkStateSpan.details).forEach((activityKey) => {
              const milikingStateObject =
                currentMilkStateSpan.details[activityKey].takeOffReason;
              if (
                devicesArray.includes(activityKey) &&
                _.isObject(milikingStateObject)
              ) {
                milikingStateObject.time = new Date(milikingStateObject.time);
                milkJarWeightTakeOffReasonData.push(milikingStateObject);
              }
            });
          }
        });
      });
      stateCopy.milkJarWeightTakeOffReasonData = milkJarWeightTakeOffReasonData;
      stateCopy.compositeStatesData = compositeStatesData;
      stateCopy.chartColorDictionary = chartColorDictionary;
      stateCopy.isCompositeStatesDataError = false;
      stateCopy.errorMessage = '';
      return stateCopy;
    }
    case COMPOSITE_STATES_DATA_ERROR:
      return {
        ...state,
        compositeStatesData: {},
        isCompositeStatesDataError: true,
        errorMessage: action.payload,
      };

    case RESET_COMPOSITE_STATES_DATA:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}

export default compositeStatesReducer;
