import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Credentials,
  LoginSuccess,
  LOGIN_REQUEST,
  UserSessionInitialState,
  UserRolesType,
  Tokens,
} from '../components/LoginForm/types';
import { LOGOUT_REQUEST } from '../components/LogoutButton/types';
import { RootState } from './rootReducer';
import { UI_DATE_FORMAT } from '../common/constants';

/** Store states
 * @constant {Object} */
export const userSessionInitialState: UserSessionInitialState = {
  loginError: undefined,
  accessToken: '',
  refreshToken: '',
  currentUser: '',
  isLoggedIn: false,
  userRoles: [],
  unitPreference: '',
  dateFormatPreference: UI_DATE_FORMAT,
};

// createSlice
export const userSessionSlice = createSlice({
  name: 'userSession',
  initialState: userSessionInitialState,
  reducers: {
    /** On successfully login set username and token to store .
     * @param {string} username - Username of the current user
     * @param {string} accessToken - accessToken of the current user
     * @param {string} refreshToken - refreshToken of the current user
     * @param {Object} userRoles - User role (user,admin,technician)
     * @return {any} accessToken Token of the current user
     */
    setLoginSuccess: (state, action: PayloadAction<LoginSuccess>) => {
      const { username, accessToken, refreshToken, userRoles } = action.payload;
      state.currentUser = username.includes('@')
        ? username.toLowerCase()
        : username;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.isLoggedIn = true;
      state.loginError = undefined;
      state.userRoles = userRoles;
    },
    setUserRoles: (state, action) => {
      state.userRoles = action.payload;
    },
    /** On unsuccessfully login update store and show error message .
     * @param {string} errorMessage - Error message
     */
    setLoginError: (state, action: PayloadAction<any>) => {
      state.currentUser = '';
      state.accessToken = '';
      state.refreshToken = '';
      state.isLoggedIn = false;
      state.loginError = action.payload;
      state.userRoles = [];
    },
    /** Sets accessToken and refreshToken when refresh occur.
     * @param {string} accessToken - accessToken of the current user
     * @param {string} refreshToken - refreshToken of the current user
     */
    setAccessTokenAndRefreshToken: (state, action: PayloadAction<Tokens>) => {
      const { accessToken, refreshToken } = action.payload;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
    },
    setUserPreference: (state, action: PayloadAction<string>) => {
      state.unitPreference = action.payload;
    },
    setDateFormatPreference: (state, action: PayloadAction<string>) => {
      state.dateFormatPreference = action.payload;
    },
    /** Action Creator - logout
     * it will receive empty data  dispatching from the saga
     */
    // TODO: not used
    setLogoutSuccess: () => userSessionInitialState,
  },
});

export const loginUser = createAction<Credentials>(LOGIN_REQUEST);
export const logoutRequest = createAction(LOGOUT_REQUEST);

export const {
  setLoginSuccess,
  setUserRoles,
  setLoginError,
  setAccessTokenAndRefreshToken,
  setLogoutSuccess,
  setUserPreference,
  setDateFormatPreference,
} = userSessionSlice.actions;

export const userSessionSelectors = {
  selectCurrentUserRoles: (state: RootState) => state.userSession.userRoles,
  selectIsOnlyUser: (state: RootState) => {
    const userRoles = state.userSession.userRoles;
    return userRoles.length === 1 && userRoles[0] === UserRolesType.User;
  },
  selectUnitPreference: (state: RootState) => state.userSession.unitPreference,
  selectDateFormatPreference: (state: RootState) =>
    state.userSession.dateFormatPreference,
  selectCurrentUser: (state: RootState) => state.userSession.currentUser,
};

export default userSessionSlice.reducer;
