import { put, take, call } from 'redux-saga/effects';
import { UPDATE_FARM_NAME_REQUEST } from './types';
import {
  updateFarmNameOfTheEntitySuccess,
  updateFarmNameOfTheEntityError,
} from './actions';
import {
  setStartLoading,
  setEndLoading,
} from '../../common/redux/loadManager/actions';
import { getLoadId } from '../../common/redux/loadManager/methods';

let currentLoadId = 0;

export default function* updateNameDetailsSaga(api) {
  while (true) {
    const updateNameDetailsAction = yield take(UPDATE_FARM_NAME_REQUEST);
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));
    const { accessToken, entityId, updatedName } =
      updateNameDetailsAction.payload;
    yield call(getUpdateNameDetails, api, accessToken, entityId, updatedName);
  }
}

function* getUpdateNameDetails(api, accessToken, entityId, updatedName) {
  try {
    const updatedNameData = yield call(
      api.updateEntityName,
      entityId,
      updatedName,
    );

    const updatedNameString = updatedNameData.data;
    yield put(updateFarmNameOfTheEntitySuccess(updatedNameString));
    yield put(setEndLoading(currentLoadId));
  } catch (error) {
    const errorMessage =
      error.toString().substr(-3) === '400'
        ? 'Entity name already exist'
        : error;
    yield put(updateFarmNameOfTheEntityError(errorMessage));
    yield put(setEndLoading(currentLoadId));
  }
}
