import {
  GET_EVENTS_DROPDOWN_TYPES_REQUEST,
  GET_EVENTS_DROPDOWN_TYPES_REQUEST_SUCCESS,
  GET_EVENTS_DROPDOWN_TYPES_REQUEST_ERROR,
} from './types';

/** Create action which will get all information event dropdown types.
 * @param {String} accessToken - the access token
 * @return {Object} Return type and info for event dropdown type
 */
export function getEventDropdownTypes(accessToken) {
  return {
    type: GET_EVENTS_DROPDOWN_TYPES_REQUEST,
    payload: {
      accessToken,
    },
  };
}

/** Create action which to allow to put dropdown types in the redux store
 * @param {Object} dropdownTypes - The dropdown types data
 * @return {Object} Return name and order of dropdown types
 */
export function getEventDropdownTypesSuccess(dropdownTypes) {
  return {
    type: GET_EVENTS_DROPDOWN_TYPES_REQUEST_SUCCESS,
    payload: { ...dropdownTypes },
  };
}

/** Create action which get errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function getEventDropdownTypesError(errorMessage) {
  return {
    type: GET_EVENTS_DROPDOWN_TYPES_REQUEST_ERROR,
    payload: errorMessage,
  };
}
