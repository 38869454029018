import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function DropDown({ defaultValue, onUpdate, dropdownData }) {
  const [value, setValue] = React.useState(defaultValue);

  const handleChange = (event) => {
    setValue(event.target.value);
    onUpdate(event.target.value);
  };

  return (
    <div>
      <FormControl variant="outlined">
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={value}
          onChange={handleChange}
        >
          {dropdownData.map((data) => {
            return (
              <MenuItem key={data.code} value={data.code}>
                {data.Language}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
