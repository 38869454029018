import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { DATA_BOTTOM_CHART_ID } from '../common/constants';
import { getPlaceholderObjectByPropertySelection } from '../common/data/new_charts_page_metadata';
import { RootState } from './rootReducer';

import {
  InitialStateInterface,
  PayloadInterface,
} from './types/dataExplorerLoadManagerTypes';

export const initialState: InitialStateInterface = {
  areAllChartsLoaded: false,
  charts: null,
};

export const dataExplorerLoadManagerSlice = createSlice({
  name: 'dataExplorerLoadManager',
  initialState,
  reducers: {
    initialDataExplorerLoadManager: (
      state,
      { payload }: PayloadAction<PayloadInterface>,
    ) => {
      const placeholdersObject = getPlaceholderObjectByPropertySelection(
        payload.chartsData,
        payload.dataChartConfigInLocalStorage,
      );

      state.areAllChartsLoaded = isEmpty(payload.chartsData)
        ? true
        : placeholdersObject.areAllChartsLoaded;
      state.charts = placeholdersObject.charts;
    },
    chartHasRendered: (state, { payload }: PayloadAction<string>) => {
      let isEverythingElseLoaded: boolean = true;
      if (state.charts) {
        Object.keys(state.charts).forEach((currentTierKey) => {
          Object.keys(state.charts![currentTierKey]).forEach(
            (currentChartType) => {
              state.charts![currentTierKey][currentChartType].forEach(
                (currentChartInfo) => {
                  if (
                    currentChartInfo.chartId.toLowerCase() ===
                    payload.toLowerCase()
                  ) {
                    currentChartInfo.isLoaded = true;
                  } else if (
                    currentChartInfo.isLoaded === false &&
                    currentTierKey !== DATA_BOTTOM_CHART_ID
                  ) {
                    isEverythingElseLoaded = false;
                  }
                },
              );
            },
          );
        });
      }

      if (isEverythingElseLoaded) {
        state.areAllChartsLoaded = true;
      }
    },
  },
});

// Selectors
export const dataExplorerLoadManagerSlelectors = {
  selectAreAllChartsLoaded: (state: RootState) =>
    state.dataExplorerLoadManager.areAllChartsLoaded,
  selectCharts: (state: RootState) => state.dataExplorerLoadManager.charts,
};

export const { initialDataExplorerLoadManager, chartHasRendered } =
  dataExplorerLoadManagerSlice.actions;
export default dataExplorerLoadManagerSlice.reducer;
