import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from '../common/types';
import { MIN_ZOOM_LEVEL } from '../components/Vector/mfrchart/constants';
import { RootState } from './rootReducer';
import { setVectorChartLoaded } from './vectorConfigurationSlice';

export const DO_NOT_RESET = 0;
export type ZoomRange = {
  start: Date;
  end: Date;
};

export type CaretTemporaryData = {
  zoomLevel: number;
  dateToZoomOn?: Date;
  exactLocation?: {
    pan: number;
    prevWindowInnerWidth: number;
  };
};

export interface ZoomInitialStateType {
  currentZoomLevel: number;
  currentDomain: Nullable<ZoomRange>;
  zoomRange: Nullable<ZoomRange>;
  innermostZoomRange: Nullable<ZoomRange>;
  explicitResetZoom: number;
  isZoomInProgress: boolean;
  caretTemporaryData: Nullable<CaretTemporaryData>;
  datePickerDates: Nullable<{ startDate: Date; endDate: Date }>;
  timelineTimeRange: Nullable<ZoomRange>;
  eventsTimeRange: Nullable<ZoomRange>;
  isFirstZoomLevelsBatchRendered: boolean;
}

export const ZoomInitialState: ZoomInitialStateType = {
  currentZoomLevel: MIN_ZOOM_LEVEL,
  currentDomain: null,
  zoomRange: null,
  innermostZoomRange: null,
  explicitResetZoom: DO_NOT_RESET, // Explicit value that will force reset zoom/pan when changed (needed in order to reset pan/zoom after changing feed height category)
  isZoomInProgress: false,
  caretTemporaryData: null,
  datePickerDates: null,
  timelineTimeRange: null,
  isFirstZoomLevelsBatchRendered: false,
  eventsTimeRange: null,
};

const zoomSlice = createSlice({
  name: 'zoom',
  initialState: ZoomInitialState,
  reducers: {
    setZoomRange(state, action: PayloadAction<ZoomRange>) {
      state.zoomRange = action.payload;
    },
    setCurrentDomain(state, action: PayloadAction<ZoomRange>) {
      state.currentDomain = action.payload;
    },
    setInnermostZoomRange(state, action: PayloadAction<Nullable<ZoomRange>>) {
      state.innermostZoomRange = action.payload;
    },
    setExplicitResetZoom(state, action: PayloadAction<number>) {
      state.explicitResetZoom = action.payload;
    },
    setCurrentZoomLevel(state, action: PayloadAction<number>) {
      state.currentZoomLevel = action.payload;
    },
    setIsZoomInProgress(state, action: PayloadAction<boolean>) {
      state.isZoomInProgress = action.payload;
    },
    setCaretTemporaryData(
      state,
      action: PayloadAction<Nullable<CaretTemporaryData>>,
    ) {
      state.caretTemporaryData = action.payload;
    },
    setDatePickerDates(
      state,
      action: PayloadAction<{ startDate: Date; endDate: Date }>,
    ) {
      state.datePickerDates = action.payload;
    },
    setTimelineZoomRange(state, action: PayloadAction<Nullable<ZoomRange>>) {
      state.timelineTimeRange = action.payload;
    },
    setEventsTimeZoomRange(state, action: PayloadAction<Nullable<ZoomRange>>) {
      state.eventsTimeRange = action.payload;
    },
    setIsFirstZoomLevelsBatchRendered(state, action: PayloadAction<boolean>) {
      state.isFirstZoomLevelsBatchRendered = action.payload;
    },
    resetZommState: () => ZoomInitialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      setVectorChartLoaded,
      (state, action: PayloadAction<boolean>) => {
        if (!action.payload) {
          state.currentZoomLevel = MIN_ZOOM_LEVEL;
          state.isFirstZoomLevelsBatchRendered = false;
        }
      },
    );
  },
});

export const {
  setZoomRange,
  setCurrentDomain,
  setInnermostZoomRange,
  setExplicitResetZoom,
  setCurrentZoomLevel,
  setIsZoomInProgress,
  setCaretTemporaryData,
  setDatePickerDates,
  setTimelineZoomRange,
  setIsFirstZoomLevelsBatchRendered,
  resetZommState,
  setEventsTimeZoomRange,
} = zoomSlice.actions;

export const selectZoomRange = (state: RootState) => state.zoom.zoomRange;

export const selectInnermostZoomRange = (state: RootState) =>
  state.zoom.innermostZoomRange;
export const selectCurrentDomain = (state: RootState) =>
  state.zoom.currentDomain;
export const selectExplicitResetZoom = (state: RootState) =>
  state.zoom.explicitResetZoom;
export const selectCurrentZoomLevel = (state: RootState) =>
  state.zoom.currentZoomLevel;
export const selectIsZoomInProgress = (state: RootState) =>
  state.zoom.isZoomInProgress;
export const selectCaretTemporaryData = (state: RootState) =>
  state.zoom.caretTemporaryData;
export const selectDatePickerDates = (state: RootState) =>
  state.zoom.datePickerDates;
export const selectTimelineZoomRange = (state: RootState) =>
  state.zoom.timelineTimeRange;
export const selectEventsTimeZoomRange = (state: RootState) =>
  state.zoom.eventsTimeRange;
export const selectIsFirstZoomLevelsBatchRendered = (state: RootState) =>
  state.zoom.isFirstZoomLevelsBatchRendered;

export default zoomSlice.reducer;
