import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from '../common/types';
import { RootState } from './rootReducer';

export type RangesByZoomLevelType = Nullable<
  Record<number, Nullable<{ start: Date; end: Date }>>
>;

export type CanvasGenerationInitialState = {
  rangesByZoomLevel: RangesByZoomLevelType;
};

export const initialState: CanvasGenerationInitialState = {
  rangesByZoomLevel: null,
};

export const canvasGenerationSlice = createSlice({
  name: 'canvasGeneration',
  initialState,
  reducers: {
    setDynamicLevelRange: (
      state,
      action: PayloadAction<{ zoomLevel: number; start: Date; end: Date }>,
    ) => {
      const { zoomLevel, start, end } = action.payload;
      if (!state.rangesByZoomLevel) {
        state.rangesByZoomLevel = {};
      }

      state.rangesByZoomLevel[zoomLevel] = { start, end };
    },
    resetDynamicLevelRange: (state, action: PayloadAction<number>) => {
      const zoomLevel = action.payload;
      if (state.rangesByZoomLevel?.[zoomLevel]) {
        state.rangesByZoomLevel[zoomLevel] = null;
      }
    },
    resetDynamicLevelsRange: () => initialState,
  },
});

export const {
  setDynamicLevelRange,
  resetDynamicLevelRange,
  resetDynamicLevelsRange,
} = canvasGenerationSlice.actions;

export const canvasGenerationSelectors = {
  selectRangesByZoomLevel: (state: RootState) =>
    state.canvasGeneration.rangesByZoomLevel,
};

export const selectRangeByZoomLevel = createSelector(
  canvasGenerationSelectors.selectRangesByZoomLevel,
  (_: RootState, zoomLevel: number) => zoomLevel,
  (zoomRanges, zoomLevel) => {
    if (!zoomRanges) {
      return;
    }

    return zoomRanges[zoomLevel];
  },
);

export default canvasGenerationSlice.reducer;
