import isEmpty from 'lodash/isEmpty';
import moment, { Moment } from 'moment';
import { format } from 'date-fns/format';

import {
  FeedIoDispenserConfig,
  Version,
} from '../../../../reducers/types/vectorDetailsTypes';
import { ZoomRange } from '../../../../reducers/zoomSlice';
import { MIN_ZOOM_LEVEL, DAY_ZOOM_LEVEL } from '../../mfrchart/constants';
import { UI_DATE_FORMAT } from '../../../../common/constants';

const availableActivitesNameMap = {
  'Dispensing (pulse)': 'Disp. (pulse)',
  'Dispensing (weight)': 'Disp. (weight)',
  'Drive (feed)': 'Drive (feed)',
  'Drive (scan)': 'Drive (scan)',
  Driving: 'Drive - other',
  Waiting: 'Wait',
  Loading: 'Load',
  Mixing: 'Mixing',
  'Wait for drive': 'Wait for drive',
  'Out of operation': 'Out of operation',
  'Critical alarm': 'Alarm',
  Dump: 'Dump',
  Grab: 'Grab',
  Scan: 'Scan',
  Wait: 'Wait',
  'Not connected': 'Not connected',
  'Extra charging': 'Extra charge',
  'Kitchen scan': 'Full kitchen scan',
  'Driving route': 'Driving route',
  'Driving route with dosing and feed height measurements':
    'Driving route with dosing and feed height measurements',
  'Driving route with feed height measurements':
    'Driving route with feed height measurements',
  'Grab task': 'Grab task',
  Synchronizing: 'Synchronize',
  'In operation hold': 'Operation hold',
  'Going to refill pos': 'Going to refill pos',
  'Arrived on refill pos': 'Arrived on refill pos',
};

const getDurationOfActivityInSeconds = (
  activityStartDate: string,
  activityEndDate: string,
  zoomStartDate: Moment,
  zoomEndDate: Moment,
) => {
  const max = moment.max(moment(activityStartDate), zoomStartDate);
  const min = moment.min(moment(activityEndDate), zoomEndDate);

  const duration = moment.duration(min.diff(max));
  return duration.asSeconds();
};

const precent = (time, totalInterval) => {
  return `${(time / (totalInterval / 100)).toFixed(2)}%`;
};

export const getNormalizedRobotVersionsForZoomRange = (
  deviceVersions: Record<string, Version[]>,
  robot: 'Mfr1' | 'Mfr2' | 'Pdb' | 'Fg',
  zoomRange: ZoomRange,
): string[][] => {
  const allValuesForRobot: Version[] = [];
  for (const [, value] of Object.entries(deviceVersions)) {
    const forRobot = value.filter((v) => v.device === robot);
    allValuesForRobot.push(...forRobot);
  }

  if (allValuesForRobot.length === 1) {
    return [[allValuesForRobot[0].version.replace('_', ': ')]];
  }

  const allProcesses: string[] = [];
  const sorted = allValuesForRobot.sort((a: Version, b: Version) => {
    allProcesses.push(a.process);
    allProcesses.push(b.process);

    if (a.process === b.process) {
      return a.date > b.date ? -1 : 1;
    }
    return a.process < b.process ? -1 : 1;
  });

  const uniqueProcessNames = [...new Set(allProcesses)];

  const versionsSeperatedByProcess: Version[][] = [];
  uniqueProcessNames.forEach((processName) => {
    const extractedVersionsForProcess = sorted.filter(
      (version) => version.process === processName,
    );
    versionsSeperatedByProcess.push(extractedVersionsForProcess);
  });

  const result: string[][] = [];
  versionsSeperatedByProcess.forEach((allVersionsForProcess) => {
    const formated: string[] = [];
    allVersionsForProcess.reverse().forEach((currentVersion, index) => {
      const match =
        allVersionsForProcess[index - 1] &&
        currentVersion.version === allVersionsForProcess[index - 1].version &&
        currentVersion.process === allVersionsForProcess[index - 1].process;
      if (
        !match &&
        moment(currentVersion.date).isBefore(moment(zoomRange.end))
      ) {
        if (moment(currentVersion.date).isBefore(moment(zoomRange.start))) {
          if (
            formated[formated.length - 1] &&
            formated[formated.length - 1].includes('(previous)')
          )
            return;
          formated.push(
            `${currentVersion.version.replace('_', ': ')} (previous)`,
          );
        } else {
          formated.push(
            `${currentVersion.version.replace('_', ': ')} (since ${format(
              currentVersion.date,
              UI_DATE_FORMAT,
            )})`,
          );
        }
      }
    });
    if (formated.length === 1) {
      formated[0] = formated[0].replace('(previous)', '');
    }
    result.push(formated.reverse());
  });

  return result.reverse();
};

export const getNormalizedConfiguration = <T extends { date: string }>(
  config: Record<string, Array<T>>,
  zoomRange: ZoomRange,
  robot: string,
): Array<T & { title: string }> => {
  const robotConfiguration: Array<T & { title: string }> = [];
  let previous: (T & { title: string }) | undefined;
  for (const [, values] of Object.entries(config)) {
    // eslint-disable-next-line @typescript-eslint/no-loop-func
    values.forEach((value) => {
      const date = moment(value.date);
      if (date.isBefore(moment(zoomRange.end))) {
        if (date.isBefore(moment(zoomRange.start))) {
          previous = { ...value, title: `${robot} config (previous)` };
        } else {
          robotConfiguration.push({
            ...value,
            title: `${robot} config (since ${format(
              value.date,
              UI_DATE_FORMAT,
            )})`,
          });
        }
      }
    });
  }

  if (previous) {
    robotConfiguration.unshift(previous);
  }

  if (robotConfiguration.length === 1) {
    robotConfiguration[0].title = `${robot} config`;
  }
  return robotConfiguration.reverse();
};

export const getMfrSummationList = (
  zoomRange: Moment[],
  mfrActivities: any,
): { name: string; precent: string }[] => {
  if (!mfrActivities) return [];
  const zoomStartDate = zoomRange[0];
  const zoomEndDate = zoomRange[1];
  let totalActivityTime = 0;
  const res = mfrActivities.flatMap((activity) => {
    const filteredActivities = activity.activitySpans.filter(
      (span) =>
        moment(span.startTime).isBefore(zoomEndDate) &&
        zoomStartDate.isBefore(moment(span.endTime)),
    );
    let timeInSeconds = 0;
    let timeOfFeeding = 0;
    let timeOfScanning = 0;
    filteredActivities.forEach((span) => {
      const seconds = getDurationOfActivityInSeconds(
        span.startTime,
        span.endTime,
        zoomStartDate,
        zoomEndDate,
      );

      if (
        activity.name === 'Driving route' ||
        activity.name ===
          'Driving route with dosing and feed height measurements' ||
        activity.name === 'Driving route with feed height measurements'
      ) {
        if (span.activity.dosing.length) {
          // Drive (feed)
          timeOfFeeding += seconds;
        } else {
          // Drive (scan)
          timeOfScanning += seconds;
        }
      } else {
        timeInSeconds += seconds;
      }
    });

    if (
      activity.name === 'Driving route' ||
      activity.name ===
        'Driving route with dosing and feed height measurements' ||
      activity.name === 'Driving route with feed height measurements'
    ) {
      totalActivityTime += timeOfScanning;
      totalActivityTime += timeOfFeeding;
      return [
        {
          name: 'Drive (scan)',
          time: timeOfScanning,
        },
        {
          name: 'Drive (feed)',
          time: timeOfFeeding,
        },
      ];
    }
    totalActivityTime += timeInSeconds;
    return {
      name: availableActivitesNameMap[activity.name],
      time: timeInSeconds,
    };
  });

  let result = res.reduce((acc, element) => {
    acc[element.name] = (acc[element.name] || 0) + element.time;
    return acc;
  }, {});
  result = Object.entries(result)
    .map((element) => {
      return {
        name: element[0],
        precent: precent(element[1], totalActivityTime),
      };
    })
    .sort((a, b) => {
      if (a.name === 'Wait' && b.name === 'Wait for drive') {
        return 1;
      }

      if (a.name === 'Wait for drive' && b.name === 'Wait') {
        return -1;
      }

      if (a.name < b.name) {
        return -1;
      }
      return 1;
    });

  return result;
};

export const getFgSummationList = (zoomRange: Moment[], fgActivities: any) => {
  if (!fgActivities) return [];
  const zoomStartDate = zoomRange[0];
  const zoomEndDate = zoomRange[1];
  let totalActivityTime = 0;
  const res = fgActivities.flatMap((activity) => {
    if (availableActivitesNameMap[activity.name] === undefined)
      return undefined;
    const filteredActivities = activity.activitySpans.filter(
      (span) =>
        moment(span.startTime).isBefore(zoomEndDate) &&
        zoomStartDate.isBefore(moment(span.endTime)),
    );
    let timeInSeconds = 0;
    let timeOfScanning = 0;
    let timeOfGrabing = 0;
    let timeOfDumping = 0;

    filteredActivities.forEach((span) => {
      if (activity.name === 'Grab task') {
        if (span.activity.scans.length > 0) {
          span.activity.scans.forEach((spanInner) => {
            const seconds = getDurationOfActivityInSeconds(
              spanInner.startTime,
              spanInner.endTime,
              zoomStartDate,
              zoomEndDate,
            );
            timeOfScanning += seconds;
          });
        }
        if (span.activity.grabs.length > 0) {
          span.activity.grabs.forEach((spanInner) => {
            const seconds = getDurationOfActivityInSeconds(
              spanInner.startTime,
              spanInner.endTime,
              zoomStartDate,
              zoomEndDate,
            );

            timeOfGrabing += seconds;
          });
        }
        if (!isEmpty(span.activity.dump)) {
          const seconds = getDurationOfActivityInSeconds(
            span.activity.dump.startTime,
            span.activity.dump.endTime,
            zoomStartDate,
            zoomEndDate,
          );

          timeOfDumping += seconds;
        }
      } else {
        const seconds = getDurationOfActivityInSeconds(
          span.startTime,
          span.endTime,
          zoomStartDate,
          zoomEndDate,
        );

        timeInSeconds += seconds;
      }
    });
    if (activity.name === 'Grab task') {
      totalActivityTime += timeOfScanning;
      totalActivityTime += timeOfGrabing;
      totalActivityTime += timeOfDumping;

      return [
        {
          name: 'Scan',
          time: timeOfScanning,
        },
        {
          name: 'Grab',
          time: timeOfGrabing,
        },
        {
          name: 'Dump',
          time: timeOfDumping,
        },
      ];
    }
    totalActivityTime += timeInSeconds;
    return {
      name: availableActivitesNameMap[activity.name],
      time: timeInSeconds,
    };
  });

  const result = res
    .filter((element) => element && element.time > 0)
    .map((element) => {
      return {
        name: element.name,
        precent: precent(element.time, totalActivityTime),
      };
    });
  return result.sort((a, b) => (a.name < b.name ? -1 : 1));
};

export const getPdbSummationList = (
  zoomRange: Moment[],
  mfrActivities: any,
) => {
  if (!mfrActivities) return [];
  const zoomStartDate = zoomRange[0];
  const zoomEndDate = zoomRange[1];
  let totalActivityTime = 0;
  const res = mfrActivities.flatMap((activity) => {
    if (availableActivitesNameMap[activity.name] === undefined)
      return undefined;
    const filteredActivities = activity.activitySpans.filter(
      (span) =>
        moment(span.startTime).isBefore(zoomEndDate) &&
        zoomStartDate.isBefore(moment(span.endTime)),
    );
    let timeInSeconds = 0;
    filteredActivities.forEach((span) => {
      const seconds = getDurationOfActivityInSeconds(
        span.startTime,
        span.endTime,
        zoomStartDate,
        zoomEndDate,
      );

      timeInSeconds += seconds;
    });
    totalActivityTime += timeInSeconds;
    return {
      name: availableActivitesNameMap[activity.name],
      time: timeInSeconds,
    };
  });

  const result = res
    .filter((element) => element && element.time > 0)
    .map((element) => {
      return {
        name: element.name,
        precent: precent(element.time, totalActivityTime),
      };
    });

  return result.sort((a, b) => (a.name < b.name ? -1 : 1));
};

export const filterFeedIoDispenserConfiguration = (
  config: FeedIoDispenserConfig[],
): FeedIoDispenserConfig[] => {
  const res = config.map((item) => {
    const freqWeightDispenserConfig = item.freqWeightDispenserConfig
      ? item.freqWeightDispenserConfig.filter((fw) => fw.siloId !== -1)
      : [];
    const ioDispenserConfig = item.ioDispenserConfig
      ? item.ioDispenserConfig.filter((io) => io.siloId !== -1)
      : [];
    const pulseDispenserConfig = item.pulseDispenserConfig
      ? item.pulseDispenserConfig.filter((pd) => pd.siloId !== -1)
      : [];
    return {
      ...item,
      freqWeightDispenserConfig,
      ioDispenserConfig,
      pulseDispenserConfig,
    };
  });
  return res;
};

/** Free time calculation */
type Duration = {
  start: number;
  end: number;
};

type PartialActivity = {
  device: string;
  startTime: Date;
  endTime: Date;
  name?: string;
  activity?: { dosing: [] };
};

const clamp = (min: number, value: number, max: number) => {
  return Math.max(min, Math.min(value, max));
};

const convertToSeconds = (input: Date) => {
  const date = new Date(input);
  return date.getTime() / 1000;
};

export const computeSystemFreeTime = (
  zoomLevel: number,
  zoomRange: ZoomRange | null,
  mfr1Activities: any[] = [],
  mfr2Activities: any[] = [],
  fgActivities: any[] = [],
): number => {
  const ALLOWED_DEVICES = ['Mfr1', 'Mfr2', 'Fg', 'Unknown'];
  if (zoomRange === null) return -1;
  const ts = {
    startEpoch: convertToSeconds(zoomRange.start),
    endEpoch: convertToSeconds(zoomRange.end),
    startDate: moment(zoomRange.start),
    endDate: moment(zoomRange.end),
  };
  // duration, which is bounded by the timespan
  const initDuration = (startArg: any, endArg: any): Duration => {
    const start = clamp(ts.startEpoch, startArg, ts.endEpoch);
    const end = clamp(ts.startEpoch, endArg, ts.endEpoch);
    return { start, end };
  };

  const availableDataInTs = () => {
    let minTime = Number.MAX_SAFE_INTEGER;
    let maxTime = 0;

    [mfr1Activities, mfr2Activities, fgActivities].forEach((activities) => {
      activities.forEach((activity) => {
        const activitiesInTs = activity.activitySpans.filter(
          (span) =>
            moment(new Date(span.endTime)).isAfter(ts.startDate) &&
            moment(new Date(span.startTime)).isBefore(ts.endDate) &&
            ALLOWED_DEVICES.includes(span.device),
        );
        if (activitiesInTs.length > 0) {
          minTime = Math.min(
            minTime,
            convertToSeconds(activitiesInTs[0].startTime),
          );
          maxTime = Math.max(
            maxTime,
            convertToSeconds(activitiesInTs[activitiesInTs.length - 1].endTime),
          );
        }
      });
    });

    minTime = Math.max(minTime, ts.startEpoch);
    maxTime = Math.min(maxTime, ts.endEpoch);

    return minTime > maxTime ? 0 : maxTime - minTime;
  };

  const deviceFreeTimeDuration = (
    activities: { name: string; activitySpans: PartialActivity[] }[],
    isFreeTime: (activity: PartialActivity) => boolean,
  ): Duration[] => {
    let activitiesInTs: PartialActivity[] = [];
    let normActivities: PartialActivity[] = [];
    let lastActivityBeforeTs: any = null;
    let firstActivityAfterTs: any = null;

    normActivities = activities.flatMap((item) => {
      return item.activitySpans.flatMap((span) => {
        if (ALLOWED_DEVICES.includes(span.device)) {
          return { ...span, name: item.name };
        }
        return [];
      });
    });
    normActivities.sort(
      (a, b) => convertToSeconds(a.startTime) - convertToSeconds(b.startTime),
    );

    activitiesInTs = normActivities.flatMap((span) => {
      if (
        moment(new Date(span.endTime)).isAfter(ts.startDate) &&
        moment(new Date(span.startTime)).isBefore(ts.endDate)
      ) {
        return span;
      }
      return [];
    });

    lastActivityBeforeTs = normActivities
      .filter(
        (span) =>
          span.endTime !== null &&
          convertToSeconds(span.endTime) < ts.startEpoch,
      )
      .slice(-1)[0];

    firstActivityAfterTs = normActivities.filter(
      (span) => convertToSeconds(span.startTime) > ts.endEpoch,
    )[0];

    if (activitiesInTs.length === 0) {
      return lastActivityBeforeTs === undefined ||
        firstActivityAfterTs === undefined
        ? []
        : [initDuration(ts.startEpoch, ts.endEpoch)];
    }

    const waitingDurations: Duration[] = [];

    const firstActivity = activitiesInTs[0];
    const firstActivityStartEpoch = convertToSeconds(firstActivity.startTime);

    // Add time between TS start & the first activity as free time
    if (
      lastActivityBeforeTs !== undefined &&
      firstActivityStartEpoch > ts.startEpoch
    ) {
      waitingDurations.push(
        initDuration(ts.startEpoch, firstActivityStartEpoch),
      );
    }

    const lastActivity = activitiesInTs.slice(-1)[0];
    const lastActivityEndEpoch = convertToSeconds(lastActivity.endTime);

    // Add time between the last activity & TS end as free time
    if (
      firstActivityAfterTs !== undefined &&
      lastActivityEndEpoch < ts.endEpoch
    ) {
      waitingDurations.push(initDuration(lastActivityEndEpoch, ts.endEpoch));
    }

    // Add free time activities & empty space between subsequent activities as free time
    activitiesInTs.forEach((activity, i) => {
      if (isFreeTime(activity)) {
        waitingDurations.push(
          initDuration(
            convertToSeconds(activity.startTime),
            convertToSeconds(activity.endTime),
          ),
        );
      }

      if (
        i + 1 < activitiesInTs.length &&
        convertToSeconds(activity.endTime) <
          convertToSeconds(activitiesInTs[i + 1].startTime)
      ) {
        waitingDurations.push(
          initDuration(
            convertToSeconds(activity.endTime),
            convertToSeconds(activitiesInTs[i + 1].startTime),
          ),
        );
      }
    });

    waitingDurations.sort((a, b) => a.start - b.start);

    return waitingDurations;
  };

  // 100% CORRECT
  const calculateOverlappingTimeForWeekOrDayView = (waitsPerDevice) => {
    const neverWaitedDevice = waitsPerDevice.find((item) => item.length === 0);
    if (neverWaitedDevice !== undefined) {
      return 0; // There is a device that never waited, so there is no free time.
    }
    let freeTimeMillis = 0;
    let prevSmallestEndTime = 0;
    const deviceIndexes = new Array(waitsPerDevice.length).fill(0);

    const shouldContinue = (deviceIdxs) => {
      let flag = true;
      for (let index = 0; index < waitsPerDevice.length; index++) {
        if (waitsPerDevice[index].length > deviceIdxs[index]) {
          flag = true;
        } else {
          flag = false;
        }
      }
      return flag;
    };

    while (shouldContinue(deviceIndexes)) {
      // Find the smallest end time and largest start time of all current waiting actions
      let largestStartTime = 0;
      let smallestEndTime = Number.MAX_SAFE_INTEGER;
      for (let i = 0; i < waitsPerDevice.length; i++) {
        const dur = waitsPerDevice[i][deviceIndexes[i]];
        if (dur) {
          largestStartTime = Math.max(largestStartTime, dur.start);
          smallestEndTime = Math.min(smallestEndTime, dur.end);
        }
      }

      // Don't allow counting the same period of time multiple times
      largestStartTime = Math.max(largestStartTime, prevSmallestEndTime);
      prevSmallestEndTime = smallestEndTime;
      // Add overlapping time if there is any between the current waiting actions
      if (smallestEndTime > largestStartTime) {
        freeTimeMillis += smallestEndTime - largestStartTime;
      }

      // Advance to the next waiting action for the action(s) with the smallest end time
      for (let i = 0; i < waitsPerDevice.length; i++) {
        if (
          waitsPerDevice[i][deviceIndexes[i]] &&
          waitsPerDevice[i][deviceIndexes[i]].end === smallestEndTime
        ) {
          deviceIndexes[i]++;
        }
      }
    }
    return freeTimeMillis;
  };
  function calculateOverlappingTime(waitsPerDevice) {
    let overlap = waitsPerDevice[0];
    for (let i = 1; i < waitsPerDevice.length; i++) {
      const tempOverlap: { start: number; end: number }[] = [];
      overlap.forEach((interval1) => {
        waitsPerDevice[i].forEach((interval2) => {
          const start = Math.max(interval1.start, interval2.start);
          const end = Math.min(interval1.end, interval2.end);
          if (start < end) {
            tempOverlap.push({ start, end });
          }
        });
      });
      overlap = tempOverlap;
    }

    let totalOverlapTime = 0;
    overlap.forEach((interval) => {
      totalOverlapTime += interval.end - interval.start;
    });
    return totalOverlapTime;
  }

  const waitingDurationsPerDevice: Duration[][] = [];
  const isMfrFreeTimeActivity = (activity: PartialActivity) => {
    if (
      activity.name === 'Waiting' ||
      ((activity.name === 'Driving route' ||
        activity.name ===
          'Driving route with dosing and feed height measurements' ||
        activity.name === 'Driving route with feed height measurements') &&
        activity.activity?.dosing?.length === 0)
    ) {
      return true;
    }
    return false;
  };
  if (mfr1Activities.length !== 0) {
    waitingDurationsPerDevice.push(
      deviceFreeTimeDuration(mfr1Activities, isMfrFreeTimeActivity),
    );
  }

  if (mfr2Activities.length !== 0) {
    waitingDurationsPerDevice.push(
      deviceFreeTimeDuration(mfr2Activities, isMfrFreeTimeActivity),
    );
  }

  if (fgActivities.length !== 0) {
    waitingDurationsPerDevice.push(
      deviceFreeTimeDuration(
        fgActivities,
        (activity: PartialActivity) =>
          activity.name === 'Waiting' || activity.name === 'Kitchen scan',
      ),
    );
  }
  return zoomLevel === MIN_ZOOM_LEVEL || zoomLevel === DAY_ZOOM_LEVEL
    ? calculateOverlappingTimeForWeekOrDayView(waitingDurationsPerDevice) /
        availableDataInTs()
    : calculateOverlappingTime(waitingDurationsPerDevice) / availableDataInTs();
};

export const getFormattedDuration = (
  zoomEndDate: Moment,
  zoomStartDate: Moment,
) => {
  const duration = moment.duration(zoomEndDate.diff(zoomStartDate));
  const durationAsMs = duration.asMilliseconds();
  const diff = moment.duration(
    durationAsMs === 604799000 ? durationAsMs + 1000 : durationAsMs,
  );
  const day = diff.days();
  const hours = diff.hours();
  const minutes = diff.minutes();
  const seconds = diff.seconds();
  const dayText = day >= 1 ? ` ${day} day${day > 1 ? 's' : ''}` : '';
  const hmsText =
    hours === 0 && minutes === 0 && seconds === 0
      ? ' '
      : ` ${hours}h ${minutes}m ${seconds}s `;
  return `(${dayText}${hmsText})`;
};
