/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import SideBarButton from './SideBarButton';

function RobotMenuButtons({ entityId, optionsArray }) {
  return (
    <>
      {optionsArray?.map((b) => {
        if (b.featureFlag === false) {
          return;
        }
        return (
          <SideBarButton
            key={entityId + b.menuTitle}
            iconClassName={b.iconClass}
            menuOptionTitle={b.menuTitle}
            appendixUrl={b.appendixUrl}
            entity={entityId}
            overviewParam={b.overviewParam}
          />
        );
      })}
    </>
  );
}

export default RobotMenuButtons;
