import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import type { Nullable } from '../common/types';
import type { RootState } from './rootReducer';
import { setVectorClickedItemType } from './vectorConfigurationSlice';
import { VectorClickedElementType } from '../components/Vector/types/vectorDataChartTypes';

export type AggregationSliceInitialState = {
  leftThumbCoordinates: Nullable<number>;
  rightThumbCoordinates: Nullable<number>;
  aggregationStartDate: Nullable<Date>;
  aggregationEndDate: Nullable<Date>;
};

export const initialState: AggregationSliceInitialState = {
  leftThumbCoordinates: null,
  rightThumbCoordinates: null,
  aggregationStartDate: null,
  aggregationEndDate: null,
};

export const aggregationSlice = createSlice({
  name: 'aggregation',
  initialState,
  reducers: {
    setAggregationCoordinates(
      state,
      action: PayloadAction<
        Nullable<{
          leftCoordinates?: number;
          rightCoordinates?: number;
          aggregationStartDate?: Date;
          aggregationEndDate?: Date;
        }>
      >,
    ) {
      state.leftThumbCoordinates = action.payload?.leftCoordinates ?? 0;
      state.rightThumbCoordinates = action.payload?.rightCoordinates ?? 0;
      state.aggregationStartDate = action.payload?.aggregationStartDate ?? null;
      state.aggregationEndDate = action.payload?.aggregationEndDate ?? null;
    },
  },
  extraReducers: (builder) =>
    builder.addCase(
      setVectorClickedItemType,
      (state, action: PayloadAction<VectorClickedElementType | null>) => {
        if (
          action.payload !== VectorClickedElementType.selectionSummation &&
          state.aggregationStartDate &&
          state.aggregationEndDate
        ) {
          state.aggregationStartDate = null;
          state.aggregationEndDate = null;
          state.leftThumbCoordinates = null;
          state.rightThumbCoordinates = null;
        }
      },
    ),
});

export const { setAggregationCoordinates } = aggregationSlice.actions;

export const aggregationSelectors = {
  selectAggregationCoordinates: (state: RootState) => {
    return {
      leftThumbCoordinates: state.aggregation.leftThumbCoordinates,
      rightThumbCoordinates: state.aggregation.rightThumbCoordinates,
    };
  },
};

export const selectAggregationDates = createSelector(
  [
    (state: RootState) => state.aggregation.aggregationStartDate,
    (state: RootState) => state.aggregation.aggregationEndDate,
  ],
  (aggregationStartDate, aggregationEndDate) => {
    if (!aggregationStartDate || !aggregationEndDate) return null;

    return {
      aggregationStartDate,
      aggregationEndDate,
    };
  },
);

export default aggregationSlice.reducer;
