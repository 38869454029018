import { getIsTestMode } from '../../common/components/CacheBust/testUtil';
import { REDIRECT } from './types';

/**
 * Create action which redirect to given page.
 * @param {string} destination - Path to page example: '/home'
 * @param {object} [state] - This field is optional. You can pass props here
 * @param {object} [search] - This field is optional. You can pass search props here
 */
export default function redirect(destination, state, search) {
  let destitantionCopy = destination;
  if (getIsTestMode()) {
    destitantionCopy += '?isTest=true';
  }
  return {
    type: REDIRECT,
    payload: {
      destination: destitantionCopy,
      search: search || '',
      state,
    },
  };
}
