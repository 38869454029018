import { put, take, call } from 'redux-saga/effects';
import { GET_CANBUS_TROUBLESHOOTS_REQUEST } from './types';
import {
  getCanbusTroubleshootError,
  getCanbusTroubleshootSuccess,
  currentCanbusTroubleshootScreen,
  getCanbusTroubleshootVersion,
} from './actions';
import { setGoogleAnalyticTracking } from '../googleAnalyticTracking/actions';
import { setStartLoading, setEndLoading } from '../loadManager/actions';
import { getLoadId } from '../loadManager/methods';

let currentLoadId = 0;

export default function* canbusTroubleshootSaga(api) {
  while (true) {
    const canbusTroubleshootAction = yield take(
      GET_CANBUS_TROUBLESHOOTS_REQUEST,
    );
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));
    const { domainName, tutorialFileName } = canbusTroubleshootAction.payload;
    yield call(getTroubleshoot, api, domainName, tutorialFileName);
  }
}

/** Create request to canbus troubleshoot
 * @param {String} domainName - Name of the selected domain (canbus)
 * @param {String} tutorialFileName - Name of the selected tutorial
 */
function* getTroubleshoot(api, domainName, tutorialFileName) {
  try {
    const canbusTroubleshootResponse = yield call(
      api.getTroubleshootData,
      domainName,
      tutorialFileName,
    );
    yield put(
      getCanbusTroubleshootSuccess({
        canbusTroubleshootResponse: canbusTroubleshootResponse.data.steps,
        tutorialFileName,
      }),
    );
    yield put(
      currentCanbusTroubleshootScreen(
        canbusTroubleshootResponse.data.steps[0].id,
      ),
    );
    yield put(
      getCanbusTroubleshootVersion({
        canbusTroubleshootResponseVersion:
          canbusTroubleshootResponse.data.version,
      }),
    );
    yield put(
      setGoogleAnalyticTracking(
        tutorialFileName,
        'Selected topic from Home view',
      ),
    );
    yield put(setEndLoading(currentLoadId));
  } catch (error) {
    yield put(getCanbusTroubleshootError(error));
    yield put(setEndLoading(currentLoadId));
  }
}
