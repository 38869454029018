import { useEffect } from 'react';

const useDisableGlobalPinchZoom = () => {
  useEffect(() => {
    const listener = (event) => {
      const { ctrlKey } = event;
      if (ctrlKey) {
        event.preventDefault();
      }
    };
    document.addEventListener('wheel', listener, { passive: false });
    return () => {
      document.removeEventListener('wheel', listener);
    };
  }, []);
};

export default useDisableGlobalPinchZoom;
