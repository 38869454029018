import { put, take, call } from 'redux-saga/effects';

import { REQUEST_GET_UPDATE_HISTORY_DATA_FOR_SYSTEM } from '../types/updateHistoryTypes';
import {
  setUpdateHistoryDataDictionary,
  setIsGetUpdateHistoryError,
} from '../../../reducers/updateHistorySlice';

export default function* updateHistorySagaForSystem(api) {
  while (true) {
    const requestUpdateHistoryDataForSystemAction = yield take(
      REQUEST_GET_UPDATE_HISTORY_DATA_FOR_SYSTEM,
    );
    const { farmId, startDate, endDate, systemId } =
      requestUpdateHistoryDataForSystemAction.payload;
    yield call(
      getUpdateHistoryDataForSystem,
      api,
      farmId,
      startDate,
      endDate,
      systemId,
    );
  }
}

/** Create request to get update history data for specific farm id, start date and end date
 * @param {string} farmId - farm id
 * @param {Date} startDate - Start date of the data range
 * @param {Date} endDate - Start date of the data range
 * @param {string} systemId - system Id
 */
function* getUpdateHistoryDataForSystem(
  api,
  farmId,
  startDate,
  endDate,
  systemId,
) {
  try {
    const updateHistoryData = yield call(
      api.getUpdateHistoryForFarm,
      farmId,
      startDate,
      endDate,
      systemId,
    );
    yield put(
      setUpdateHistoryDataDictionary({
        updateHistoryDataForSystem: updateHistoryData.data,
        systemId,
      }),
    );
  } catch (error) {
    yield put(setIsGetUpdateHistoryError(error));
  }
}
