export const GET_CANBUS_TROUBLESHOOTS_REQUEST =
  'GET_CANBUS_TROUBLESHOOTS_REQUEST';
export const GET_CANBUS_TROUBLESHOOTS_SUCCESS =
  'GET_CANBUS_TROUBLESHOOTS_SUCCESS';
export const GET_CANBUS_TROUBLESHOOTS_ERROR = 'GET_CANBUS_TROUBLESHOOTS_ERROR';
export const CURRENT_CANBUS_TROUBLESHOOTS_SCREEN =
  'CURRENT_CANBUS_TROUBLESHOOTS_SCREEN';
export const CLEAR_CANBUS_TROUBLESHOOTS_DATA =
  'CLEAR_CANBUS_TROUBLESHOOTS_DATA';
export const PREVIOUS_CANBUS_TROUBLESHOOT_SCREEN =
  'PREVIOUS_CANBUS_TROUBLESHOOT_SCREEN';
export const GET_CANBUS_TROUBLESHOOTS_VERSION =
  'GET_CANBUS_TROUBLESHOOTS_VERSION';
