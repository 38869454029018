import { put, take, call } from 'redux-saga/effects';
import { CREATE_BACKUP_ERROR_MESSAGE } from '../../common/constants';
import {
  createEntityBackupSettingsError,
  createEntityBackupSettingsRequest,
} from '../../reducers/entityBackupSettingsSlice';

export default function* createEntityBackupSettingsSaga(api) {
  while (true) {
    const crateEntityBackupSettingsAction = yield take(
      createEntityBackupSettingsRequest.type,
    );
    const { entityId } = crateEntityBackupSettingsAction.payload;
    yield call(createEntityBackupSettings, api, entityId);
  }
}

function* createEntityBackupSettings(api, entityId) {
  try {
    yield call(api.createEntityBackup, entityId);
  } catch (error) {
    const errorCode = error && error.toString().substr(-3);
    const errorStatusMessage =
      errorCode === '400'
        ? error.response.data.message
        : CREATE_BACKUP_ERROR_MESSAGE;

    yield put(createEntityBackupSettingsError({ errorStatusMessage }));
  }
}
