import axiosInstance from '../axiosInstance';
import {
  API_PREFIX_WITH_DOMAIN,
  TROUBLESHOOT_AND_FILE_URL_APPENDIX,
  TROUBLESHOOT_URL,
} from './config';

// const MOCK_TROUBLESHOOT = {
//   data: [
//     {
//       id: 0,
//       image: 'ACB_troubleshooting.png',
//       isLast: false,
//       options: [
//         { id: 1, description: 'Yes', nextStepId: 2 },
//         { id: 2, description: 'No', nextStepId: 1 },
//       ],
//       question: 'Disconnect CAN 0-1 from ACB and rescan. ACB still in error?',
//     },
//     {
//       id: 1,
//       image: 'ACB_troubleshooting.png',
//       isLast: true,
//       options: [],
//       question: 'Problem is cable between APSU or APSU itself.',
//     },
//     {
//       id: 2,
//       image: 'ACB_troubleshooting.png',
//       isLast: false,
//       options: [
//         { id: 1, description: 'Yes', nextStepId: 3 },
//         { id: 2, description: 'No', nextStepId: 4 },
//       ],
//       question:
//         'Shutdown entity and connect CAN 0-1 and disconnect CAN 0-2 from ACB and rescan. ACB still in error?',
//     },
//     {
//       id: 3,
//       image: 'ACB_troubleshooting.png',
//       isLast: true,
//       options: [],
//       question: 'Problem is ACB or cable between ACB and GIOB 5.',
//     },
//   ],
// };

// const MOCK_TUTORIAL_TROUBLESHOOT = {
//   data: [{ file: 'ACB_CAN_0.json', displayName: 'ACB CAN 0' }],
// };

export const api = {
  async getTroubleshootData(domainName, tutorialFileName) {
    // api/domains/{domainName}/troubleshoot?file={file}
    // API_PREFIX_WITH_DOMAIN, TROUBLESHOOT_URL_APPENDIX
    const getTroubleshootServiceUrl = `${API_PREFIX_WITH_DOMAIN}/${domainName}${TROUBLESHOOT_AND_FILE_URL_APPENDIX}${tutorialFileName}`;
    return axiosInstance.get(getTroubleshootServiceUrl);
    // return new Promise((resolve) => {
    //   resolve(MOCK_TROUBLESHOOT);
    // });
  },

  async getTroubleshootTutorialsData(domainName) {
    // GET api/domains/{domainName}/troubleshoot
    const getTroubleshootTutorialServiceUrl = `${API_PREFIX_WITH_DOMAIN}/${domainName}${TROUBLESHOOT_URL}`;
    return axiosInstance.get(getTroubleshootTutorialServiceUrl);
    // return new Promise((resolve) => {
    //   resolve(MOCK_TUTORIAL_TROUBLESHOOT);
    // });
  },
};

export default { api };
