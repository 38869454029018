import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FarmOptionsTypes } from '../components/TableFilter/types';
import { RootState } from './rootReducer';
import { Nullable } from '../common/types';

export const farmsOptionsInitialState: FarmOptionsTypes.InitialState = {
  selectedColumns: null,
};

export const farmsOptions = createSlice({
  name: 'farmsOptions',
  initialState: farmsOptionsInitialState,
  reducers: {
    addOrRemoveColumns: (
      state,
      action: PayloadAction<{
        key: string;
        columns: FarmOptionsTypes.Selection[];
      }>,
    ) => {
      const { key, columns } = action.payload;
      if (!state.selectedColumns) {
        state.selectedColumns = {};
      }

      state.selectedColumns[key] = columns;
    },
    resetFarmsOptions: () => farmsOptionsInitialState,
  },
});

export const { addOrRemoveColumns, resetFarmsOptions } = farmsOptions.actions;

// selectors
export const selectColumns = createSelector(
  (state: RootState) => state.farmsOptions.selectedColumns,
  (columns): Nullable<FarmOptionsTypes.Selection[]> => {
    return columns ? Object.values(columns).flat() : null;
  },
);

export const selectColumnsByFilterName = createSelector(
  (state: RootState) => state.farmsOptions.selectedColumns,
  (_: RootState, filterName: string) => filterName,
  (columns, filterName): Nullable<FarmOptionsTypes.Selection[]> => {
    if (!columns) {
      return null;
    }

    return columns[filterName];
  },
);

export default farmsOptions.reducer;
