import { put, take, call } from 'redux-saga/effects';
import { UPDATE_MILK_JAR_WEIGHT_REQUESTED } from '../types/dataChartTypes';
import {
  getMilkJarWeightDataSuccess,
  getMilkJarWeightDataError,
} from '../actions/dataChartActions';

export default function* milkJarSaga(api) {
  while (true) {
    // #region wait for the action that will initiate this whole saga
    const updateMilkJarWeightRequestedAction = yield take(
      UPDATE_MILK_JAR_WEIGHT_REQUESTED,
    );
    const { entityId, startDate, endDate } =
      updateMilkJarWeightRequestedAction.payload;

    yield call(getMilkJarWeightData, api, entityId, startDate, endDate);
  }
}

/** Create request to get chart data for milk jar weight
 * @param {string} entityId - entity id
 * @param {Date} startDate - Start date of the data range
 * @param {Date} endDate - Start date of the data range
 */
function* getMilkJarWeightData(api, entityId, startDate, endDate) {
  try {
    const chartData = yield call(
      api.getMilkJarWeightDetails,
      entityId,
      startDate,
      endDate,
    );
    yield put(getMilkJarWeightDataSuccess(chartData.data, startDate, endDate));
  } catch (error) {
    yield put(getMilkJarWeightDataError(error));
  }
}
