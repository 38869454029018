import axiosInstance from '../axiosInstance';
import { GET_UPDATES_DETAILS_URL } from './config';

export const api = {
  async getUpdates(id: string, fromDate: string, toDate: string) {
    return axiosInstance.get(
      `${GET_UPDATES_DETAILS_URL}${id}?fromDate=${fromDate}&toDate=${toDate}`,
    );
  },
};

export type UpdatesApiType = typeof api;
