import axiosInstance from '../axiosInstance';
import {
  GET_DOMAINS_FOR_ENTITY_SERVICE_URL,
  GET_COMPONENTS_DATA_FOR_ENTITY_AND_DOMAIN_SERVICE_URL,
  ENTITY_ID_URL_APPENDIX,
  DATE_TIME_URL_APPENDIX,
} from './config';

export const api = {
  async getDomainsForEntity(entityId) {
    const getDomainsForEntityUrl =
      GET_DOMAINS_FOR_ENTITY_SERVICE_URL + entityId;
    return axiosInstance.get(getDomainsForEntityUrl);
  },

  async getComponentsDataForEntityAndDomain(entityId, domainId, dateTimestamp) {
    let getComponentsDataForEntityAndDomainUrl =
      GET_COMPONENTS_DATA_FOR_ENTITY_AND_DOMAIN_SERVICE_URL +
      domainId +
      ENTITY_ID_URL_APPENDIX +
      entityId;

    if (dateTimestamp) {
      getComponentsDataForEntityAndDomainUrl +=
        DATE_TIME_URL_APPENDIX + dateTimestamp;
    }
    return axiosInstance.get(getComponentsDataForEntityAndDomainUrl);
  },
};

export type DomainsApiType = typeof api;
