// api/entities/info/?entityId={entityId}

import axiosInstance from '../axiosInstance';
import { GET_ENTITY_INFO_SERVICE_URL } from './config';

export const api = {
  async getEntityInfo(entityId) {
    const getEntityInfoUrl = GET_ENTITY_INFO_SERVICE_URL.replace(
      '{id}',
      entityId,
    );
    return axiosInstance.get(getEntityInfoUrl);
  },
};

export type GetEntityInfoApiType = typeof api;
