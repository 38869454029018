import {
  GET_DOMAINS_FOR_ENTITY_REQUEST_SUCCESS,
  GET_DOMAINS_FOR_ENTITY_REQUEST_ERROR,
} from '../components/DomainDetails/types';

/** Store states
 * @constant {Object} */
export const initialState = {
  domainsData: {},
  isDomainsError: false,
  errorMessage: '',
};

/** Domains Reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function domainsReducer(state = initialState, action = {}) {
  switch (action.type) {
    /** Action Creator - Set domains data to store if
     * API request passed successfully */
    case GET_DOMAINS_FOR_ENTITY_REQUEST_SUCCESS:
      return {
        ...state,
        domainsData: action.payload.domainsData,
        isDomainsError: false,
        errorMessage: '',
      };

    /** Action Creator - Set domains data to store if
     * API request passed successfully */
    case GET_DOMAINS_FOR_ENTITY_REQUEST_ERROR:
      return {
        ...state,
        domainsData: {},
        isDomainsError: true,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
}

export default domainsReducer;
