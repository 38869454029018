import ReactGA from 'react-ga4';

/**
 * Google Analytics Tracking Event
 * @param {string} categoryName - Category name of the selected event / Required
 * @param {string} eventName - Name of the Event / Required
 * @param {string} [eventLabel] - Optional event description
 */

const googleAnalyticsEventTracker = (categoryName, eventName, eventLabel) => {
  ReactGA.event({
    category: categoryName, // Required
    action: eventName, // Required
    label: eventLabel,
  });
};

export default googleAnalyticsEventTracker;
