import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';
import CriticalAlarmsAndEventsTypes from './types/vectorCriticalAlarmsAndEventsTypes';

export const vectorCriticalAlarmsAndEventsInitialState: CriticalAlarmsAndEventsTypes.VectorCriticalAlarmsAndEventsInitialState =
  {
    criticalAlarmsProperties: {},
    vectorCriticalAlarmSummationOnZoomChange: {},
    eventSummationOnZoomChange: {},
    eventsInfo: null,
  };

const vectorCriticalAlarmsAndEventsSlice = createSlice({
  name: 'vectorCriticalAlarmsAndEvents',
  initialState: vectorCriticalAlarmsAndEventsInitialState,
  reducers: {
    setVectorCriticalAlarmsProperties: (
      state,
      action: PayloadAction<CriticalAlarmsAndEventsTypes.CriticalAlarmProperties>,
    ) => {
      state.criticalAlarmsProperties = action.payload;
    },
    setVectorCriticalAlarmSummationOnZoomChange: (
      state,
      action: PayloadAction<CriticalAlarmsAndEventsTypes.CriticalAlarmProperties>,
    ) => {
      state.vectorCriticalAlarmSummationOnZoomChange = action.payload;
    },
    setVectorEventSummationOnZoomChange: (
      state,
      { payload }: PayloadAction<CriticalAlarmsAndEventsTypes.EventsType>,
    ) => {
      state.eventSummationOnZoomChange = payload;
    },
    setVectorEventsInfo: (
      state,
      { payload }: PayloadAction<CriticalAlarmsAndEventsTypes.EventsInfo>,
    ) => {
      state.eventsInfo = payload;
    },
  },
});

export const {
  setVectorCriticalAlarmsProperties,
  setVectorCriticalAlarmSummationOnZoomChange,
  setVectorEventSummationOnZoomChange,
  setVectorEventsInfo,
} = vectorCriticalAlarmsAndEventsSlice.actions;

export const selectVectorCriticalAlarmsProperties = (state: RootState) =>
  state.vectorCriticalAlarmsAndEvents.criticalAlarmsProperties;
export const selectVectorCriticalAlarmSummationOnZoomChange = (
  state: RootState,
) =>
  state.vectorCriticalAlarmsAndEvents.vectorCriticalAlarmSummationOnZoomChange;
export const selectVectorEventSummationOnZoomChange = (state: RootState) =>
  state.vectorCriticalAlarmsAndEvents.eventSummationOnZoomChange;
export const selectVectorEventsInfo = (state: RootState) =>
  state.vectorCriticalAlarmsAndEvents.eventsInfo;

export default vectorCriticalAlarmsAndEventsSlice.reducer;
