import { put, take, call } from 'redux-saga/effects';
import { GET_EVENT_MENU_LEVELS_REQUEST } from './types';
import { getEventMenuLevelsError, getEventMenuLevelsSuccess } from './actions';
import { setStartLoading, setEndLoading } from '../loadManager/actions';
import { getLoadId } from '../loadManager/methods';

let currentLoadId = 0;

export default function* eventsLevelMenuOptionsSaga(api) {
  while (true) {
    yield take(GET_EVENT_MENU_LEVELS_REQUEST);
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));
    yield call(getEventsLevelMenuOptions, api);
  }
}

function* getEventsLevelMenuOptions(api) {
  try {
    const eventMenuLevelData = yield call(api.getEventsLevelOptions);
    yield put(
      getEventMenuLevelsSuccess({
        eventMenuLevelData: eventMenuLevelData.data,
      }),
    );
    yield put(setEndLoading(currentLoadId));
  } catch (error) {
    yield put(getEventMenuLevelsError(error));
    yield put(setEndLoading(currentLoadId));
  }
}
