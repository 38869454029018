import {
  GET_EVENT_MENU_LEVELS_REQUEST,
  GET_EVENT_MENU_LEVELS_REQUEST_SUCCESS,
  GET_EVENT_MENU_LEVELS_REQUEST_ERROR,
} from './types';

/** Create action which will get all information events menu levels .
 * @param {String} accessToken - the access token
 * @return {Object} Return type and info for event menu level
 */
export function getEventMenuLevels(accessToken) {
  return {
    type: GET_EVENT_MENU_LEVELS_REQUEST,
    payload: {
      accessToken,
    },
  };
}

/** Create action which to allow to put menu levels in the redux store
 * @param {Object} menuLevels - The menu levels data
 * @return {Object} Return name and order of menu levels
 */
export function getEventMenuLevelsSuccess(menuLevels) {
  return {
    type: GET_EVENT_MENU_LEVELS_REQUEST_SUCCESS,
    payload: { ...menuLevels },
  };
}

/** Create action which get errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function getEventMenuLevelsError(errorMessage) {
  return {
    type: GET_EVENT_MENU_LEVELS_REQUEST_ERROR,
    payload: errorMessage,
  };
}
