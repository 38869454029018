import {
  GET_ENTITY_KPI_DETAILS_REQUEST,
  GET_ENTITY_KPI_DETAILS_REQUEST_SUCCESS,
  GET_ENTITY_KPI_DETAILS_REQUEST_ERROR,
  CLEAR_ENTITY_KPI_DETAILS,
} from './types';

/** Create action which call  api.
 * @param {Object} entityId - Id of the entity
 * @return {Object} Return type and selectedEntity
 */
export function getEntityKpiDetails(entityId) {
  return {
    type: GET_ENTITY_KPI_DETAILS_REQUEST,
    payload: { entityId },
  };
}

/** Create action which get provided entityKpiDetails
 * {current status, weekly average, chart details } and set them to redux store.
 * @param {Object} entityKpiDetails - Will be object with (current status, weekly average, chart details)
 * @return {Object} Return type and farmDetails
 */
export function getEntityKpiDetailsSuccess(entityKpiDetails) {
  return {
    type: GET_ENTITY_KPI_DETAILS_REQUEST_SUCCESS,
    payload: { entityKpiDetails },
  };
}

/** Create action which get errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function getEntityKpiDetailsError(errorMessage) {
  return {
    type: GET_ENTITY_KPI_DETAILS_REQUEST_ERROR,
    payload: errorMessage,
  };
}

/** Create action which clears entity KPI details.
 */
export function clearEntityKpiDetails() {
  return {
    type: CLEAR_ENTITY_KPI_DETAILS,
    payload: {},
  };
}
