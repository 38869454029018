import axiosInstance from '../axiosInstance';
import { GET_USER_ROLES } from './config';

export const MOCK_GET_USER_ROLES_RESPONSE = {
  data: ['Engineer'],
};

export const api = {
  async getUserRoles() {
    return axiosInstance.get(GET_USER_ROLES);
  },
};

export type GetUserRolesApi = typeof api;
