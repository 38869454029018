import { put, take, call } from 'redux-saga/effects';
import { GET_EVENT_DROPDOWN_LEVELS_REQUEST } from './types';

import { setStartLoading, setEndLoading } from '../loadManager/actions';
import { getLoadId } from '../loadManager/methods';
import {
  getEventDropdownLevelError,
  getEventDropdownLevelSuccess,
} from '../../../reducers/eventsLevelsSlice';

let currentLoadId = 0;

export default function* eventsDropdownLevelsSaga(api) {
  while (true) {
    yield take(GET_EVENT_DROPDOWN_LEVELS_REQUEST);
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));
    yield call(getEventsDropdownLevels, api);
  }
}

function* getEventsDropdownLevels(api) {
  try {
    const eventDropdownLevelData = yield call(api.getEventsDropdownLevels);
    yield put(
      getEventDropdownLevelSuccess({
        eventDropdownLevelData: eventDropdownLevelData.data,
      }),
    );
    yield put(setEndLoading(currentLoadId));
  } catch (error) {
    yield put(getEventDropdownLevelError(error));
    yield put(setEndLoading(currentLoadId));
  }
}
