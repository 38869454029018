import { put, take, call } from 'redux-saga/effects';
import { UPDATE_LANGUAGE_REQUEST } from './types';
import { updateLanguageError, setPreferredLanguage } from './actions';
import {
  setStartLoading,
  setEndLoading,
} from '../../redux/loadManager/actions';
import { getLoadId } from '../../redux/loadManager/methods';

let currentLoadId = 0;

export default function* updateLanguageSaga(api) {
  while (true) {
    const updateLanguageAction = yield take(UPDATE_LANGUAGE_REQUEST);
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));
    const { accessToken, languageValue } = updateLanguageAction.payload;
    yield call(updateLanguageRequest, api, accessToken, languageValue);
  }
}

/** Create request to update preferred language
 * @param {string} accessToken - User access token
 * @param {string} updatedLanguagePreferences - language preference
 */
function* updateLanguageRequest(api, accessToken, languageValue) {
  try {
    yield call(api.updateLelyPreferredLanguage, languageValue);
    yield put(setPreferredLanguage(languageValue));
    yield put(setEndLoading(currentLoadId));
  } catch (error) {
    yield put(updateLanguageError(error));
    yield put(setEndLoading(currentLoadId));
  }
}
