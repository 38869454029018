import { createSlice } from '@reduxjs/toolkit';
import {
  System,
  Update,
  UpdateHistoryState,
} from '../components/UpdatesHistory/types/updateHistoryTypes';

export const initialState: UpdateHistoryState = {
  updateHistoryData: null,
  updatesDataDictionary: {},
  isGetUpdateHistoryError: false,
  errorMessage: '',
};

const updateHistory = createSlice({
  name: 'updateHistory',
  initialState,
  reducers: {
    setUpdateHistoryData(state, action) {
      state.updateHistoryData = action.payload.updateHistoryData;
      state.updatesDataDictionary = {};
      state.isGetUpdateHistoryError = false;
      state.errorMessage = '';
    },
    setUpdateHistoryDataDictionary(state, action) {
      const responseData = action.payload.updateHistoryDataForSystem;
      const systemIdForWhichUpdatesDataWasReceived = action.payload.systemId;

      responseData?.systems?.forEach((currentSystemData: System) => {
        if (
          currentSystemData.systemId === systemIdForWhichUpdatesDataWasReceived
        ) {
          if (currentSystemData.type !== 'VectorSystem') {
            Object.values(currentSystemData.updates).forEach((updates) => {
              updates.forEach((update) => {
                const isDowngrade = detectDowngrade(update);
                update.isDowngrade = isDowngrade;
              });
            });
          }

          state.updatesDataDictionary[systemIdForWhichUpdatesDataWasReceived] =
            currentSystemData;
        }
      });
    },
    setIsGetUpdateHistoryError(state, action) {
      state.updateHistoryData = null;
      state.updatesDataDictionary = {};
      state.isGetUpdateHistoryError = true;
      state.errorMessage = action.payload;
    },
    resetUpdateHistoryState: () => initialState,
  },
});

export const {
  setUpdateHistoryData,
  setUpdateHistoryDataDictionary,
  setIsGetUpdateHistoryError,
  resetUpdateHistoryState,
} = updateHistory.actions;

export const detectDowngrade = (update: Update) => {
  const oldVersion = update.oldVersion;
  const newVersion = update.newVersion;
  const unknownVersions = ['???', 'N/A'];
  if (
    unknownVersions.includes(oldVersion) ||
    unknownVersions.includes(newVersion)
  ) {
    update.isDowngrade = false;
    return;
  }
  const oldVersionSplit = oldVersion.split('-');
  const newVersionSplit = newVersion.split('-');
  const oldVersionMajor = oldVersionSplit[1].split('.')[0];
  const oldVersionMinor = oldVersionSplit[1].split('.')[1];
  const oldVersionBuild = oldVersionSplit[1].split('.')[2];
  const newVersionMajor = newVersionSplit[1].split('.')[0];
  const newVersionMinor = newVersionSplit[1].split('.')[1];
  const newVersionBuild = newVersionSplit[1].split('.')[2];
  const isDowngrade =
    newVersionMajor < oldVersionMajor ||
    (newVersionMajor === oldVersionMajor &&
      newVersionMinor < oldVersionMinor) ||
    (newVersionMajor === oldVersionMajor &&
      newVersionMinor === oldVersionMinor &&
      newVersionBuild < oldVersionBuild);
  return isDowngrade;
};

export default updateHistory.reducer;
