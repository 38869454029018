import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../reducers/rootReducer';

/** Hook listens for redirect that could be initiated through saga generator function */
const useRedirectListener = () => {
  const { redirect } = useAppSelector((state) => state.redirect);
  const navigate = useNavigate();

  useEffect(() => {
    if (redirect) {
      navigate(redirect.destination + redirect.search, {
        state: redirect.state,
        replace: true,
      });
    }
  }, [redirect]);
};

export default useRedirectListener;
