import { TableName } from '../components/TableFilter/types';

export namespace TableTypes {
  export type TableRow = {
    key: string;
    title: string;
    classNames?: string;
  };

  export type GenericTableRow<T> = {
    key: keyof T;
    title: string;
    classNames?: string;
  };

  export type SortingObject = {
    key: string;
    direction: 'asc' | 'desc';
  };
}

export type Nullable<T> = T | null;

export type CaretDirection = 'forwards' | 'backwards';

export type CaretCustomEvent = CustomEvent<{
  direction: CaretDirection;
}>;

export type AwaitedApiResultType<T extends (...args: any) => any> = Awaited<
  ReturnType<T>
>;

export const FGG = 'Feed grabber grabs';
export const LoadingPerformance = 'Loading Performance';
export const DispenseWeight = 'Dispense weight';
export const DosingSpeed = 'Dosing speed';

// other common types
export const RESET_STORAGE = 'RESET_STORAGE';
export const RESET_STORAGE_WITHOUT_USER_SESSION =
  'RESET_STORAGE_WITHOUT_USER_SESSION';

export enum Robots {
  Vector = 'Vector robot',
  Astronaut = 'Astronaut robot',
}

export interface IGenericTable {
  searchValue?: string;
  prevSearchArr?: string[] | null;
  filters: Record<string, string[]>;
  sortingObject: TableTypes.SortingObject;
}

export type PerformanceIndicatorsStorage = {
  selectedKPI: string;
  expandedSectors: string[];
};

export type LocalStorageGlobalObject = {
  [K in AggregationTypes]: string;
} & {
  farmsListConfig: IGenericTable;
  astronautSettingsConfig: IGenericTable;
  amfSettingsConfig: Omit<IGenericTable, 'searchValue'>;
  fgGrabbsAggregation: {
    sortingObject: TableTypes.SortingObject;
  };
  feedHeightAggragation: {
    sortingObject: TableTypes.SortingObject;
  };
  loadResultTable: {
    sortingObject: TableTypes.SortingObject;
  };
  dispenseWeightAggregation: {
    sortingObject: TableTypes.SortingObject;
  };
  activityResultTable: {
    sortingObject: TableTypes.SortingObject;
  };
  backup: {
    sortingObject: TableTypes.SortingObject;
  };
  vectorConfig: {
    offEvents: string[];
  };
  adeConfig: {
    grids1: boolean;
    dots1: boolean;
    grids2: boolean;
    dots2: boolean;
    legend: boolean;
  };
  performanceIndicators: {
    selectedKPI: string;
  };
  updateHistory: {
    expandedDropdown: boolean;
  };
};

export type AggregationTypes = 'Height' | 'Dosing speed' | 'Accuracy';

export type LocalStorageAcceptableKeys = keyof LocalStorageGlobalObject;

export type FiltersByTableType = Record<TableName, string[]>;

export type LabelValuePairType = { label: string; value: string };

export type GetArrayType<T> = T extends (infer U)[] ? U : never;
