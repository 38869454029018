import {
  GET_CANBUS_TROUBLESHOOTS_TUTORIAL_REQUEST,
  GET_CANBUS_TROUBLESHOOTS_TUTORIAL_SUCCESS,
  GET_CANBUS_TROUBLESHOOTS_TUTORIAL_ERROR,
} from './types';

/** Create action which will get all information for canbus troubleshoot tutorial.
 * @param {String} accessToken - the access token
 * @param {String} domainName - Name of the selected domain (canbus)
 * @return {Object} Return type and info for event dropdown level
 */
export function getCanbusTroubleshootTutorial(accessToken, domainName) {
  return {
    type: GET_CANBUS_TROUBLESHOOTS_TUTORIAL_REQUEST,
    payload: {
      accessToken,
      domainName,
    },
  };
}

/** Create action which to allow to put canbus troubleshoot tutorial details in the redux store
 * @param {Object} troubleshootTutorialDetails - Canbus troubleshoot data
 * @return {Object} Return name and order of dropdown levels
 */
export function getCanbusTroubleshootTutorialSuccess(
  troubleshootTutorialDetails,
) {
  return {
    type: GET_CANBUS_TROUBLESHOOTS_TUTORIAL_SUCCESS,
    payload: { ...troubleshootTutorialDetails },
  };
}

/** Create action which get errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function getCanbusTroubleshootTutorialError(errorMessage) {
  return {
    type: GET_CANBUS_TROUBLESHOOTS_TUTORIAL_ERROR,
    payload: errorMessage,
  };
}
