import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';

type VerticalLineInitialState = {
  left: number;
};

export const initialState: VerticalLineInitialState = {
  left: 0,
};

export const verticalLineSlice = createSlice({
  name: 'verticalLine',
  initialState,
  reducers: {
    setVerticalLineLeft(state, action: PayloadAction<number>) {
      state.left = action.payload;
    },
  },
});

export const { setVerticalLineLeft } = verticalLineSlice.actions;

export const verticalLineSelectors = {
  selectVerticalLineParameters: (state: RootState) => state.verticalLine.left,
};

export default verticalLineSlice.reducer;
