// #region important constants and configurations
export const IS_ACTIVE_CSS_CLASSNAME = ' is-active';
export const DEFAULT_X_TIER_WIDTH_IN_PX = 100;
export const MIN_ZOOM_LEVEL = 1;
export const PENULTIMATE_ZOOM_LEVEL = 2000;
export const MAX_ZOOM_LEVEL = 4000;
export const DAY_ZOOM_LEVEL = 7;
export const FIRST_DYNAMIC_LEVEL = 358;
export const FIRST_LEVEL_WITH_DELAY = 24;
export const NEXT_DYNAMIC_CANVAS_LOAD_THRESHOLD = 3;
export const AVAILABLE_ZOOM_LEVELS = [
  MIN_ZOOM_LEVEL,
  1.5,
  2,
  4,
  DAY_ZOOM_LEVEL,
  12,
  16,
  FIRST_LEVEL_WITH_DELAY,
  40,
  64,
  104,
  164,
  230,
  FIRST_DYNAMIC_LEVEL,
  480,
  650,
  1000,
  2200,
  MAX_ZOOM_LEVEL,
];
export const LAST_STATIC_ZOOM_LEVEL =
  AVAILABLE_ZOOM_LEVELS[AVAILABLE_ZOOM_LEVELS.indexOf(FIRST_DYNAMIC_LEVEL) - 1];
export const ZOOM_INCREMENT = 4;
export const IS_CURRENT_BROWSER_FIREFOX =
  navigator.userAgent.indexOf('Firefox') > 0;

export const timelineHeight = 47;
export const separationLineWidthOffset = 35;

export const lineChartSVGConstants = {
  horizontalOffset: 70,
  lineChartRightOffset: 15,
  widthOffset: 60,
  unitLabelHeight: 12,
  aggregationThumbWidth: 16,
};

export const STATE_ARROWS_MIN_LEVEL = FIRST_LEVEL_WITH_DELAY;
