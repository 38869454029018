import { put, take, call } from 'redux-saga/effects';
import { GET_ALARMS_DROPDOWN_LEVELS_REQUEST } from './types';
import {
  getAlarmDropdownLevelsError,
  getAlarmDropdownLevelsSuccess,
} from './actions';
import { setStartLoading, setEndLoading } from '../loadManager/actions';
import { getLoadId } from '../loadManager/methods';

let currentLoadId = 0;

export default function* alarmsDropdownLevelsSaga(api) {
  while (true) {
    yield take(GET_ALARMS_DROPDOWN_LEVELS_REQUEST);
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));
    yield call(getAlarmsDropdownLevels, api);
  }
}

function* getAlarmsDropdownLevels(api) {
  try {
    const alarmDropdownLevelData = yield call(api.getAlarmsDropdownLevels);
    yield put(
      getAlarmDropdownLevelsSuccess({
        alarmDropdownLevelData: alarmDropdownLevelData.data,
      }),
    );
    yield put(setEndLoading(currentLoadId));
  } catch (error) {
    yield put(getAlarmDropdownLevelsError(error));
    yield put(setEndLoading(currentLoadId));
  }
}
