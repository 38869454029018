import {
  GET_COMPONENTS_FOR_DOMAIN_AND_ENTITY_REQUEST,
  GET_COMPONENTS_FOR_DOMAIN_AND_ENTITY_REQUEST_ERROR,
  GET_COMPONENTS_FOR_DOMAIN_AND_ENTITY_REQUEST_SUCCESS,
} from './types';

/** Create action to get all components for selected domain.
 * @param {Object} selectedDomainId - the id of the domain of the entity for which we want to get the data
 * @param {Object} selectedEntityId - the id of the entity whose domain we want to query
 * @param {Object} [date] - the moment in time for which we want to get component data
 * @return {Object} Return type and selectedentity
 */
export function getComponentsForDomainAndEntity(
  selectedEntityId,
  selectedDomainId,
  date,
) {
  return {
    type: GET_COMPONENTS_FOR_DOMAIN_AND_ENTITY_REQUEST,
    payload: {
      entityId: selectedEntityId,
      domainId: selectedDomainId,
      date,
    },
  };
}

/** Create action which to allow to put components data for a specific domain in the redux store
 * @param {Object} componentsData - the data for the components in the selected domain (and entity)
 * @return {Object} Return type and components data
 */
export function getComponentsForDomainAndEntitySuccess(componentsData) {
  return {
    type: GET_COMPONENTS_FOR_DOMAIN_AND_ENTITY_REQUEST_SUCCESS,
    payload: { ...componentsData },
  };
}

/** Create action which get errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function getComponentsForDomainAndEntityError(errorMessage) {
  return {
    type: GET_COMPONENTS_FOR_DOMAIN_AND_ENTITY_REQUEST_ERROR,
    payload: errorMessage,
  };
}
