import {
  UPDATE_LANGUAGE_REQUEST_ERROR,
  SET_PREFERRED_LANGUAGE,
  GET_LANGUAGES_REQUEST_SUCCESS,
  GET_LANGUAGES_REQUEST_ERROR,
} from '../common/components/Translation/types';

/** Store states
 * @constant {Object} */
export const initialState = {
  preferredLanguage: 'en',
  errorMessage: '',
  availableLanguages: [],
};

/** Updated language Reducer.
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function translationReducer(state = initialState, action = {}) {
  switch (action.type) {
    /** Action Creator */
    case GET_LANGUAGES_REQUEST_SUCCESS:
      return {
        ...state,
        availableLanguages: action.payload,
      };
    /** Action Creator */
    case GET_LANGUAGES_REQUEST_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };

    /** Action Creator set preferred language */
    case SET_PREFERRED_LANGUAGE:
      return {
        ...state,
        preferredLanguage: action.payload.language,
      };

    /** Action Creator - Show error message if
     * request to API failed */
    case UPDATE_LANGUAGE_REQUEST_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
}

export default translationReducer;
