import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';

type DictionaryByChartName = Record<string, string[]>;

export type ColorDictionaryInitialState = {
  colorDictionaryByChartName: DictionaryByChartName | {};
};

export const initialState: ColorDictionaryInitialState = {
  colorDictionaryByChartName: {},
};

// createSlice
export const colorDictionarySlice = createSlice({
  name: 'colorDictionary',
  initialState,
  reducers: {
    setDictionaryByChartName: (
      state,
      {
        payload,
      }: PayloadAction<{ chartName: string; keys: Record<string, string> }>,
    ) => {
      state.colorDictionaryByChartName[payload.chartName] = payload.keys;
    },
    resetColorDictionary: () => initialState,
  },
});

export const { resetColorDictionary, setDictionaryByChartName } =
  colorDictionarySlice.actions;

// selectors
export const colorDictionarySelectors = {
  selectColorDictionary: (state: RootState) =>
    state.colorDictionary.colorDictionaryByChartName,
};

export const selectFeedColorDictionary = createSelector(
  colorDictionarySelectors.selectColorDictionary,
  (dictionary) => dictionary['Accuracy'],
);

export default colorDictionarySlice.reducer;
