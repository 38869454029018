export const API_PREFIX =
  window.REACT_APP_API_URL ||
  `${window.location.protocol}//${window.location.hostname}/api`;
export const ACCESS_TOKEN_EXPIRATION_PERIOD_IN_MINUTES = 1;
export const MINUTES_BEFORE_ACCESS_TOKEN_EXPIRATION_TO_REFRESH = 0.3; // exactly how many minutes before access token expiration period to attempt to refresh the access token
export const USER_MAX_IDLE_TIME_PERIOD_IN_MINUTES =
  ACCESS_TOKEN_EXPIRATION_PERIOD_IN_MINUTES -
  MINUTES_BEFORE_ACCESS_TOKEN_EXPIRATION_TO_REFRESH;
export const WAIT_ACCESS_TOKEN_RENEW_IN_MS_IMMEDIATELY_AFTER_REFRESH =
  MINUTES_BEFORE_ACCESS_TOKEN_EXPIRATION_TO_REFRESH * 60 * 1000;
export const LOGIN_SERVICE_URL = '/users/login';
export const REFRESH_ACCESS_TOKEN_SERVICE_URL = '/users/refresh';
export const LOGOUT_SERVICE_URL = '/users/logout';
export const GET_FARMS_SERVICE_URL = '/farms';
export const GET_UPDATES_DETAILS_URL = `${API_PREFIX}/AstronautUpdates/`;
export const GET_FARM_ENTITIES_SERVICE_URL = `${API_PREFIX}/farms/`;
export const GET_LOGS_FOR_ENTITY_AND_HOUR_SERVICE_URL = `${API_PREFIX}/logs?entityId=`;
export const GET_LOG_NODES_URL = `${API_PREFIX}/logs/fileNames?entityId=`;
export const DOWNLOAD_LOGS_URL = `${API_PREFIX}/logs/download?entityId=`;
export const GET_LOG_CONTENT_URL = `${API_PREFIX}/logs/fileContent?entityId=`;
export const ENTITY_KPIS_SERVICE_URL = `${API_PREFIX}/Overview/kpi/entity/`;
export const FILTER_TIMELINE_START_DATE = '?fromDate=';
export const FROM_DATE_URL_APPENDIX = '&fromDate=';
export const TO_DATE_URL_APPENDIX = '&toDate=';
export const FILTER_EVENT_OFFSET = '&offset=';
export const FILTER_EVENT_LIMIT = '&limit=';
export const EVENTS_TYPE_ORDER = '&eventsTypeOrder=';
export const EVENT_ID = '&eventId=';
export const FILTER_EVENT_BY_LEVEL = '&levels=';
export const FILTER_INDICATIONS_BY_LEVEL = '&level=';
export const FILTER_EVENT_BY_TYPE = '&levels=';
export const COMPARE_SETTINGS_BY_ENTITY_ID = '&EntityIdToCompare=';
export const GET_ALARMS_FOR_ENTITY_OVERVIEW_SERVICE_URL = `${API_PREFIX}/Overview/alarmsStats/entity/`;
export const GET_ALARMS_FOR_ENTITY_OVERVIEW_TO_DATE = '?toDate=';
export const GET_INDICATIONS_FOR_ENTITY_SERVICE_URL = `${API_PREFIX}/events/indicators?entityId=`;
export const GET_EVENTS_FOR_ENTITY_SERVICE_URL = `${API_PREFIX}/events?entityId=`;
export const GET_EVENTS_CONTEXT_URL = `${API_PREFIX}/events/context?entityId=`;
export const GET_ENTITY_OVERVIEW_SERVICE_URL = `${API_PREFIX}/entities/{id}`;
export const GET_DOMAINS_FOR_ENTITY_SERVICE_URL = `${API_PREFIX}/domains/?entityId=`;
export const GET_COMPONENTS_DATA_FOR_ENTITY_AND_DOMAIN_SERVICE_URL = `${API_PREFIX}/components/?domainId=`;
export const GET_ENTITY_INFO_SERVICE_URL = `${API_PREFIX}/entities/{id}/info`;
export const ENTITY_ID_URL_APPENDIX = '&entityId=';
export const DATE_TIME_URL_APPENDIX = '&dateTime=';
export const LOG_FILENAME_APPENDIX = '&logFileName=';
export const GET_ENTITY_SETTINGS = `${API_PREFIX}/settings/?entityId=`;
export const GET_SETTINGS_OPTIONS = `${API_PREFIX}/settings/options/?entityId=`;
export const GET_ENTITY_BACKUPS = `${API_PREFIX}/settings/backupsAndRestorations/?entityId=`;
export const CREATE_ENTITY_BACKUPS = `${API_PREFIX}/settings/backupsAndRestorations/createBackup`;
export const LISTEN_FOR_CREATION_ENTITY_BACKUPS = `${API_PREFIX}/astronautbackupshub`;
export const GET_BACKUP_PROGRESS = `${API_PREFIX}/settings/backupsAndRestorations/progress`;
export const RESTORE_ENTITY_BACKUPS = `${API_PREFIX}/settings/backupsAndRestorations/restoreBackup`;
export const LISTEN_FOR_RESTORE_ENTITY_BACKUPS = `${API_PREFIX}/settings/backups/restore/progress/?entityId=`;
export const UPDATE_ENTITY_BACKUP_DESCRIPTION = `${API_PREFIX}/settings/backupsAndRestorations/changeDescription`;
export const UPDATE_ENTITY_NAME = `${API_PREFIX}/entities/rename`;
export const ALARMS_DROPDOWN_LEVELS = `${API_PREFIX}/alarms/levels`;
export const EVENTS_DROPDOWN_LEVELS = `${API_PREFIX}/events/levels`;
export const EVENTS_DROPDOWN_TYPES = `${API_PREFIX}/events/types`;
export const SELECTED_EVENT_DETAILS = `${API_PREFIX}/events/details?entityId={entityId}&eventId={eventId}`;
export const API_PREFIX_WITH_DOMAIN = `${API_PREFIX}/domains`;
export const TROUBLESHOOT_AND_FILE_URL_APPENDIX = '/troubleshoot/file?file=';
export const TROUBLESHOOT_URL = '/troubleshoot';
export const GET_GRAPH_SERVICE_URL = `${API_PREFIX}/graphs/?graphName=`;
export const GET_DATA_CHART_SERVICE_URL = `${API_PREFIX}/datavisualization/`;
export const GET_LINKED_ASTRONAUT_ENTITY_ID_APPENDIX =
  '&linkedAstronaut.entityId=';
export const GET_LINKED_ASTRONAUT_GRAPH_NAME_APPENDIX =
  'linkedAstronaut.graphNames=';
export const GET_DATA_FOR_CHARTS_SERVICE_URL = `${API_PREFIX}/datavisualization/config/?entityId=`;
export const DATA_KEY_URL_APPENDIX = 'graphNames=';
export const GET_ENTITY_STATE = `${API_PREFIX}/entities/{id}/state`;
export const CANBUS_SIGNALR_URL = `${API_PREFIX}/realtimehub`;
export const RESTORE_SIGNALR_URL = `${API_PREFIX}/devicesettingshub`;
export const GET_VECTOR_DATA_CHART_SERVICE_URL = `${API_PREFIX}/VectorDataExplorer?entityId=`;
export const GET_VECTOR_CONFIGURATION_SERVICE_URL = `${API_PREFIX}/VectorDataExplorer/config?entityId=`;
export const USERNAME_URL_KEY = 'username';
// Feature Flag
export const GET_FEATURE_FLAG_DETAILS_URL = `${API_PREFIX}/FeatureFlags/`;
export const GET_LANGUAGES = `${API_PREFIX}/usersettings/languages`;
export const UPDATE_LANGUAGE = `${API_PREFIX}/usersettings/language`;
export const GET_USER_ROLES = `${API_PREFIX}/users/roles`;
export const GET_DATA_FOR_CHARTS_EVENTS_SERVICE_URL = `${API_PREFIX}/datavisualization/events?&entityId=`;
export const GET_EVENTS_LEVEL_MENU_OPTIONS = `${API_PREFIX}/datavisualization/menuoptions`;
export const GET_COMPOSITE_STATE_SERVICE_URL = `${API_PREFIX}/graphs/stategraph`;
export const GET_UPDATE_HISTORY_SERVICE_URL = '/farms/systemUpdates/';
export const FILTER_SYSTEM_ID = '&systemId=';
export const GET_PERFORMANCE_INDICATORS = `${API_PREFIX}/VectorKpis?entityId=`;

export const ApisWithFarmName = [
  /.*\/logs/gim,
  /.*\/settings/gim,
  /.*\/components/gim,
  /.*\/(VectorDataExplorer|VectorKpis)\?entityId/gim,
  /.*\/astronautUpdates/gim,
  /.*\/graphs\/stategraph/gim,
  /.*\/entities\/.*\/(?:info|state)/gim,
  /.*\/events(?:\/details|\?entityId=)/gim,
  /.*\/events(?:\/context|\?entityId=)/gim,
  /.*\/\boverview\b\/(?:timeline|alarmsStats|kpi)/gim,
  /.*\/datavisualization\/(?:events|config|\?graphNames)/gim,
  /.*\/farms\//gim,
];

export const ApisWithoutAccessToken = [
  '/users/login',
  '/users/refresh',
  'latest_application_version',
];

export const ApisWithoutRefreshing = [
  ...ApisWithoutAccessToken,
  '/users/logout',
];
