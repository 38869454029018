import {
  GET_CHARTS_CONFIGURATION_REQUEST,
  GET_CHARTS_CONFIGURATION_REQUEST_SUCCESS,
  GET_CHARTS_CONFIGURATION_REQUEST_ERROR,
  GET_CHARTS_LINKED_CONFIGURATION_REQUEST_SUCCESS,
} from '../types/dataChartTypes';

/** Get data charts config file.
 * @param {any} entityId - entity ID
 */
export function getDataChartConfigFile(entityId) {
  return {
    type: GET_CHARTS_CONFIGURATION_REQUEST,
    payload: {
      entityId,
    },
  };
}

/** Set data config file to store.
 * @param {Array} charts - All charts.
 */
export function getDataChartConfigFileSuccess(charts) {
  return {
    type: GET_CHARTS_CONFIGURATION_REQUEST_SUCCESS,
    payload: {
      charts,
    },
  };
}

/** Set data config file to store.
 * @param {Array} charts - All charts.
 */
export function getDataChartLinkedConfigFileSuccess(linkedCharts) {
  return {
    type: GET_CHARTS_LINKED_CONFIGURATION_REQUEST_SUCCESS,
    payload: {
      linkedCharts,
    },
  };
}

/** Get error message if there are no farms.
 * @param {String} errorMessage - Error message.
 */
export function getDataChartConfigFileError(errorMessage) {
  return {
    type: GET_CHARTS_CONFIGURATION_REQUEST_ERROR,
    payload: errorMessage,
  };
}
