import { put, take, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { Credentials } from './types';
import {
  setStartLoading,
  setEndLoading,
} from '../../common/redux/loadManager/actions';
import { setPreferredLanguage } from '../../common/components/Translation/actions';
import { getLoadId } from '../../common/redux/loadManager/methods';
import { LOCAL_STORAGE_KEYS } from '../../common/constants';
import { AuthResponse, LoginApi } from '../../api/lelyBackend/loginApi';
import {
  loginUser,
  setLoginSuccess,
  setLoginError,
} from '../../reducers/userSessionSlice';

let currentLoadId = 0;

export default function* loginSaga(api: LoginApi) {
  while (true) {
    const loginRequest: PayloadAction<Credentials> = yield take(loginUser);
    if (loginRequest.payload) {
      currentLoadId = getLoadId();
      yield put(setStartLoading(currentLoadId));
      const { username, encodedPassword } = loginRequest.payload;
      yield call(authorizeUser, api, username, encodedPassword);
    }
  }
}

function* authorizeUser(api: LoginApi, username: string, password: string) {
  let response: AxiosResponse<AuthResponse>;
  try {
    response = yield call(api.login, username, password);
    const { accessToken, refreshToken, userRoles, userLanguage } =
      response.data;
    yield put(
      setLoginSuccess({ username, accessToken, refreshToken, userRoles }),
    );
    yield put(setPreferredLanguage(userLanguage || 'en'));
    localStorage.setObject(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, accessToken);
    /** Update state when server is online */
    /** Stop loader */
    yield put(setEndLoading(currentLoadId));
  } catch (error: any) {
    yield put(setLoginError(error));
    yield put(setEndLoading(currentLoadId));
  }
}
