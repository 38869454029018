import {
  GET_ALARMS_FOR_ENTITY_OVERVIEW_REQUEST,
  GET_ALARMS_FOR_ENTITY_OVERVIEW_REQUEST_SUCCESS,
  GET_ALARMS_FOR_ENTITY_OVERVIEW_REQUEST_ERROR,
  CLEAR_ALARMS_FOR_ENTITY_OVERVIEW,
} from './types';

/** Create action which calls the get alarms for entity overview api.
 * @param {Object} entityId - Id of the entity
 * @return {Object} Return type and selectedEntity
 */
export function getAlarmsForEntityOverview(entityId, toDate) {
  return {
    type: GET_ALARMS_FOR_ENTITY_OVERVIEW_REQUEST,
    payload: { entityId, toDate },
  };
}

/** Create action which get alarms for entity overview data
 *  after successful request is made
 * @param {Object} alarmsForEntityOverviewDetails
 * @return {Object} action
 */
export function getAlarmsForEntityOverviewSuccess(
  alarmsForEntityOverviewDetails,
) {
  return {
    type: GET_ALARMS_FOR_ENTITY_OVERVIEW_REQUEST_SUCCESS,
    payload: { alarmsForEntityOverviewDetails },
  };
}

/** Create action which get errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function getAlarmsForEntityOverviewError(errorMessage) {
  return {
    type: GET_ALARMS_FOR_ENTITY_OVERVIEW_REQUEST_ERROR,
    payload: errorMessage,
  };
}

/** Create action which clears details for alarm data for entity overview.
 */
export function clearAlarmOverviewDetails() {
  return {
    type: CLEAR_ALARMS_FOR_ENTITY_OVERVIEW,
    payload: {},
  };
}
