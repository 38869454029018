import cloneDeep from 'lodash/cloneDeep';
import { createSlice } from '@reduxjs/toolkit';

export const eventLevelsInitialState = {
  eventLevels: [],
  errorMessage: '',
  detailedEventLevels: {},
};

const eventDropdownLevelSlice = createSlice({
  name: 'eventDropdownLevel',
  initialState: eventLevelsInitialState,
  reducers: {
    getEventDropdownLevelSuccess: (state, action) => {
      const eventLevelsSortedByOrder = cloneDeep(
        action.payload.eventDropdownLevelData,
      );
      function sortEventsByOrder(a, b) {
        if (a.order > b.order) {
          return -1;
        }
        if (a.order < b.order) {
          return 1;
        }
        return 0;
      }
      eventLevelsSortedByOrder.sort(sortEventsByOrder);
      const sortedEventLevelNames = eventLevelsSortedByOrder.map(
        (currentEventLevel) => {
          return currentEventLevel.name;
        },
      );
      sortedEventLevelNames.unshift('SHOW ALL');
      state.eventLevels = sortedEventLevelNames;
      state.detailedEventLevels = action.payload.eventDropdownLevelData;
      state.errorMessage = '';
    },
    getEventDropdownLevelError: (state, action) => {
      state.errorMessage = action.payload.message;
      state.eventLevels = [];
    },
  },
});

export const { getEventDropdownLevelSuccess, getEventDropdownLevelError } =
  eventDropdownLevelSlice.actions;

export default eventDropdownLevelSlice.reducer;
