import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import merge from 'lodash/merge';
import { DispenseWeight, FGG } from '../common/types';
import { SettingsWithMembers } from '../components/Vector/types/vectorDataChartTypes';
import { padNumberWithZero } from '../components/Vector/util';
import { setFeedProperties } from './appliedPropertiesSlice';
import { RootState } from './rootReducer';
import { setVectorDataChart } from './vectorDataChartSlice';

// initialState
export type FencesStateInitialState = {
  hovered: string | null;
  selected: Record<string, string[]> | null;
  currentChartName: string;
};

export const initialState: FencesStateInitialState = {
  hovered: null,
  selected: null,
  currentChartName: '',
};

// createSlice
export const fencesStateSlice = createSlice({
  name: 'fencesState',
  initialState,
  reducers: {
    setHoveredFence: (
      state,
      { payload: hoveredFence }: PayloadAction<string | null>,
    ) => {
      if (!hoveredFence) {
        state.hovered = null;
      } else if (state.hovered !== hoveredFence) {
        state.hovered = hoveredFence;
      }
    },
    setSelectedFences: (
      state,
      action: PayloadAction<Record<string, string[]>>,
    ) => {
      state.selected = action.payload;
    },
    setCurrentChartName: (state, { payload }: PayloadAction<string>) => {
      state.currentChartName = payload;
    },
    resetFencesState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      setFeedProperties,
      (state, { payload: appliedFeedProps }) => {
        const selectedFields: string[] = [];

        if (state.currentChartName) {
          let fenceMembers = appliedFeedProps[state.currentChartName].members;

          if (state.currentChartName === 'DosingMfr1') {
            fenceMembers = merge(
              appliedFeedProps['DosingMfr1'].members,
              appliedFeedProps['DosingMfr2'].members,
            );
          }

          for (const [, value] of Object.entries(fenceMembers)) {
            if (value.selected) {
              selectedFields.push(value.id);
            }
          }

          if (!state.selected) {
            state.selected = { [state.currentChartName]: selectedFields };
          } else {
            if (
              state.currentChartName === FGG ||
              state.currentChartName === DispenseWeight
            ) {
              state.selected[state.currentChartName] = selectedFields;
              return;
            }

            const allTimeSelectedExtraction: string[] = [];
            if (state.selected && state.selected[state.currentChartName]) {
              state.selected[state.currentChartName].forEach(
                (member: string) => {
                  if (
                    !Object.values(
                      appliedFeedProps[state.currentChartName].members,
                    ).find((prop) => prop.id === member)
                  ) {
                    allTimeSelectedExtraction.push(member);
                  }
                },
              );
            }

            state.selected[state.currentChartName] = [
              ...selectedFields,
              ...allTimeSelectedExtraction,
            ];
          }
        }
      },
    );

    builder.addCase(setVectorDataChart, (state, action: PayloadAction<any>) => {
      const fgGrabsChartName = FGG;
      const otherSettings: SettingsWithMembers<string> =
        action.payload.chartData.settings.otherSettings;

      // https://stackoverflow.com/questions/33351816/how-to-prevent-automatic-sort-of-object-numeric-property
      const sortingKeyString = 'id-';

      if (!state.selected) {
        state.selected = {};

        const settleSelected = (chartName) => {
          const { members } = otherSettings[chartName];
          const sortedMembersNames: typeof members = Object.keys(members)
            .sort((a, b) => {
              const realA = members[a];
              const realB = members[b];
              const { aValue, bValue } = padNumberWithZero(realA, realB);
              return aValue.toLowerCase().localeCompare(bValue.toLowerCase());
            })
            .reduce((obj, key) => {
              obj[`${sortingKeyString}${key}`] = members[key];
              return obj;
            }, {});

          state.selected![chartName] =
            Object.keys(sortedMembersNames).length > 0
              ? [Object.keys(sortedMembersNames)[0].split('id-')[1]]
              : ([] as string[]);
        };

        if (otherSettings[fgGrabsChartName]) {
          settleSelected(fgGrabsChartName);
        }
        if (otherSettings[DispenseWeight]) {
          settleSelected(DispenseWeight);
        }
      }
    });
  },
});

export const {
  resetFencesState,
  setHoveredFence,
  setSelectedFences,
  setCurrentChartName,
} = fencesStateSlice.actions;

// selectors

const persistentEmptyArr = [];

export const fencesStateSelectors = {
  selectHoveredFence: (state: RootState) => state.fencesState.hovered,
  selectActiveFences: (state: RootState) => {
    if (
      state.fencesState.selected &&
      state.fencesState.selected[state.fencesState.currentChartName]
    ) {
      return state.fencesState.selected[state.fencesState.currentChartName];
    }

    return persistentEmptyArr;
  },
};

export default fencesStateSlice.reducer;
