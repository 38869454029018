import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';

export const initialState = {
  graphElementsData: {
    'Inactive lines': { iconClassName: 'lely-icon-graph', isChecked: true },
    'Data points': { iconClassName: 'lely-icon-data-points', isChecked: true },
    'Horizontal grid': {
      iconClassName: 'lely-icon-menu',
      isChecked: true,
    },
    'Vertical grid': {
      iconClassName: 'lely-icon-list',
      isChecked: true,
    },
  },
  areInactiveLinesVisible: true,
  isHorizontalGridOn: true,
  isVerticalGridOn: true,
  areDotsOn: true,
};

const vectorGraphElementsSlice = createSlice({
  name: 'vectorGraphElements',
  initialState,
  reducers: {
    setVectorGraphElementsData(state, action) {
      state.graphElementsData = action.payload;
    },
    setIsVectorHorizontalGridOn(state, action) {
      state.isHorizontalGridOn = action.payload;
      state.graphElementsData['Horizontal grid'].isChecked = action.payload;
    },
    setIsVectorVerticalGridOn(state, action) {
      state.isVerticalGridOn = action.payload;
      state.graphElementsData['Vertical grid'].isChecked = action.payload;
    },
    setAreVectorDotsOn(state, action) {
      state.areDotsOn = action.payload;
      state.graphElementsData['Data points'].isChecked = action.payload;
    },
    setAreInactiveLinesVisible(state, action) {
      state.areInactiveLinesVisible = action.payload;
      state.graphElementsData['Inactive lines'].isChecked = action.payload;
    },
  },
});

export const {
  setVectorGraphElementsData,
  setIsVectorHorizontalGridOn,
  setIsVectorVerticalGridOn,
  setAreVectorDotsOn,
  setAreInactiveLinesVisible,
} = vectorGraphElementsSlice.actions;

export const vectorGraphElementsSelectors = {
  selectGraphElementsData: (state: RootState) => state.vectorGraphElements,
  selectIsHorizontalGridOn: (state: RootState) =>
    state.vectorGraphElements.isHorizontalGridOn,
  selectIsVerticalGridOn: (state: RootState) =>
    state.vectorGraphElements.isVerticalGridOn,
  selectAreDotsOn: (state: RootState) => state.vectorGraphElements.areDotsOn,
  selectAreInactiveLinesVisible: (state: RootState) =>
    state.vectorGraphElements.areInactiveLinesVisible,
};
export default vectorGraphElementsSlice.reducer;
