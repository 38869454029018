import React, { useState } from 'react';
import './LoginForm.scss';
import { useDispatch } from 'react-redux';
import Paper from '@mui/material/Paper/Paper';
import Typography from '@mui/material/Typography/Typography';
import Container from '@mui/material/Container/Container';
import TextField from '@mui/material/TextField/TextField';
import FormControl from '@mui/material/FormControl/FormControl';
import InputLabel from '@mui/material/InputLabel/InputLabel';
import FilledInput from '@mui/material/FilledInput/FilledInput';
import InputAdornment from '@mui/material/InputAdornment/InputAdornment';
import IconButton from '@mui/material/IconButton/IconButton';
import Button from '@mui/material/Button/Button';
import { useMsal } from '@azure/msal-react';
import { ReactComponent as LelyLogo } from './logo-lely.svg';
import { ReactComponent as AzureLogo } from './logo-azure.svg';
import { loginUser } from '../../reducers/userSessionSlice';
import { useAppSelector } from '../../reducers/rootReducer';

function LoginForm() {
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isAzureEnabled] = useState(true);
  const { loginError } = useAppSelector((state) => state.userSession);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const onChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setUsername(value);
  };

  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setPassword(value);
  };

  const onSubmitCredentials = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const cyrillicPattern = /^[\u0400-\u04FF]+$/;
    const checkedPassword = password
      .split('')
      .map((el) => {
        if (el.match(cyrillicPattern)) {
          return encodeURI(el);
        }
        return el;
      })
      .join('');
    const encodedPassword = btoa(checkedPassword);
    dispatch(loginUser({ username, encodedPassword }));
  };

  const { instance } = useMsal();
  const handleLogin = () => {
    instance.loginRedirect({
      scopes: [''],
    });
  };

  const isLoginButtonEnabled = username.length > 0 && password.length > 0;

  let errorMessageComponent;
  if (loginError) {
    const isAccountLocked =
      loginError.message && loginError.message.indexOf('403') !== -1;
    if (isAccountLocked) {
      errorMessageComponent = (
        <Paper>
          <Typography component="p">
            User account locked due to 5 unsuccessful login attempts.
          </Typography>
        </Paper>
      );
    } else {
      errorMessageComponent = (
        <Paper>
          <Typography component="p">Incorrect username/password.</Typography>
        </Paper>
      );
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <form onSubmit={onSubmitCredentials}>
        <div className="logo-container">
          <LelyLogo />
        </div>
        <div className="title fs--b-title">
          Welcome to Lely Technician Diagnostic Assistant
        </div>
        <div className="header">Log in with ADFS</div>
        <TextField
          id="username"
          inputProps={{ 'data-testid': 'txt-username' }}
          label="Email address"
          value={username}
          onChange={onChangeUsername}
          margin="normal"
          variant="filled"
          fullWidth
        />
        <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
          <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
          <FilledInput
            id="password"
            inputProps={{ 'data-testid': 'txt-password' }}
            value={password}
            type={showPassword ? 'text' : 'password'}
            onChange={onChangePassword}
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {!showPassword ? (
                    <i className="lely-icon-hide" />
                  ) : (
                    <i className="lely-icon-show" />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Button
          disabled={!isLoginButtonEnabled}
          data-testid="btn-login"
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className="btn-login fs--b-base"
        >
          Log in
        </Button>
        {errorMessageComponent}
      </form>
      {isAzureEnabled && (
        <>
          <div className="delimeter">or</div>
          <Button
            data-testid="btn-login"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="btn-login azure-login"
            onClick={handleLogin}
          >
            <AzureLogo />
            Log in with Azure AD
          </Button>
        </>
      )}
    </Container>
  );
}

export default LoginForm;
