import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { DATE_RANGE_DAY } from '../components/DataCharts/RangesLogic';
import { RootState } from './rootReducer';
import { Nullable } from '../common/types';
import type {
  AstronautPropertySelectInitialState,
  FilteredData,
  KeyToLabelDictionary,
  LinkedFilteredData,
  SelectedPropertyValidity,
} from '../components/AstronautDataExplorer/AstronautFilterMenu/types';
import {
  filterActiveEvents,
  getCheckboxesState,
} from '../common/components/PropertySelect/propertySelectUtil';
import { DatePickerRange } from '../components/AstronautDataExplorer/types';

export const astronautPropertySelectInitialState: AstronautPropertySelectInitialState =
  {
    selectedProperties: {
      Coordinator: '1',
      'Cow Handling': '1',
      Milking: '2',
      'Fluids Destination': '2',
    },
    latestAppliedProperties: {
      Coordinator: '1',
      'Cow Handling': '1',
      Milking: '2',
      'Fluids Destination': '2',
    },
    latestGeneralEventsProperties: null,
    dataKeyToLabelDictionary: null,
    nameToDataKeyDictionary: null,
    areSelectedPropertiesValid: {
      valid: true,
      selectionError: '',
    },
    filteredData: null,
    linkedFilteredData: null,
    timeRange: DATE_RANGE_DAY,
    latestAdvancedEventsProperties: null,
    disabledFields: null,
    shouldShowErrorMessage: false,
  };

const astronautPropertySelectSlice = createSlice({
  name: 'astronautPropertySelect',
  initialState: astronautPropertySelectInitialState,
  reducers: {
    setAstronautSelectedProperties(
      state,
      action: PayloadAction<Nullable<Record<string, string>>>,
    ) {
      state.selectedProperties = action.payload;
    },

    setAstronautLatestAppliedProperties(
      state,
      action: PayloadAction<Nullable<Record<string, string>>>,
    ) {
      state.latestAppliedProperties = action.payload;
    },
    setAstronautLatestGeneralEventsProperties(
      state,
      action: PayloadAction<
        AstronautPropertySelectInitialState['latestGeneralEventsProperties']
      >,
    ) {
      state.latestGeneralEventsProperties = action.payload;
    },
    setAstronautLatestAdvancedEventsProperties(
      state,
      action: PayloadAction<
        AstronautPropertySelectInitialState['latestAdvancedEventsProperties']
      >,
    ) {
      state.latestAdvancedEventsProperties = action.payload;
    },
    setDataKeyToLabelDictionary(
      state,
      action: PayloadAction<KeyToLabelDictionary>,
    ) {
      state.dataKeyToLabelDictionary = action.payload;
    },
    setNameToDataKeyDictionary(
      state,
      action: PayloadAction<Nullable<Record<string, string>>>,
    ) {
      state.nameToDataKeyDictionary = action.payload;
    },
    setAstronautFilteredData(
      state,
      action: PayloadAction<Nullable<FilteredData[]>>,
    ) {
      state.filteredData = action.payload;
    },
    setAstronautLinkedFilteredData(
      state,
      action: PayloadAction<Nullable<LinkedFilteredData>>,
    ) {
      state.linkedFilteredData = action.payload;
    },
    setAreSelectedPropertiesValid(
      state,
      action: PayloadAction<SelectedPropertyValidity>,
    ) {
      state.areSelectedPropertiesValid = action.payload;
    },
    setTimeRange(state, action: PayloadAction<DatePickerRange>) {
      state.timeRange = action.payload;
    },
    setDisabledFields(state, action: PayloadAction<Nullable<string[]>>) {
      state.disabledFields = action.payload;
    },
    setShouldShowErrorMessage(state, action: PayloadAction<boolean>) {
      state.shouldShowErrorMessage = action.payload;
    },
    resetAstronautPropertiesSelection: () =>
      astronautPropertySelectInitialState,
  },
});

export const {
  setAstronautSelectedProperties,
  setAstronautLatestAppliedProperties,
  setAstronautLatestGeneralEventsProperties,
  setAstronautLatestAdvancedEventsProperties,
  setDataKeyToLabelDictionary,
  setNameToDataKeyDictionary,
  setAstronautFilteredData,
  setAstronautLinkedFilteredData,
  setAreSelectedPropertiesValid,
  setTimeRange,
  resetAstronautPropertiesSelection,
  setDisabledFields,
  setShouldShowErrorMessage,
} = astronautPropertySelectSlice.actions;

export const getAstronautSelectedProperties = (state: RootState) =>
  state.astronautPropertySelect.selectedProperties;

export const getAstronautLatestAppliedProperties = (state: RootState) =>
  state.astronautPropertySelect.latestAppliedProperties;

export const getAstronautLatestGeneralEventsProperties = (state: RootState) =>
  state.astronautPropertySelect.latestGeneralEventsProperties;
export const getAstronautLatestAdvancedEventsProperties = (state: RootState) =>
  state.astronautPropertySelect.latestAdvancedEventsProperties;
export const getAstronautDataKeyToLabelDictionary = (state: RootState) =>
  state.astronautPropertySelect.dataKeyToLabelDictionary;
export const getAstronautFilteredData = (state: RootState) =>
  state.astronautPropertySelect.filteredData;
export const getAstronautLinkedFilteredData = (state: RootState) =>
  state.astronautPropertySelect.linkedFilteredData;
export const getAreSelectedPropertiesValid = (state: RootState) =>
  state.astronautPropertySelect.areSelectedPropertiesValid;
export const getAstronautNameToDataKeyDictionary = (state: RootState) =>
  state.astronautPropertySelect.nameToDataKeyDictionary;
export const getCurrentTimeRange = (state: RootState) =>
  state.astronautPropertySelect.timeRange;
export const getDisabledFields = (state: RootState) =>
  state.astronautPropertySelect.disabledFields;
export const getShouldShowErrorMessage = (state: RootState) =>
  state.astronautPropertySelect.shouldShowErrorMessage;
export const selectFieldAvailability = (
  state: RootState,
  dayDisabled: boolean,
) => state.astronautPropertySelect.timeRange === DATE_RANGE_DAY && dayDisabled;
export default astronautPropertySelectSlice.reducer;

export const selectedEventsStrings = createSelector(
  [
    getAstronautLatestGeneralEventsProperties,
    getAstronautLatestAdvancedEventsProperties,
  ],
  (generalEvents, advancedEvents) => {
    const generalSelectedKeys = !generalEvents
      ? []
      : Object.keys(generalEvents).filter(
          (key) => generalEvents[key].isSelected,
        );
    const advancedSelectedKeys = !advancedEvents
      ? []
      : Object.keys(advancedEvents).filter(
          (key) => advancedEvents[key].isSelected,
        );
    return [...advancedSelectedKeys, ...generalSelectedKeys];
  },
);

export const selectEventsActiveState = createSelector(
  [getAstronautLatestGeneralEventsProperties],
  (generalEvents) => {
    if (!generalEvents) {
      return {
        some: false,
        every: false,
      };
    }

    const filteredClickedEvents = filterActiveEvents(generalEvents);

    return getCheckboxesState(generalEvents, filteredClickedEvents);
  },
);

export const currentSelectedEventProperties = createSelector(
  [
    getAstronautLatestGeneralEventsProperties,
    getAstronautLatestAdvancedEventsProperties,
  ],
  (generalEvents, advancedEvents) => {
    return {
      events: generalEvents ? filterActiveEvents(generalEvents) : [],
      advancedEvents: advancedEvents ? filterActiveEvents(advancedEvents) : [],
    };
  },
);

export const selectAdvancedEventsActiveState = createSelector(
  [getAstronautLatestAdvancedEventsProperties],
  (advancedGeneralEvents) => {
    if (!advancedGeneralEvents) {
      return {
        some: false,
        every: false,
      };
    }

    const filteredClickedEvents = filterActiveEvents(advancedGeneralEvents);

    return getCheckboxesState(advancedGeneralEvents, filteredClickedEvents);
  },
);

export const selectIsFilterLoaded = createSelector(
  [
    getAstronautLatestGeneralEventsProperties,
    getAstronautLatestAdvancedEventsProperties,
    (state: RootState) => state.dataExplorerLoadManager.areAllChartsLoaded,
  ],
  (generalEvents, advancedEvents, chartsLoaded) => {
    return !!generalEvents && !!advancedEvents && chartsLoaded;
  },
);
