import { createAction } from '@reduxjs/toolkit';
import { RESET_STORAGE, RESET_STORAGE_WITHOUT_USER_SESSION } from '../types';

/** Create action which calls resets the contents of the application local storage
 * @return {Object} Action object
 */
export const resetApplicationStore = createAction(RESET_STORAGE);

/** Create action which calls resets the contents of the application local storage and remove everything without userSession data
 * @return {Object} Action object
 */
export const resetApplicationStoreWithoutUserSession = createAction(
  RESET_STORAGE_WITHOUT_USER_SESSION,
);
