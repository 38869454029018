import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  VectorClickedElementType,
  VectorConfigurationInitialState,
} from '../components/Vector/types/vectorDataChartTypes';
import { RootState } from './rootReducer';

// initialState

export const vectorConfigurationInitialState: VectorConfigurationInitialState =
  {
    vectorConfigurationData: {},
    isVectorConfigurationError: false,
    errorMessage: '',
    clickedElementType: null,
    vectorChartLoaded: false,
  };

export const vectorConfiguration = createSlice({
  name: 'vectorConfiguration',
  initialState: vectorConfigurationInitialState,
  reducers: {
    getVectorConfigurationSuccess: (state, action: PayloadAction<any>) => {
      state.vectorConfigurationData = action.payload;
      state.isVectorConfigurationError = false;
      state.errorMessage = '';
    },
    setVectorClickedItemType: (
      state,
      action: PayloadAction<VectorClickedElementType | null>,
    ) => {
      state.clickedElementType = action.payload;
    },
    getVectorConfigurationError: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
      state.isVectorConfigurationError = true;
    },
    setVectorChartLoaded: (state, action: PayloadAction<boolean>) => {
      state.vectorChartLoaded = action.payload;
    },
    resetVectorConfiguration: () => vectorConfigurationInitialState,
  },
});

export const {
  setVectorClickedItemType,
  getVectorConfigurationSuccess,
  getVectorConfigurationError,
  setVectorChartLoaded,
  resetVectorConfiguration,
} = vectorConfiguration.actions;

export const getVectorConfiguration = createAction<{ externalId: string }>(
  'GET_VECTOR_CHARTS_CONFIGURATION_REQUEST',
);

// selectors
export const vectorConfigurationSelectors = {
  selectClickedElementType: (state: RootState) =>
    state.vectorConfiguration.clickedElementType,
  isClickedElement: (
    state: RootState,
    element: VectorClickedElementType | undefined,
  ) => {
    return element
      ? state.vectorConfiguration.clickedElementType === element
      : true;
  },
  selectIsVectorChartLoaded: (state: RootState) =>
    state.vectorConfiguration.vectorChartLoaded,
};

export default vectorConfiguration.reducer;
