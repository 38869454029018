import isEmpty from 'lodash/isEmpty';
import axiosInstance from '../axiosInstance';
import {
  DetailsInterface,
  EventsButtonType,
} from '../../containers/EventsPage/types';
import {
  GET_EVENTS_FOR_ENTITY_SERVICE_URL,
  EVENTS_DROPDOWN_LEVELS,
  FROM_DATE_URL_APPENDIX,
  TO_DATE_URL_APPENDIX,
  FILTER_EVENT_BY_TYPE,
  EVENTS_DROPDOWN_TYPES,
  SELECTED_EVENT_DETAILS,
  FILTER_EVENT_OFFSET,
  FILTER_EVENT_LIMIT,
  EVENTS_TYPE_ORDER,
  GET_EVENTS_CONTEXT_URL,
  EVENT_ID,
} from './config';

export const api = {
  async getEventsForEntity(
    entityId: string,
    startDate: string | undefined,
    endDate: string | undefined,
    selectedTypes: string[],
    searchParam: Record<string, string | string[]> | [],
    offset: number,
    limit: number,
  ) {
    let getEventsForEntityUrl = GET_EVENTS_FOR_ENTITY_SERVICE_URL + entityId;
    const typesUrlAppendix = selectedTypes
      .map((currentType) => {
        if (currentType === 'SHOW ALL') {
          return '';
        }
        return FILTER_EVENT_BY_TYPE + currentType;
      })
      .join('');
    getEventsForEntityUrl += typesUrlAppendix;
    getEventsForEntityUrl = startDate
      ? getEventsForEntityUrl + FROM_DATE_URL_APPENDIX + startDate
      : getEventsForEntityUrl;
    getEventsForEntityUrl = endDate
      ? getEventsForEntityUrl + TO_DATE_URL_APPENDIX + endDate
      : getEventsForEntityUrl;
    getEventsForEntityUrl += FILTER_EVENT_OFFSET + offset;
    getEventsForEntityUrl += FILTER_EVENT_LIMIT + limit;

    // Add all search criteria in to the URL
    if (!isEmpty(searchParam)) {
      // Contains Free Text
      if (Array.isArray(searchParam)) {
        searchParam.forEach((freeText) => {
          getEventsForEntityUrl += `&freetext=${freeText}`;
        });
      }
      // Contains Name or Source
      else {
        for (const [key, value] of Object.entries(searchParam)) {
          getEventsForEntityUrl += `&${key}=${value}`;
        }
      }
    }
    return axiosInstance.get<any>(getEventsForEntityUrl);
  },

  async getEventsDropdownLevels() {
    return axiosInstance.get(EVENTS_DROPDOWN_LEVELS);
  },

  async getEventsDropdownTypes() {
    return axiosInstance.get(EVENTS_DROPDOWN_TYPES);
  },

  async getDetailsForSelectedEvent(entityId: string, eventData: string) {
    const eventDetailsServiceUrl = SELECTED_EVENT_DETAILS.replace(
      '{entityId}',
      entityId,
    ).replace('{eventId}', eventData);
    return axiosInstance.get<any>(eventDetailsServiceUrl);
  },

  async getMoreEvents(
    entityId: string,
    selectedTypes: string[],
    searchParam: Record<string, string | string[]> | [],
    limit: number,
    type: EventsButtonType,
    eventId: string | undefined,
  ) {
    let getEventsForEntityUrl = GET_EVENTS_CONTEXT_URL + entityId;
    const typesUrlAppendix = selectedTypes
      .map((currentType) => {
        if (currentType === 'SHOW ALL') {
          return '';
        }
        return FILTER_EVENT_BY_TYPE + currentType;
      })
      .join('');
    getEventsForEntityUrl += typesUrlAppendix;
    getEventsForEntityUrl += FILTER_EVENT_LIMIT + limit;
    getEventsForEntityUrl += EVENTS_TYPE_ORDER + type;
    getEventsForEntityUrl += EVENT_ID + eventId;

    // Add all search criteria in to the URL
    if (!isEmpty(searchParam)) {
      // Contains Free Text
      if (Array.isArray(searchParam)) {
        searchParam.forEach((freeText) => {
          getEventsForEntityUrl += `&freetext=${freeText}`;
        });
      }
      // Contains Name or Source
      else {
        for (const [key, value] of Object.entries(searchParam)) {
          if (key.includes('description')) {
            getEventsForEntityUrl += `&freetext=${value}`;
          } else {
            getEventsForEntityUrl += `&${key}=${value}`;
          }
        }
      }
    }
    return axiosInstance.get<DetailsInterface[]>(getEventsForEntityUrl);
  },
};

export type ApiType = typeof api;
