import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';
import { Nullable } from '../common/types';
import { updateSearchParams } from '../common/utils';

// initialState
export type LogsInitialState = {
  logNodes: string[];
  logsContent: Record<string, string>[];
  activeLogNode: Nullable<string>;
  logDateTime: Nullable<string>;
  selectedNodesForDownload: string[];
  selectedStartDateForDownload: Date | null;
  selectedEndDateForDownload: Date | null;
  downloadError: DownloadError | null;
  logsDownloadURL: string | null;
};

type DownloadError = {
  downloadingBlocked: boolean;
  message: string;
};

type LogContentResPayload = {
  preceding: boolean;
  content: string;
  logDateTime: string;
};

export const initialState: LogsInitialState = {
  logNodes: [],
  logsContent: [],
  activeLogNode: null,
  logDateTime: null,
  selectedNodesForDownload: [],
  selectedStartDateForDownload: null,
  selectedEndDateForDownload: null,
  downloadError: null,
  logsDownloadURL: null,
};

// createSlice
export const logsSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    setLogNodes: (state, action: PayloadAction<string[]>) => {
      state.logNodes = action.payload;
      state.activeLogNode =
        action.payload.length > 0 && !state.activeLogNode
          ? action.payload[0]
          : state.activeLogNode;

      if (!state.activeLogNode) {
        updateSearchParams('logNode', '');
      }
    },
    setActiveLogNode(state, action: PayloadAction<string | null>) {
      state.activeLogNode = action.payload;
    },
    setLogDateTimeAndResetNodes(state, action: PayloadAction<string>) {
      if (state.logDateTime === action.payload) {
        return;
      }

      state.logDateTime = action.payload;
      state.logNodes = [];
      state.logsContent = [];
    },
    setLogDateTime(state, action: PayloadAction<string | null>) {
      if (state.logDateTime === action.payload) {
        return;
      }

      state.logDateTime = action.payload;
    },
    setLogContent(state, action: PayloadAction<LogContentResPayload>) {
      const { logDateTime, preceding, content } = action.payload;
      const currentContent = [...state.logsContent];

      if (preceding) {
        currentContent.unshift({ [logDateTime]: content });
        const uniqueContent = getUniqueObjectsByKey(currentContent);
        state.logsContent = uniqueContent.slice(0, 2);
      } else {
        currentContent.push({ [logDateTime]: content });
        const uniqueContent = getUniqueObjectsByKey(currentContent);

        state.logsContent =
          uniqueContent.length > 2 ? uniqueContent.slice(1) : uniqueContent;
      }
    },
    setSelectedNodesForDownload(state, action: PayloadAction<string[]>) {
      state.selectedNodesForDownload = action.payload;
    },
    setSelectedStartDateForDownload(state, action: PayloadAction<Date | null>) {
      state.selectedStartDateForDownload = action.payload;
    },
    setSelectedEndDateForDownload(state, action: PayloadAction<Date | null>) {
      state.selectedEndDateForDownload = action.payload;
    },
    setDownloadError(state, action: PayloadAction<DownloadError | null>) {
      state.downloadError = action.payload;
    },
    setLogsDownloadURL(state, action: PayloadAction<string | null>) {
      state.logsDownloadURL = action.payload;
    },
    resetLogContent(state) {
      state.logsContent = [];
    },
    resetLogsState: () => initialState,
  },
});

export const {
  setLogNodes,
  setActiveLogNode,
  setLogDateTime,
  setLogDateTimeAndResetNodes,
  setLogContent,
  setSelectedNodesForDownload,
  setSelectedStartDateForDownload,
  setSelectedEndDateForDownload,
  setDownloadError,
  setLogsDownloadURL,
  resetLogContent,
  resetLogsState,
} = logsSlice.actions;

export const logsSelectors = {
  selectStatus: (state: RootState) => state.logs.logNodes,
  selectActiveLogNode: (state: RootState) => state.logs.activeLogNode,
  selectLogDateTime: (state: RootState) => state.logs.logDateTime,
  selectLogsContent: (state: RootState) => state.logs.logsContent,
  selectedNodesForDownload: (state: RootState) =>
    state.logs.selectedNodesForDownload,
  selectedStartDateForDownload: (state: RootState) =>
    state.logs.selectedStartDateForDownload,
  selectedEndDateForDownload: (state: RootState) =>
    state.logs.selectedEndDateForDownload,
  selectedDownloadURL: (state: RootState) => state.logs.logsDownloadURL,
  downloadError: (state: RootState) => state.logs.downloadError,
  selectLogContentStartDate: (state: RootState) => {
    const content = state.logs.logsContent;

    const result: {
      contentFirstLogDate: string | null;
      contentSecondLogDate: string | null;
    } = {
      contentFirstLogDate: null,
      contentSecondLogDate: null,
    };

    if (content.length >= 1) {
      result.contentFirstLogDate = Object.keys(content[0])[0];
    }
    if (content.length >= 2) {
      result.contentSecondLogDate = Object.keys(content[1])[0];
    }

    return result;
  },
};

export const getLogNodesAction = createAction<{
  entityId: string;
  startDate: string;
}>('logs/getLogNodes');

export const getLogContentAction = createAction<{
  entityId: string;
  logFileName: string;
  startDate: string;
  preceding: boolean;
}>('logs/getLogContent');

export const downloadLogFiles = createAction<{
  entityId: string;
  startDate: Date;
  endDate: Date;
  fileNames: string[];
}>('logs/downloadLogFiles');

function getUniqueObjectsByKey(array: Record<string, string>[]) {
  const keys = new Set(); // to remember keys we've encountered
  const result: Record<string, string>[] = []; // the unique objects we return

  for (const obj of array) {
    // Get the key of the object (assuming always one key per object)
    const key = Object.keys(obj)[0];

    if (!keys.has(key)) {
      keys.add(key);
      result.push(obj); // add first occurrence of this key to result
    }
  }

  return result;
}
export default logsSlice.reducer;
