import { useEffect } from 'react';
import { RELOAD_ON_ERROR_COUNTER } from '../constants';

function useErrorHandling() {
  const reloadFunc = (e) => {
    const reloadCounter: string | null = sessionStorage.getItem(
      RELOAD_ON_ERROR_COUNTER,
    );

    if (
      /Loading chunk [\d]+ failed/.test(e?.error?.message) ||
      e.message.includes('because its MIME type')
    ) {
      if (reloadCounter && +reloadCounter < 2) {
        sessionStorage.setItem(
          RELOAD_ON_ERROR_COUNTER,
          `${+reloadCounter + 1}`,
        );
        window.location.reload();
      } else if (!reloadCounter) {
        sessionStorage.setItem(RELOAD_ON_ERROR_COUNTER, '1');
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('error', reloadFunc);
    return () => {
      window.removeEventListener('error', reloadFunc);
    };
  }, []);
}

export default useErrorHandling;
