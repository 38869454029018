import { UserRolesType } from '../../components/LoginForm/types';

export enum SettingsFilterStringType {
  Default = 'Default',
  None = 'None',
}

export enum AdvancedSettingsEnum {
  User = 'User',
  Engineer = 'Engineer',
  None = 'None',
}

/* "keyof typeof UserRolesType" ensures that we set 
only values from UserRolesType as keys to the object */
export const advancedSettingsByRole: SettingsTypes.AdvancedSettingsByRole = {
  [UserRolesType.User]: [],
  [UserRolesType.Engineer]: [AdvancedSettingsEnum.Engineer],
  [UserRolesType.Admin]: [AdvancedSettingsEnum.Engineer],
};

export const settingTypeToString = {
  [AdvancedSettingsEnum.Engineer]: 'Engineering',
  [AdvancedSettingsEnum.None]: 'Engineering',
};

export namespace SettingsTypes {
  export type GetEntitySettingsOptionsReqType = {
    farmAstronauts: { externalId: string; name: string }[];
  };

  export type AdvancedSettingsByRole = Record<
    keyof typeof UserRolesType,
    Exclude<AdvancedSettingsEnum, AdvancedSettingsEnum.User>[]
  >;

  export type EntitySettingType = {
    id: string;
    default: boolean;
    default_value: string;
    description: string;
    dynamicColumns?: SettingDynamicColumn[];
    settingRole: AdvancedSettingsEnum;
    engineeringName: string;
    node: string;
    unit: string;
    value: string;
    location: string;
    name: string;
  };

  export type SettingDynamicColumn = {
    columnName: string;
    value: string;
    isIdentical: boolean;
  };

  // initialState
  export type EntitySettingsInitialState = {
    entitySettings: EntitySettingType[];
    errorMessage: string;
    filterSetting: SettingsFilterStringType | string;
    searchText: string;
  };

  export type SettingsOptionsInitialState = {
    entitySettingsOptions: FarmAstronaut[];
    filteredRobotOptions: FarmAstronaut[];
    advancedSettings: AdvancedSettingsEnum[];
    errorMessage: string;
  };

  export type FarmAstronaut = {
    externalId: string;
    name: string;
  };
}
