import React from 'react';
import { MOPINION_FORM_ID } from '../../customHooks/useMopinion';
import SideBarButton from './SideBarButton';
import { useAppSelector } from '../../../reducers/rootReducer';

interface CustomWindow extends Window {
  srv?: any; // Define the srv property as optional
}

declare let window: CustomWindow;

function DownButtons() {
  const { preferredLanguage } = useAppSelector((state) => state.translate);
  const helpButtonText = preferredLanguage === 'en' ? 'Help' : 'Hulp';

  const onClickHandler = () => {
    if (window.srv) {
      window.srv.openModal(true, MOPINION_FORM_ID);
    }
  };

  return (
    <div className="down bordertop">
      <SideBarButton
        link="https://lely.rightanswers.com/portal/app/portlets/results/viewsolution.jsp?solutionid=231016085348207"
        iconClassName="lely-icon-help"
        menuOptionTitle={helpButtonText}
        navigateTo={false}
        translation={false}
      />
      <SideBarButton
        iconClassName="lely-icon-chat-bubble"
        onClickFunction={onClickHandler}
        menuOptionTitle="Feedback"
        translation={false}
        navigateTo={false}
      />
    </div>
  );
}

export default DownButtons;
