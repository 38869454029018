import { put, take, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { SettingsWithMembers } from '../types/vectorDataChartTypes';
import {
  setStartLoading,
  setEndLoading,
} from '../../../common/redux/loadManager/actions';
import { getLoadId } from '../../../common/redux/loadManager/methods';
import {
  fetchVectorChartData,
  resetVectorDataChart,
  setVectorDataChart,
  setVectorDataChartError,
} from '../../../reducers/vectorDataChartSlice';
import {
  getDispensingMembers,
  getFeedGrabberGrabsMembers,
  populateNamesFromChartData,
} from '../util';
import {
  VectorDataChartResponse,
  VectorDataExtendedResponse,
} from '../../../reducers/types/vectorDataTypes';
import { DataChartsApiType } from '../../../api/lelyBackend/dataChartsApi';

let currentLoadId = 0;

export default function* vectorDataChartSaga(api) {
  while (true) {
    const getVectorDataChartRequestedAction: ReturnType<
      typeof fetchVectorChartData
    > = yield take(fetchVectorChartData.type);
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));
    yield put(resetVectorDataChart());
    const { entityId, startDate, endDate } =
      getVectorDataChartRequestedAction.payload;

    yield call(getVectorDataChart, api, entityId, startDate, endDate);
  }
}

function* getVectorDataChart(
  api: DataChartsApiType,
  entityId: string,
  startDate: string,
  endDate: string,
) {
  try {
    const vectorChartData: AxiosResponse<VectorDataChartResponse> = yield call(
      api.getVectorDataForCharts,
      entityId,
      startDate,
      endDate,
    );

    const { data: chartData } = vectorChartData;

    const { rationNames, fenceNames, feedNames } =
      populateNamesFromChartData(chartData);

    const vectorFeedGrabberData = getFeedGrabberGrabsMembers(
      chartData.fgActivities,
      feedNames,
    );

    const vectorDispenseWeightData = getDispensingMembers(
      chartData.pdbActivities,
      feedNames,
    );

    const vectorOtherSettingData: SettingsWithMembers<string | number> = {
      'Feed height': {
        members: {
          ...fenceNames,
        },
      },
      Accuracy: {
        members: {
          ...feedNames,
        },
      },
      DosingMfr1: {
        members: {
          ...rationNames,
        },
      },
      DosingMfr2: {
        members: {
          ...rationNames,
        },
      },
      'Feed grabber grabs': {
        icon: 'lely-icon-grabber',
        members: {
          ...vectorFeedGrabberData,
        },
      },
      'Dispense weight': {
        icon: 'lely-icon-pdb',
        members: {
          ...vectorDispenseWeightData,
        },
      },
    };

    const extendedChartData: VectorDataExtendedResponse = {
      chartData: {
        ...chartData,
        settings: {
          ...chartData.settings,
          otherSettings: {
            ...vectorOtherSettingData,
          },
        },
      },
      startDate,
      endDate,
    };

    yield put(setVectorDataChart(extendedChartData));
    yield put(setEndLoading(currentLoadId));
  } catch (e: any) {
    yield put(setVectorDataChartError(e));
    yield put(setEndLoading(currentLoadId));
  }
}
