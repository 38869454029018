import axiosInstance from '../axiosInstance';
import {
  ENTITY_ID_URL_APPENDIX,
  FROM_DATE_URL_APPENDIX,
  TO_DATE_URL_APPENDIX,
  GET_COMPOSITE_STATE_SERVICE_URL,
} from './config';

const composeUrl = (
  state: 'milking' | 'cleaning',
  entityId: string,
  startDate: string,
  endDate: string,
) => {
  let compositeStateServiceUrl = `${GET_COMPOSITE_STATE_SERVICE_URL}/${state}?`;
  compositeStateServiceUrl += ENTITY_ID_URL_APPENDIX + entityId;
  compositeStateServiceUrl +=
    FROM_DATE_URL_APPENDIX + startDate + TO_DATE_URL_APPENDIX + endDate;
  return compositeStateServiceUrl;
};

export const api = {
  async getCompositeStateDetails(
    stateName: 'milking' | 'cleaning',
    entityId: string,
    startDate: string,
    endDate: string,
  ) {
    const requestUrl = composeUrl(stateName, entityId, startDate, endDate);
    return axiosInstance.get(requestUrl);
  },
};

export type CompositeStateGraphApiType = typeof api;
