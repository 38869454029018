/* eslint-disable no-console */
import { call, put, take } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { getLogNodesAction, setLogNodes } from '../../reducers/logsSlice';
import { LogsApiType } from '../../api/lelyBackend/logsApi';
import { AwaitedApiResultType } from '../../common/types';

export default function* logsFilterMenuSaga(api: LogsApiType) {
  while (true) {
    const logNodesAction: PayloadAction<{
      entityId: string;
      startDate: string;
    }> = yield take(getLogNodesAction.type);

    if (logNodesAction.payload) {
      const { entityId, startDate } = logNodesAction.payload;
      yield call(getLogsMenuOptions, api, entityId, startDate);
    }
  }
}

function* getLogsMenuOptions(
  api: LogsApiType,
  entityId: string,
  logDateTime: string,
) {
  try {
    const logNodes: AwaitedApiResultType<typeof api.getLogNodes> = yield call(
      api.getLogNodes,
      entityId,
      logDateTime,
    );

    yield put(setLogNodes(logNodes.data));
  } catch (error) {
    console.error('Error while fetching logs menu options:', error);
  }
}
