import { put, take, call } from 'redux-saga/effects';
import { GET_ENTITY_INFO_REQUEST } from './types';
import { getEntityInfoError, getEntityInfoSuccess } from './actions';
import {
  setStartLoading,
  setEndLoading,
} from '../../redux/loadManager/actions';
import { getLoadId } from '../../redux/loadManager/methods';

let currentLoadId = 0;

export default function* entityInformationSaga(api) {
  while (true) {
    const entityInformationAction = yield take(GET_ENTITY_INFO_REQUEST);
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));
    const { entityId } = entityInformationAction.payload;
    yield call(getEntityInformation, api, entityId);
  }
}

function* getEntityInformation(api, entityId) {
  try {
    const entityInfoData = yield call(api.getEntityInfo, entityId);
    yield put(
      getEntityInfoSuccess({
        entityInfoData: entityInfoData.data,
      }),
    );
    yield put(setEndLoading(currentLoadId));
  } catch (error) {
    yield put(getEntityInfoError(error));
    yield put(setEndLoading(currentLoadId));
  }
}
