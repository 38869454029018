import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';

// initialState
type InterceptorsInitialState = {
  status: 'initialized' | 'not-initialized';
};

export const initialState: InterceptorsInitialState = {
  status: 'not-initialized',
};

// createSlice
export const interceptorsSlice = createSlice({
  name: 'interceptors',
  initialState,
  reducers: {
    setInterceptorsStatus: (
      state,
      action: PayloadAction<'initialized' | 'not-initialized'>,
    ) => {
      state.status = action.payload;
    },
  },
});

export const { setInterceptorsStatus } = interceptorsSlice.actions;

// selectors
export const selectInterceptorsStatus = (state: RootState) =>
  state.interceptors.status;

export default interceptorsSlice.reducer;
