export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const REFRESH_REQUEST = 'REFRESH_REQUEST';

export type Credentials = {
  username: string;
  encodedPassword: string;
};
export type LoginSuccess = {
  username: string;
  accessToken: string;
  refreshToken: string;
  userRoles: UserRolesType[];
};
export type Error = {
  errorMessage: string;
};

export enum UserRolesType {
  User = 'User',
  Engineer = 'Engineer',
  Admin = 'Admin',
}

export type Tokens = {
  accessToken: string;
  refreshToken: string;
};

export type UserSessionInitialState = {
  loginError: any;
  currentUser: string;
  accessToken: string;
  refreshToken: string;
  isLoggedIn: boolean;
  userRoles: UserRolesType[];
  unitPreference: string;
  dateFormatPreference: string;
};
