import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { LOCAL_STORAGE_KEYS } from '../constants';
import { setLoginSuccess } from '../../reducers/userSessionSlice';
import { getUserRoles } from '../components/UserRoles/actions';

const useAzureADActiveAccountLogin = () => {
  const dispatch = useDispatch();

  const { accounts, instance } = useMsal();

  useEffect(() => {
    if (accounts.length > 0) {
      instance.setActiveAccount(accounts[0]);
      // eslint-disable-next-line no-console

      const tokenRequest = {
        account: accounts[0],
        scopes: ['User.Read'],
      };

      // Acquire an access token
      instance
        .acquireTokenSilent(tokenRequest)
        .then((response) => {
          // eslint-disable-next-line no-console
          localStorage.setObject(
            LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
            response.idToken,
          );

          dispatch(
            setLoginSuccess({
              username: response.account.username,
              accessToken: response.idToken,
              refreshToken: (response as any).refreshToken, // missing
              userRoles: [],
            }),
          );
          dispatch(getUserRoles());
        })
        .catch(async (e) => {
          // Catch interaction_required errors and call interactive method to resolve
          if (e instanceof InteractionRequiredAuthError) {
            await instance.acquireTokenRedirect(tokenRequest);
          }

          throw e;
        });
    }
  }, [accounts, instance]);
};

export default useAzureADActiveAccountLogin;
