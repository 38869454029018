import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../reducers/rootReducer';
import { checkFeatureFlag } from '../../../reducers/featureFlagSlice';
import { userSessionSelectors } from '../../../reducers/userSessionSlice';

export default function useFeatureFlag(
  flagNames: string[],
  interceptorsStatus: 'initialized' | 'not-initialized',
) {
  const dispatch = useDispatch();
  const { accessToken } = useAppSelector((state) => state.userSession);
  const currentUser = useAppSelector(userSessionSelectors.selectCurrentUser);

  useEffect(() => {
    if (interceptorsStatus === 'initialized') {
      dispatch(checkFeatureFlag({ flagNames, currentUser }));
    }
  }, [accessToken, currentUser, interceptorsStatus]);
}
