const translations = {
  // Left Menu
  OVERVIEW: 'Overzicht',
  DIAGNOSTICS: 'Diagnostiek',
  'MILKING PROCESS': 'Melk Process',
  'DATA EXPLORER': 'Data Verkenner',
  EVENTS: 'Evenementen',
  LOGS: 'Logs',
  SETTINGS: 'Instellingen',
  UPDATES: 'Updates',
  LANGUAGE: 'Taal',
  USER: 'Gebruiker',
  ONLINE: 'Online',
  OFFLINE: 'Offline',
  ROBOT: 'Robot',
  FARM: 'Boerderij',
  'LOCAL TIME': 'Lokale tijd',

  // Pages
  APPLY: 'Toepassen',

  // Languages
  NEDERLANDS: 'Nederlands',
  ENGLISH: 'English',
  FRANÇAIS: 'Français',
  DANSK: 'Dansk',
  SVENSKA: 'Svenska',
  NORSK: 'Norsk',
  SUOMI: 'Suomi',
  DEUTSCH: 'Deutsch',
  EESTI: 'Eesti',
  ITALIANO: 'Italiano',
  POLSKI: 'Polski',
  ČESKY: 'Česky',
  РУССКИЙ: 'Русский',
  LATVIEŠU: 'Latviešu',
  LIETUVIŲ: 'Lietuvių',
  TÜRKÇE: 'Türkçe',
  ÍSLENSKA: 'Íslenska',
  ESPAÑOL: 'Español',
  ΕΛΛΗΝΙΚΆ: 'Ελληνικά',
  한국어: '한국어',
  日本語: '日本語',
  PORTUGUÊS: 'Português',
  HRVATSKI: 'Hrvatski',
  ROMÂNESC: 'Românesc',
  SLOVENŠČINA: 'Slovenščina',

  // Left Menu Vector
  'VECTOR DATA EXPLORER': 'Data Verkenner',
  'VECTOR PERFORMANCE INDICATORS': 'Performance indicators',
};

export default translations;
