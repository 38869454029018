import { put, take, call } from 'redux-saga/effects';
import { GET_ENTITY_STATUS_REQUEST } from './types';
import { getEntityStatusSuccess, getEntityStatusError } from './actions';

export default function* entityStatusSaga(api) {
  while (true) {
    const entityStatusAction = yield take(GET_ENTITY_STATUS_REQUEST);
    const { entityId } = entityStatusAction.payload;
    yield call(getEntityStatus, api, entityId);
  }
}

function* getEntityStatus(api, entityId) {
  try {
    const entityStatusData = yield call(api.getEntityStatus, entityId);
    yield put(getEntityStatusSuccess(entityStatusData.data));
  } catch (error) {
    yield put(getEntityStatusError(error));
  }
}
