import {
  GET_ALARMS_FOR_ENTITY_OVERVIEW_REQUEST_SUCCESS,
  CLEAR_ALARMS_FOR_ENTITY_OVERVIEW,
} from '../common/components/TableGrid/types';
import { GET_ALARMS_DROPDOWN_LEVELS_REQUEST_ERROR } from '../common/redux/alarmDropdownLevel/types';

/** Store states
 * @constant {Object} */
export const initialState = {
  alarmOverviewDetails: {},
  errorMessage: '',
};

/** Alarm Entity Overview Details Reducer.
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function alarmEntityOverviewDetailsReducer(state = initialState, action = {}) {
  switch (action.type) {
    /** Action Creator - Set alarm entity overview details to store
     * if API request passed successfully */
    case GET_ALARMS_FOR_ENTITY_OVERVIEW_REQUEST_SUCCESS:
      return {
        ...state,
        alarmOverviewDetails: action.payload,
        errorMessage: '',
      };

    /** Action Creator - Show error message if
     * request to API failed */
    case GET_ALARMS_DROPDOWN_LEVELS_REQUEST_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        alarmOverviewDetails: {},
      };

    case CLEAR_ALARMS_FOR_ENTITY_OVERVIEW:
      return {
        ...state,
        alarmOverviewDetails: {},
        errorMessage: '',
      };
    default:
      return state;
  }
}

export default alarmEntityOverviewDetailsReducer;
